import React from "react";
import cx from "classnames";
import "./FieldSet.scss";
import { ReactNode } from "react";

interface Props {
  header: string | ReactNode;
  children: ReactNode;
  className?: string;
  headerClassName?: string;
}

export const FieldSet: React.FunctionComponent<Props> = ({
  header,
  children,
  className,
  headerClassName,
}) => {
  return (
    <div className={cx("fieldset", className)}>
      <h5 className={cx("fieldset-header", headerClassName)}>{header}</h5>
      <div className="fieldset-inner">{children}</div>
    </div>
  );
};
