import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FieldSet } from "../../../../components/fieldSet/FieldSet";
import {
  BeneficialOwner,
  BeneficialOwnerType,
  CaseStatus,
} from "../../../../data/models/caseTypes";
import {
  beneficialOwnerQueue,
  OwnerSaveType,
} from "../../../../data/queues/BeneficialOwnerQueue";
import { Status } from "../../../../data/types";
import { caseErrorState, caseSaveState } from "../../../../state/caseSaveState";
import { LinkAnchors } from "../CaseEdit";
import { CaseEditError } from "../CaseEditError";
import { CaseSaveError } from "../CasePage";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { ServerError } from "../../../../network/API";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { MemoizedBeneficialOwnerOverlay } from "./BeneficialOwnerOverlay";
import { caseBeneficialOwnerState } from "../../../../state/caseBeneficialOwnerState";
import { caseMainState } from "../../../../state/caseMainState";
import { dataOutreach } from "../../../../data/dataOutreach";
import { useCaseEditStatus } from "../../../../hooks/useCaseEditStatus";
import { LegalEntityType } from "../../../../data/models/LegalEntityTypes";
import { caseCompanyState } from "../../../../state/caseCompanyState";
import { RadioGroup } from "../../../../components/form/RadioGroup";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { BeneficialOwnerId } from "../../../../data/models/CommonTypes";
import { FormName } from "../menus/CaseEditMenu";
import { Form } from "../../../../components/form/Form";
import { T, TCondition, TDefault } from "../../../../components/translation/T";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { TransSwitch } from "../../../../components/translation/TransSwitch";
import { generatePath, useNavigate } from "react-router";
import { CASE_REVIEW_ROUTE } from "../../review/CaseReviewPage";
import { useMultiForm } from "../../../../components/form/MultiFormContext";
import { useLoadCase } from "../../../../hooks/useLoadCase";
import { caseStatusState } from "../../../../state/caseStatusState";
import { getBeneficialOwnerName } from "./BeneficialOwnerListItem";
import "./BeneficialOwners.scss";
import { WarningBox } from "../../../../components/boxes/WarningBox";

export const BeneficialOwners = () => {
  const ref = useRef<HTMLFormElement>(null);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [error, setError] = useState<CaseSaveError | null>(null);
  const setDataSaved = useSetRecoilState(caseSaveState);
  const setDataError = useSetRecoilState(caseErrorState);
  const [activeOwner, setActiveOwner] = useState<BeneficialOwner | null>(null);
  const [{ id, beneficialOwnerType, status: caseStatus }, setMainData] =
    useRecoilState(caseMainState);
  const { legalEntityType } = useRecoilValue(caseCompanyState);
  const [beneficialOwners, setBeneficialOwners] = useRecoilState(
    caseBeneficialOwnerState
  );
  const [inputStatus, disabled] = useCaseEditStatus(status);
  const { currentUser, isLegacyCase } = useRecoilValue(caseStatusState);

  const { reload: reloadCase } = useLoadCase();

  // This makes the component rerender infinitely when the case is in CLOSED
  const multiForm = useMultiForm();
  const navigate = useNavigate();

  useEffect(() => {}, [beneficialOwners]);

  const onSendToOutreach = useCallback(() => {
    if (!multiForm || multiForm.isInvalid) {
      multiForm?.forceValidation();
      return;
    }

    dataOutreach
      .send(id)
      .then(() => {
        setStatus(Status.SUCCESS);
        navigate(generatePath(CASE_REVIEW_ROUTE, { id: id }));
      })
      .catch(() => {
        setStatus(Status.ERROR);
        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [id, navigate, multiForm]);

  const onClose = useCallback(() => {
    setError(null);
    setActiveOwner(null);
  }, []);

  const saveCallback = useCallback(
    (
      err: "ONGOING_RESPONSE" | ServerError<BeneficialOwner> | null,
      response: BeneficialOwner | undefined,
      added = false
    ) => {
      if (err) {
        setStatus(Status.ERROR);
        setDataError((dataErrors) =>
          dataErrors.concat({
            date: new Date(),
            message: (
              <>
                Error when trying to save owner{" "}
                <b>Please check this owner again.</b>
              </>
            ),
          })
        );
        return;
      }

      if (!response) {
        return;
      }

      setStatus(Status.DEFAULT);

      if (added) {
        setBeneficialOwners((owners) => [...owners, response]);

        setDataSaved((dataSaved) =>
          dataSaved.concat({
            date: new Date(),
          })
        );
      }
    },
    [setBeneficialOwners, setDataError, setDataSaved]
  );

  const onRemove = useCallback(
    (requestId: BeneficialOwnerId, ownerId: BeneficialOwnerId) => {
      setStatus(Status.PENDING);
      beneficialOwnerQueue
        .deleteOwner(id, requestId)
        .then((response) => {
          setStatus(Status.DEFAULT);
          setBeneficialOwners([...response]);
          setDataSaved((dataSaved) =>
            dataSaved.concat({
              date: new Date(),
            })
          );
          const shouldReloadCase =
            caseStatus !== CaseStatus.DATA_COLLECTION && response.length === 0;

          if (shouldReloadCase) reloadCase(id, currentUser.id);
        })
        .catch(() => {
          setStatus(Status.ERROR);
          setDataError((dataErrors) =>
            dataErrors.concat({
              date: new Date(),
              message: (
                <>
                  Error when trying to save owner{" "}
                  <b>Please check this owner again.</b>
                </>
              ),
            })
          );
          return;
        });
    },
    [
      id,
      setBeneficialOwners,
      setDataError,
      setDataSaved,
      caseStatus,
      currentUser.id,
      reloadCase,
    ]
  );

  const onAdd = useCallback(() => {
    beneficialOwnerQueue.saveOwner(
      id,
      {},
      OwnerSaveType.FULL,
      (err, response) => {
        saveCallback(err, response, true);
      }
    );
  }, [id, saveCallback]);

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(onAdd, 500);
  }, [onAdd]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataOutreach.claim(id).then(onAdd).catch(onAdd); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [id, onAdd]);

  const onBeneficialOwnerTypeChange = useCallback(
    (newValue: BeneficialOwnerType) => {
      const currentValue = beneficialOwnerType;

      setMainData((data) => ({
        ...data,
        beneficialOwnerType: newValue,
      }));

      dataOutreach
        .updateBeneficialOwnerType(id, newValue)
        .then(() => {})
        .catch(() => {
          setMainData((data) => ({
            ...data,
            beneficialOwnerType: currentValue,
          }));
        });
    },
    [beneficialOwnerType, id, setMainData]
  );

  const renderAddOwnerButton = () => {
    const isSolePropWithMaxBO =
      legalEntityType === LegalEntityType.SOLE_PROPRIETARY &&
      beneficialOwners.length >= 1;

    if (isSolePropWithMaxBO) {
      return (
        <div className="m-top-10">
          <WarningBox>
            <T>
              Cannot add more beneficial owners since legal entity is of type
              "Sole proprietary"
            </T>
          </WarningBox>
        </div>
      );
    }

    if (
      caseStatus === CaseStatus.COMPLETED_VERIFICATION ||
      caseStatus === CaseStatus.CLOSED
    ) {
      return null;
    }

    // legacy cases do not allow to add more BO unless in DATA COLLECTION
    if (caseStatus !== CaseStatus.DATA_COLLECTION && isLegacyCase) {
      return null;
    }

    return (
      <Button
        onClick={onAdd}
        block
        className="m-top-20"
        status={
          beneficialOwnerType === undefined ? Status.DISABLED : inputStatus
        }
      >
        <T>Add beneficial owner</T>
      </Button>
    );
  };

  const shouldDisplaySendToOutreach = useMemo(() => {
    return caseStatus === CaseStatus.OUTREACH ||
      caseStatus === CaseStatus.VERIFICATION
      ? true
      : false;
  }, [caseStatus]);

  const fullSelectedOwners = useMemo(
    () => beneficialOwners.filter((owner) => !owner.outreachSent),
    [beneficialOwners]
  );

  return (
    <div className="beneficial-owners">
      <ScrollPositionAnchor id={LinkAnchors.OWNERS.anchor} />

      <FieldSet header={<T>{LinkAnchors.OWNERS.name}</T>}>
        <CaseEditError
          error={error}
          setError={setError}
          retry={retry}
          onClose={onClose}
          reclaimAndSave={reclaimAndSave}
        />

        <Form name={FormName.OWNER} ref={ref}>
          <RadioGroup
            name="beneficialOwnerType"
            label="Select how the beneficial owners excercise their control"
            value={beneficialOwnerType}
            onChange={onBeneficialOwnerTypeChange}
            alternatives={[
              {
                text: "By ownership",
                value: BeneficialOwnerType.OWNERSHIP,
              },
              {
                text: "By role",
                value: BeneficialOwnerType.ROLE,
              },
            ]}
            validators={[
              new RequiredValidator("Please select a type of beneficial owner"),
            ]}
            message={
              beneficialOwners.length > 0
                ? "Current owners has to be removed in order to change type"
                : ""
            }
            disabled={disabled || beneficialOwners.length > 0}
          />
        </Form>

        <div className="m-bottom-10">
          <b>
            <T>Ultimate Beneficial Owners</T>
          </b>
        </div>

        <AnimateHeight name={beneficialOwners.length.toString()}>
          <>
            {beneficialOwners.length === 0 && (
              <div className="no-owners">
                <span>
                  <TransSwitch>
                    <TCondition
                      condition={
                        beneficialOwnerType === BeneficialOwnerType.OWNERSHIP
                      }
                    >
                      Please appoint all natural persons holding MORE than 25 %
                      ownership in the company. If there is no one directly or
                      indirectly holding more 25% shares, voting rights or
                      controls of the company, then please change input to By
                      Role
                    </TCondition>
                    <TCondition
                      condition={
                        beneficialOwnerType === BeneficialOwnerType.ROLE &&
                        legalEntityType === LegalEntityType.LIMITED
                      }
                    >
                      Please appoint natural persons holding the position of
                      senior manager (e.g. chief executive officer, chief
                      financial officer, chief operational officer, or members
                      of the board of directors, etc.)
                    </TCondition>
                    <TCondition
                      condition={
                        beneficialOwnerType === BeneficialOwnerType.ROLE &&
                        legalEntityType === LegalEntityType.TRUST
                      }
                    >
                      Please appoint settlor, all trustees & fiduciaries
                      protector (if any), beneficiaries and any such other
                      natural persons exercising ultimate control over the trust
                      (by means of direct or indirect ownership or by other
                      means).
                    </TCondition>
                    <TCondition
                      condition={
                        beneficialOwnerType === BeneficialOwnerType.ROLE &&
                        (legalEntityType === LegalEntityType.ASSOCIATION ||
                          legalEntityType === LegalEntityType.OTHER)
                      }
                    >
                      please appoint a natural person holding Senior Managing
                      Official position (Chairman, President Secretary,
                      Treasurer) to complete the beneficial owner section.
                    </TCondition>
                    <TDefault>
                      Please select how the beneficial owner excercise their
                      control over the company. If there is no one exercising
                      more than 25% of the voting rights, or controls the
                      company by other means, please select to enter by Role.
                    </TDefault>
                  </TransSwitch>
                </span>
              </div>
            )}
            <div>
              {beneficialOwners.map((owner) => (
                <MemoizedBeneficialOwnerOverlay
                  owner={owner}
                  onRemove={() => onRemove(owner.id, owner.beneficialOwnerId)}
                  status={inputStatus}
                  key={owner.id}
                  initialOpen={owner.id === activeOwner?.id}
                  scrollToRef={ref}
                  beneficialOwnerType={beneficialOwnerType}
                />
              ))}
            </div>
            <Form name={FormName.OWNER}>
              <HiddenInput
                label="Owners"
                value={beneficialOwners.length < 1 ? undefined : true}
                validators={[
                  new RequiredValidator(
                    "Please add at least one beneficial owner"
                  ),
                ]}
                scrollToRef={ref}
              />
            </Form>
            {renderAddOwnerButton()}
          </>
        </AnimateHeight>
      </FieldSet>
      {shouldDisplaySendToOutreach && !isLegacyCase && (
        <div className="send-to-outreach-container">
          <div className="send-to-outreach-text">
            <TransSwitch>
              <TCondition condition={fullSelectedOwners.length > 0}>
                You will send an outreach to the following beneficial owners:
              </TCondition>
              <TDefault>Add a beneficial owner to send an outreach</TDefault>
            </TransSwitch>
          </div>
          <ul className="send-to-outreach-ul m-top-10">
            {fullSelectedOwners.map((ubo) =>
              ubo ? (
                <li key={ubo.id} className="send-to-outreach-li text-small">
                  {getBeneficialOwnerName(ubo.firstName, ubo.lastName)}
                </li>
              ) : null
            )}
          </ul>
          <div className="send-to-outreach-button">
            <Button
              onClick={onSendToOutreach}
              block
              className="m-top-30"
              status={
                fullSelectedOwners.length > 0 ? inputStatus : Status.DISABLED
              }
            >
              <T>Send outreach</T>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
