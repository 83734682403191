import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import { SuccessBox } from "../../../../components/boxes/SuccessBox";
import { ZoomableImage } from "../../../../components/images/ZoomableImage";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { ButtonPane } from "../../../../components/interactions/Buttons/ButtonPane";
import { Link } from "../../../../components/links/Link";
import { Name } from "../../../../components/name/Name";
import { T } from "../../../../components/translation/T";
import { Visible } from "../../../../components/visible/Visible";
import { dataReview } from "../../../../data/dataReview";
import { CaseId } from "../../../../data/models/CommonTypes";
import { BeneficialOwner } from "../../../../data/models/caseTypes";
import { Status } from "../../../../data/types";
import { CASE_REVIEW_ROUTE } from "../../review/CaseReviewPage";
import { ReviewRejectWrapper } from "./ReviewRejectWrapper";

export interface Props {
  owner: BeneficialOwner;
  caseId: CaseId;
  completed: () => void;
}
export const AssociateSelfieReview: React.FunctionComponent<Props> = ({
  owner,
  completed,
  caseId,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const alreadyConfirmed = !!owner.identitySignedOff;
  const [isClear, setIsClear] = useState<boolean>();
  const [isTampered, setIsTampered] = useState<boolean>();
  const { identity } = owner;

  const onReject = () => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataReview
        .rejectOwner(owner)
        .then(() => setStatus(Status.SUCCESS))
        .catch((err) => setStatus(Status.ERROR));
    }, 600);
  };

  if (!identity) {
    return null;
  }

  if (alreadyConfirmed) {
    return (
      <div className="associate-review">
        <div className="associate-review-top">
          <h2>
            <Name associate={owner} />
          </h2>
        </div>

        <div className="review-box m-top-40">
          <div className="tablet-columns">
            <div>
              <div className="associate-review-selfie">
                <ZoomableImage image={identity.selfie} alt="Selfie" />
              </div>
            </div>
            <div>
              <SuccessBox relative>
                <T>This owner has already been confirmed</T>
              </SuccessBox>

              <div className="m-top-20 center">
                <Link link={generatePath(CASE_REVIEW_ROUTE, { id: caseId })}>
                  <T>Back to review page</T>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="associate-review">
      <div className="associate-review-top">
        <h2>
          <Name associate={owner} />
        </h2>
      </div>

      <div className="review-box m-top-40">
        <div className="tablet-columns">
          <div>
            <div className="associate-review-selfie">
              <ZoomableImage image={identity.selfie} alt="Selfie" />
            </div>
          </div>
          <div>
            <h3>
              <T>Review questions</T>
            </h3>
            <ReviewRejectWrapper
              onReject={onReject}
              status={status}
              caseId={caseId}
            >
              <div className="review-question">
                <div className="review-question-text">
                  <T>Is the picture in focus and show the face clearly?</T>
                </div>
                <div className="small m-top-10">
                  <i>
                    <T>
                      The face should be looking into the camera and have
                      nothing obstructing any part of the face
                    </T>
                  </i>
                </div>
                <ButtonPane
                  onClick={(value) => setIsClear(value)}
                  className="small m-top-20"
                  status={
                    status !== Status.DEFAULT ? Status.DISABLED : Status.DEFAULT
                  }
                  buttons={[
                    {
                      text: t("Yes"),
                      active: isClear,
                      data: true,
                    },
                    {
                      text: t("No"),
                      active: isClear === false,
                      data: false,
                    },
                  ]}
                />
              </div>
              <div className="review-question">
                <div className="review-question-text">
                  <T>Is this a live selfie?</T>
                </div>
                <div className="small m-top-10">
                  <i>
                    <T>
                      The picture should not be of another picture or computer
                      screen
                    </T>
                  </i>
                </div>
                <ButtonPane
                  onClick={(value) => setIsTampered(value)}
                  className="small m-top-20"
                  status={
                    status !== Status.DEFAULT ? Status.DISABLED : Status.DEFAULT
                  }
                  buttons={[
                    {
                      text: t("Yes"),
                      active: isTampered,
                      data: true,
                    },
                    {
                      text: t("No"),
                      active: isTampered === false,
                      data: false,
                    },
                  ]}
                />
              </div>
              <div className="review-actions relative">
                <Visible show={isClear === true && isTampered === true}>
                  <Button onClick={completed} block>
                    <T>Continue</T>
                  </Button>
                </Visible>
                <Visible show={isClear === false || isTampered === false}>
                  <Button onClick={onReject} block className="danger-button">
                    <T>Reject selfie</T>
                  </Button>
                </Visible>
              </div>
            </ReviewRejectWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
