import { FaHeadset } from "react-icons/fa";
import { T } from "../translation/T";
import cx from "classnames";
import "./SupportBox.scss";

const EMAIL = "wlxglobalsupport@worldline.com";

export const SupportBox = ({ className }: { className?: string }) => {
  return (
    <div className={cx("support-box", className)}>
      <span>
        <T>Need help?</T>
        <FaHeadset size={18} />
      </span>
      <a href="mailto:wlxglobalsupport@worldline.com" className="link">
        {EMAIL}
      </a>
    </div>
  );
};
