import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { generatePath, useNavigate } from "react-router";
import { dataCompany } from "../../data/dataCompany";
import { CompanyRegistrationId } from "../../data/models/CommonTypes";
import { Status } from "../../data/types";
import i18n, { Country } from "../../i18n";
import { ServerError } from "../../network/API";
import { COMPANY_PAGE_URL } from "../../pages/company/CompanyPage";
import { AnimateHeight } from "../animate/AnimateHeight";
import { WarningBox } from "../boxes/WarningBox";
import { CountrySelector } from "../countrySelector/CountrySelector";
import { CreateCaseButton } from "../createCaseButton/CreateCaseButton";
import { Form } from "../form/Form";
import { HiddenInput } from "../form/HiddenInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { Button } from "../interactions/Buttons/Button";
import { NewOverlay } from "../overlay/NewOverlay";
import { SearchInput } from "../searchInput/SearchInput";
import { getCountryDisplayName } from "../translation/I18Utils";
import { T } from "../translation/T";
import {
  companyRegistrationIdValidators,
  countryHasRegistryMap,
} from "./companyValidators";

export function cleanNonDigits(value: string) {
  return value.replace(/\D/g, "") as CompanyRegistrationId;
}

interface Props {
  onClose: () => void;
}

const CompanySearchOverlayInner: React.FunctionComponent<Props> = ({
  onClose,
}) => {
  const { language } = i18n;
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [isNewCompany, setIsNewCompany] = useState(false);
  const [id, setId] = useState<CompanyRegistrationId>();
  const [country, setCountry] = useState<Country>();
  const navigate = useNavigate();
  const ref = React.useRef<HTMLFormElement>(null);

  const searchCompany = () => {
    if (id && country) {
      setStatus(Status.PENDING);
      dataCompany
        .getCompany(country, id)
        .then((company) => {
          if (company) {
            navigate(
              generatePath(COMPANY_PAGE_URL, {
                country,
                companyRegistrationId: cleanNonDigits(id),
              })
            );
            onClose();
          }
        })
        .catch((error: ServerError<any>) => {
          if (error.status === 404) {
            setIsNewCompany(true);
          } else {
            setStatus(Status.ERROR);

            setTimeout(() => {
              setStatus(Status.DEFAULT);
            }, 3000);
          }
        });
    }
  };

  const reset = () => {
    setIsNewCompany(false);
    setId(undefined);
    setStatus(Status.DEFAULT);
  };

  const hint =
    country && !countryHasRegistryMap[country] ? (
      <T
        id="No business registry enabled for {{ country }}"
        options={{
          country: getCountryDisplayName(country, language),
        }}
      />
    ) : undefined;

  return (
    <>
      <Form
        onSubmit={(_, ref) => {
          if (ref.isInvalid) {
            return;
          }
          searchCompany();
        }}
        ref={ref}
      >
        <h4>
          <T>Search for company</T>
        </h4>
        <AnimateHeight name={isNewCompany.toString()}>
          {isNewCompany ? (
            <div key="new">
              <WarningBox transparent>
                <T>This company has not been worked on before</T>
                <br />
                <T>Do you want to start a new case?</T>
              </WarningBox>
              {country && id && (
                <CreateCaseButton
                  className="m-top-20"
                  country={country}
                  companyRegistrationId={cleanNonDigits(id)}
                  block
                />
              )}
              <div className="m-top-20">
                <Button block onClick={reset} type="button" className="as-link">
                  <T>search for another company</T>
                </Button>
              </div>
            </div>
          ) : (
            <div key="search">
              <div className="m-bottom-20">
                <CountrySelector value={country} onChange={setCountry} />
                <HiddenInput
                  name="country"
                  value={country}
                  validators={[new RequiredValidator("Country is required")]}
                  label="Country"
                  scrollToRef={ref}
                />
              </div>
              <SearchInput
                value={id}
                onChange={(value) => setId(value as CompanyRegistrationId)}
                validators={
                  country ? companyRegistrationIdValidators[country] : []
                }
                autoFocus
                hint={hint}
              />
              <Button type="submit" block className="m-top-10" status={status}>
                <FaSearch style={{ marginRight: 10 }} /> <T>Search</T>
              </Button>
            </div>
          )}
        </AnimateHeight>
      </Form>
    </>
  );
};

export const CompanySearchOverlay: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="company-search-overlay-overlay">
      <Button ghost className="block" onClick={() => setIsOpen(true)}>
        <FaSearch style={{ marginRight: 10 }} /> <T>Search company</T>
      </Button>
      <NewOverlay open={isOpen} onClose={() => setIsOpen(false)}>
        <CompanySearchOverlayInner onClose={() => setIsOpen(false)} />
      </NewOverlay>
    </div>
  );
};
