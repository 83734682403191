import { TextInput, TextInputProps } from "../../form/TextInput";
import cx from "classnames";
import { Alternative } from "../InputTypes";
import { useCallback, useEffect, useRef, useState } from "react";
import { motion, useMotionValue } from "framer-motion";
import "./Searchable.scss";

interface Props<T> extends TextInputProps {
  onSelect: (value: T) => void;
  alternatives: Alternative<T>[];
}

export function Searchable<T>({
  className,
  alternatives,
  onSelect,
  ...props
}: Props<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [alignment, setAlignment] = useState<"top" | "bottom">("top");
  const [showList, setShowList] = useState(false);
  const maxHeight = useMotionValue(1000);
  const top = useMotionValue<number | string>("");

  const getFocusedElementIndex = useCallback(() => {
    if (!listRef.current) {
      return null;
    }

    const focused = listRef.current.querySelector(":focus")?.parentNode;
    if (!focused) {
      return null;
    }

    return Array.from(listRef.current.children).indexOf(focused as Element);
  }, []);

  const setFocusedElement = useCallback(
    (index: number) => {
      if (!listRef.current) {
        return;
      }
      const children = listRef.current.children;
      if (index < 0) {
        inputRef.current?.focus();
        return;
      }
      if (index >= children.length) {
        return;
      }

      (children[index].children[0] as HTMLElement).focus();
    },
    [listRef]
  );

  const calculateStyle = useCallback(() => {
    const wrapperBox = ref.current?.getBoundingClientRect();

    if (wrapperBox) {
      const distanceToBottomOfViewport = window.innerHeight - wrapperBox.bottom;
      const distanceToTopOfViewport = wrapperBox.top;

      if (distanceToBottomOfViewport > distanceToTopOfViewport) {
        setAlignment("bottom");
        const inputBottom = inputRef.current?.getBoundingClientRect().bottom;
        top.set(inputBottom ? inputBottom - wrapperBox.top : 0);
        maxHeight.set(Math.min(distanceToBottomOfViewport - 10, 600));
      } else {
        setAlignment("top");
        top.set("unset");
        maxHeight.set(Math.min(distanceToTopOfViewport - 10, 600));
      }
    }
  }, [maxHeight, top]);

  useEffect(() => {
    if (props.value) {
      setShowList(true);
      calculateStyle();
    } else {
      setShowList(false);
    }
  }, [calculateStyle, props.value]);

  useEffect(() => {
    calculateStyle();
  }, [calculateStyle, showList]);

  const handleKeyEvent = useCallback(
    ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
      if (key === "Escape") {
        setShowList(false);
        inputRef.current?.blur();
      }
      if (key === "ArrowDown") {
        const focusedElementIndex = getFocusedElementIndex();
        if (focusedElementIndex === null) {
          setFocusedElement(0);
        } else {
          if (alignment === "top") {
            setFocusedElement(focusedElementIndex - 1);
          } else {
            setFocusedElement(focusedElementIndex + 1);
          }
        }
      }
      if (key === "ArrowUp") {
        const focusedElementIndex = getFocusedElementIndex();
        if (focusedElementIndex === null) {
          setFocusedElement(0);
        } else {
          if (alignment === "top") {
            setFocusedElement(focusedElementIndex + 1);
          } else {
            setFocusedElement(focusedElementIndex - 1);
          }
        }
      }
    },
    [alignment, getFocusedElementIndex, setFocusedElement]
  );

  return (
    <div
      className={cx("searchable", className, {
        "searchable--top": alignment === "top",
      })}
      ref={ref}
      onKeyDown={handleKeyEvent}
      onBlur={(event) => {
        // prevent blur when clicking children
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowList(false);
        }
      }}
    >
      <TextInput
        {...props}
        onFocus={() => {
          setShowList(true);
        }}
        ref={inputRef}
      />
      {showList && alternatives.length > 0 && (
        <motion.ul
          className={cx("searchable-list")}
          ref={listRef}
          style={{
            maxHeight,
            top,
          }}
        >
          {alternatives.map(({ text, value }, index) => (
            <li key={index}>
              <button
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowList(false);
                  onSelect(value);
                }}
              >
                <div className="truncated text-small">{text}</div>
              </button>
            </li>
          ))}
        </motion.ul>
      )}
    </div>
  );
}
