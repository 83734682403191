import { DateTime } from "luxon";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { LogoVertical } from "../../components/images/LogoVertical";
import { TabView } from "../../components/tabView/TabView";
import { T } from "../../components/translation/T";
import { SupportBox } from "../../components/verticalNav/SupportBox";
import { UserRole, UserSession } from "../../data/dataAuth";
import { DashboardCase } from "../../data/dataDashboard";
import { FilterUtils } from "../../data/models/Filter";
import { userState } from "../../state/userState";
import { Filter as FilterInterface, Store, STORE_KEY } from "../../Store";
import { PageStripped } from "../PageStripped";
import { DASHBOARD_ROUTE } from "../sales/Dashboard/DashboardPage";
import { LoginBackground } from "./LoginBackground";
import { LoginEmail } from "./LoginEmail";
import { LoginOtp } from "./LoginOtp";
import "./LoginPage.scss";

export const LOGIN_ROUTE = "/";

export const LoginPage: React.FunctionComponent = () => {
  const requestId = useRef<string>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [user, setUserState] = useRecoilState(userState);
  const navigate = useNavigate();
  const location = useLocation();

  const onNext = useCallback(
    (code: string) => {
      requestId.current = code;
      setTabIndex(tabIndex + 1);
    },
    [tabIndex]
  );

  const getUriByRole = function (role: UserRole): string {
    switch (role) {
      case UserRole.SALES:
        return `${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(
          Store.getValue<FilterInterface<DashboardCase>>(
            STORE_KEY.STORE_DASHBOARD_FILTER
          )
        )}`;

      default:
        return DASHBOARD_ROUTE;
    }
  };

  const onLogin = useCallback(
    (data: UserSession) => {
      setUserState(data);
      Store.setValue(STORE_KEY.STORE_USER, data);
      const url = new URLSearchParams(location.search).get("expired");

      if (url) {
        navigate(url);
      } else {
        const uri = getUriByRole(data.role);
        navigate(uri);
      }
    },
    [navigate, setUserState, location]
  );

  const onReset = useCallback(() => {
    requestId.current = undefined;
    setTabIndex(0);
  }, []);

  useEffect(() => {
    if (user) {
      const isValid = DateTime.now() < DateTime.fromISO(user.expiry);
      if (isValid) {
        const target = getUriByRole(user.role);
        navigate(target);
      }
    }
  });

  if (user) {
    const isValid = DateTime.now() < DateTime.fromISO(user.expiry);
    if (isValid) {
      return null;
    }
  }

  return (
    <PageStripped title="Sign in" className="login-page">
      <LoginBackground />
      <section>
        <article style={{ position: "relative" }}>
          <div>
            <header>
              <LogoVertical />
            </header>

            <div className="login-top">
              <h1>
                <T>Welcome to WLX Outreach</T>
              </h1>
            </div>

            <TabView index={tabIndex}>
              <LoginEmail onNext={onNext} />
              <LoginOtp
                requestId={requestId.current}
                onLogin={onLogin}
                onReset={onReset}
              />
            </TabView>
          </div>
        </article>
        <div className="bottom-box">
          <SupportBox />
        </div>
      </section>
    </PageStripped>
  );
};
