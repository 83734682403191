import { caseStatusState } from "../../../state/caseStatusState";
import { useRecoilValue } from "recoil";
import styles from "./IsLegacyCase.module.scss";
import { T } from "../../../components/translation/T";

export const IsLegacyCase = () => {
  const { isLegacyCase } = useRecoilValue(caseStatusState);

  if (!isLegacyCase) return null;

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <T>This is a legacy case</T>
      </div>
      <div className={styles.subtitle} style={{ marginBottom: 10 }}>
        <T>(Created before 2023-03-30)</T>
      </div>
      <div className={styles.subtitle}>
        <T>
          Some new features may not be available on this case. If needed,
          retracting the case to "creating case" will enable them.
        </T>
      </div>
    </div>
  );
};
