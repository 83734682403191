import React, { ReactNode } from "react";
import cx from "classnames";
import { Link as RouterLink } from "react-router-dom";
import { External } from "../icons/External";
import "./Link.scss";

interface Props {
  link: string;
  download?: string;
  external?: boolean;
  children: ReactNode;
  className?: string;
  blank?: boolean;
  hideExternal?: boolean;
}

export const Link: React.FunctionComponent<Props> = ({
  link,
  external,
  children,
  className,
  download,
  blank = false,
  hideExternal = false,
}) => {
  if (external) {
    return (
      <a
        className={cx("link", "external-link", "postfix", className)}
        href={link}
        target="_blank"
        rel="noreferrer"
        download={download}
      >
        {children}
        {!hideExternal && <External />}
      </a>
    );
  }
  return (
    <RouterLink
      className={cx("link", className)}
      to={link}
      target={blank ? "_blank" : undefined}
    >
      {children}
    </RouterLink>
  );
};
