import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import { Retry } from "../../../../components/retry/Retry";
import { Status } from "../../../../data/types";
import { CaseStatus } from "../../../../data/models/caseTypes";
import { Link } from "../../../../components/links/Link";
import { DASHBOARD_ROUTE } from "../DashboardPage";
import { Filter as FilterInterface } from "../../../../Store";
import { FilterUtils } from "../../../../data/models/Filter";
import { DashboardCase, dataDashboard } from "../../../../data/dataDashboard";
import { CaseStatusDisplay } from "../../../../components/caseStatus/CaseStatus";
import "./Filter.scss";
import { motion } from "framer-motion";
import { T } from "../../../../components/translation/T";

interface Props {
  filter: FilterInterface<DashboardCase>;
}

interface BreadcrumbsProps {
  filter: FilterInterface<DashboardCase>;
  statuses: Record<CaseStatus, number | string>;
}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({
  statuses,
  filter,
}) => {
  const elems: React.ReactNode[] = [];

  const params = FilterUtils.getParams(filter);

  let total = 0;

  Object.keys(CaseStatus).forEach((key) => {
    params.set("status", key);
    params.set("page", "0");
    elems.push(
      <li
        key={key}
        className={cx("filter-breadcrumb", key.toLowerCase(), {
          "is-active": filter.status?.[0] === key,
        })}
      >
        <Link link={`${DASHBOARD_ROUTE}?${params.toString()}`}>
          <span className="filter-name-and-count">
            <CaseStatusDisplay status={key as CaseStatus} />{" "}
            <motion.span
              className="filter-count"
              key={statuses[key as CaseStatus]}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              ({statuses[key as CaseStatus]})
            </motion.span>
          </span>
          <span className="filter-arrow" />
        </Link>
      </li>
    );
    total += Number(statuses[key as CaseStatus]);
  });

  params.delete("status");

  return (
    <div className="filter-breadcrumbs-wrapper">
      <div className="filter-breadcrumbs --alone">
        <div
          className={cx("filter-breadcrumb", "all", {
            "is-active": !filter.status?.length,
          })}
        >
          <Link link={`${DASHBOARD_ROUTE}?${params.toString()}`}>
            <span className="filter-name-and-count">
              <T>All</T> <span className="filter-count">({total})</span>
            </span>
          </Link>
        </div>
      </div>
      <ul className="filter-breadcrumbs">{elems}</ul>
    </div>
  );
};

const defaultStatuses: Record<CaseStatus, string> = {
  [CaseStatus.DATA_COLLECTION]: "-",
  [CaseStatus.OUTREACH]: "-",
  [CaseStatus.VERIFICATION]: "-",
  [CaseStatus.COMPLETED_VERIFICATION]: "-",
  [CaseStatus.CLOSED]: "-",
};

export const Filter: React.FunctionComponent<Props> = ({ filter }) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [caseStatuses, setCaseStatuses] =
    useState<Record<CaseStatus, number | string>>(defaultStatuses);

  const load = useCallback(() => {
    dataDashboard
      .loadStatusOverview(filter)
      .then((statuses) => {
        setStatus(Status.SUCCESS);
        setCaseStatuses(statuses);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [filter]);

  useEffect(load, [load, filter]);

  return (
    <div className="dashboard-filter">
      <Retry status={status}>
        <Breadcrumbs statuses={caseStatuses} filter={filter} />
      </Retry>
    </div>
  );
};
