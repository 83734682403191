import { CaseId } from "./models/CommonTypes";

import { data } from "./data";
const BASE_URL = "/api/columbus";

export interface ColumbusArchive {
  caseId: string;
  filename: string;
  documentType: "KYC" | "UBO";
  archived: boolean;
}

// the amount of files that should be archived in columbus. UboFiles is always 1, since it is a zip-file containing all the UBO files

export const dataColumbus = {
  getColumbusArchiveStatus: (caseId: CaseId, totalDocuments: number) =>
    data.get<{ data: Promise<ColumbusArchive[]>; status: number }>(
      `${BASE_URL}/status?caseId=${caseId}&count=${totalDocuments}`,
      true,
      undefined,
      true
    ),
};
