import { Country } from "../i18n";
import { Filter } from "../Store";
import { data } from "./data";
import {
  CaseId,
  CompanyRegistrationId,
  UserId,
  UTCDate,
} from "./models/CommonTypes";
import {
  CaseStatus,
  CaseType,
  CaseStaleReason,
  CaseClosedReason,
} from "./models/caseTypes";

import { Page, SortDirection } from "./models/Page";
import { DocumentType } from "./dataDocument";

const BASE_PATH = "/api/dashboard";

export interface DashboardDocument {
  filename: string;
  type: DocumentType;
  refNumber?: string;
}
export interface DashboardCase {
  caseId: CaseId;
  country: Country;
  companyName: string;
  companyRegistrationId: CompanyRegistrationId;
  created: UTCDate;
  updated?: UTCDate;
  status: CaseStatus;
  staleReason?: CaseStaleReason;
  caseType: CaseType;
  currentUser: {
    id: string;
    name: string;
  };
  documents?: DashboardDocument[];
  closedReason: CaseClosedReason;
}

type DashboardRequest = Filter<DashboardCase>;

type StatusRequest = Pick<Filter<DashboardCase>, "countries" | "userId">;

export interface DashboardUser {
  id: UserId;
  name: string;
  count: number;
}

export const dataDashboard = {
  loadCases: (
    {
      page,
      size = 20,
      sortField = "created",
      sortDirection = SortDirection.DESC,
      countries = [],
      status = [],
      userId,
    }: DashboardRequest,
    options: RequestInit
  ) => {
    const params = new URLSearchParams();
    params.append("page", page.toString());
    params.append("size", size.toString());
    params.append("sortField", sortField);
    params.append("sortDirection", sortDirection);
    countries.forEach((country) => params.append("countries", country));
    status.forEach((status) => params.append("status", status));
    if (userId) {
      params.append("userId", userId.toString());
    }

    return data.get<Page<DashboardCase>>(
      `${BASE_PATH}?${params.toString()}`,
      true,
      options
    );
  },

  loadStatusOverview: ({ countries = [], userId }: StatusRequest) => {
    const params = new URLSearchParams();
    countries.forEach((country) => params.append("countries", country));
    if (userId) {
      params.append("userId", userId.toString());
    }

    return data.get<Record<CaseStatus, number>>(
      `${BASE_PATH}/status-filter?${params.toString()}`,
      true
    );
  },

  loadUsers: () => {
    return data.get<DashboardUser[]>(`${BASE_PATH}/users`);
  },
};
