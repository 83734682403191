import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../../../components/form/TextInput";
import { MaxDateValidator } from "../../../../../components/form/validators/MaxDateValidator";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { ButtonPane } from "../../../../../components/interactions/Buttons/ButtonPane";
import { T } from "../../../../../components/translation/T";
import { SecurityMarkHelpModal } from "../components/SecurityMarkHelpModal";
import { DateTime } from "luxon";

function calcAge(date: string) {
  const isoDate = DateTime.fromISO(date);
  const years = DateTime.now().diff(isoDate, "years").years;
  return Math.abs(Math.floor(years));
}
interface Props {
  setDone: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const IdCard: React.FunctionComponent<Props> = ({ setDone }) => {
  const [clear, setClear] = useState<boolean>();
  const [faceVisible, setFaceVisible] = useState<boolean>();
  const [hasSecurityMark, setHasSecurityMark] = useState<boolean>();
  const [isFullIdCard, setIsFullIdCard] = useState<boolean>();

  useEffect(() => {
    if (
      clear === false ||
      faceVisible === false ||
      hasSecurityMark === false ||
      isFullIdCard === false
    ) {
      setDone(false);
      return;
    }

    if (clear === undefined) {
      setDone(undefined);
      return;
    }

    if (faceVisible === undefined) {
      setDone(undefined);
      return;
    }

    if (hasSecurityMark === undefined) {
      setDone(undefined);
      return;
    }

    if (isFullIdCard === undefined) {
      setDone(undefined);
      return;
    }

    setDone(clear && faceVisible && hasSecurityMark && isFullIdCard);
  }, [setDone, clear, faceVisible, hasSecurityMark, isFullIdCard]);

  return (
    <div className="review-passport">
      <div className="review-question">
        <div className="review-question-text">
          <T>Does the pictures contain both sides of a National ID Card?</T>
        </div>
        <div className="small m-top-10">
          <i>
            <T>
              A driver's license is NOT allowed to be used and should be
              rejected
            </T>
          </i>
        </div>
        <ButtonPane
          className="small m-top-20"
          onClick={(value) => setIsFullIdCard(value)}
          buttons={[
            {
              text: "Yes",
              active: isFullIdCard,
              data: true,
            },
            {
              text: "No",
              active: isFullIdCard === false,
              data: false,
            },
          ]}
        />
      </div>

      <div className="review-question">
        <div className="review-question-text">
          <T>Are the pictures clear and show the full id-card?</T>
        </div>
        <div className="small m-top-10">
          <i>
            <T>
              The text should be clearly readable and no part of the ID card
              must be obstructed
            </T>
          </i>
        </div>
        <ButtonPane
          className="small m-top-20"
          onClick={(value) => setClear(value)}
          buttons={[
            {
              text: "Yes",
              active: clear,
              data: true,
            },
            {
              text: "No",
              active: clear === false,
              data: false,
            },
          ]}
        />
      </div>

      <div className="review-question">
        <div className="review-question-text">
          <T>Is the face clearly visable?</T>
        </div>
        <div className="small m-top-10">
          <i>
            <T>
              The face should not be obscured by light reflections or blurry
            </T>
          </i>
        </div>
        <ButtonPane
          onClick={(value) => setFaceVisible(value)}
          className="small m-top-20"
          buttons={[
            {
              text: "Yes",
              active: faceVisible,
              data: true,
            },
            {
              text: "No",
              active: faceVisible === false,
              data: false,
            },
          ]}
        />
      </div>

      <div className="review-question">
        <div className="review-question-text">
          <T>Are the security marks clearly visible?</T>
          <SecurityMarkHelpModal />
        </div>
        <div className="small m-top-10">
          <i>
            <T>
              The security marks can be a foil icon, usally a national logo.
              There can also be a second picture of the face in a plastic window
            </T>
          </i>
        </div>
        <ButtonPane
          onClick={(value) => setHasSecurityMark(value)}
          className="small m-top-20"
          buttons={[
            {
              text: "Yes",
              active: hasSecurityMark,
              data: true,
            },
            {
              text: "No",
              active: hasSecurityMark === false,
              data: false,
            },
          ]}
        />
      </div>
    </div>
  );
};
