import { Country } from "../../i18n";
import { CaseCompanyState } from "../../state/caseCompanyState";
import { DashboardCase, DashboardDocument } from "../dataDashboard";
import { IdType } from "./AssociateTypes";
import {
  BeneficialOwnerId,
  Cas,
  CaseId,
  CompanyId,
  CompanyRegistrationId,
  OutreachRequestId,
  UserId,
  UTCDate,
} from "./CommonTypes";
import { LegalEntityType } from "./LegalEntityTypes";

export enum Validity {
  MISSING = "has-missing-information",
  PARTIAL = "has-partial-information",
  VALID = "has-required-information",
  DEFAULT = "",
}

export enum CaseFeature {}

export interface Company {
  id: CompanyId;
  companyRegistrationId: CompanyRegistrationId;
  legalName: string;
  legalEntityType?: LegalEntityType;
  country: Country;
  address: Address;
  lastRegistryLookup: UTCDate;
  companyKYC: CompanyKyc;
  ongoingCase?: OngoingCase;
  historicCases: HistoricCase[];
}

export interface Address {
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}

export type SaveCompanyRequest = Partial<
  Pick<Company, "legalName" | "legalEntityType" | "address">
>;

export interface OngoingCase
  extends Omit<DashboardCase, "companyName" | "companyName"> {
  beneficialOwnerCount: number;
  completedBeneficialOwners: number;
  documents: DashboardDocument[];
}

export interface HistoricCase {
  caseId: CaseId;
  created: UTCDate;
  closed: UTCDate;
  caseType: CaseType;
  closedReason: CaseClosedReason;
  beneficialOwnerCount: number;
  documents: DashboardDocument[];
}

export enum BeneficialOwnerType {
  OWNERSHIP = "OWNERSHIP",
  ROLE = "ROLE",
}

export interface CompanyKyc {
  cas: Cas;
  mcc: string[];
  mostSoldItem?: string;
  nonWorldlineBusiness?: string;
  hasNonWorldlineBusiness?: boolean;
  businessModel?: BusinessModel;
  productDescription?: string;
  regulatedEntity?: boolean;
  regulatedBy?: string;
  licensedEntity?: boolean;
  licensedBy?: string;
  thirdPartyPayments?: boolean;
  estimatedAnnualTurnover?: number;
  rangeOfTransactionsMin?: number;
  rangeOfTransactionsMax?: number;
  rangeOfWlTransactionsMin?: number;
  rangeOfWlTransactionsMax?: number;
  estimatedTransactionsPerYear?: number;
  mainCountriesOperates: string[];
  listedEntity?: boolean;
  listedTickerName?: string;
  listedIsin?: string;
  businessChannel?: BusinessChannel;
}

export interface CaseTimeline {
  created: UTCDate;
  sentToOwners?: UTCDate;
  reviewedCompleted?: UTCDate;
  closed?: UTCDate;
}

export enum BusinessChannel {
  ECOM = "ECOM",
  OMNI = "OMNI",
  POS = "POS",
}

export enum BusinessModel {
  B2B = "B2B",
  B2C = "B2C",
  B2B2C = "B2B2C",
  MARKETPLACE = "MARKETPLACE",
  WHOLESALE = "WHOLESALE",
}

export interface Case {
  id: CaseId;
  updated?: UTCDate;
  currentUser: {
    id?: UserId;
    name?: string;
  };
  status: CaseStatus;
  type: CaseType;
  timeline: CaseTimeline;
  caseClosedReason?: CaseClosedReason;
  beneficialOwners: BeneficialOwner[];
  beneficialOwnerType?: BeneficialOwnerType;
  companyKyc: CompanyKyc;
  company: CaseCompanyState;
  lookups: {};
  registryResult: RegistryResult;
  hasCompleteKycData?: boolean;
  reviewedCompleted?: UTCDate;
}

export enum RegistryResult {
  RESOLVED = "RESOLVED",
  NO_DATA = "NO_DATA",
  NO_REGISTRY_AVAILABLE = "NO_REGISTRY_AVAILABLE",
}

export type MainCaseData = Omit<
  Case,
  "beneficialOwners" | "companyKyc" | "company" | "currentUser" | "timeline"
>;

export type OutreachRequest = {
  id: OutreachRequestId;

  linkId?: string;
  // outreachId?: string;
  // beneficialOwnerId?: string;
  created?: UTCDate;

  email?: string;
  phoneNumber?: string;

  emailSent?: UTCDate;
  smsSent?: UTCDate;

  pageViewed?: UTCDate;
  confirmedData?: UTCDate;
  confirmedIdentity?: UTCDate;

  signedOff?: UTCDate;
  signedOffBy: UserId;
};

export interface IndirectOwnership {
  id: string;
  name: string;
  parentCompanyId?: string;
  parentOwnership?: number;
  companyRegistrationId: string;
  address: Address;
  country: Country;
}

export type BeneficialOwner = {
  id: BeneficialOwnerId;
  created: UTCDate;
  updated?: UTCDate;

  title?: string;
  firstName?: string;
  lastName?: string;
  address: {
    street?: string;
    postalCode?: string;
    city?: string;
    // Alpha-2
    country?: string;
  };

  dateOfBirth?: UTCDate;
  nationality?: string;
  ownership?: number;

  communication: Communication;

  tracking: BeneficialOwnerTracking;
  indirectOwnership: IndirectOwnership[];

  identity?: BeneficialOwnerIdentity;
  identitySignedOff?: UTCDate;
  identitySignedOffBy?: string;

  outreachSent?: UTCDate;

  beneficialOwnerId: BeneficialOwnerId;
};

export interface Communication {
  email?: string;
  phoneNumber?: string;
  language?: string;
  emailSent?: UTCDate;
  smsSent?: UTCDate;
  reminderSent?: UTCDate;
}

export interface BeneficialOwnerTracking {
  pageViewed?: UTCDate;
  confirmedData?: UTCDate;
  confirmedIdentity?: UTCDate;
}

export interface BeneficialOwnerIdentity {
  uploaded?: UTCDate;
  selfie?: string;
  passport?: string;
  idFront?: string;
  idBack?: string;
  textExtracted?: string;
  ipAddress?: string;
  userAgent?: string;
}

export enum CaseType {
  NEW_CUSTOMER = "NEW_CUSTOMER",
  CURRENT_CUSTOMER = "CURRENT_CUSTOMER",
}

export enum CaseClosedReason {
  // Säljaren / Kunden ångra sig
  DISCARDED = "DISCARDED",
  // Det fanns ingen data i registret
  REGISTRY_NOT_VALID = "REGISTRY_NOT_VALID",
  // Beneficial owner sa NEJ
  OUTREACH_REJECTED = "OUTREACH_REJECTED",
  // Allt var OK
  CASE_FINALIZED = "CASE_FINALIZED",
}

export enum CaseStaleReason {
  UNREACHABLE = "UNREACHABLE",
  HAVE_CANCELLED = "CANCELLED",
  DO_NOT_COOPERATE = "NOT_COOPERATE",
  REMAIN_IN_AWAITING_REPLY = "REMAIN",
  ERROR_IN_CONTRACT = "ERROR_IN_CONTRACT",
  GIVEBACK_RECEIVED = "GIVEBACK_RECEIVED",
  GIVEBACK_IN_PROGRESS = "GIVEBACK_IN_PROGRESS",
  GIVEBACK_RESOLVED = "GIVEBACK_RESOLVED",
  AWAITING_MANUAL_UBO_REGISTRATION = "AWAITING_MANUAL_UBO_REGISTRATION",
  MANUAL_UBO_REGISTRATION_FINISHED = "MANUAL_UBO_REGISTRATION_FINISHED",
}

export enum CaseStatus {
  DATA_COLLECTION = "DATA_COLLECTION",
  OUTREACH = "OUTREACH",
  VERIFICATION = "VERIFICATION",
  COMPLETED_VERIFICATION = "COMPLETED_VERIFICATION",
  CLOSED = "CLOSED",
}

export enum BeneficialOwnerExceptionType {
  NO_OWNERS_GOVERNMENTAL = "NO_OWNERS_GOVERNMENTAL",
  NO_OWNERS_LISTED_COMPANY = "NO_OWNERS_LISTED_COMPANY",
  NO_OWNERS_FINANCIAL_INSTITUTION = "NO_OWNERS_FINANCIAL_INSTITUTION",
  NO_OWNERS_ABOVE_LIMIT = "NO_OWNERS_ABOVE_LIMIT",
}

export interface Address {
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}

export interface Contact {
  phoneNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  function?: string;
}

export enum MccRiskLevel {
  NONE = "NONE",
  RISK_APPROVAL = "RISK_APPROVAL",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export interface MerchantCategory {
  code: string;
  name: string;
  note?: string;
  category: string;
  cardNotPresent: MccRiskLevel;
  faceToFace: MccRiskLevel;
}

export interface TextBlock {
  text: string;
  width: number; //Percentage of total image, ex: 0.223 = 22.3% of the image total width
  height: number;
  left: number; // Percentage offset from the left side of the image
  top: number;
}

export interface ResolvedData {
  idType?: IdType;
  documentNumber?: string;
  nationality?: string; //2 alpha
  issuingCountry?: string; //2 alpha
  issuingAuthority?: string;
  dateOfBirth?: string; //iso date
  issueDate?: string; //iso date
  expiryDate?: string; //iso date
}

export interface IdScan {
  passport?: TextBlock[];
  idFront?: TextBlock[];
  idBack?: TextBlock[];
  idImage?: TextBlock[];
  resolvedData: ResolvedData;
}

export interface GeoIpDetails {
  ip: string;
  country: string;
  countryCode: string;
  city?: string;
}
