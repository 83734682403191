import React, { useCallback, useContext } from "react";
import { useFormValidity } from "../../../../components/form/MultiFormContext";
import { ScrollPositionContext } from "../../../../components/scrollPosition/ScrollPositionContext";
import { LinkAnchors } from "../CaseEdit";
import { CaseEditMenuItem } from "./CaseEditMenuItem";
import { T } from "../../../../components/translation/T";
import "./CaseEditMenu.scss";
import { useRecoilValue } from "recoil";
import { caseStatusState } from "../../../../state/caseStatusState";
import { CaseType } from "../../../../data/models/caseTypes";

export enum FormName {
  DOCUMENT_COLLECTION = "document-collection",
  COMPANY_DATA = "company-data",
  FINANCIAL_DATA = "financial-data",
  OWNER = "owner",
}

const scrollToElement = (element: HTMLElement, container?: HTMLDivElement) => {
  if (container) {
    const y = element.getBoundingClientRect().top + container.scrollTop - 50;
    container.scrollTo({
      top: y,
      left: 0,
      behavior: "smooth",
    });
  } else {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export const CaseEditMenu: React.FunctionComponent = () => {
  const { type } = useRecoilValue(caseStatusState);
  const isDocumentCollectionValid = useFormValidity(
    FormName.DOCUMENT_COLLECTION
  );
  const isCompanyDataValid = useFormValidity(FormName.COMPANY_DATA);
  const isFinancialDataValid = useFormValidity(FormName.FINANCIAL_DATA);
  const isOwnerValid = useFormValidity(FormName.OWNER);
  const scrollPositionContext = useContext(ScrollPositionContext);

  const scrollPosition = scrollPositionContext?.closestScrollEntry;

  const isNewMerchant = type === CaseType.NEW_CUSTOMER;

  const onClick = useCallback(
    (id: string) => {
      const element = document.getElementById(id);

      if (element) {
        scrollToElement(
          element,
          scrollPositionContext?.scrollRef?.current ?? undefined
        );
      }
    },
    [scrollPositionContext?.scrollRef]
  );

  return (
    <div className="case-edit-vertical-menu">
      <div className="case-edit-menu-links">
        <strong className="menu-header">
          <T>Case sections</T>
        </strong>
        <ul>
          {isNewMerchant && (
            <CaseEditMenuItem
              onClick={onClick}
              anchor={LinkAnchors.DOCUMENT_COLLECTION}
              validity={isDocumentCollectionValid}
              active={
                scrollPosition?.id === LinkAnchors.DOCUMENT_COLLECTION.anchor
              }
            />
          )}
          <CaseEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.COMPANY_DATA}
            validity={isCompanyDataValid}
            active={scrollPosition?.id === LinkAnchors.COMPANY_DATA.anchor}
          />
          <CaseEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.FINANCIAL_DATA}
            validity={isFinancialDataValid}
            active={scrollPosition?.id === LinkAnchors.FINANCIAL_DATA.anchor}
          />
          <CaseEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.OWNERS}
            validity={isOwnerValid}
            active={scrollPosition?.id === LinkAnchors.OWNERS.anchor}
          />
        </ul>
      </div>
    </div>
  );
};
