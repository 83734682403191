import { Case } from "../../../data/models/caseTypes";
import { OwnerListItem } from "../review/ReviewOwnerListItem";
import { CompanyCard } from "./CompanyCard";
import { CompleteFinancialKyc } from "./CompleteFinancialKyc";
import { CompleteGeneralKyc } from "./CompleteGeneralKyc";
import { CaseCard } from "./CaseCard";
import { T } from "../../../components/translation/T";
import { OnboardingCaseId } from "../../../data/models/CommonTypes";
import { motion, Variants } from "framer-motion";
import "./CaseComplete.scss";

interface Props extends React.PropsWithChildren {
  outreach: Case;
  onboardingId?: OnboardingCaseId;
}

export const CaseComplete = ({ outreach, onboardingId, children }: Props) => {
  const { id, company, companyKyc, beneficialOwners } = outreach;

  const external = typeof onboardingId !== "undefined";

  return (
    <div className="case-complete content">
      <motion.div
        className="tablet-columns cards"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <motion.div variants={staggeredChildren}>
          <CaseCard outreach={outreach} />
        </motion.div>
        <motion.div variants={staggeredChildren}>
          <CompanyCard {...{ company, external }} />
        </motion.div>
      </motion.div>
      <motion.div
        className="tablet-columns m-top-40"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div>
          <div>
            <h5>
              <T>General KYC</T>
            </h5>
            <CompleteGeneralKyc kyc={companyKyc} />
          </div>
          <div className="m-top-40">
            <h5>
              <T>Financial KYC</T>
            </h5>
            <CompleteFinancialKyc kyc={companyKyc} country={company.country} />
          </div>
        </div>
        <div>
          <h5>
            <T>Beneficial Owners</T>
          </h5>
          <div className="beneficial-owners">
            {beneficialOwners.map((owner) => (
              <OwnerListItem
                caseId={id}
                onboardingId={onboardingId}
                key={owner.id}
                owner={owner}
                enableConfirmDetails={false}
              />
            ))}
          </div>
          {children && (
            <>
              <div className="divider" />
              <div className="m-top-30">{children}</div>
            </>
          )}
        </div>
      </motion.div>
    </div>
  );
};

const container: Variants = {
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const staggeredChildren: Variants = {
  hidden: { opacity: 0, y: -25 },
  show: {
    opacity: 1,
    y: 0,
  },
};
