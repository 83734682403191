import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { MultiForm } from "../../../components/form/MultiForm";
import { CaseId, UserId } from "../../../data/models/CommonTypes";
import { Case, CaseStatus } from "../../../data/models/caseTypes";
import { ServerError } from "../../../network/API";
import { CaseStatusState } from "../../../state/caseStatusState";
import { CasePageMenu } from "../../CasePageMenu";
import { CaseEdit } from "./CaseEdit";
import { CaseEditMenu } from "./menus/CaseEditMenu";
import { ScrollPosition } from "../../../components/scrollPosition/ScrollPosition";
import { AnimatePresence, motion } from "framer-motion";
import { Spinner } from "../../../components/spinner/Spinner";
import "./CasePage.scss";
import { CaseLoadWrapper } from "../CaseLoadWrapper";
import {
  CLAIM_ERROR_CODE,
  CAS_ERROR_CODE,
  SERVER_ERROR_CODE,
} from "../../../data/dataOutreach";

export const CASE_ROUTE = "/outreach/:id";

export enum CaseSaveError {
  CAS_ERROR,
  CLAIM_ERROR,
  SERVER_ERROR,
  SAVE_ERROR,
}

export function handleError(
  err: ServerError<any>,
  setError: (caseError: CaseSaveError) => void
) {
  if (err.status === CLAIM_ERROR_CODE) {
    setError(CaseSaveError.CLAIM_ERROR);
    return;
  }

  if (err.status === CAS_ERROR_CODE) {
    setError(CaseSaveError.CAS_ERROR);
    return;
  }

  if (err.status === SERVER_ERROR_CODE) {
    setError(CaseSaveError.SERVER_ERROR);
    return;
  }

  setError(CaseSaveError.SAVE_ERROR);
}

export function getCaseIsEditable({
  status,
}: CaseStatusState | Case | { status: CaseStatus }) {
  return (
    status === CaseStatus.DATA_COLLECTION ||
    status === CaseStatus.OUTREACH ||
    status === CaseStatus.VERIFICATION
  );
}

export function getUserIsAllowedToEdit(
  {
    currentUser,
  }:
    | CaseStatusState
    | Case
    | {
        currentUser: {
          id?: UserId;
          name?: string;
        };
      },
  userId?: UserId
) {
  if (!userId || !currentUser.id) {
    return false;
  }

  return userId === currentUser.id;
}

const CaseInner: React.FunctionComponent<{ outreachCase?: Case }> = ({
  outreachCase,
}) => {
  const [mockLoading, setMockLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMockLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const show = !mockLoading && outreachCase;

  return (
    <AnimatePresence mode="wait" initial={false}>
      {!show && (
        <motion.div
          className="loader"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          key="loader"
        >
          <Spinner size="large" />
        </motion.div>
      )}
      {show && (
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 20,
          }}
          key="content"
        >
          <CaseEdit />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const CasePage: React.FunctionComponent = () => {
  const { id } = useParams<{ id: CaseId }>();
  const [outreach, setOutreach] = useState<Case>();
  const scrollRef = React.useRef<HTMLDivElement>(null);

  if (!id) {
    return null;
  }

  return (
    <MultiForm>
      <ScrollPosition scrollRef={scrollRef}>
        <CasePageMenu
          title="Case"
          className="case-page"
          caseId={id}
          menuChildren={<CaseEditMenu />}
          ref={scrollRef}
        >
          <section>
            <article>
              <CaseLoadWrapper caseId={id} onChange={setOutreach}>
                <CaseInner outreachCase={outreach} />
              </CaseLoadWrapper>
            </article>
          </section>
        </CasePageMenu>
      </ScrollPosition>
    </MultiForm>
  );
};
