import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSetRecoilState } from "recoil";
import { Retry } from "../../../../components/retry/Retry";
import { dataReview } from "../../../../data/dataReview";
import { BeneficialOwnerId, CaseId } from "../../../../data/models/CommonTypes";
import { BeneficialOwner } from "../../../../data/models/caseTypes";
import { Status } from "../../../../data/types";
import { caseStatusState } from "../../../../state/caseStatusState";
import { CasePageMenu } from "../../../CasePageMenu";
import { AssociateCompareReview } from "./AssociateCompareReview";
import { AssociateIdCardReview } from "./AssociateIdCardReview";
import { AssociateIdDataInputView } from "./AssociateIdDataInputView";
import { AssociateSelfieReview } from "./AssociateSelfieReview";
import "./OwnerReviewPage.scss";

export const OWNER_REVIEW_PAGE =
  "/outreach/:id/review/owner/:beneficialOwnerId";

enum ReviewState {
  SELFIE = "SELFIE",
  ID_DOCUMENT = "ID_DOCUMENT",
  COMPARE = "COMPARE",
  DATA_INPUT = "DATA_INPUT",
}

interface Props {
  caseId: CaseId;
  owner: BeneficialOwner;
}

const OwnerReviewPageInner: React.FunctionComponent<Props> = ({
  owner,
  caseId,
}) => {
  const setStatusData = useSetRecoilState(caseStatusState);

  const [reviewState, setReviewState] = useState<ReviewState>(
    owner?.identitySignedOff ? ReviewState.DATA_INPUT : ReviewState.SELFIE
  );

  if (!owner) {
    return null;
  }

  switch (reviewState) {
    case ReviewState.SELFIE:
      return (
        <AssociateSelfieReview
          caseId={caseId}
          owner={owner}
          completed={() => setReviewState(ReviewState.ID_DOCUMENT)}
        />
      );
    case ReviewState.ID_DOCUMENT:
      return (
        <AssociateIdCardReview
          caseId={caseId}
          owner={owner}
          completed={() => setReviewState(ReviewState.COMPARE)}
        />
      );

    case ReviewState.COMPARE:
      return (
        <AssociateCompareReview
          caseId={caseId}
          owner={owner}
          completed={() => setReviewState(ReviewState.DATA_INPUT)}
        />
      );

    //Om ID-dokumentet är signed-off visar vi input av data
    case ReviewState.DATA_INPUT:
      return (
        <AssociateIdDataInputView
          caseId={caseId}
          owner={owner}
          setStatusData={setStatusData}
        />
      );

    default: {
      //logga nåt?
      return null;
    }
  }
};

export const OwnerReviewPage = () => {
  const { id: caseId, beneficialOwnerId } = useParams<{
    id: CaseId;
    beneficialOwnerId: string;
  }>();
  const [owner, setOwner] = useState<BeneficialOwner>();
  const [status, setStatus] = useState<Status>(Status.PENDING);

  const load = useCallback(() => {
    if (!beneficialOwnerId) {
      return;
    }

    dataReview
      .getOwner(beneficialOwnerId as BeneficialOwnerId)
      .then((data) => {
        setStatus(Status.SUCCESS);
        setOwner(data);
      })
      .catch((err) => {
        setStatus(Status.ERROR);
      });
  }, [beneficialOwnerId]);

  useEffect(() => {
    load();
  }, [load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 600);
  }, [load]);

  if (!caseId || !beneficialOwnerId) {
    return <div>Broken Link! Can't open case information</div>;
  }

  return (
    <CasePageMenu
      title="Beneficial owner review"
      className="owner-review-page"
      caseId={caseId}
    >
      <section>
        <article>
          <Retry status={status} retry={retry}>
            {owner && <OwnerReviewPageInner owner={owner} caseId={caseId} />}
          </Retry>
        </article>
      </section>
    </CasePageMenu>
  );
};
