import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { dataAuth } from "../../data/dataAuth";
import { LOGOUT_ROUTE } from "../../pages/login/Logout";
import { userState } from "../../state/userState";
import { Store, STORE_KEY } from "../../Store";
import { Button } from "../interactions/Buttons/Button";
import { Overlay } from "../overlay/Overlay";
import { T } from "../translation/T";

const WARNING_MINUTES = 15;
const INTERVAL_SECONDS = 3 * 1000;

export const LogoutWarning = () => {
  const [showWarning, setShowWarning] = useState(false);
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const setUserState = useSetRecoilState(userState);
  const timer = useRef<number>();

  useEffect(() => {
    if (user) {
      dataAuth
        .getCurrentSession()
        .then((resp) => {
          setUserState(resp);
          Store.setValue(STORE_KEY.STORE_USER, resp);
        })
        .catch(() => {});
    }
    // We just wanna run this on initial page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserState]);

  useEffect(() => {
    if (!user) {
      clearInterval(timer.current);
      return;
    }

    timer.current = window.setInterval(() => {
      const expiry = DateTime.fromISO(user.expiry);
      if (expiry.diffNow().milliseconds < 0) {
        setShowWarning(false);
        navigate(LOGOUT_ROUTE);
      } else if (expiry.diffNow("minutes").minutes < WARNING_MINUTES) {
        setShowWarning(true);
      }
    }, INTERVAL_SECONDS);

    return () => {
      clearInterval(timer.current);
    };
  }, [navigate, user]);

  return (
    <div className="light-theme">
      <Overlay open={showWarning} onClose={() => {}} disableClose={true}>
        <div>
          <h4>
            <T>You are about the be logged out</T>
          </h4>
          <p>
            <T>You will soon be logged out of the application.</T>
          </p>
          <div className="m-top-20">
            <Button
              onClick={() => {
                setShowWarning(false);
                navigate(LOGOUT_ROUTE);
              }}
              className="danger-button"
            >
              <T>Logout</T>
            </Button>
            <Button
              onClick={() => {
                dataAuth
                  .getCurrentSession()
                  .then((resp) => {
                    setUserState(resp);
                    Store.setValue(STORE_KEY.STORE_USER, resp);
                  })
                  .catch(() => {
                    navigate(LOGOUT_ROUTE);
                  })
                  .finally(() => {
                    setShowWarning(false);
                  });
              }}
            >
              <T>Stay logged in</T>
            </Button>
          </div>
        </div>
      </Overlay>
    </div>
  );
};
