import React, { useCallback, ReactNode, useEffect, useRef } from "react";
import cx from "classnames";
import { Close } from "../icons/Close";
import { Button } from "../interactions/Buttons/Button";
import { Status } from "../../data/types";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
import "./NewOverlay.scss";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import { useEditSidebarWidth } from "../../hooks/useEditSidebarWidth";

interface Props {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  disableClose?: boolean;
  status?: Status;
  className?: string;
  /**
   * Set a layoutId to apply layout animations on the modal, see framer-motion docs for more info
   */
  layoutId?: string;
  width?: number;
}

const ESC = "Escape";

const DEFAULT_TRANSITION_PROPS: Partial<MotionProps> = {
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
  transition: { type: "spring", duration: 0.25 },
};

export const Inner: React.FunctionComponent<{
  children: ReactNode;
  onClose: () => void;
  disableClose?: boolean;
  layoutId?: string;
  width?: number;
}> = ({ children, onClose, disableClose, layoutId, width }) => {
  const overlayRef = useRef<HTMLDialogElement>(null);
  const offset = useEditSidebarWidth();

  const tryClose = () => {
    if (disableClose) {
      return;
    }
    onClose();
  };

  const escapeButtonHandler = useCallback(
    (ev: KeyboardEvent) => {
      if (disableClose) {
        return;
      }

      if (ev.code === ESC) {
        (document.activeElement as HTMLElement).blur();
        onClose();
      }
    },
    [onClose, disableClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", escapeButtonHandler, false);
    return () => {
      window.removeEventListener("keydown", escapeButtonHandler, false);
    };
  }, [escapeButtonHandler]);

  useEffect(() => {
    const ref = overlayRef.current;
    if (!ref) {
      return;
    }
    disableBodyScroll(ref);
    return () => {
      enableBodyScroll(ref);
    };
  }, []);

  return (
    <motion.div
      className="overlay-inner"
      style={{
        right: offset,
      }}
    >
      <motion.div
        id="new-overlay-backdrop"
        onClick={tryClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15, ease: "linear" }}
        style={{
          right: offset,
        }}
      />
      <motion.div
        className="overlay-inner-wrapper"
        style={{
          right: offset,
        }}
      >
        <motion.dialog
          className="overlay"
          style={{ ...(width ? { maxWidth: width } : {}) }}
          open
          ref={overlayRef}
          layoutId={layoutId}
          transition={{ type: "spring", stiffness: 250, damping: 24 }}
          {...(layoutId ? { layoutId } : DEFAULT_TRANSITION_PROPS)}
        >
          <Button
            type="button"
            className="overlay-close action ghost small"
            onClick={tryClose}
          >
            <Close />
          </Button>
          <div className="overlay-body">{children}</div>
        </motion.dialog>
      </motion.div>
    </motion.div>
  );
};

export const NewOverlay: React.FunctionComponent<Props> = ({
  open,
  children,
  onClose,
  disableClose = false,
  status,
  className,
  layoutId,
  width,
}) => {
  const classes = cx("new-overlay-wrapper", status, className);

  return (
    <div className={classes}>
      <AnimatePresence>
        {open && (
          <Inner {...{ onClose, disableClose, layoutId, width }}>
            {children}
          </Inner>
        )}
      </AnimatePresence>
    </div>
  );
};
