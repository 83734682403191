import React, { FunctionComponent } from "react";
import cx from "classnames";

import "./OwnerShare.scss";

interface Props {
  share: number;
  large?: boolean;
}

export const OwnerShare: FunctionComponent<Props> = ({
  share,
  large = false,
}) => {
  const classes = cx("owner-share", {
    large: large,
  });

  const alfa = (share * 3.6 * Math.PI) / 180; // 360 deg / 100
  const radius = 60;
  const padding = 0;
  const outer = radius + padding;

  if (share === null || share === undefined) {
    return null;
  }

  if (share === 0 || share === 100) {
    return (
      <div className={classes}>
        <svg viewBox="0 0 120 120">
          <circle
            r={radius}
            cx={outer}
            cy={outer}
            className={share === 100 ? "owner-share-fg" : "owner-share-bg"}
          />
        </svg>
        <div className="blur" />
      </div>
    );
  }

  let x = 0;
  let y = 0;
  let xOffset = 0;
  let yOffset = 0;
  let largeArcFlag = 0;
  let largeArcFlagInverse = 1;

  if (share < 25) {
    x = radius * Math.sin(alfa);
    y = Math.sqrt(radius * radius - x * x);
    xOffset = outer + x;
    yOffset = outer - y;
  } else if (share < 50) {
    x = radius * Math.sin(Math.PI - alfa);
    y = Math.sqrt(radius * radius - x * x);
    xOffset = outer + x;
    yOffset = outer + y;
  } else if (share < 75) {
    x = radius * Math.sin(alfa - Math.PI);
    y = Math.sqrt(radius * radius - x * x);
    xOffset = outer - x;
    yOffset = outer + y;
    largeArcFlag = 1;
    largeArcFlagInverse = 0;
  } else {
    x = radius * Math.sin(2 * Math.PI - alfa);
    y = Math.sqrt(radius * radius - x * x);
    xOffset = outer - x;
    yOffset = outer - y;
    largeArcFlag = 1;
    largeArcFlagInverse = 0;
  }

  return (
    <div className={classes}>
      <svg viewBox="0 0 120 120">
        <circle cx="60" cy="60" r="56" className="owner-share-circle" />
        <path
          className="owner-share-fg"
          d={
            "M " +
            outer +
            " " +
            outer +
            " " +
            "L" +
            outer +
            " " +
            padding +
            " " +
            "A" +
            radius +
            " " +
            radius +
            " 1 " +
            largeArcFlag +
            " 1 " +
            xOffset +
            " " +
            yOffset +
            " Z"
          }
        />
        <path
          className="owner-share-bg"
          d={
            "M " +
            outer +
            " " +
            outer +
            " " +
            "L" +
            xOffset +
            " " +
            yOffset +
            " " +
            "A" +
            radius +
            " " +
            radius +
            " 1 " +
            largeArcFlagInverse +
            " 1 " +
            outer +
            " " +
            padding +
            " Z"
          }
        />
        <line
          className="risk-indicator-line"
          x1={outer}
          x2={xOffset}
          y1={outer}
          y2={yOffset}
        />
        <line
          className="risk-indicator-line"
          x1={outer}
          x2={outer}
          y1={outer}
          y2={padding}
        />
      </svg>
      <div className="blur" />
    </div>
  );
};
