import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardUser, dataDashboard } from "../../data/dataDashboard";
import { UserId } from "../../data/models/CommonTypes";
import { Status } from "../../data/types";
import { useDebounceValue } from "../../hooks/useDebounce";
import { Alternative } from "../interactions/InputTypes";
import { Searchable } from "../interactions/Searchable/Searchable";
import { T } from "../translation/T";

interface Props {
  onChange: (value?: UserId) => void;
  value?: UserId;
  disabled?: boolean;
}

export const UserSelect = ({ onChange, value, disabled }: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [internal, setInternal] = useState<Status>(Status.PENDING);
  const [users, setUsers] = useState<DashboardUser[]>([]);
  const debouncedSearch = useDebounceValue(search, 150);

  const selectedUser = useMemo(
    () => users.find((u) => u.id === value),
    [users, value]
  );

  const filteredUsers = useMemo(
    () =>
      users.filter(({ name }) =>
        name.toLowerCase().includes(debouncedSearch.toLowerCase())
      ),
    [debouncedSearch, users]
  );

  const userAlternatives: Alternative<UserId | undefined>[] = useMemo(
    () => [
      {
        text: <T>All users</T>,
        value: undefined,
      },
      ...(filteredUsers || [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name, count }) => ({
          text: (
            <>
              <span>{name}</span> <small>({count})</small>
            </>
          ),
          value: id,
        })),
    ],
    [filteredUsers]
  );

  useEffect(() => {
    dataDashboard
      .loadUsers()
      .then((users) => {
        setUsers(users);
        setInternal(Status.SUCCESS);
      })
      .catch(() => {
        setInternal(Status.ERROR);
      });
  }, []);

  return (
    <div className="user-select">
      <Searchable
        onChange={setSearch}
        value={search}
        name="userSearch"
        placeholder={
          selectedUser
            ? t("Filtered on {{user}}", {
                user: selectedUser.name,
              })
            : t("Filter on user")
        }
        disabled={internal === Status.ERROR ? true : disabled}
        onSelect={(user) => {
          onChange(user);
          setSearch("");
        }}
        alternatives={userAlternatives}
      />
    </div>
  );
};
