import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { Status } from "../data/types";
import { caseStatusState } from "../state/caseStatusState";

export const useCaseEditStatus = (
  status = Status.DEFAULT
): [Status, boolean] => {
  const { edit } = useRecoilValue(caseStatusState);

  return useMemo(
    () => [
      !edit ? Status.DISABLED : status,
      !edit || status === Status.DISABLED,
    ],
    [edit, status]
  );
};
