export const MOCK_ROUTE = "/mock-route";

export const MockPage = () => {
  return (
    <div>
      <h1>This is a hidden page :)</h1>
      <h5>Here we mock stupid stuff hehehe</h5>
    </div>
  );
};
