import { FormEvent, useCallback, useState } from "react";
import { Form, FormContainer } from "../../../components/form/Form";
import { Select } from "../../../components/form/Select";
import { TextInput } from "../../../components/form/TextInput";
import { EmailBackendValidator } from "../../../components/form/validators/EmailBackendValidator";
import { EmailValidator } from "../../../components/form/validators/EmailValidator";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { AddCircleOutline } from "../../../components/icons/AddCircleOutline";
import { Button } from "../../../components/interactions/Buttons/Button";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import { UserRole } from "../../../data/dataAuth";
import { NewUserRequest, userAdministration } from "../../../data/dataManager";
import { Status } from "../../../data/types";

export interface Props {
  onSave: () => void;
}

const DEFAULT_ADD_USER = (): NewUserRequest => ({
  name: "",
  email: "",
  role: UserRole.SALES,
});

export const AddUserModal: React.FunctionComponent<Props> = ({ onSave }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="add-user-modal">
      <NewOverlay onClose={() => setIsOpen(false)} open={isOpen} width={800}>
        <AddUserModalInner onSave={onSave} onClose={() => setIsOpen(false)} />
      </NewOverlay>

      <Button onClick={() => setIsOpen(true)}>
        New User <AddCircleOutline />
      </Button>
    </div>
  );
};

interface InnerProps extends Props {
  onClose: () => void;
}

export const AddUserModalInner: React.FunctionComponent<InnerProps> = ({
  onSave,
  onClose,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const [newUserRequest, setNewUserRequest] = useState<NewUserRequest>(
    DEFAULT_ADD_USER()
  );

  const handleSave = useCallback(
    (_: FormEvent<HTMLFormElement>, form: FormContainer) => {
      if (form.isInvalid) {
        return;
      }

      setIsSaving(true);
      userAdministration
        .createUser(newUserRequest)
        .then(() => {
          onSave();
          onClose();
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [newUserRequest, onSave, onClose]
  );

  return (
    <Form onSubmit={handleSave}>
      <h4>New user</h4>
      <div className="tablet-columns">
        <div className="column">
          <TextInput
            onChange={(value) => {
              setNewUserRequest({ ...newUserRequest, name: value });
            }}
            value={newUserRequest.name}
            name="name"
            label="Name"
            placeholder="Display name of the user"
            validators={[new RequiredValidator("Name is required")]}
          />
        </div>
        <div className="column">
          <TextInput
            onChange={(value) => {
              setNewUserRequest({ ...newUserRequest, email: value });
            }}
            value={newUserRequest.email}
            name="email"
            label="Email"
            placeholder="Email of the user, will be used as login"
            validators={[
              new RequiredValidator("Email is required"),
              new EmailValidator("Email is not valid"),
              new MaxLengthValidator(
                50,
                "Email must be less than 50 characters"
              ),
              new EmailBackendValidator("Email is not valid"),
            ]}
          />
        </div>
        <div className="column">
          <Select
            label="Role"
            name="role"
            value={newUserRequest.role}
            onChange={(value) => {
              if (value === UserRole.MANAGER) {
                return;
              }
              setNewUserRequest({ ...newUserRequest, role: value });
            }}
            alternatives={[
              { value: UserRole.SALES, text: "Sales" },
              { value: UserRole.RISK, text: "Risk" },
              { value: UserRole.OPERATIONS, text: "Operations" },
            ]}
            validators={[new RequiredValidator("Role is required")]}
          />
        </div>
      </div>

      <div className="tablet-columns m-top-20">
        <div>
          <Button
            type="submit"
            status={isSaving ? Status.DISABLED : Status.DEFAULT}
            block
          >
            Save
          </Button>
        </div>

        <div>
          <Button type="button" ghost block onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Form>
  );
};
