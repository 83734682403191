import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { TextBlock } from "../../data/models/caseTypes";
import { Spinner } from "../spinner/Spinner";
import { T, TCondition, TDefault } from "../translation/T";
import { TransSwitch } from "../translation/TransSwitch";
import "./ZoomableImage.scss";

export interface Props {
  image?: string;
  alt: string;
  showTextBlocks?: boolean;
  textBlocks?: TextBlock[];
}

// https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
function convertToBlob(b64Data: string, sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays);
  return blob;
}

function updateBackgroundPosition(
  e: React.MouseEvent<HTMLElement, MouseEvent>
) {
  var zoomer = e.currentTarget;
  const offsetX = e.nativeEvent.offsetX
    ? e.nativeEvent.offsetX
    : e.nativeEvent.pageX;
  const offsetY = e.nativeEvent.offsetY
    ? e.nativeEvent.offsetY
    : e.nativeEvent.pageY;
  const x = (offsetX / zoomer.offsetWidth) * 100;
  const y = (offsetY / zoomer.offsetHeight) * 100;
  zoomer.style.backgroundPosition = x + "% " + y + "%";
}

export const ZoomableImage: React.FunctionComponent<Props> = ({
  image,
  alt,
  showTextBlocks = false,
  textBlocks,
}) => {
  const [sticky, setSticky] = useState(false);
  const imageRef = useRef<HTMLImageElement>();
  const [localImage, setLocalImage] = useState<string>();

  useEffect(() => {
    setSticky(false);
  }, [setSticky]);

  useEffect(() => {
    if (!image) {
      return;
    }

    const b64Data = image.split(",")[1];
    const blob = convertToBlob(b64Data);
    const blobUrl = URL.createObjectURL(blob);
    imageRef.current = new Image();
    imageRef.current.src = blobUrl;
    setLocalImage(imageRef.current.src);
  }, [image]);

  if (!image) {
    return <span>No image found</span>;
  }

  if (!localImage) {
    return (
      <div className="center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="zoomage__image__container">
      <div className="zoomable">
        <figure
          className={cx({
            sticky: sticky,
          })}
          style={{
            backgroundImage: `url(${localImage})`,
          }}
          onMouseMove={(e) => {
            if (sticky) {
              return;
            }
            updateBackgroundPosition(e);
          }}
          onClick={() => {
            setSticky(!sticky);
          }}
        >
          <img src={localImage} alt={alt} />
          <span className="small help-text">
            <T>Hover image to zoom</T>
          </span>
          <span className="small click-to-freeze">
            <TransSwitch>
              <TCondition condition={sticky}>
                Click to unfreeze image
              </TCondition>
              <TDefault>Click to freeze image</TDefault>
            </TransSwitch>
          </span>
          <div className="text-blocks">
            {showTextBlocks &&
              textBlocks?.map((block) => {
                return (
                  <div
                    key={block.top + block.left}
                    className="detected-text"
                    style={{
                      top: `${block.top * 100}%`,
                      left: `${block.left * 100}%`,
                      height: `${block.height * 100}%`,
                      width: `${block.width * 100}%`,
                    }}
                  ></div>
                );
              })}
          </div>
        </figure>
      </div>
    </div>
  );
};
