import { Alternative } from "./../interactions/InputTypes";
import europe from "../europe.json";
import world from "../world.json";
import i18n, { Language } from "../../i18n";
import React from "react";

const displayNamesCache: Record<string, Intl.DisplayNames> = {};
const displayLanguageCache: Record<string, Intl.DisplayNames> = {};

export const getCountryDisplayName = (
  country: string,
  language?: Language | string
) => {
  const lang =
    language || i18n.language ? i18n.language : Language.ENGLISH.toString();

  if (!displayNamesCache[lang]) {
    displayNamesCache[lang] = new Intl.DisplayNames(lang, {
      type: "region",
    });
  }

  return displayNamesCache[lang].of(country);
};

export const getLanguageName = (
  language: Language,
  currentLanguage?: Language | string
) => {
  const lang =
    currentLanguage || i18n.language
      ? i18n.language
      : Language.ENGLISH.toString();
  if (!displayLanguageCache[lang]) {
    displayLanguageCache[lang] = new Intl.DisplayNames(lang, {
      type: "language",
    });
  }

  return displayLanguageCache[lang].of(language);
};

function sortCountries(a: Alternative<any>, b: Alternative<any>) {
  return (a.text as string).localeCompare(b.text as string);
}

export function getCountryOpts(
  allowEmpty: boolean = false,
  placeholder?: string | React.ReactNode,
  language: Language | string = i18n.language,
  availableCountries?: string[]
) {
  let countryList;

  if (availableCountries) {
    countryList = availableCountries.map(
      (country): Alternative<string> => ({
        value: country,
        text: getCountryDisplayName(country, language),
        disabled: false,
      })
    );
    countryList.sort(sortCountries);
  } else {
    const europeanCountries = Object.keys(europe).map(
      (country): Alternative<string> => ({
        value: country,
        text: getCountryDisplayName(country, language),
        disabled: false,
      })
    );

    const restOfCountries = Object.keys(world).map(
      (country): Alternative<string> => ({
        value: country,
        text: getCountryDisplayName(country, language),
        disabled: false,
      })
    );

    countryList = [
      ...europeanCountries.sort(sortCountries),
      ...restOfCountries.sort(sortCountries),
    ];
  }

  if (allowEmpty) {
    countryList.unshift({
      value: "",
      text: placeholder || "None",
      disabled: false,
    });
  } else {
    countryList.unshift({
      value: "",
      text: "Country",
      disabled: true,
    });
  }

  return countryList;
}

export const getLanguageOpts = (
  allowEmpty: boolean = false,
  language: Language | string = i18n.language,
  languages: Language[] = Object.values(Language)
) => {
  const languageNames = new (Intl as any).DisplayNames(language, {
    type: "language",
  });

  const languageList = languages.map(
    (language): Alternative<string | undefined> => ({
      value: language,
      text: languageNames.of(language),
      disabled: false,
    })
  );

  if (allowEmpty) {
    languageList.unshift({
      value: undefined,
      text: "None",
      disabled: false,
    });
  } else {
    languageList.unshift({
      value: undefined,
      text: "Language",
      disabled: true,
    });
  }

  return languageList;
};
