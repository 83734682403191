import { useState, useCallback, useEffect } from "react";
import { dataOnboarding } from "../../data/dataOnboarding";
import {
  BeneficialOwnerId,
  OnboardingCaseId,
} from "../../data/models/CommonTypes";
import { BeneficialOwner } from "../../data/models/caseTypes";
import { Status } from "../../data/types";
import { ServerError } from "../../network/API";

export const useLoadOnboardingOwner = (
  onboardingCaseId?: OnboardingCaseId,
  ownerId?: BeneficialOwnerId
) => {
  const [owner, setOwner] = useState<BeneficialOwner>();
  const [loadStatus, setLoadStatus] = useState<Status>(Status.DEFAULT);
  const [errorCode, setErrorCode] = useState<number>();

  const load = useCallback(
    (onboardingCaseId: OnboardingCaseId, ownerId: BeneficialOwnerId) => {
      setLoadStatus(Status.PENDING);
      setErrorCode(undefined);
      dataOnboarding
        .getOwner(onboardingCaseId, ownerId)
        .then((response) => {
          setLoadStatus(Status.SUCCESS);
          setOwner(response);
          setLoadStatus(Status.SUCCESS);
        })
        .catch((error: ServerError<unknown>) => {
          if (error.status === 403) {
            setErrorCode(error.status);
          }
          setLoadStatus(Status.ERROR);
        });
    },
    []
  );

  useEffect(() => {
    if (onboardingCaseId && ownerId) {
      load(onboardingCaseId, ownerId);
    }
  }, [onboardingCaseId, load, ownerId]);

  return { owner, loadStatus, errorCode, reload: load };
};
