import { useCallback, useRef, useState } from "react";
import { FieldSet } from "../../../../components/fieldSet/FieldSet";
import { Form } from "../../../../components/form/Form";
import { hasRealErrors } from "../../../../components/form/FormHelpers";
import { MCC } from "../../../../components/mcc/MCC";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { clearCas } from "../../../../components/utils";
import { BusinessChannel, CompanyKyc } from "../../../../data/models/caseTypes";
import { Status } from "../../../../data/types";
import { LinkAnchors } from "../CaseEdit";
import { CaseEditError } from "../CaseEditError";
import { CaseSaveError, handleError } from "../CasePage";
import { FormName } from "../menus/CaseEditMenu";
import { ButtonPaneWithLabel } from "../../../../components/interactions/Buttons/ButtonPaneInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { caseKycState } from "../../../../state/caseKycState";
import { caseMainState } from "../../../../state/caseMainState";
import { TextInput } from "../../../../components/form/TextInput";
import { AnimateHeightMotion } from "../../../../components/animate/AnimateHeightMotion";
import { TextArea } from "../../../../components/form/TextArea";
import { useCaseEditStatus } from "../../../../hooks/useCaseEditStatus";
import { caseCompanyState } from "../../../../state/caseCompanyState";
import { dataOutreach } from "../../../../data/dataOutreach";
import { caseErrorState, caseSaveState } from "../../../../state/caseSaveState";
import { RadioGroup } from "../../../../components/form/RadioGroup";
import { T } from "../../../../components/translation/T";
import { Select } from "../../../../components/form/Select";
import { businessModelAlternatives } from "../../../../components/businessModel/BusinessModelDisplay";
import { companyKycQueue } from "../../../../data/queues/CompanyKycQueue";
import { ServerError } from "../../../../network/API";

export function CompanyData() {
  const [error, setError] = useState<CaseSaveError | null>(null);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const setDataSaved = useSetRecoilState(caseSaveState);
  const setDataError = useSetRecoilState(caseErrorState);

  const mainData = useRecoilValue(caseMainState);
  const companyData = useRecoilValue(caseCompanyState);
  const [kycData, setKycData] = useRecoilState(caseKycState);
  const prevSaved = useRef<string>(JSON.stringify(clearCas(companyData)));
  const [, disabled] = useCaseEditStatus(status);

  const { id } = mainData;

  const onClose = useCallback(() => {
    setError(null);
  }, []);

  const onSave = useCallback(
    (updatedKyc: CompanyKyc) => {
      const stringCopy = JSON.stringify(clearCas(updatedKyc));
      if (prevSaved.current === stringCopy) {
        return;
      } else {
        prevSaved.current = stringCopy;
      }

      companyKycQueue.save(
        id,
        { ...updatedKyc },
        (err: ServerError<CompanyKyc> | null, response?: CompanyKyc) => {
          if (err) {
            prevSaved.current = "";
            setStatus(Status.DEFAULT);
            handleError(err, setError);
            setDataError((dataErrors) =>
              dataErrors.concat({
                date: new Date(),
              })
            );
          }

          if (response) {
            setStatus(Status.DEFAULT);
            setKycData((prevData) => ({ ...prevData, cas: response.cas }));
            setDataSaved((dataSaved) =>
              dataSaved.concat({
                date: new Date(),
              })
            );
          }
        }
      );
    },
    [id, setDataError, setDataSaved, setKycData]
  );

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(onSave, 500);
  }, [onSave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataOutreach
        .claim(id)
        .then(() => onSave(kycData))
        .catch(onSave); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [id, onSave, kycData]);

  const onUpdate = useCallback(
    <T extends keyof CompanyKyc>(value: CompanyKyc[T], key: T) => {
      setKycData((prevData) => ({
        ...prevData,
        [key]: value,
        ...(key === "businessChannel" &&
          value === BusinessChannel.POS && {
            thirdPartyPayments: undefined,
            mainCountriesOperates: [],
          }),
      }));
    },
    [setKycData]
  );

  return (
    <Form
      onSaveTrigger={(_, form) => {
        const realErrors = hasRealErrors(form);
        if (!realErrors) {
          onSave(kycData);
        }
      }}
      name={FormName.COMPANY_DATA}
    >
      <div className="company-data">
        <ScrollPositionAnchor id={LinkAnchors.COMPANY_DATA.anchor} />

        <CaseEditError
          error={error}
          setError={setError}
          retry={retry}
          onClose={onClose}
          reclaimAndSave={reclaimAndSave}
        />
        <FieldSet header={<T>{LinkAnchors.COMPANY_DATA.name}</T>}>
          <div>
            <RadioGroup
              label="Business channel"
              value={kycData.businessChannel}
              onChange={(value) => onUpdate(value, "businessChannel")}
              alternatives={[
                {
                  text: "Point of sale",
                  value: BusinessChannel.POS,
                },
                {
                  text: "E-commerce",
                  value: BusinessChannel.ECOM,
                },
                {
                  text: "Omni channel",
                  value: BusinessChannel.OMNI,
                },
              ]}
              validators={[
                new RequiredValidator("Please select a business channel"),
              ]}
              disabled={disabled}
            />
          </div>
          <div className="tablet-columns">
            <div>
              <MCC
                onChange={(value) =>
                  setKycData((prevData) => ({ ...prevData, mcc: value }))
                }
                value={kycData.mcc}
                name="mcc"
                disabled={disabled}
              />
            </div>
            <div>
              <TextInput
                name="mostSoldItem"
                value={kycData.mostSoldItem}
                onChange={(value) => onUpdate(value, "mostSoldItem")}
                label="Most sold item"
                disabled={disabled}
              />
            </div>
          </div>
          <div>
            <Select
              label="Business model"
              value={kycData.businessModel}
              onChange={(value) => onUpdate(value, "businessModel")}
              alternatives={[
                {
                  text: <T>Select business model</T>,
                  value: undefined,
                },
                ...businessModelAlternatives,
              ]}
              placeholder="Select business model"
              validators={[
                new RequiredValidator("Please select a business channel"),
              ]}
              disabled={disabled}
            />
          </div>
          <div>
            <TextArea
              name="productDescription"
              value={kycData.productDescription}
              onChange={(value) => onUpdate(value, "productDescription")}
              label="Please describe the product/service that your company accepts payments for"
              validators={[new RequiredValidator("Description is required")]}
              disabled={disabled}
            />
          </div>
          <div>
            <ButtonPaneWithLabel
              label="Does the merchant operate other businesses not processed via the WL entity?"
              value={kycData.hasNonWorldlineBusiness}
              className="m-top-20"
              onChange={(value) => onUpdate(value, "hasNonWorldlineBusiness")}
              small
              buttons={[
                {
                  text: "Yes",
                  active: kycData.hasNonWorldlineBusiness === true,
                  data: true,
                },
                {
                  text: "No",
                  active: kycData.hasNonWorldlineBusiness === false,
                  data: false,
                },
              ]}
              validators={[new RequiredValidator("Required to answer")]}
              disabled={disabled}
            />
            <AnimateHeightMotion presence>
              {kycData.hasNonWorldlineBusiness && (
                <TextArea
                  name="nonWorldlineBusiness"
                  value={kycData.nonWorldlineBusiness}
                  onChange={(value) => onUpdate(value, "nonWorldlineBusiness")}
                  label="Describe the additional types of businesses"
                  validators={[new RequiredValidator("This field is required")]}
                  disabled={disabled}
                />
              )}
            </AnimateHeightMotion>
          </div>
          <div>
            <ButtonPaneWithLabel
              label="Is your company supervised by a regulatory authority?"
              value={kycData.regulatedEntity}
              className="m-top-20"
              onChange={(value) => onUpdate(value, "regulatedEntity")}
              small
              buttons={[
                {
                  text: "Yes",
                  active: kycData.regulatedEntity === true,
                  data: true,
                },
                {
                  text: "No",
                  active: kycData.regulatedEntity === false,
                  data: false,
                },
              ]}
              validators={[new RequiredValidator("Required to answer")]}
              disabled={disabled}
            />
            <AnimateHeightMotion presence>
              {kycData.regulatedEntity && (
                <TextInput
                  name="regulatedBy"
                  value={kycData.regulatedBy}
                  onChange={(value) => onUpdate(value, "regulatedBy")}
                  label="Name of regulator/supervisor"
                  validators={[new RequiredValidator("This field is required")]}
                  disabled={disabled}
                />
              )}
            </AnimateHeightMotion>
          </div>
          <div>
            <ButtonPaneWithLabel
              label="Is your company a licensed company?"
              value={kycData.licensedEntity}
              className="m-top-20"
              onChange={(value) => onUpdate(value, "licensedEntity")}
              small
              buttons={[
                {
                  text: "Yes",
                  active: kycData.licensedEntity === true,
                  data: true,
                },
                {
                  text: "No",
                  active: kycData.licensedEntity === false,
                  data: false,
                },
              ]}
              validators={[new RequiredValidator("Required to answer")]}
              disabled={disabled}
            />
            <AnimateHeightMotion presence>
              {kycData.licensedEntity && (
                <TextInput
                  name="licensedBy"
                  value={kycData.licensedBy}
                  onChange={(value) => onUpdate(value, "licensedBy")}
                  label="Name of the licensing body"
                  validators={[new RequiredValidator("This field is required")]}
                  disabled={disabled}
                />
              )}
            </AnimateHeightMotion>
          </div>
        </FieldSet>
      </div>
    </Form>
  );
}
