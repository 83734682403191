import { ReactNode, RefObject, useMemo } from "react";
import i18n from "../../i18n";
import { ValidationProps } from "../form/hooks/useValidation";
import { Select } from "../form/Select";
import { getCountryOpts } from "../translation/I18Utils";

interface Props extends ValidationProps {
  label: string;
  name: string;
  value?: string;
  hint?: string;
  disabled?: boolean;
  onChange: (
    value: string | undefined,
    name: string,
    event: React.ChangeEvent
  ) => void;
  scrollToRef?: RefObject<HTMLElement>;
  filteredCountries?: string[];
  allowedCountries?: string[];
  className?: string;
  placeholder?: ReactNode | string;
  message?: string;
  benelux?: boolean;
}

export const CountrySelect = ({
  value,
  onChange,
  filteredCountries = [],
  allowedCountries,
  className,
  placeholder,
  benelux = false,
  ...props
}: Props) => {
  const { language } = i18n;

  const countryAlternatives = useMemo(
    () => getCountryOpts(true, placeholder, language, allowedCountries),
    [placeholder, language, allowedCountries]
  );

  const alternatives = useMemo(() => {
    const alts = filteredCountries
      ? countryAlternatives.filter(
          (country) => !filteredCountries.includes(country.value || "")
        )
      : countryAlternatives;

    if (benelux) {
      return [
        alts[0],
        {
          value: "benelux",
          text: "Benelux",
        },
        ...alts.slice(1),
      ];
    }
    return alts;
  }, [benelux, countryAlternatives, filteredCountries]);

  return (
    <Select
      className={className}
      value={value || undefined}
      alternatives={alternatives}
      onChange={onChange}
      {...props}
    />
  );
};
