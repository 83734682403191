import React, {
  useState,
  useCallback,
  ReactNode,
  useRef,
  useEffect,
  FormEvent,
} from "react";
import { Status } from "../../data/types";
import { dataAuth } from "../../data/dataAuth";
import { Button } from "../../components/interactions/Buttons/Button";
import { Input } from "../../components/interactions/Input/Input";
import { Store, STORE_KEY } from "../../Store";
import { useIsMountedRef } from "../../hooks/useIsMounted";
import { T } from "../../components/translation/T";

interface Props {
  onNext: (code: string) => void;
}

function isValidEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function getPotentialError(email: string) {
  if (isValidEmail(email)) {
    return "";
  }

  if (!email) {
    return "Email has to be supplied";
  }

  return "Seems like this is an invalid email address?";
}

export const LoginEmail: React.FunctionComponent<Props> = ({ onNext }) => {
  const [email, setEmail] = useState<string>(
    Store.getValue<string>(STORE_KEY.STORE_LOGIN_EMAIL)
  );
  const [emailError, setEmailError] = useState<ReactNode>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const requestId = useRef<string>();
  const statusRef = useRef<Status>(Status.DEFAULT);
  const mounted = useIsMountedRef();
  const formRef = useRef<HTMLFormElement>(null);

  const updateStatus = useCallback((statusValue: Status) => {
    statusRef.current = statusValue;
    setStatus(statusValue);
  }, []);

  const onEmailChange = useCallback(
    (val: string) => {
      updateStatus(Status.DEFAULT);
      setEmailError("");
      const trimmedEamil = val.trim();
      setEmail(trimmedEamil);
    },
    [updateStatus]
  );

  const onBlur = useCallback(() => {
    const error = getPotentialError(email);
    if (error) {
      updateStatus(Status.ERROR);
      setEmailError(error);
    } else {
      updateStatus(Status.DEFAULT);
      setEmailError("");
    }
  }, [email, updateStatus]);

  const onEmailSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      updateStatus(Status.PENDING);

      const error = getPotentialError(email);
      if (error) {
        updateStatus(Status.ERROR);
        setEmailError(error);
        return;
      }

      setEmailError("");

      dataAuth
        .startLogin(email)
        .then((data) => {
          Store.setValue(STORE_KEY.STORE_LOGIN_EMAIL, email);
          requestId.current = data.requestId;
          updateStatus(Status.DEFAULT);
          onNext(requestId.current);
        })
        .catch((err) => {
          setEmailError("Ooh no! Something bad happened. Try again?");
          updateStatus(Status.ERROR);
          requestId.current = undefined;

          setTimeout(() => {
            if (!mounted.current) {
              return;
            }

            if (statusRef.current !== Status.ERROR) {
              return;
            }

            updateStatus(Status.DEFAULT);
          }, 4000);
        });
    },
    [email, onNext, updateStatus, mounted]
  );

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    const elem = formRef.current.querySelector("[name='input-login-email']");
    if (elem) {
      setTimeout(() => {
        (elem as HTMLInputElement).focus();
      }, 100);
    }
  }, []);

  return (
    <form onSubmit={onEmailSubmit} ref={formRef}>
      <Input
        label="Enter email"
        name="input-login-email"
        value={email}
        onChange={onEmailChange}
        status={status}
        message={emailError}
        onBlur={onBlur}
      />
      <Button block status={status} type="submit">
        <T>Sign in</T>
      </Button>
    </form>
  );
};
