import React, { useState, useEffect } from "react";
import { ButtonPane } from "../../../../../components/interactions/Buttons/ButtonPane";
import { T } from "../../../../../components/translation/T";
import { SecurityMarkHelpModal } from "../components/SecurityMarkHelpModal";

interface Props {
  setDone: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const Passport: React.FunctionComponent<Props> = ({ setDone }) => {
  const [clear, setClear] = useState<boolean>();
  const [faceVisible, setFaceVisible] = useState<boolean>();
  const [hasSecurityMark, setHasSecurityMark] = useState<boolean>();

  useEffect(() => {
    if (clear === false || faceVisible === false || hasSecurityMark === false) {
      setDone(false);
      return;
    }

    if (clear === undefined) {
      setDone(undefined);
      return;
    }

    if (faceVisible === undefined) {
      setDone(undefined);
      return;
    }

    if (hasSecurityMark === undefined) {
      setDone(undefined);
      return;
    }

    setDone(clear && faceVisible && hasSecurityMark);
  }, [setDone, clear, faceVisible, hasSecurityMark]);

  return (
    <div className="review-passport">
      <h4>
        <T>Passport</T>
      </h4>

      <div className="m-bottom-40">
        <T>Is the image clear and show the full passport?</T>
        <div className="small m-top-10">
          <i>
            <T>
              The text should be clearly readable and no part of the passport
              must be obstructed
            </T>
          </i>
        </div>
        <ButtonPane
          className="small m-top-20"
          onClick={(value) => setClear(value)}
          buttons={[
            {
              text: "Yes",
              active: clear,
              data: true,
            },
            {
              text: "No",
              active: clear === false,
              data: false,
            },
          ]}
        />
      </div>

      <div className="m-bottom-40">
        <T>Is the face clearly visible?</T>
        <div className="small m-top-10">
          <i>
            <T>
              The face should not be obscured by light reflections or blurry
            </T>
          </i>
        </div>
        <ButtonPane
          onClick={(value) => setFaceVisible(value)}
          className="small m-top-20"
          buttons={[
            {
              text: "Yes",
              active: faceVisible,
              data: true,
            },
            {
              text: "No",
              active: faceVisible === false,
              data: false,
            },
          ]}
        />
      </div>

      <div className="m-bottom-40">
        <T>Are the security marks clearly visible?</T>
        <SecurityMarkHelpModal />
        <div className="small m-top-10">
          <i>
            <T>
              The security marks can be a foil icon, usally a national logo.
              There can also be a second picture of the face in a plastic window
            </T>
          </i>
        </div>
        <ButtonPane
          onClick={(value) => setHasSecurityMark(value)}
          className="small m-top-20"
          buttons={[
            {
              text: "Yes",
              active: hasSecurityMark,
              data: true,
            },
            {
              text: "No",
              active: hasSecurityMark === false,
              data: false,
            },
          ]}
        />
      </div>
    </div>
  );
};
