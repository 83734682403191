import cx from "classnames";
import React from "react";
import { T } from "../../../../../components/translation/T";
import { MISSING_DATE } from "../../../../../components/utils";

interface Props {
  sentDate: string;
}

export const EventSentToOwners: React.FunctionComponent<Props> = ({
  sentDate,
}) => {
  return (
    <li
      className={cx({
        active: sentDate !== MISSING_DATE,
      })}
    >
      <div className="event">
        <div className="timeline-date">{sentDate}</div>

        <div>
          <b>
            <T>Sent to beneficial owners.</T>
          </b>
          <div className="additional-event-info text-small">
            <T>Sent request for collection of data.</T>
          </div>
        </div>
      </div>
    </li>
  );
};
