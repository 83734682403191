import React, { ReactNode } from "react";
import { generatePath } from "react-router";
import {
  AnimateHeight,
  Speed
} from "../../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../../components/boxes/SuccessBox";
import { Pending } from "../../../../components/icons/Pending";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Link } from "../../../../components/links/Link";
import { T } from "../../../../components/translation/T";
import { CaseId } from "../../../../data/models/CommonTypes";
import { Status } from "../../../../data/types";
import { CASE_REVIEW_ROUTE } from "../../review/CaseReviewPage";

interface Props {
  caseId: CaseId;
  children: ReactNode;
  onReject: () => void;
  status: Status;
}

export const ReviewRejectWrapper: React.FunctionComponent<Props> = ({
  caseId,
  children,
  onReject,
  status,
}) => {
  let elem;

  if (status === Status.PENDING) {
    elem = (
      <div className="center">
        <Pending />
      </div>
    );
  }

  if (status === Status.SUCCESS) {
    elem = (
      <div>
        <SuccessBox relative>
          <b>
            <T>Thanks!</T>
          </b>{" "}
          &nbsp;
          <T>
            We have rejected this document. The owner will be asked to supply
            identification again.
          </T>
        </SuccessBox>
        <p className="center">
          <Link link={generatePath(CASE_REVIEW_ROUTE, { id: caseId })}>
            <T>Back to review page</T>
          </Link>
        </p>
      </div>
    );
  }

  if (status === Status.ERROR) {
    elem = (
      <div>
        <ErrorBox relative>
          <T>We couldn't reject these documents. Try again?</T>
        </ErrorBox>
        <Button onClick={onReject} block className="danger-button m-top-20">
          <T>Reject</T>
        </Button>
        <div className="center m-top-10">
          <Link link={generatePath(CASE_REVIEW_ROUTE, { id: caseId })}>
            <T>Back to review page</T>
          </Link>
        </div>
      </div>
    );
  }

  if (status === Status.DEFAULT) {
    elem = children;
  }

  return (
    <div className="reject-wrapper">
      <AnimateHeight name={status} speed={Speed.VERY_SLOW}>
        <div style={{ padding: "1px 0" }}>{elem}</div>
      </AnimateHeight>
    </div>
  );
};
