import styles from "./MissingColumbusData.module.scss";
import { T } from "../../../components/translation/T";

export const MissingColumbusData = ({ date }: { date: Date }) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <T>This case is missing data for automatic Columbus archiving</T>
      </div>
      <div className={styles.subtitle}>
        <T>{`Reason: Case was reviewed before the release date for the Columbus API integration: ${date.toLocaleDateString()}`}</T>
      </div>
      <div className={styles.text} style={{ marginBottom: 10 }}>
        <T>
          To archive this case in Columbus, please move it back to 'Pending
          review' and redo the review. This step is necessary as we didn't store
          required data before the Columbus integration.
        </T>
      </div>
      <div className={styles.subtitle}>
        <T>However!</T>
      </div>
      <div className={styles.text} style={{ marginBottom: 10 }}>
        <T>
          If the case files have already been manually archived, you can ignore
          this message. This message is
        </T>
        <strong>
          <T> only </T>
        </strong>
        <T>
          relevant for cases that haven't been archived yet, and wishes to do so
          with the Columbus integration.
        </T>
      </div>
      <div className={styles.text} style={{ marginBottom: 10 }}>
        <T>We apologize for this inconvenience.</T>
      </div>
    </div>
  );
};
