import { DateTimeFormatOptions } from "luxon";
import { nanoid } from "nanoid";
import { TFunction } from "react-i18next";
import { UTCDate } from "../data/models/CommonTypes";
import { MerchantCategory } from "../data/models/caseTypes";
import {
  Country,
  CountryCurrency,
  Language,
  TRANSLATION_NAMESPACE,
} from "../i18n";
import { PHONE_COUNTRY_CODES } from "./form/validators/PhoneCountryCodes";

export const MISSING_DATE = " - ";

export function id() {
  return nanoid();
}

interface CasType {
  cas?: number;
  [key: string]: any;
}

export function clearCas(data: CasType | CasType[]) {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const copy = { ...item };
      delete copy.cas;
      return copy;
    });
  }

  const copy = { ...data };
  delete copy.cas;
  return copy;
}

export function getMccOpts(categories: MerchantCategory[], t: TFunction) {
  return categories.map((category) => ({
    value: category.code,
    text: `${category.code} - ${t(category.name, {
      ns: TRANSLATION_NAMESPACE.MCC,
    })}`,
    disabled: false,
  }));
}

export function getCost(country: Country, value: number, digits?: number) {
  // TODO: Add lang parameter here
  return new Intl.NumberFormat(`${country}-en`, {
    style: "currency",
    currency: CountryCurrency[country],
    minimumFractionDigits: digits || 2,
    maximumFractionDigits: digits || 2,
  }).format(value);
}

export function getIntlDate(
  date?: Date | string | UTCDate,
  language: Language = Language.ENGLISH,
  options?: DateTimeFormatOptions
) {
  if (!date) {
    return MISSING_DATE;
  }

  const dateObject = new Date(date);

  if (dateObject instanceof Date && !isNaN(dateObject as any)) {
    return new Intl.DateTimeFormat(language, options).format(dateObject);
  } else {
    return MISSING_DATE;
  }
}

export const getCountryFromPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return;
  }

  for (let i = 5; i >= 1; i--) {
    const code = phoneNumber.substring(0, i);
    const country: string | undefined = PHONE_COUNTRY_CODES[code];
    if (country) {
      return country;
    }
  }
};
