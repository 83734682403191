import styles from "./StaleReason.module.scss";
import { FC, useEffect, useState } from "react";
import { T } from "../translation/T";
import { CaseStaleReason } from "../../data/models/caseTypes";
import { Radio } from "../icons/Radio";
import { Button } from "../interactions/Buttons/Button";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { staleReasonToString } from "./StaleReasonChip";
import { useWidth } from "../../hooks/useWidth";
import { dataOutreach } from "../../data/dataOutreach";
import { CaseId } from "../../data/models/CommonTypes";
import { Status } from "../../data/types";
import { MdError } from "react-icons/md";
import { useHeight } from "../../hooks/useHeight";
import { useRecoilValue } from "recoil";
import { UserSession } from "../../data/dataAuth";
import { userState } from "../../state/userState";
import { CaseUser } from "./StaleReasonChip";

interface Props {
  chipPosition: {
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    bottom: number;
  };
  open: boolean;
  selectedReason: CaseStaleReason | null;
  onClose: () => void;
  caseId: CaseId;
  reloadOutreachCases: () => void;
  caseUser?: CaseUser;
}

const isAllowedToEdit = (
  caseUser?: CaseUser,
  currentUser?: UserSession | null
) => {
  if (!currentUser) return false;
  if (!caseUser) return false;
  return caseUser.id === currentUser.userId;
};

export const StaleReasonPopover: FC<Props> = ({
  open,
  selectedReason,
  chipPosition,
  onClose,
  caseId,
  reloadOutreachCases,
  caseUser,
}) => {
  const screenWidth = useWidth();
  const screenHeight = useHeight();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const currentUser = useRecoilValue(userState);
  const canEdit = isAllowedToEdit(caseUser, currentUser);

  useEffect(() => {
    if (!open) return;
    setStatus(Status.DEFAULT);
  }, [open]);

  const handleSelectReason = async (reason: CaseStaleReason) => {
    if (status === Status.PENDING || !canEdit) return;

    try {
      setStatus(Status.PENDING);
      await dataOutreach.updateStaleReason(caseId, reason);
      reloadOutreachCases();
      setStatus(Status.SUCCESS);
    } catch (error) {
      setStatus(Status.ERROR);
    }
  };

  const handleRemoveReason = async () => {
    if (status === Status.PENDING || !canEdit) return;
    await dataOutreach.removeStaleReason(caseId);
    reloadOutreachCases();
  };

  const getLeftPosition = () => {
    const POPOVER_WIDTH = 375;
    const MARGIN_X = 10;

    // right position of popover
    const popoverRight = chipPosition.left + POPOVER_WIDTH;

    // x coordinate of the middle of the chip
    const chipXCoordinateMiddle = chipPosition.left + chipPosition.width / 2;

    const popoverHalfWidth = POPOVER_WIDTH / 2;

    // final left position of the popover if to be centered on the chip
    const centeredPopoverLeft = chipXCoordinateMiddle - popoverHalfWidth;

    const overflowingScreenLeft = centeredPopoverLeft < 0;
    const overflowingScreenRight = popoverRight > screenWidth;

    if (overflowingScreenLeft) {
      return chipPosition.right;
    }

    if (overflowingScreenRight) {
      const widthOverflow = popoverRight - screenWidth;
      // return chipPosition.left - POPOVER_WIDTH;
      return chipPosition.left - widthOverflow - MARGIN_X;
    }

    return centeredPopoverLeft;
  };

  const getTopPosition = () => {
    const POPOVER_HEIGHT = 535;
    // add logic for now overflowing
    const MARGIN_Y = 10;

    const popoverBottom = chipPosition.bottom + POPOVER_HEIGHT;
    const popoverTop = chipPosition.top - POPOVER_HEIGHT;

    const overflowingScreenBottom = popoverBottom > screenHeight;
    const overflowingScreenTop = popoverTop < 0;

    if (overflowingScreenBottom && overflowingScreenTop) {
      const chipYMiddle = chipPosition.top + chipPosition.height / 2;
      const popoverHalfHeight = POPOVER_HEIGHT / 2;
      const centeredPopoverTop = chipYMiddle - popoverHalfHeight;

      const isStillOverflowing =
        centeredPopoverTop < 0 ||
        centeredPopoverTop + POPOVER_HEIGHT > screenHeight;
      if (isStillOverflowing) return 0;
      return centeredPopoverTop;
    }

    if (overflowingScreenBottom) {
      return chipPosition.bottom - POPOVER_HEIGHT - MARGIN_Y;
    }

    return chipPosition.bottom + MARGIN_Y;
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={styles.popover}
          initial={{
            scale: 0.8,
            opacity: 0,
            left: getLeftPosition(),
            top: getTopPosition(),
          }}
          exit={{ scale: 0.8, opacity: 0 }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          transition={{ duration: 0.1 }}
        >
          <div
            className={`${styles.header} ${!canEdit && styles.headerDisabled}`}
          >
            <strong>
              <T>
                {canEdit ? "Choose stale reason" : "Not allowed, not your case"}
              </T>
            </strong>
          </div>
          <ul className={styles.ul}>
            {Object.values(CaseStaleReason).map((reason) => {
              return (
                <ListItem
                  key={reason}
                  onClick={() => handleSelectReason(reason)}
                  reason={reason}
                  selected={reason === selectedReason}
                  disabled={canEdit ? false : true}
                />
              );
            })}
          </ul>
          <div className={styles.footer}>
            <div className={styles.buttons}>
              <Button className={"mini ghost"} type="button" onClick={onClose}>
                <T>Close</T>
              </Button>
              {selectedReason && (
                <Button
                  className={"mini"}
                  type="button"
                  onClick={handleRemoveReason}
                  status={
                    canEdit
                      ? status === Status.PENDING
                        ? Status.PENDING
                        : Status.DEFAULT
                      : Status.DISABLED
                  }
                >
                  <T>Remove reason</T>
                </Button>
              )}
            </div>
            {status === Status.ERROR && (
              <div className={styles.errorMessage}>
                <MdError />
                <T>Could not update stale reason</T>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const ListItem = ({
  reason,
  selected,
  onClick,
  disabled,
}: {
  reason: CaseStaleReason;
  selected: boolean;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <li
      onClick={onClick}
      className={`${styles.li} ${selected && styles.liSelected} ${
        disabled && styles.liDisabled
      }`}
    >
      <>
        <div className={styles.text}>
          <T>{staleReasonToString(reason)}</T>
        </div>
        <div className={styles.icon}>
          <Radio checked={selected} disabled={disabled} />
        </div>
      </>
    </li>
  );
};
