import React, { useCallback } from "react";
import cx from "classnames";
import { BeneficialOwner, Validity } from "../../../../data/models/caseTypes";
import { Edit } from "../../../../components/icons/Edit";
import { BeneficialOwnerListItem } from "./BeneficialOwnerListItem";
import { Minus } from "../../../../components/icons/Minus";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../data/types";
import "./BeneficialOwnerRow.scss";
import { Disabled } from "../../../../components/icons/Disabled";

interface Props {
  owner: BeneficialOwner;
  setActiveOwner: (owner: BeneficialOwner) => void;
  status: Status;
  onRemove: (owner: BeneficialOwner) => void;
  validity?: Validity;
  leftActions?: React.ReactNode;
  allowRemove?: boolean;
}

export const BeneficialOwnerRow: React.FunctionComponent<Props> = ({
  owner,
  setActiveOwner,
  status,
  onRemove,
  validity,
  leftActions,
  allowRemove = false,
}) => {
  const onClick = useCallback(() => {
    if (owner.outreachSent) return;
    setActiveOwner(owner);
  }, [owner, setActiveOwner]);

  const onRemoveClick = useCallback(
    (_: any, e: React.MouseEvent) => {
      e.stopPropagation();
      onRemove(owner);
    },
    [owner, onRemove]
  );

  const buttonClass = cx("beneficial-owner-row-button", {
    "beneficial-owner-row-button--disabled": owner.outreachSent,
    status,
  });

  const spanClass = cx("button", "action", "ghost", "small", "positive", {
    "is-disabled": owner.outreachSent,
    "no-border": owner.outreachSent,
    status,
  });

  return (
    <div className={"beneficial-owner-row"}>
      {leftActions && (
        <div className="beneficial-owner-left-action">{leftActions}</div>
      )}
      <button
        onClick={onClick}
        className={buttonClass}
        disabled={owner.outreachSent ? true : false}
      >
        <BeneficialOwnerListItem
          {...{ owner, status, validity }}
          actions={
            <span className={spanClass}>
              {owner.outreachSent ? <Disabled /> : <Edit />}
            </span>
          }
        />
      </button>
      {allowRemove && (
        <Button
          status={status}
          action
          ghost
          className="beneficial-owner-row-action negative danger-button"
          onClick={onRemoveClick}
        >
          <Minus />
        </Button>
      )}
    </div>
  );
};
