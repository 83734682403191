import { Link } from "../../../components/links/Link";
import { T } from "../../../components/translation/T";
import { dataOutreachDownloadUrl } from "../../../data/dataOutreach";
import { CaseId } from "../../../data/models/CommonTypes";
import { CaseCompanyState } from "../../../state/caseCompanyState";

interface Props {
  id: CaseId;
  company: CaseCompanyState;
}

export const DownloadCase = ({ id, company }: Props) => {
  const fileName = `outreach_case_${company.legalName}_${company.companyRegistrationId}.zip`;

  return (
    <div className="download-case">
      <Link
        className="as-button ghost m-bottom-30 block"
        external
        download={fileName}
        link={dataOutreachDownloadUrl(id)}
      >
        <T>Download case files</T>
      </Link>
    </div>
  );
};
