import { useNavigate, useParams } from "react-router";
import { Button } from "../../../components/interactions/Buttons/Button";
import { CaseId } from "../../../data/models/CommonTypes";
import { useRecoilValue } from "recoil";
import { caseStatusState } from "../../../state/caseStatusState";
import { CaseStatus } from "../../../data/models/caseTypes";
import { T } from "../../../components/translation/T";
import { caseStatusToString } from "../../../components/caseStatus/CaseStatus";
import { dataOutreach } from "../../../data/dataOutreach";
import { Visibility } from "../../../components/icons/Visibility";
import { Edit } from "../../../components/icons/Edit";
import { AiOutlineStepBackward } from "react-icons/ai";
import { useState, useEffect } from "react";
import { Status } from "../../../data/types";
import cx from "classnames";
import { useLoadCase } from "../../../hooks/useLoadCase";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { AnimatePresence, motion, MotionProps } from "framer-motion";

const motionProps: MotionProps = {
  initial: { opacity: 0.8 },
  animate: { opacity: 1, transform: "translateY(-60px)" },
  exit: {
    opacity: 0,
    transform: "translateY(0px)",
    transition: {
      opacity: { duration: 0.2 },
      transform: { duration: 0.25 },
    },
  },
};

export const EditCase = ({ isAllowedToEdit }: { isAllowedToEdit: boolean }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: CaseId }>();
  const caseStatus = useRecoilValue(caseStatusState);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [showToast, setShowToast] = useState<boolean>(false);

  const { reload: reloadCase } = useLoadCase();

  // TODO: need to make a check

  useEffect(() => {
    if (caseStatus.sentToOnboarding) {
      setStatus(Status.DISABLED);
    }
  }, [caseStatus.sentToOnboarding]);

  const onEditCaseClick = () => {
    navigate(`/outreach/${id}`);
  };

  const onMoveCaseBack = () => {
    if (!id) return;

    dataOutreach
      .completedToPending(id)
      .then(() => {
        reloadCase(id, caseStatus.currentUser.id);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 8000);
      })
      .catch((e: any) => {
        setStatus(Status.ERROR);
        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 3000);
      });
  };

  const editCase =
    caseStatus.status === CaseStatus.OUTREACH ||
    caseStatus.status === CaseStatus.VERIFICATION;

  const moveToPendingReivewClass = cx("move-to-pending-review", {
    "move-to-pending-review--disabled": status === Status.DISABLED,
  });

  return (
    <div className="edit-case">
      <AnimatePresence>
        {showToast && (
          <motion.div className="edit-case-toaster" {...motionProps}>
            <SuccessBox>
              <T>Case successfully moved back to Pending review</T>
            </SuccessBox>
          </motion.div>
        )}
      </AnimatePresence>
      <Button block onClick={onEditCaseClick}>
        <div className="edit-case-button-text">
          {editCase ? (
            <>
              <Edit /> <T>Edit case</T>
            </>
          ) : (
            <>
              <Visibility />
              <T>View case</T>
            </>
          )}
        </div>
      </Button>
      {caseStatus.status === CaseStatus.COMPLETED_VERIFICATION && (
        <>
          {/* <div className="edit-case-message">
        <T
          id='You can only edit a case in "{{outreach}}" and "{{verification}}"'
          options={{
            outreach: caseStatusToString(CaseStatus.OUTREACH),
            verification: caseStatusToString(CaseStatus.VERIFICATION),
          }}
        />
      </div> */}
          <div className={moveToPendingReivewClass}>
            <strong>
              {status === Status.DEFAULT ? <T>Be aware!</T> : <T>Notice</T>}
            </strong>
            {status === Status.DEFAULT ? (
              <p>
                <T
                  id='If you move the case back to "{{ verification }}" you will have to
                 verify the beneficial owners again.'
                  options={{
                    verification: caseStatusToString(CaseStatus.VERIFICATION),
                  }}
                />
              </p>
            ) : (
              <p>
                <T>
                  This case has been sent to onboarding and is no longer
                  editable.
                </T>
              </p>
            )}
            <Button
              status={isAllowedToEdit ? status : Status.DISABLED}
              block
              onClick={onMoveCaseBack}
              className="move-to-pending-review-button warning-button"
            >
              <AiOutlineStepBackward />
              <T
                id='Move case back to "{{verification}}"'
                options={{
                  verification: caseStatusToString(CaseStatus.VERIFICATION),
                }}
              />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
