import { useSetRecoilState } from "recoil";
import { translationStateSelector } from "../../state/translationState";
import cx from "classnames";
import { Badge } from "../badge/Badge";
import { TranslationAlternative } from "./TranslationManagement";
import "./TranslationManagementOverlayAlternatives.scss";

interface Props {
  alternatives: TranslationAlternative[];
  selected: string;
}

export const TranslationManagementOverlayAlternatives: React.FunctionComponent<
  Props
> = ({ alternatives, selected }) => {
  const setTranslation = useSetRecoilState(translationStateSelector);

  return (
    <div className="translation-management-overlay-alternatives">
      <h5>
        Conditional translation <Badge>{alternatives.length}</Badge>
      </h5>
      <ul className="conditional-translation-list">
        {alternatives.map(({ value, translated }) => (
          <li key={value} className="translation-row">
            <div
              onClick={() => {
                setTranslation({
                  selected: value,
                  alternatives: alternatives.map(({ value }) => value),
                });
              }}
              className={cx({ selected: value === selected })}
            >
              <span>{value}</span>
              {translated ? (
                <i>{translated}</i>
              ) : (
                <i className="not-found">Not translated</i>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
