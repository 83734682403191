import cx from "classnames";
import React from "react";
import { T } from "../../../../../components/translation/T";
import { CaseStatus } from "../../../../../data/models/caseTypes";

interface Props {
  status: CaseStatus;
  date: string;
}

export const EventComplete: React.FunctionComponent<Props> = ({
  status,
  date,
}) => {
  return (
    <li
      className={cx({
        active: status === CaseStatus.COMPLETED_VERIFICATION,
      })}
    >
      <div className="event">
        <div className="timeline-date">{date}</div>{" "}
        <b>
          <T>Case complete</T>
        </b>
      </div>
    </li>
  );
};
