import { Card } from "../../../components/card/Card";
import { CompanyRegistrationIdDisplay } from "../../../components/companyRegistrationId/CompanyRegistrationIdDisplay";
import { DataList } from "../../../components/datalist/DataList";
import { DataListItem } from "../../../components/datalist/DataListItem";
import { FlagWithCountry } from "../../../components/flags/FlagWithCountry";
import { LegalEntityTypeDisplay } from "../../../components/legalEntityType/LegalEntityTypeDisplay";
import { Link } from "../../../components/links/Link";
import { T } from "../../../components/translation/T";
import { Address } from "../../../data/models/caseTypes";
import { CaseCompanyState } from "../../../state/caseCompanyState";
import { companyLink } from "../../../util/CompanyLink";

const buildAddress = (address: Address) => {
  return (
    <>
      <div>{address.street || "-"}</div>
      <div>{`${address.postalCode || ""} ${address.city || ""}`}</div>
      <div>{address.country || "-"}</div>
    </>
  );
};

interface Props {
  company: CaseCompanyState;
  external?: boolean;
}

export const CompanyCard = ({ company, external }: Props) => {
  const { legalName, companyRegistrationId, country, legalEntityType } =
    company;

  const companyName = legalName ? legalName : companyRegistrationId;

  return (
    <Card>
      <Card.Header>
        <h5>
          <T>Company</T>
        </h5>
        <FlagWithCountry
          className="header-badge"
          country={country}
          rounded
          height={18}
        />
      </Card.Header>
      <div className="card-body">
        <DataList>
          <DataListItem
            text="Company name"
            value={
              external ? (
                companyName
              ) : (
                <Link link={companyLink(country, companyRegistrationId)}>
                  {companyName}
                </Link>
              )
            }
          />
          <DataListItem
            text="Company number"
            value={
              <CompanyRegistrationIdDisplay
                country={country}
                id={companyRegistrationId}
              />
            }
          />
          <DataListItem
            text="Legal entity type"
            value={<LegalEntityTypeDisplay legalEntityType={legalEntityType} />}
          />
          <DataListItem text="Address" value={buildAddress(company.address)} />
        </DataList>
      </div>
    </Card>
  );
};
