import {
  BeneficialOwnerId,
  CaseId,
  OnboardingCaseId,
  UTCDate,
} from "./models/CommonTypes";
import { BeneficialOwner, Communication } from "./models/caseTypes";
import { data } from "./data";
const BASE_URL = "/api/review";

export interface ConfirmResponse {
  signoffCompleted?: boolean;
}

export interface OnboardingTeam {
  name: string;
  email?: string;
  team: OnboardingTeamNames;
  isKYC: boolean;
  isUBO: boolean;
}

export enum OnboardingTeamNames {
  TEST = "TEST",
  RB_MM_BNL = "RB_MM_BNL",
  RB_KIA_CZ = "RB_KIA_CZ",
  RB_KIA_NL = "RB_KIA_NL",
  RB_KIA_BE = "RB_KIA_BE",
  GSV = "GSV",
  DC_HBR = "DC_HBR",
}

export interface SendOnboardingTeam {
  team: OnboardingTeamNames;
}

export interface OnboardingStatus {
  created: UTCDate;
  sent: UTCDate;
  viewed?: UTCDate;
  closed?: UTCDate;
  team: OnboardingTeamNames;
  email: string;
  id: OnboardingCaseId;
}

// the amount of files that should be archived in columbus. UboFiles is always 1, since it is a zip-file containing all the UBO files

export const dataReview = {
  confirmOwner: (owner: BeneficialOwner, expiryDate: string) =>
    data.post<ConfirmResponse>(`${BASE_URL}/owner/${owner.id}/signoff`, {
      expiryDate,
    }),

  rejectOwner: (owner: BeneficialOwner) =>
    data.post(`${BASE_URL}/owner/${owner.id}/reject`),

  sendToOnboarding: (caseId: CaseId, sendOnboardingTeam: SendOnboardingTeam) =>
    data.post<OnboardingStatus>(
      `${BASE_URL}/${caseId}/send-to-onboarding`,
      sendOnboardingTeam
    ),

  getOnboardingStatus: (caseId: CaseId) =>
    data.get<OnboardingStatus>(`${BASE_URL}/${caseId}/onboarding`),

  getOwner: (id: BeneficialOwnerId) =>
    data.get<BeneficialOwner>(`${BASE_URL}/owner/${id}`, true),

  remindOwner: (id: BeneficialOwnerId, communication: Communication) =>
    data.post(`${BASE_URL}/remind/${id}`, communication),

  getOnboardingTeams: () => data.get<OnboardingTeam[]>(`${BASE_URL}/teams`),
};
