import { DataList } from "../../../components/datalist/DataList";
import { DataListItem } from "../../../components/datalist/DataListItem";
import { T } from "../../../components/translation/T";
import { getCost } from "../../../components/utils";
import { CompanyKyc } from "../../../data/models/caseTypes";
import { Country } from "../../../i18n";
import { MainCountriesOfOperation } from "./MainCountriesOfOperation";

interface Props {
  kyc: CompanyKyc;
  country: Country;
}

export const CompleteFinancialKyc = ({ kyc, country }: Props) => {
  const {
    rangeOfTransactionsMin,
    rangeOfTransactionsMax,
    rangeOfWlTransactionsMin,
    rangeOfWlTransactionsMax,
    estimatedAnnualTurnover,
    estimatedTransactionsPerYear,
    thirdPartyPayments,
    listedEntity,
    listedIsin,
    listedTickerName,
    mainCountriesOperates,
  } = kyc;
  return (
    <div className="completed-kyc">
      <div>
        <DataList align="left">
          <DataListItem
            text="Third party payments"
            value={thirdPartyPayments ? <T>Yes</T> : <T>No</T>}
          />
          {listedEntity ? (
            <DataListItem
              text="Listed entity"
              value={`${listedIsin} - ${listedTickerName}`}
            />
          ) : (
            <DataListItem text="Listed entity" value={<T>No</T>} />
          )}
        </DataList>

        <div className="divider" />

        <DataList type="vertical" align="left">
          {mainCountriesOperates && mainCountriesOperates.length > 0 && (
            <DataListItem
              text="Main countries of operation (more than 20% of annual turnover)"
              value={
                <MainCountriesOfOperation countries={mainCountriesOperates} />
              }
            />
          )}
          <DataListItem
            text="Estimated range of transaction value"
            value={`${getCost(
              country,
              rangeOfTransactionsMin || 0
            )} - ${getCost(country, rangeOfTransactionsMax || 0)}`}
          />
          <DataListItem
            text="Estimated range of WL processed transaction value"
            value={`${getCost(
              country,
              rangeOfWlTransactionsMin || 0
            )} - ${getCost(country, rangeOfWlTransactionsMax || 0)}`}
          />
          <DataListItem
            text="Estimated annual turnover"
            value={getCost(country, estimatedAnnualTurnover || 0)}
          />
          <DataListItem
            text="Estimated number of transactions per year"
            value={estimatedTransactionsPerYear}
          />
        </DataList>
      </div>
    </div>
  );
};
