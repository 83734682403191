import { atom } from "recoil";
import { BeneficialOwnerId } from "../data/models/CommonTypes";
import { BeneficialOwner } from "../data/models/caseTypes";

export const DUMMY_OWNER_ID =
  "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" as BeneficialOwnerId;

// export function checkIdExpiry(associates: Associate[]) {
//   return associates.map((associate) => {
//     if (!associate.identity) {
//       return associate;
//     }

//     if (!associate.identity.idExpiry) {
//       return associate;
//     }

//     if (associate.identity.idExpiry === NO_EXPIRY_DATE) {
//       return {
//         ...associate,
//         identity: { ...associate.identity, noExpiry: true },
//       };
//     }

//     return associate;
//   });
// }

export const caseBeneficialOwnerState = atom<BeneficialOwner[]>({
  key: "case-beneficial-owner-state",
  default: [],
});
