import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import cx from "classnames";
import { Input, FormContext } from "./FormContext";
import { VALIDATION_STATE } from "./hooks/useValidation";
import { id } from "../utils";
import { MultiFormContext, useMultiForm } from "./MultiFormContext";
import "./Form.scss";
import { scrollToError } from "./utils/scrollToError";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >,
    "onSubmit" | "onBlur"
  > {
  formContainer?: React.MutableRefObject<FormContainer | undefined>;
  onSubmit?(
    event: React.FormEvent<HTMLFormElement>,
    formRef: FormContainer
  ): void;
  onSaveTrigger?(
    event: React.FormEvent<HTMLFormElement>,
    formRef: FormContainer
  ): void;
  forceValidation?: boolean;
}

export class FormContainer {
  public inputs: Input[] = [];
  public id: string = id();
  private listeners: ((formContainer: FormContainer) => void)[] = [];

  constructor(
    public setForceErrors: (value: boolean) => void,
    public name: string | undefined
  ) {}

  updateInput(newInput: Input) {
    const existingIdx = this.inputs.findIndex(
      (input) => input.name === newInput.name
    );
    if (existingIdx > -1) {
      this.inputs.splice(existingIdx, 1, newInput);
    } else {
      this.inputs.push(newInput);
    }
    this.listeners.forEach((listener) => listener(this));
  }

  removeInput(inputName: string) {
    const existingIdx = this.inputs.findIndex(
      (input) => input.name === inputName
    );
    if (existingIdx > -1) {
      this.inputs.splice(existingIdx, 1);
    }
  }

  resetValidation() {
    this.inputs.forEach((input) => input.resetValidation());
  }

  isInputValid(name: string | undefined) {
    return this.inputs.find((input) => input.name === name)?.isValid === true;
  }

  get isValid(): boolean {
    return this.inputs.every((input) => input.isValid);
  }

  get isInvalid(): boolean {
    return !this.inputs.every((input) => input.isValid);
  }

  get isPending(): boolean {
    return this.inputs.some(
      (input) => input.validationState === VALIDATION_STATE.PENDING
    );
  }

  addListener(listener: (formContainer: FormContainer) => void) {
    this.listeners.push(listener);
  }

  removeListener(listener: (formContainer: FormContainer) => void) {
    const idx = this.listeners.indexOf(listener);
    if (idx > -1) {
      this.listeners.splice(idx, 1);
    }
  }
}

export function shouldSaveOnBlur(element: HTMLElement) {
  const type = (element as unknown as HTMLInputElement)?.type;
  return [
    "text",
    "date",
    "number",
    "textarea",
    "button",
    "select-one",
    "radio",
  ].includes(type);
}

export const Form = React.forwardRef<HTMLFormElement, Props>(
  (
    { formContainer, onSubmit, onSaveTrigger, forceValidation, ...props },
    ref
  ) => {
    const [forceErrors, setForceErrors] = useState(forceValidation);
    const formRef = useRef<FormContainer>(
      new FormContainer((value) => setForceErrors(value), props.name)
    );
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const delayedSubmit = useRef<() => void | undefined>();
    const delayedBlur = useRef<() => void | undefined>();
    const multiForm = useMultiForm();
    const formProps = { ...props };
    const setFormValidity = useContext(MultiFormContext)?.setValidity;

    useEffect(() => {
      const name = formRef.current.name;
      const savedFormRef = formRef.current;
      multiForm?.addForm(savedFormRef);
      return () => {
        multiForm?.removeForm(savedFormRef);
        if (name) {
          setFormValidity?.({
            action: "DELETE",
            form: name,
          });
        }
      };
    }, [multiForm, setFormValidity]);

    const onSubmitDelayed = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.persist();
        if (!forceErrors) {
          setForceErrors(true);
        }

        if (formRef.current.isPending && onSubmit) {
          setShowSpinner(true);
          delayedSubmit.current = () => onSubmit(event, formRef.current);
        } else {
          onSubmit && onSubmit(event, formRef.current);
          if (formRef.current.isValid) {
            setForceErrors(false);
            formRef.current.resetValidation();
          } else {
            const firstInputError = formRef.current.inputs.find(
              (input) => input.isInvalid
            );

            if (firstInputError) {
              scrollToError(firstInputError.scrollToRef);
            }
          }
        }
      },
      [onSubmit, forceErrors]
    );

    const onBlurSaveDelayed = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.persist();

        if (!shouldSaveOnBlur(event.target as HTMLElement)) {
          return;
        }
        if (formRef.current.isPending && onSaveTrigger) {
          delayedBlur.current = () => onSaveTrigger(event, formRef.current);
        } else {
          onSaveTrigger && onSaveTrigger(event, formRef.current);
        }
      },
      [onSaveTrigger]
    );

    // const onChangeSaveDelayed = useCallback(
    //   (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     event.persist();

    //     if (shouldSaveOnBlur(event.target as HTMLElement)) {
    //       return;
    //     }

    //     if (formRef.current.isPending && onSaveTrigger) {
    //       delayedBlur.current = () => onSaveTrigger(event, formRef.current);
    //     } else {
    //       onSaveTrigger && onSaveTrigger(event, formRef.current);
    //     }
    //   },
    //   [onSaveTrigger]
    // );

    const updateInput = useCallback(
      (newInput: Input) => {
        formRef.current.updateInput(newInput);
        if (typeof formRef.current.name !== "undefined") {
          setFormValidity?.({
            action: "SET",
            form: formRef.current.name,
            isValid: formRef.current.isValid,
          });
        }

        if (!formRef.current.isPending) {
          setShowSpinner(false);

          // Allow parent to update state before calling its onSubmit() function
          setTimeout(() => {
            /**
             * Since this isn't a closure pending can change between this being called and executed.
             * By checken here there shouldn't be any code that can change formRef.current until the
             * form onSubmit has executed
             */
            if (formRef.current.isPending) {
              return;
            }
            if (delayedSubmit.current) {
              delayedSubmit.current?.();
              delayedSubmit.current = undefined;
              if (formRef.current.isValid) {
                setForceErrors(false);
                formRef.current.resetValidation();
              }
            }
            if (delayedBlur.current) {
              delayedBlur.current?.();
              delayedBlur.current = undefined;
            }
          });
        }
      },
      [setFormValidity]
    );

    const removeInput = useCallback((inputName: string) => {
      formRef.current.removeInput(inputName);
    }, []);

    useEffect(() => {
      if (formContainer) {
        formContainer.current = formRef.current;
      }
    }, [formContainer]);

    useEffect(() => {
      setForceErrors(forceValidation);
    }, [forceValidation]);

    const value = useMemo(
      () => ({
        forceErrors: forceErrors || false,
        updateInput,
        removeInput,
      }),
      [forceErrors, removeInput, updateInput]
    );

    return (
      <form
        {...formProps}
        className={cx(formProps.className, "wl-form", {
          "wl-form-pending": showSpinner,
        })}
        onSubmit={onSubmit && onSubmitDelayed}
        onBlur={onSaveTrigger && onBlurSaveDelayed}
        // onChange={onSaveTrigger && onChangeSaveDelayed}
        noValidate
        autoComplete="off"
        ref={ref}
      >
        <FormContext.Provider value={value}>
          {props.children}
          {showSpinner ? (
            <div className="form-pending-spinner">Loading...</div>
          ) : null}
        </FormContext.Provider>
      </form>
    );
  }
);
