import { API, ServerError } from "../network/API";
import { LOGOUT_ROUTE } from "../pages/login/Logout";
import { setRecoilExternalState } from "../RecoilExternalStatePortal";
import { userState } from "../state/userState";
import { Store, STORE_KEY } from "../Store";

function actAndDelagate<T>(err: ServerError<T>) {
  if (err.status === 401) {
    Store.setValue(STORE_KEY.STORE_USER, null);
    setRecoilExternalState(userState, null);
    const params = new URLSearchParams();
    const currentUrl = new URL(window.location.href);
    params.set("expired", `${currentUrl.pathname}${currentUrl.search}`);
    window.location.href = `${LOGOUT_ROUTE}?${params}`;
  }
  return Promise.reject(err);
}

export const data = {
  get: <T>(
    url: string,
    refreshCache?: boolean,
    options?: RequestInit,
    withStatusCode?: boolean
  ) => {
    return API.get<T>(url, refreshCache, options, withStatusCode).catch(
      actAndDelagate
    );
  },

  post: <T>(url: string, body?: any) => {
    return API.post<T>(url, body).catch(actAndDelagate);
  },

  delete: <T>(url: string, body?: any) => {
    return API.delete<T>(url, body).catch(actAndDelagate);
  },
};
