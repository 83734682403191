import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router";
import { CaseId, CompanyRegistrationId } from "../../data/models/CommonTypes";
import { Status } from "../../data/types";
import { Country } from "../../i18n";
import { CASE_ROUTE } from "../../pages/sales/case/CasePage";
import {
  Button,
  ButtonProps,
  SubmitProps,
} from "../interactions/Buttons/Button";
import { CreateCaseButtonText } from "./CreateCaseButtonText";
import { useCreateCase } from "./useCreateCase";

interface Props
  extends Omit<ButtonProps | SubmitProps, "onClick" | "children"> {
  companyRegistrationId: CompanyRegistrationId;
  country: Country;
}

export const CreateCaseButton = ({
  companyRegistrationId,
  country,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const { status, createNewCase, newCaseId, existingCaseId } = useCreateCase(
    companyRegistrationId,
    country
  );

  useEffect(() => {
    if (status === Status.SUCCESS && (newCaseId || existingCaseId)) {
      navigate(
        generatePath(CASE_ROUTE, {
          id: (newCaseId || existingCaseId) as CaseId,
        })
      );
    }
  }, [newCaseId, navigate, status, existingCaseId]);

  return (
    <>
      <Button {...props} onClick={createNewCase} status={status}>
        <CreateCaseButtonText status={status} />
      </Button>
    </>
  );
};
