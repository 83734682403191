import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { T } from "../../../../components/translation/T";

export const BeneficialOwnerSaveError = () => {
  const reload = () => window.location.reload();

  return (
    <div className="update-associate-error">
      <ErrorBox relative>
        <T>Something went wrong. We couldn't save the information.</T>
        <br />
        <button type="button" className="button small ghost" onClick={reload}>
          <T>Reload page</T>
        </button>
      </ErrorBox>
    </div>
  );
};
