import { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { dataDocument } from "../data/dataDocument";
import { dataOutreach } from "../data/dataOutreach";
import { CaseId, UserId } from "../data/models/CommonTypes";
import { Case, CaseStatus, RegistryResult } from "../data/models/caseTypes";
import { Status } from "../data/types";
import { ServerError } from "../network/API";
import {
  getCaseIsEditable,
  getUserIsAllowedToEdit,
} from "../pages/sales/case/CasePage";
import { caseBeneficialOwnerState } from "../state/caseBeneficialOwnerState";
import { caseCompanyState } from "../state/caseCompanyState";
import { caseDocumentState } from "../state/caseDocumentState";
import { caseKycState } from "../state/caseKycState";
import { caseMainState } from "../state/caseMainState";
import { caseStatusState } from "../state/caseStatusState";
import { dataReview } from "../data/dataReview";

const getNeedManualCompanyData = ({
  registryResult,
  status,
}: {
  status: CaseStatus;
  registryResult: RegistryResult;
}) => {
  return (
    (registryResult === RegistryResult.NO_DATA ||
      registryResult === RegistryResult.NO_REGISTRY_AVAILABLE) &&
    status === CaseStatus.DATA_COLLECTION
  );
};

// first release of outreach
const afterReleaseDateString = "2023-03-30T00:00:01.000000Z";
export const RELASE_DATE_1 = new Date(afterReleaseDateString);

// second release of outreach
const hasNotConfirmedDocumentExpirtyDate = "2023-06-20T00:00:01.000000Z";
export const RELEASE_DATE_2 = new Date(hasNotConfirmedDocumentExpirtyDate);

export const useLoadCase = (caseId?: CaseId, userId?: UserId) => {
  const [outreachCase, setOutreachCase] = useState<Case>();
  const [loadStatus, setLoadStatus] = useState<Status>(Status.DEFAULT);
  const [errorCode, setErrorCode] = useState<number>();

  const setMainData = useSetRecoilState(caseMainState);
  const setStatusData = useSetRecoilState(caseStatusState);
  const setCompanyData = useSetRecoilState(caseCompanyState);
  const setKycData = useSetRecoilState(caseKycState);
  const setBeneficialOwners = useSetRecoilState(caseBeneficialOwnerState);
  const setDocuments = useSetRecoilState(caseDocumentState);

  const load = useCallback(
    (caseId: CaseId, userId?: UserId) => {
      setLoadStatus(Status.PENDING);
      setErrorCode(undefined);
      Promise.all([
        dataOutreach.get(caseId, true),
        dataDocument.getAll(caseId, true),
        dataReview.getOnboardingStatus(caseId).catch(() => null),
      ])
        .then(([outreachResponse, documents, onboardingStatus]) => {
          let isLegacyCase = false;
          if (outreachResponse.timeline.sentToOwners) {
            const SENT_TO_OWNERS_DATE = new Date(
              outreachResponse.timeline.sentToOwners
            );
            isLegacyCase = SENT_TO_OWNERS_DATE < RELASE_DATE_1;
          }

          const caseIsEditable = getCaseIsEditable(outreachResponse);

          const userIsAllowedToEdit = getUserIsAllowedToEdit(
            outreachResponse,
            userId
          );

          setLoadStatus(Status.SUCCESS);

          setMainData({
            ...outreachResponse,
            id: caseId,
          });
          setOutreachCase({ id: caseId, ...outreachResponse });
          setStatusData({
            id: caseId,
            timeline: outreachResponse.timeline,
            type: outreachResponse.type,
            updated: outreachResponse.updated,
            status: outreachResponse.status,
            currentUser: outreachResponse.currentUser,
            caseClosedReason: outreachResponse.caseClosedReason,
            registryResult: outreachResponse.registryResult,
            edit: caseIsEditable && userIsAllowedToEdit,
            needManualCompanyData: getNeedManualCompanyData(outreachResponse),
            hasCompleteKycData: outreachResponse.hasCompleteKycData,
            sentToOnboarding: !onboardingStatus
              ? undefined
              : onboardingStatus.created,
            isLegacyCase,
            reviewedCompleted: outreachResponse.reviewedCompleted,
          });

          setCompanyData({
            ...outreachResponse.company,
          });
          setKycData(outreachResponse.companyKyc);
          setBeneficialOwners(outreachResponse.beneficialOwners);
          setDocuments(documents);
          setLoadStatus(Status.SUCCESS);
        })
        .catch((error: ServerError<unknown>) => {
          if (error.status === 403) {
            setErrorCode(error.status);
          }
          setLoadStatus(Status.ERROR);
        });
    },
    [
      setBeneficialOwners,
      setCompanyData,
      setDocuments,
      setKycData,
      setMainData,
      setStatusData,
    ]
  );

  useEffect(() => {
    if (caseId) {
      load(caseId, userId);
    }
  }, [caseId, load, userId]);

  return { outreachCase, loadStatus, errorCode, reload: load };
};
