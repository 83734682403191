import { dataOutreach } from "../../../data/dataOutreach";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class EmailBackendValidator extends BaseValidator {
  name = "Email backend validator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length === 0) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
        return;
      }

      dataOutreach
        .validateEmail(value)
        .then(({ valid }) => {
          if (valid) {
            resolve({
              status: VALIDATION_STATE.SUCCESS,
            });
          } else {
            resolve({
              status: VALIDATION_STATE.FAILED,
              message: this.error,
            });
          }
        })
        .catch(() => {
          resolve({
            status: VALIDATION_STATE.FAILED,
            message: "Could not validate email, try again later",
          });
        });
    });
  }
}
