import { generatePath } from "react-router";
import { CaseId } from "../data/models/CommonTypes";
import { CaseStatus } from "../data/models/caseTypes";
import { CASE_ROUTE } from "../pages/sales/case/CasePage";
import { CASE_COMPLETE_PAGE } from "../pages/sales/complete/CaseCompletePage";
import { CASE_REVIEW_ROUTE } from "../pages/sales/review/CaseReviewPage";

function resolvePath(status: CaseStatus) {
  if (status === CaseStatus.DATA_COLLECTION) {
    return CASE_ROUTE;
  }

  if (
    status === CaseStatus.COMPLETED_VERIFICATION ||
    status === CaseStatus.CLOSED
  ) {
    return CASE_COMPLETE_PAGE;
  }

  return CASE_REVIEW_ROUTE;
}

export function caseLink(caseId: CaseId, status: CaseStatus) {
  return generatePath(resolvePath(status), {
    id: caseId,
  });
}
