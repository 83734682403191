import React from "react";
import {
  FunctionComponent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormContainer } from "../../../../components/form/Form";
import { PersistentOverlay } from "../../../../components/overlay/PersistentOverlay";
import { id } from "../../../../components/utils";
import {
  BeneficialOwner,
  BeneficialOwnerType,
  Validity,
} from "../../../../data/models/caseTypes";
import { Status } from "../../../../data/types";
import { FormName } from "../menus/CaseEditMenu";
import { BeneficialOwnerForm } from "./BeneficialOwnerForm";
import { BeneficialOwnerRow } from "./BeneficialOwnerRow";
import "./BeneficialOwnerRow.scss";

interface Props {
  owner: BeneficialOwner;
  onRemove: (owner: BeneficialOwner) => void;
  status: Status;
  beneficialOwnerType?: BeneficialOwnerType;
  initialOpen?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
}

export const BeneficialOwnerOverlay: FunctionComponent<Props> = ({
  owner,
  onRemove,
  status,
  beneficialOwnerType = BeneficialOwnerType.OWNERSHIP,
  initialOpen = false,
  scrollToRef,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const formContainer = useRef<FormContainer>();
  const formId = useRef(id());
  const [isFormValid, setIsFormValid] = useState(false);

  // const checkboxClass = cx("select-bo-checkbox", {
  //   "select-bo-checkbox--checked": isSelected,
  // });

  // const getLeftActions = () => {
  //   if (
  //     caseStatus === CaseStatus.OUTREACH ||
  //     caseStatus === CaseStatus.VERIFICATION
  //   ) {
  //     return (
  //       <div className={checkboxClass} onClick={onSelectOwner}>
  //         {isSelected && <IoMdCheckmark />}
  //       </div>
  //     );
  //   }
  // };

  useEffect(() => {
    const onUpdate = () => {
      if (
        formContainer.current &&
        isFormValid !== formContainer.current.isValid
      ) {
        setIsFormValid(formContainer.current.isValid);
      }
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, [isFormValid]);

  return (
    <>
      <BeneficialOwnerRow
        setActiveOwner={() => setIsOpen(true)}
        validity={
          formContainer.current?.isValid ? Validity.VALID : Validity.MISSING
        }
        {...{ status, onRemove, owner, allowRemove: true }}
      />
      <PersistentOverlay
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="overlay-edit"
      >
        <BeneficialOwnerForm
          owner={owner}
          onClose={() => setIsOpen(false)}
          formName={`${FormName.OWNER}-${formId.current}`}
          formContainer={formContainer}
          scrollToRef={scrollToRef}
          beneficialOwnerType={beneficialOwnerType}
        />
      </PersistentOverlay>
    </>
  );
};

export const MemoizedBeneficialOwnerOverlay = React.memo(
  BeneficialOwnerOverlay
);
