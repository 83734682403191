import { useMemo } from "react";
import { numericFormatter } from "react-number-format";
import { T } from "../../../../components/translation/T";
import { Country, CountryCurrency } from "../../../../i18n";

export const useEstimatedTurnover = (
  country: Country,
  averageTransaction: number,
  transactions?: number
) => {
  return useMemo(() => {
    const value = averageTransaction * (transactions || 0);

    if (!value) {
      return;
    }
    return (
      <>
        <T>Calculated</T>:{" "}
        {numericFormatter(value.toString(), {
          thousandSeparator: " ",
          decimalSeparator: ",",
          decimalScale: 0,
        })}{" "}
        {CountryCurrency[country]}
      </>
    );
  }, [averageTransaction, transactions, country]);
};
