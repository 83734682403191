import { Country } from "../i18n";
import { cache } from "../network/Cache";
import { data } from "./data";
import { SaveBeneficialOwner } from "./models/AssociateTypes";
import {
  BeneficialOwnerId,
  CaseId,
  CompanyId,
  CompanyRegistrationId,
} from "./models/CommonTypes";
import {
  BeneficialOwner,
  BeneficialOwnerType,
  Case,
  CaseStaleReason,
  CompanyKyc,
  MerchantCategory,
} from "./models/caseTypes";
import { API } from "../network/API";

const BASE_URL = "/api/outreach";

export const CAS_ERROR_CODE = 409;
export const CLAIM_ERROR_CODE = 423;
export const SERVER_ERROR_CODE = 500;

export interface OutreachRequest {
  companyRegistrationId: CompanyId;
  country: string;
}

export interface OutreachLookupResponse {
  status: OutreachLookupStatus;
  caseId?: CaseId;
  result?: string;
}

export enum OutreachLookupStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  WORKING = "WORKING",
}

export enum OutreachLookupResult {
  RESOLVED = "RESOLVED",
  COMPANY_NOT_FOUND = "COMPANY_NOT_FOUND",
  COMPANY_NOT_ACTIVE = "COMPANY_NOT_ACTIVE",
  // Typ tekniskt fel, försök igen senare
  REGISTRY_NOT_AVAILABLE = "REGISTRY_NOT_AVAILABLE",
}

export type OutreachCaseResponse = Omit<Case, "id">;

export const dataOutreachDownloadUrl = (id: CaseId) =>
  API.getUrl(`${BASE_URL}/${id}/zip`);

export const dataOutreach = {
  create: (companyRegistrationId: CompanyRegistrationId, country: Country) =>
    data.post<{ lookupId: string; caseId: CaseId }>(`${BASE_URL}`, {
      companyRegistrationId,
      country,
    }),

  poll: (lookupId: string) =>
    data.get<OutreachLookupResponse>(`${BASE_URL}/lookup/${lookupId}`, true),

  get: (outreachId: CaseId, forceRefresh = true) =>
    data.get<OutreachCaseResponse>(`${BASE_URL}/${outreachId}`, forceRefresh),

  send: (outreachId: CaseId) =>
    data.post(`${BASE_URL}/${outreachId}/send-to-owners`),

  claim: (outreachId: CaseId) => data.post(`${BASE_URL}/${outreachId}/claim`),

  discard: (outreachId: CaseId) =>
    data.post(`${BASE_URL}/${outreachId}/discard`),

  retract: (outreachId: CaseId) =>
    data.post(`${BASE_URL}/${outreachId}/retract`),

  updateKyc: (outreachId: CaseId, kyc: CompanyKyc) =>
    data
      .post<CompanyKyc>(`${BASE_URL}/${outreachId}/kyc`, kyc)
      .then((response) => {
        cache.delete("/api/company");
        return response;
      }),

  updateBeneficialOwnerType: (
    outreachId: CaseId,
    beneficialOwnerType: BeneficialOwnerType
  ) =>
    data.post<void>(`${BASE_URL}/${outreachId}/beneficialownertype`, {
      type: beneficialOwnerType,
    }),

  postBeneficialOwner: (
    outreachId: CaseId,
    beneficialOwner: SaveBeneficialOwner
  ) =>
    data.post<BeneficialOwner>(`${BASE_URL}/${outreachId}/beneficialowner`, {
      ...beneficialOwner,
    }),

  updateBeneficialOwnerContact: (
    outreachId: CaseId,
    beneficialOwner: Omit<
      SaveBeneficialOwner,
      "firstName" | "lastName" | "title"
    >
  ) =>
    data.post<BeneficialOwner>(
      `${BASE_URL}/${outreachId}/beneficialowner/${beneficialOwner.id}/communication`,
      {
        ...beneficialOwner,
      }
    ),

  deleteBeneficialOwner: (
    outreachId: CaseId,
    beneficialOwnerId: BeneficialOwnerId
  ) =>
    data.delete<BeneficialOwner[]>(
      `${BASE_URL}/${outreachId}/beneficialowner/${beneficialOwnerId}`
    ),

  loadMcc: () => data.get<MerchantCategory[]>(`${BASE_URL}/mcc`),

  validateEmail: (email: string) =>
    data.post<{ valid: boolean }>(`${BASE_URL}/util/email`, {
      email,
    }),

  updateStaleReason: (outreachId: CaseId, reason: CaseStaleReason) => {
    return data.post<void>(`${BASE_URL}/${outreachId}/stale-reason`, {
      staleReason: reason,
    });
  },

  removeStaleReason: (outreachId: CaseId) => {
    return data.post<void>(`${BASE_URL}/${outreachId}/reset-stale-reason`);
  },

  completedToPending: (outreachId: CaseId) => {
    return data.post<void>(`${BASE_URL}/${outreachId}/completed-to-pending`);
  },
};
