import { data } from "./data";
import { DocumentId, UTCDate, UserId } from "./models/CommonTypes";
import { CaseStatus } from "./models/caseTypes";
import { API } from "../network/API";

const BASE_URL = "/api/outreach";

export enum DocumentType {
  CONTRACT = "CONTRACT",
  OTHER = "OTHER",
}

export interface CaseDocument {
  id: DocumentId;
  uploaded?: UTCDate;
  uploadedBy?: string;
  filename?: string;
  mimeType?: string;
  type: DocumentType;
  refNumber?: string;
}

export interface NewCaseDocument extends Partial<CaseDocument> {
  type: DocumentType;
}

export const dataDocument = {
  getAll: (caseId: string, skipCache = true) =>
    data.get<CaseDocument[]>(`${BASE_URL}/${caseId}/documents`, skipCache),

  get: (caseId: string, documentId: DocumentId) =>
    data.get<CaseDocument>(`${BASE_URL}/${caseId}/documents/${documentId}`),

  delete: (caseId: string, documentId: DocumentId) =>
    data.delete<CaseDocument>(`${BASE_URL}/${caseId}/documents/${documentId}`),
};

export interface ExcelQueryParams {
  userId?: UserId;
  status?: CaseStatus[];
  countries?: string[];
}

export const dataExcelDownloadUrl = ({
  userId,
  status = [],
  countries = [],
}: ExcelQueryParams) => {
  const base_url = `/api/excel/download`;
  const params = new URLSearchParams();

  if (userId) params.set("userId", userId);
  countries.forEach((country) => params.append("countries", country));
  status.forEach((status) => params.append("status", status));

  const stringParams = params.toString();
  const url_params = stringParams && `?${stringParams}`;

  // console.log(`${base_url}${url_params}`);

  return API.getUrl(`${base_url}${url_params}`);
};
