import React, { useMemo } from "react";
import cx from "classnames";
import { DASHBOARD_ROUTE } from "../../pages/sales/Dashboard/DashboardPage";
import { Link } from "../links/Link";
import { FilterUtils } from "../../data/models/Filter";
import { Store, STORE_KEY, Filter as FilterInterface } from "../../Store";
import { DashboardCase } from "../../data/dataDashboard";
import { LogoStripped } from "../images/LogoStripped";
import { SpinnerMotion } from "../spinner/SpinnerMotion";
import "./VerticalMenu.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
  logo?: boolean;
}

export const VerticalMenu: React.FunctionComponent<Props> = ({
  className,
  children,
  logo = true,
}) => {
  const homeRoute = useMemo(
    () =>
      `${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(
        Store.getValue<FilterInterface<DashboardCase>>(
          STORE_KEY.STORE_DASHBOARD_FILTER
        )
      )}`,
    []
  );

  return (
    <div className={cx("vertical-menu", className)}>
      {logo && (
        <Link link={homeRoute}>
          <div className="logo-wrapper">
            <LogoStripped />
            <SpinnerMotion size={"mini"} once />
          </div>
        </Link>
      )}
      {children}
    </div>
  );
};
