import debounce from "lodash.debounce";
import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { TextInput } from "../form/TextInput";
import { ValidationProps } from "../form/hooks/useValidation";
import { Status } from "../../data/types";
import { useTranslation } from "react-i18next";
import { dataCompany } from "../../data/dataCompany";
import "./AddressSearch.scss";

export interface AddressLocationChange {
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}

interface Props extends ValidationProps {
  onChange: ({ street, city, postalCode }: AddressLocationChange) => void;
  country?: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
}

interface Styles {
  bottom: number | string;
  top: number | string;
  borderRadius: string;
}

export const AddressSearch: React.FunctionComponent<Props> = ({
  onChange,
  country,
  setSearchValue,
  searchValue,
  ...props
}) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const elemRef = useRef<HTMLDivElement>(null);
  const styles = useRef<Styles>();
  const { t } = useTranslation();

  useEffect(() => {
    if (searchValue.length < 3) {
      setSuggestions([]);
    }
  }, [searchValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce(async (value: string) => {
      if (!styles.current) {
        const wrapperBox = elemRef.current?.getBoundingClientRect();

        const box = elemRef.current
          ?.querySelector("input")
          ?.getBoundingClientRect();

        if (box) {
          const inputHeight = box.height;
          const labelHeight = wrapperBox ? box.top - wrapperBox.top : 28;

          const distanceToBottomOfViewport = window.innerHeight - box.bottom;
          const distanceToTopOfViewport = box.top;

          if (distanceToBottomOfViewport > distanceToTopOfViewport) {
            styles.current = {
              top: `${inputHeight + labelHeight + 4}px`,
              bottom: "auto",
              borderRadius: "0 0 5px 5px",
            };
          } else {
            styles.current = {
              top: "auto",
              bottom: `${inputHeight + 4}px`,
              borderRadius: "5px 5px 0 0",
            };
          }
        }
      }

      if (value.length < 3) {
        return;
      }

      const filter = country ? [country] : [];

      dataCompany.searchAddress(value, filter).then((suggestions) => {
        if (suggestions.length) {
          setStatus(Status.DEFAULT);
        } else {
          setStatus(Status.ERROR);
        }
        setSuggestions(suggestions);
      });
    }, 250),
    [country]
  );

  const select = useCallback(
    (value: string) => {
      dataCompany.getAddress(value).then((location) => {
        onChange(location);
        setSearchValue("");
      });
    },
    [onChange, setSearchValue]
  );

  return (
    <div className={cx("address-search")} ref={elemRef}>
      <TextInput
        className={cx(status)}
        label={t("Company address")}
        {...props}
        // list={identifier.current}
        onChange={(value) => {
          setSearchValue(value);
          search(value);
        }}
        value={searchValue}
        autocomplete="off"
        placeholder={t("Search address...")}
      />
      <ul
        className={cx("search-results", {
          "populated-list": !!suggestions.length,
        })}
        style={styles.current}
      >
        {!suggestions.length && <li />}
        {suggestions.map((text) => (
          <li key={text}>
            <button
              onClick={(ev) => {
                ev.preventDefault();
                setSuggestions([]);
                select(text);
              }}
            >
              <div className="truncated text-small">{text}</div>
            </button>
          </li>
        ))}
      </ul>
      {/* <datalist id={identifier.current}>
        {suggestions.map((text) => (
          <option value={text} key={text}>
            {text}
          </option>
        ))}
      </datalist> */}
    </div>
  );
};
