import { cache } from "../network/Cache";
import { data } from "./data";
import { UserRole } from "./dataAuth";
import { CaseId, UserId, UTCDate } from "./models/CommonTypes";
import { Page } from "./models/Page";

const BASE_URL = "/api/manager";

export interface NewUserRequest {
  email: string;
  name: string;
  role: UserRole; // Exclude<UserRole, UserRole.MANAGER>;
}

export interface User {
  id: UserId;
  email: string;
  name: string;
  role: UserRole;
  created: UTCDate;
}

export const userAdministration = {
  createUser: (user: NewUserRequest) =>
    data.post<User>(`${BASE_URL}/user`, user).then((user) => {
      cache.delete(`${BASE_URL}/user`);
      return user;
    }),

  updateUser: (user: NewUserRequest, userId: UserId) =>
    data.post<User>(`${BASE_URL}/user/${userId}`, user).then((user) => {
      cache.delete(`${BASE_URL}/user`);
      return user;
    }),

  getUsers: (page: number, size: number, inRole?: UserRole) =>
    data.get<Page<User>>(
      `${BASE_URL}/user?page=${page}&size=${size}${
        inRole ? `&inRole=${inRole}` : ""
      }`
    ),

  getUser: (id: UserId) => data.get<User>(`${BASE_URL}/user/${id}`),

  deleteUser: (id: UserId) =>
    data.delete(`${BASE_URL}/user/${id}`).then(() => {
      cache.delete("/api/manager/user");
    }),

  delegate: (caseId: CaseId, userId: UserId) =>
    data.post(`/api/outreach/${caseId}/delegate`, { userId }).then(() => {
      cache.delete(`/api/outreach/${caseId}`);
    }),

  isValidEmail: (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
};
