import React, { ReactNode } from "react";
import cx from "classnames";
import { ErrorOutline } from "../icons/ErrorOutline";
import { Checkmark } from "../icons/Checkmark";
import { Pending } from "../icons/Pending";
import "./StatusBox.scss";
import { WarningOutline } from "../icons/WarningOutline";

interface Props {
  className: string;
  children: string | ReactNode;
}

export const StatusBox: React.FunctionComponent<Props> = ({
  className,
  children,
}) => {
  return (
    <div className={className}>
      <div className={cx("status-box-icon")}>
        <ErrorOutline />
        <WarningOutline />
        <Checkmark />
        <Pending />
      </div>
      <div className="status-box-message">{children}</div>
    </div>
  );
};
