import React, { FunctionComponent } from "react";
import { Language } from "../../../../i18n";
import "./IMessage.scss";

interface Props {
  language: Language;
  message: string;
  originalText?: string;
}

export const IMessage: FunctionComponent<Props> = ({
  language,
  message,
  originalText,
}) => {
  const languageNames = new (Intl as any).DisplayNames(language, {
    type: "language",
  });
  return (
    <div className="imessage">
      {/* <p className="from-me no-tail margin-b_none">
        The Caspians plan to introduce a new chocolate with red pepper smoothie
        at their juice bar that could cause controversy…and a make a few
        enemies...
      </p>
      <p className="from-me no-tail">
        Erika is at Johnny&rsquo;s house and both are packing for the weekend
        cruise. Their dialog is only for the younger viewers: Beyoncé this,
        Kanye that...
      </p>
      <p className="from-me no-tail">Diego&rsquo;s boat blows up.</p>
      <p className="from-me">...CLIFFHANGER...</p>
      <p className="from-me emoji">😎</p> */}
      {originalText && (
        <React.Fragment>
          <span className="language-label">{languageNames.of("en")}</span>
          <pre className="from-them">{originalText}</pre>
        </React.Fragment>
      )}
      <span className="language-label">{languageNames.of(language)}</span>
      <pre className="from-them">{message}</pre>
    </div>
  );
};
