import { atom } from "recoil";
import { CompanyId, CompanyRegistrationId } from "../data/models/CommonTypes";
import { Company } from "../data/models/caseTypes";
import { Country } from "../i18n";

export interface CaseCompanyState
  extends Pick<
    Company,
    | "id"
    | "companyRegistrationId"
    | "legalName"
    | "legalEntityType"
    | "country"
    | "address"
  > {}

export const caseCompanyState = atom<CaseCompanyState>({
  key: "case-company-state",
  default: {
    id: "0" as CompanyId,
    companyRegistrationId: "0" as CompanyRegistrationId,
    legalName: "",
    country: Country.BELGIUM,
    address: {},
  },
});
