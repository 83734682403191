import { data } from "./data";
import { UserId, UTCDate } from "./models/CommonTypes";

export enum LoginMethod {
  OTP = "OTP",
  AZURE = "AZURE",
  GOOGLE = "GOOGLE",
}

export enum UserRole {
  MANAGER = "MANAGER",
  SALES = "SALES",
  OPERATIONS = "OPERATIONS",
  RISK = "RISK",
}

interface LoginResponse {
  requestId: string;
}

export interface UserSession {
  userId: UserId;
  expiry: UTCDate;
  role: UserRole;
  name: string;
  email: string;
}

export const dataAuth = {
  startLogin: (email: string) =>
    data.post<LoginResponse>("/api/login", {
      email,
    }),

  postOtp: (requestId: string, otp: string) =>
    data.post<UserSession>("/api/login/otp", {
      code: otp,
      requestId,
    }),

  getCurrentSession: () => data.get<UserSession>("/api/whoami", true),
};
