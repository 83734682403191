import { useCallback, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { CompanyRegistrationIdDisplay } from "../../components/companyRegistrationId/CompanyRegistrationIdDisplay";
import { CreateCaseButton } from "../../components/createCaseButton/CreateCaseButton";
import { FieldSet } from "../../components/fieldSet/FieldSet";
import { Flag } from "../../components/flags/Flag";
import { LegalEntityTypeDisplay } from "../../components/legalEntityType/LegalEntityTypeDisplay";
import { Retry } from "../../components/retry/Retry";
import { FormattedDate } from "../../components/time/FormattedDate";
import { getCountryDisplayName } from "../../components/translation/I18Utils";
import { T } from "../../components/translation/T";
import { dataCompany } from "../../data/dataCompany";
import {
  CompanyId,
  CompanyRegistrationId,
} from "../../data/models/CommonTypes";
import { Company } from "../../data/models/caseTypes";
import { Status } from "../../data/types";
import i18n, { Country } from "../../i18n";
import { ServerError } from "../../network/API";
import { userState } from "../../state/userState";
import { NOT_FOUND_PAGE_ROUTE } from "../NotFoundPage";
import { Page } from "../Page";
import { CaseList } from "./CaseList";
import "./CompanyPage.scss";
import { Button } from "../../components/interactions/Buttons/Button";
import { createPortal } from "react-dom";
import { Backdrop } from "../../components/backdrop/Backdrop";
import { EditCompanyDataModal as Modal } from "./components/EditCompanyDataModal";
import { StatusBox } from "../../components/boxes/StatusBox";
import { SearchInput } from "../../components/searchInput/SearchInput";
import { DocumentSearch } from "../sales/Dashboard/FilterView";

export const COMPANY_PAGE_URL = "/company/:country/:companyRegistrationId";

export interface NewCompany extends Company {
  companyId: CompanyId;
  // id?: never
}

interface Props {
  company: Company;
  reloadOutreachCases: () => void;
  handleEditButton: () => void;
}

const SEARCH_INIT_STATE: DocumentSearch = {
  searchString: "",
  searchResult: [],
  numOfMatches: 0,
};

const CompanyPageInner = ({
  company,
  reloadOutreachCases,
  handleEditButton,
}: Props) => {
  const [documentSearch, setDocumentSearch] =
    useState<DocumentSearch>(SEARCH_INIT_STATE);
  const user = useRecoilValue(userState);
  const { language } = i18n;

  const {
    address,
    legalEntityType,
    legalName,
    companyRegistrationId,
    lastRegistryLookup,
    country,
    ongoingCase,
    historicCases,
  } = company;

  const onDocumentSearch = useCallback(
    (searchString: string) => {
      if (searchString.length === 0)
        return setDocumentSearch(SEARCH_INIT_STATE);

      const ongoingDocuments = ongoingCase?.documents ?? [];
      const historicDocuments = historicCases.flatMap((c) => c.documents);
      const documentsToSearch = ongoingDocuments.concat(historicDocuments);

      const regex = new RegExp(searchString, "i");
      let searchResult: string[] = [];
      let numOfMatches = 0;

      documentsToSearch.forEach((doc) => {
        const { refNumber, filename } = doc;
        let hasBeenAddedToMatches = false;

        if (refNumber && regex.test(refNumber)) {
          numOfMatches++;
          hasBeenAddedToMatches = true;
          searchResult.push(refNumber);
        }

        if (regex.test(filename)) {
          if (!hasBeenAddedToMatches) {
            numOfMatches++;
          }
          searchResult.push(filename);
        }
      });

      setDocumentSearch({
        searchString,
        searchResult,
        numOfMatches: numOfMatches,
      });
    },
    [ongoingCase, historicCases]
  );

  return (
    <div className="company-wrapper">
      <div>
        <div className="tag m-bottom-0">
          <Flag country={country} height={20} rounded />
          <span>
            <T>Company in</T> {getCountryDisplayName(country, language)}
          </span>
        </div>
        <h3>{legalName || <T>No name</T>}</h3>
      </div>

      <hr />

      <FieldSet header="Company information">
        <div className="tablet-columns m-bottom-20">
          <div>
            <i>
              <T>Legal name</T>:
            </i>
          </div>
          <div>
            <b>{legalName || <T>No name</T>}</b>
          </div>
          <div>
            <i>
              <T>Company registration number</T>:
            </i>
          </div>
          <div>
            <b>
              <CompanyRegistrationIdDisplay
                country={country}
                id={companyRegistrationId}
              />
            </b>
          </div>
          <div>
            <i>
              <T>Legal entity type</T>:
            </i>
          </div>
          <div>
            <b>
              <LegalEntityTypeDisplay legalEntityType={legalEntityType} />
            </b>
          </div>
          <div>
            <i>
              <T>Last registry lookup</T>:
            </i>
          </div>
          <div>
            <b>
              <FormattedDate date={lastRegistryLookup} />
            </b>
          </div>
        </div>
        <div className="tablet-columns">
          <div>
            <strong>
              <T>Company address</T>
            </strong>
            <div>{address.street}</div>
            <div>
              {address.city} - {address.postalCode}
            </div>

            <div>{address.country}</div>
          </div>
        </div>
      </FieldSet>
      <div className="edit-button-wrapper-column">
        <div className="edit-button-wrapper">
          <Button
            onClick={handleEditButton}
            status={company.ongoingCase ? Status.DISABLED : Status.DEFAULT}
          >
            Edit company information
          </Button>
        </div>
        {company.ongoingCase && (
          <p className="edit-button-warning-text">
            You are not allowed to edit company information if you have an
            ongoing case
          </p>
        )}
      </div>
      <div className="company-page-document-search">
        <SearchInput
          placeholder="Search for documents"
          value={documentSearch.searchString}
          onChange={onDocumentSearch}
        />

        <div style={{ height: 30 }}>
          {documentSearch.searchString.length > 0 &&
            (documentSearch.numOfMatches > 0 ? (
              <span>
                {documentSearch.numOfMatches}
                <T> documents found</T>
              </span>
            ) : (
              <span>No documents found</span>
            ))}
        </div>
      </div>
      <div>
        <h4>
          <T>Ongoing case</T>
        </h4>
        {ongoingCase ? (
          <div className="case-list-wrapper">
            <CaseList
              cases={[
                {
                  ...ongoingCase,
                  companyName: legalName,
                  companyRegistrationId,
                },
              ]}
              user={user}
              reloadOutreachCases={reloadOutreachCases}
              documentSearchResult={documentSearch.searchResult}
            />
          </div>
        ) : (
          <>
            <p>
              <T>No cases open for company</T>
            </p>
            <CreateCaseButton
              block
              country={company.country}
              companyRegistrationId={company.companyRegistrationId}
            />
          </>
        )}
      </div>

      <hr />
      <div>
        <h4>
          <T>Historic cases</T>
        </h4>
        <div className="case-list-wrapper">
          <CaseList
            cases={company.historicCases}
            user={user}
            reloadOutreachCases={reloadOutreachCases}
            documentSearchResult={documentSearch.searchResult}
          />
        </div>
      </div>
    </div>
  );
};

export const CompanyPage = () => {
  const { companyRegistrationId, country } = useParams<{
    companyRegistrationId: CompanyRegistrationId;
    country: Country;
  }>();
  const navigate = useNavigate();
  const [company, setCompany] = useState<Company>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [modalOpen, setModalOpen] = useState(false);

  const load = useCallback(() => {
    if (!companyRegistrationId || !country) {
      return;
    }
    setStatus(Status.PENDING);
    dataCompany
      .getCompany(country, companyRegistrationId)
      .then((company) => {
        setCompany(company);
        setStatus(Status.SUCCESS);
      })
      .catch((e: ServerError<any>) => {
        if (e.status === 404) {
          navigate(NOT_FOUND_PAGE_ROUTE);
          return;
        }
        setStatus(Status.ERROR);
      });
  }, [companyRegistrationId, country, navigate]);

  useEffect(() => {
    load();
  }, [load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 600);
  }, [load]);

  if (!companyRegistrationId || !country) {
    return <Navigate to={NOT_FOUND_PAGE_ROUTE} />;
  }

  return (
    <div>
      <Backdrop
        open={modalOpen}
        onClick={() => setModalOpen(false)}
        variant={"dark"}
      />

      <Page title="Company" className="company-page" padded centered background>
        <section style={{ width: "100%" }}>
          <article
            style={{
              position: "relative",
              paddingBottom: 50,
            }}
          >
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              company={company as NewCompany}
              reloadOutreachCases={load}
            />
            <Retry status={status} retry={retry}>
              {company && (
                <CompanyPageInner
                  company={company}
                  reloadOutreachCases={load}
                  handleEditButton={() => setModalOpen(true)}
                />
              )}
            </Retry>
          </article>
        </section>
      </Page>
    </div>
  );
};
