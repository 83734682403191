import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo } from "react";
import i18n, { Country } from "../../i18n";
import { Store, STORE_KEY } from "../../Store";
import { Flag } from "../flags/Flag";
import { Select } from "../form/Select";
import { Alternative } from "../interactions/InputTypes";
import { getCountryDisplayName } from "../translation/I18Utils";
import "./CountrySelector.scss";

interface Props {
  className?: string;
  onChange: (country: Country) => void;
  value?: Country;
}

export const CountrySelector: React.FunctionComponent<Props> = ({
  className,
  onChange,
  value,
}) => {
  const { language } = i18n;

  const handleOnChange = useCallback(
    (country?: Country) => {
      if (!country) {
        return;
      }
      onChange(country);
      Store.setValue(STORE_KEY.STORE_COUNTRY, country);
    },
    [onChange]
  );

  useEffect(() => {
    if (!value) {
      const country = Store.getValue<Country>(STORE_KEY.STORE_COUNTRY);
      if (country) {
        handleOnChange(country);
      }
    }
  }, [handleOnChange, value]);

  const topFive = useMemo((): Country[] => {
    const countries = Object.values(Country); //.filter((c) => c !== value);
    const firstFive = countries.slice(0, 5);
    const isInFirstFive = value && firstFive.includes(value);

    return [
      ...(isInFirstFive
        ? firstFive
        : [...firstFive.slice(0, -1), ...(value ? [value] : [])]),
    ];
  }, [value]);

  const otherAlternatives = useMemo((): Alternative<Country | undefined>[] => {
    const countries = Object.values(Country).filter(
      (c) => !topFive.includes(c)
    );

    return [
      { value: undefined, text: "Other countries" },
      ...countries.map((country) => ({
        text: getCountryDisplayName(country, language),
        value: country,
      })),
    ];
  }, [language, topFive]);

  return (
    <div className={cx("country-selector", className)}>
      <ul>
        {topFive.map((country) => {
          const selected = country === value;
          return (
            <motion.li
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.15 }}
              layout="position"
              key={country}
              className={cx({ selected })}
            >
              <button type="button" onClick={() => handleOnChange(country)}>
                <Flag country={country} />
                <AnimatePresence initial={false}>
                  {selected && (
                    <motion.div
                      className="active-country-label"
                      initial={{
                        opacity: 0,
                        width: 0,
                      }}
                      animate={{
                        opacity: 1,
                        width: "auto",
                      }}
                      exit={{
                        opacity: 0,
                        width: 0,
                      }}
                      transition={{
                        duration: 0.2,
                        ease: "easeOut",
                      }}
                    >
                      <span>{country}</span>
                    </motion.div>
                  )}
                </AnimatePresence>
              </button>
            </motion.li>
          );
        })}
      </ul>

      <Select
        className="input-compact compact-input"
        onChange={(country) => handleOnChange(country)}
        alternatives={otherAlternatives}
      />
    </div>
  );
};
