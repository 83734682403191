import { ITEM_HEIGHT, ITEM_OFFSET } from "./Timeline";

export function ToVerification() {
  return (
    <>
      <line
        x1="10"
        x2="10"
        y1={ITEM_OFFSET}
        y2={ITEM_OFFSET + ITEM_HEIGHT}
        className="active"
      />
      <circle r="4" cx="10" cy={ITEM_OFFSET + ITEM_HEIGHT} className="active" />
    </>
  );
}
