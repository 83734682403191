import { Page } from "./Page";
import { SpinnerMotion } from "../components/spinner/SpinnerMotion";
import { Link } from "../components/links/Link";
import { motion, Variants } from "framer-motion";
import "./NotFoundPage.scss";

export const NOT_FOUND_PAGE_ROUTE = "/404";

const DURATION = 0.075;

const variants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  show: {
    opacity: [null, 1, 1, 0],
    scale: [1, 1, 1, 1],
    transition: {
      duration: DURATION * 30,
      repeat: Infinity,
      repeatDelay: DURATION * 5,
      times: [0, 0.25, 0.85, 1],
    },
  },
};

export const NotFoundPage = () => {
  return (
    <Page stripped background>
      <div className="not-found-page">
        <SpinnerMotion once size="large" />
        <hr />
        <h1 className="text-header">404</h1>
        <motion.h4
          className="text-passive four-oh-four"
          transition={{
            staggerChildren: DURATION,
            staggerDirection: 1,
          }}
          initial="hidden"
          animate="show"
        >
          <motion.span variants={variants}>four</motion.span>
          <motion.span variants={variants}> - </motion.span>
          <motion.span variants={variants}>oh</motion.span>
          <motion.span variants={variants}> - </motion.span>
          <motion.span variants={variants}>four</motion.span>
        </motion.h4>
        <h2>Page was not found</h2>
        <Link link="/" className="as-button m-top-20">
          Take me home!
        </Link>
      </div>
    </Page>
  );
};
