import { LegalEntityType } from "../../data/models/LegalEntityTypes";
import { T } from "../translation/T";

export const LegalEntityTypeDisplay = ({
  legalEntityType,
}: {
  legalEntityType?: LegalEntityType;
}) => {
  if (!legalEntityType) {
    return <>-</>;
  }

  return (
    <span>
      <T>{legalEntityTypeString(legalEntityType)}</T>
    </span>
  );
};

export const legalEntityTypeString = (legalEntityType: LegalEntityType) => {
  switch (legalEntityType) {
    case LegalEntityType.ASSOCIATION:
      return "Association / cooperative";
    case LegalEntityType.LIMITED:
      return "Limited Company";
    case LegalEntityType.SOLE_PROPRIETARY:
      return "Sole proprietary";
    case LegalEntityType.TRUST:
      return "Trust";
    case LegalEntityType.OTHER:
      return "Other";
    case LegalEntityType.GENERAL_PARTNERSHIP:
      return "General partnership";
    case LegalEntityType.LIMITED_PARTNERSHIP:
      return "Limited partnership";
    case LegalEntityType.PUBLIC_COMPANY:
      return "Public company";
    default:
      return legalEntityType;
  }
};
