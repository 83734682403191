import { useMemo } from "react";
import { getCountryFromPhoneNumber } from "../utils";

interface Props {
  phoneNumber?: string;
}

export const PhoneNumberCountry = ({ phoneNumber }: Props) => {
  const country = useMemo(
    () =>
      phoneNumber && phoneNumber.length > 4
        ? getCountryFromPhoneNumber(phoneNumber)
        : undefined,
    [phoneNumber]
  );

  return <>{country}</>;
};
