import { data } from "./data";
import { CaseId, CompanyId, CompanyRegistrationId } from "./models/CommonTypes";
import { Company, SaveCompanyRequest } from "./models/caseTypes";

const BASE_URL = "/api/company";

export interface AddressSuggestion {
  street?: string;
  city?: string;
  postalCode?: string;
}

export const dataCompany = {
  getCompany: (country: string, companyRegistrationId: CompanyRegistrationId) =>
    data.get<Company>(
      `${BASE_URL}?companyRegistrationId=${companyRegistrationId}&country=${country}`,
      true
    ),

  updateCompany: (
    companyId: CompanyId,
    caseId: CaseId,
    request: SaveCompanyRequest
  ) => data.post<void>(`${BASE_URL}/${companyId}/${caseId}`, request),

  searchAddress: (query: string, countryFilter: string[] = []) =>
    data.post<string[]>(`${BASE_URL}/address/search`, { query, countryFilter }),

  getAddress: (query: string) =>
    data.post<AddressSuggestion>(`${BASE_URL}/address/suggest`, {
      text: query,
    }),
};
