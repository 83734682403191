import { useState, useCallback, useEffect } from "react";
import { dataOnboarding } from "../../data/dataOnboarding";
import { CaseId, OnboardingCaseId } from "../../data/models/CommonTypes";
import { Case } from "../../data/models/caseTypes";
import { Status } from "../../data/types";
import { ServerError } from "../../network/API";

export const useLoadOnboarding = (onboardingCaseId?: OnboardingCaseId) => {
  const [outreachCase, setOutreachCase] = useState<Case>();
  const [loadStatus, setLoadStatus] = useState<Status>(Status.DEFAULT);
  const [errorCode, setErrorCode] = useState<number>();

  const load = useCallback((onboardingCaseId: OnboardingCaseId) => {
    setLoadStatus(Status.PENDING);
    setErrorCode(undefined);
    dataOnboarding
      .get(onboardingCaseId, true)
      .then((outreachResponse) => {
        setLoadStatus(Status.SUCCESS);
        setOutreachCase({ id: "0" as CaseId, ...outreachResponse });
        setLoadStatus(Status.SUCCESS);
      })
      .catch((error: ServerError<unknown>) => {
        if (error.status === 403) {
          setErrorCode(error.status);
        }
        setLoadStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    if (onboardingCaseId) {
      load(onboardingCaseId);
    }
  }, [onboardingCaseId, load]);

  return { outreachCase, loadStatus, errorCode, reload: load };
};
