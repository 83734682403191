import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useEffect, useState } from "react";
import { DashboardDocument } from "../../data/dataDashboard";
import { DocumentType } from "../../data/dataDocument";
import { useHeight } from "../../hooks/useHeight";
import { useWidth } from "../../hooks/useWidth";
import { T } from "../translation/T";
import styles from "./Document.module.scss";
import { MdAttachFile } from "react-icons/md";
import cx from "classnames";
import { isDocumentMatch } from "./DocumentChip";

interface Props {
  documents?: DashboardDocument[];
  chipPosition: {
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    bottom: number;
  };
  open: boolean;
  documentSearchResult: string[];
}

export const DocumentPopover: React.FunctionComponent<Props> = ({
  documents,
  chipPosition,
  open,
  documentSearchResult,
}) => {
  const [popoverDimensions, setPopoverDimensions] = useState({
    height: 300,
    width: 400,
  });
  const screenWidth = useWidth();
  const screenHeight = useHeight();
  const contractDocuments = documents?.filter(
    (document) => document.type === DocumentType.CONTRACT
  );
  const otherDocuments = documents?.filter(
    (document) => document.type !== DocumentType.CONTRACT
  );
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!popoverRef.current) return;
    const { height } = popoverRef.current.getBoundingClientRect();

    // needed because of the scale animation on the popover
    const SCALE_CONSTANT = 0.8;
    const originalHeight = height / SCALE_CONSTANT;

    setPopoverDimensions({ height: originalHeight, width: 400 });
  }, [open]);

  const getLeftPosition = () => {
    const MARGIN_X = 10;
    // right position of popover
    const popoverRight = chipPosition.left + popoverDimensions.width;
    // x coordinate of the middle of the chip
    const chipXCoordinateMiddle = chipPosition.left + chipPosition.width / 2;
    const popoverHalfWidth = popoverDimensions.width / 2;
    // final left position of the popover if to be centered on the chip
    const centeredPopoverLeft = chipXCoordinateMiddle - popoverHalfWidth;
    // chip is overflowing to the left of the screen
    if (centeredPopoverLeft < 0) return MARGIN_X;
    // chip is overflowing to the right of the screen
    if (popoverRight > screenWidth) {
      const widthOverflow = popoverRight - screenWidth;
      return chipPosition.left - widthOverflow - MARGIN_X;
    }
    return centeredPopoverLeft;
  };

  const getTopPosition = () => {
    const MARGIN_Y = 5;
    const chipBottom = chipPosition.bottom;
    const chipTop = chipPosition.top;
    const popoverHeight = popoverDimensions.height;

    const popoverBottom = chipBottom + popoverHeight;

    const popoverIsOverflowingScreenBottom = popoverBottom > screenHeight;

    if (popoverIsOverflowingScreenBottom) {
      // try to place the popover on top of the chip, as opposed to under it (default)
      const popoverAboveTrigger = chipTop - popoverHeight - MARGIN_Y;

      const popoverIsOverflowingTopScreen = popoverAboveTrigger < 0;
      const popoverMiddleOfScreen = screenHeight / 2 - popoverHeight / 2;

      return popoverIsOverflowingTopScreen
        ? popoverMiddleOfScreen
        : popoverAboveTrigger;
    }
    return chipBottom + MARGIN_Y;
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={styles.popover}
          style={{ top: getTopPosition(), left: getLeftPosition() }}
          ref={popoverRef}
          initial={{
            scale: 0.8,
            opacity: 0,
          }}
          exit={{ scale: 0.8, opacity: 0 }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          transition={{ duration: 0.1 }}
        >
          <div className={styles.title}>
            <T>Attached documents</T>
          </div>
          {contractDocuments && contractDocuments.length > 0 && (
            <div className={styles.documents}>
              {contractDocuments.map((document, index) => {
                const isMatch = isDocumentMatch(document, documentSearchResult);
                return (
                  <Document
                    key={document.filename + index + "contract"}
                    {...document}
                    isMatch={isMatch}
                  />
                );
              })}
            </div>
          )}
          {otherDocuments && otherDocuments.length > 0 && (
            <div className={styles.documents}>
              {otherDocuments.map((document, index) => {
                const isMatch = isDocumentMatch(document, documentSearchResult);
                return (
                  <Document
                    key={document.filename + index + "other"}
                    {...document}
                    isMatch={isMatch}
                  />
                );
              })}
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Document = ({
  filename,
  type,
  refNumber,
  isMatch,
}: DashboardDocument & { isMatch: boolean }) => {
  const documentClass = cx(styles.document, {
    [styles.highlight]: isMatch,
  });

  return (
    <div className={documentClass}>
      <div className={styles.docIcon}>
        <MdAttachFile />
      </div>
      <div className={styles.docContent}>
        <div className={styles.docTitle}>{filename}</div>
        <div className={styles.docInfo}>
          <div className={styles.docLabelType}>
            <div className={styles.label}>
              <T>type</T>:{" "}
            </div>
            <div className={styles.docType}>{type.toLowerCase()}</div>
          </div>
          {refNumber && (
            <div className={styles.docLabelRef}>
              <div className={styles.label}>
                <T>ref</T>:{" "}
              </div>

              <div>
                <div className={styles.docRef}>{refNumber}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
