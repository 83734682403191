import React, { FunctionComponent, RefObject, useRef } from "react";
import { useValidation, ValidationProps } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import cx from "classnames";
import "./HiddenInput.scss";
import { AnimateHeightMotion } from "../animate/AnimateHeightMotion";
import { T } from "../translation/T";

function translate(v?: string | React.ReactNode) {
  if (!v) {
    return v;
  }
  if (typeof v === "string") {
    return <T>{v}</T>;
  }
  return v;
}

interface Props extends ValidationProps {
  value?: any;
  label: string;
  name?: string;
  scrollToRef: RefObject<HTMLElement>;
  animate?: boolean;
}

export const HiddenInput: FunctionComponent<Props> = ({
  value = "",
  name,
  validators = [],
  forceValidation = false,
  scrollToRef,
  animate = true,
}) => {
  const inputId = useRef("text_" + Math.random().toString(36).substring(2, 9));

  const [validity, errorMessages, resetValidation, status] = useValidation(
    value,
    validators,
    forceValidation
  );
  useForm(inputId.current, validity, value, resetValidation, scrollToRef);

  return (
    <div className={cx("hidden-input", status)}>
      <input
        value={value}
        type="hidden"
        id={inputId.current}
        name={name || inputId.current}
      />
      {animate ? (
        <AnimateHeightMotion>
          {errorMessages.length > 0 && (
            <div className="input-messages">
              <div className="input-message">{translate(errorMessages[0])}</div>
            </div>
          )}
        </AnimateHeightMotion>
      ) : (
        <div className="input-messages">
          <div className="input-message">{translate(errorMessages[0])}</div>
        </div>
      )}
    </div>
  );
};
