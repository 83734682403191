import { atom } from "recoil";
import { CaseId, UserId, UTCDate } from "../data/models/CommonTypes";
import {
  CaseStatus,
  Case,
  RegistryResult,
  CaseType,
} from "../data/models/caseTypes";

export interface CaseStatusState
  extends Pick<
    Case,
    | "id"
    | "updated"
    | "currentUser"
    | "type"
    | "status"
    | "caseClosedReason"
    | "registryResult"
    | "timeline"
    | "hasCompleteKycData"
    | "reviewedCompleted"
  > {
  edit: boolean;
  needManualCompanyData: boolean;
  sentToOnboarding?: UTCDate;
  isLegacyCase: boolean;
}

export const caseStatusState = atom<CaseStatusState>({
  key: "case-status-state",
  default: {
    id: "" as CaseId,
    status: CaseStatus.DATA_COLLECTION,
    type: CaseType.NEW_CUSTOMER,
    timeline: { created: new Date().toISOString() as UTCDate },
    edit: false,
    currentUser: {
      id: "" as UserId,
      name: "",
    },
    registryResult: RegistryResult.RESOLVED,
    needManualCompanyData: false,
    isLegacyCase: false,
  },
});
