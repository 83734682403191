import React from "react";

import "./Progress.scss";

export interface ProgressObject {
  progress: number;
  name: string;
}

export const Progress: React.FunctionComponent<ProgressObject> = ({
  name,
  progress,
}) => {
  return (
    <div className="progress text-small">
      <div className="progress-pending-name">{name}</div>
      <div className="progress-pending-bar-bg">
        <div
          className="progress-pending-bar-overlay"
          style={{
            transform: `translate3d(-${100 - progress}%, 0, 0)`,
          }}
        />
      </div>
    </div>
  );
};
