import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { Status } from "../../data/types";
import { Button } from "../interactions/Buttons/Button";
import { Spinner } from "../spinner/Spinner";
import { T } from "../translation/T";

interface Props {
  retry?: () => void;
  children: React.ReactNode | string;
  status: Status;
}

export const Retry: React.FC<Props> = (props) => {
  const { retry, children, status } = props;
  const navigate = useNavigate();

  const onRetry = useCallback(() => {
    if (retry) {
      retry();
    }
  }, [retry]);

  const backToDashboard = () => {
    navigate("/");
  };

  let elem = null;
  if (status === Status.PENDING) {
    elem = (
      <div className="retry-pending center">
        <Spinner size="small" />
      </div>
    );
  }

  if (status === Status.ERROR) {
    elem = (
      <div className="retry-error">
        <p>
          <T>Could not load page</T>
        </p>
        {retry && (
          <Button onClick={onRetry}>
            <T>Retry</T>
          </Button>
        )}

        <Button ghost onClick={backToDashboard}>
          <T>Back to dashboard</T>
        </Button>
      </div>
    );
  }

  if (status === Status.SUCCESS) {
    elem = children;
  }

  return <>{elem}</>;
};
