import { useMemo } from "react";
import { numericFormatter } from "react-number-format";
import { T } from "../../../../components/translation/T";

export const useEstimatedTransactionCount = (
  averageTransaction: number,
  turnover?: number
) => {
  return useMemo(() => {
    const value = (turnover || 0) / averageTransaction;

    if (!value) {
      return;
    }

    return (
      <>
        <T>Calculated</T>:{" "}
        {numericFormatter(value.toString(), {
          thousandSeparator: " ",
          decimalSeparator: ",",
          decimalScale: 0,
        })}
      </>
    );
  }, [turnover, averageTransaction]);
};
