import cx from "classnames";
import React, { useEffect, useState } from "react";
import { generatePath } from "react-router";
import { useRecoilValue } from "recoil";
import { AssignmentCompleted } from "../components/icons/AssignmentCompleted";
import { Edit } from "../components/icons/Edit";
import { ListEdit } from "../components/icons/ListEdit";
import { Link } from "../components/links/Link";
import { Menu } from "../components/menu/Menu";
import { MenuWrapper } from "../components/menuWrapper/MenuWrapper";
import { VerticalMenu } from "../components/verticalMenu/VerticalMenu";
import { CaseId } from "../data/models/CommonTypes";
import { CaseStatus } from "../data/models/caseTypes";
import { CASE_ROUTE } from "./sales/case/CasePage";
import { DASHBOARD_ROUTE } from "./sales/Dashboard/DashboardPage";
import { CASE_REVIEW_ROUTE } from "./sales/review/CaseReviewPage";
import { caseStatusState } from "../state/caseStatusState";
import { CASE_COMPLETE_PAGE } from "./sales/complete/CaseCompletePage";
import "./Page.scss";
import { SupportBox } from "../components/verticalNav/SupportBox";
import { T } from "../components/translation/T";

export interface PageProps {
  caseId: CaseId;
  children: React.ReactNode;
  className?: string;
  title?: string;
  stripped?: boolean;
  menuChildren?: React.ReactNode;
  sidebarComponent?: React.ReactNode;
}

export const CasePageMenu = React.forwardRef<HTMLDivElement, PageProps>(
  (
    {
      caseId,
      children,
      className,
      title,
      stripped = false,
      menuChildren,
      sidebarComponent,
    },
    ref
  ) => {
    const [menuOpen, toggleMenu] = useState<boolean>(false);
    const caseStatus = useRecoilValue(caseStatusState);

    useEffect(() => {
      if (!title) {
        document.title = "Worldline Outreach";
        return;
      }
      document.title = `${title} | Worldline Outreach`;
    }, [title]);

    const dashboardLink = () => {
      return (
        <Link className="as-button ghost block" link={DASHBOARD_ROUTE}>
          Dashboard
        </Link>
      );
    };

    // const enableDetailsPage = caseStatus.status === CaseStatus.DATA_COLLECTION;

    const enableReviewPage =
      caseStatus && caseStatus.status !== CaseStatus.DATA_COLLECTION;

    const enableCompletedPage =
      caseStatus.status === CaseStatus.COMPLETED_VERIFICATION ||
      caseStatus.status === CaseStatus.CLOSED;

    const menuHasError =
      caseStatus.hasCompleteKycData === false &&
      (caseStatus.status === CaseStatus.OUTREACH ||
        caseStatus.status === CaseStatus.VERIFICATION);

    return (
      <div className={cx("page", "case-page-menu", "background", className)}>
        <MenuWrapper
          {...{ menuOpen, toggleMenu, ref, sidebarComponent }}
          menuComponent={
            <VerticalMenu logo={true}>
              <nav>
                {dashboardLink()}

                <Menu
                  menuItems={[
                    {
                      name: (
                        <>
                          <Edit />
                          <T>Case details</T>
                        </>
                      ),
                      link: generatePath(CASE_ROUTE, { id: caseId }),
                      disabled: false,
                      error: menuHasError,
                    },
                    {
                      name: (
                        <>
                          <ListEdit />
                          <T>Review</T>
                        </>
                      ),
                      link: generatePath(CASE_REVIEW_ROUTE, { id: caseId }),
                      disabled: !enableReviewPage,
                    },
                    {
                      name: (
                        <>
                          <AssignmentCompleted />
                          <T>Summary</T>
                        </>
                      ),
                      link: generatePath(CASE_COMPLETE_PAGE, {
                        id: caseId,
                      }),
                      disabled: !enableCompletedPage,
                    },
                  ]}
                />
                {menuChildren}
              </nav>
              <div className="bottom-content">
                <SupportBox />
              </div>
            </VerticalMenu>
          }
        >
          {children}
        </MenuWrapper>
      </div>
    );
  }
);
