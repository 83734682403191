import { atom } from "recoil";
import { CaseId } from "../data/models/CommonTypes";
import {
  CaseStatus,
  CaseType,
  MainCaseData,
  RegistryResult,
} from "../data/models/caseTypes";

export const caseMainState = atom<MainCaseData>({
  key: "case-main-state",
  default: {
    id: "0" as CaseId,
    status: CaseStatus.DATA_COLLECTION,
    type: CaseType.NEW_CUSTOMER,
    lookups: {},
    registryResult: RegistryResult.RESOLVED,
  },
});
