import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Trans } from "react-i18next";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Status } from "../../../data/types";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { Pending } from "../../../components/icons/Pending";
import {
  dataReview,
  OnboardingStatus,
  OnboardingTeam,
  OnboardingTeamNames,
  SendOnboardingTeam,
} from "../../../data/dataReview";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { Link } from "../../../components/links/Link";
import { generatePath } from "react-router";
import { CASE_COMPLETE_PAGE } from "../complete/CaseCompletePage";
import { T, TCondition, TDefault } from "../../../components/translation/T";
import { Select } from "../../../components/form/Select";
import { Alternative } from "../../../components/interactions/InputTypes";
import { Form } from "../../../components/form/Form";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { TextInput } from "../../../components/form/TextInput";
import { EmailBackendValidator } from "../../../components/form/validators/EmailBackendValidator";
import { EmailValidator } from "../../../components/form/validators/EmailValidator";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { getIntlDate } from "../../../components/utils";
import i18n, { Language } from "../../../i18n";
import { TransSwitch } from "../../../components/translation/TransSwitch";
import { useRecoilState, useRecoilValue } from "recoil";
import { caseStatusState } from "../../../state/caseStatusState";
import { UTCDate } from "../../../data/models/CommonTypes";
import { WarningBox } from "../../../components/boxes/WarningBox";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import { useInterval } from "../../../hooks/useInterval";

import { ColumbusArchive, dataColumbus } from "../../../data/dataColumbus";

import { ErrorOutline } from "../../../components/icons/ErrorOutline";
import { isCaseReviewedBeforeColumbus } from "./CaseReviewPage";
import { caseBeneficialOwnerState } from "../../../state/caseBeneficialOwnerState";

import styles from "./SendToOnboarding.module.scss";
import { Checkmark } from "../../../components/icons/Checkmark";

const COLUMBUS_STATUS_REFRESH_INTERVAL = 4000;
const MAX_POLLING_ATTEMPTS = 25;
// 15 polling attempts * 4 seconds interval = check for a total of 1 minute
const useIntervalOptions = {
  maxAttempts: MAX_POLLING_ATTEMPTS,
  interval: COLUMBUS_STATUS_REFRESH_INTERVAL,
  startImmediately: false,
};

const PENDING_STATUS_CODE = 204;
const SUCCESS_STATUS_CODE = 200;
const ERROR_STATUS_CODE = 400;

export const SendToOnboarding = ({
  isAllowedToEdit,
}: {
  isAllowedToEdit: boolean;
}) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [onboardingTeam, setOnboardingTeam] = useState<OnboardingTeamNames>();
  const [onboardingTeams, setOnboardingTeams] = useState<OnboardingTeam[]>();
  const [email, setEmail] = useState<string | undefined>();
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus>();
  const [caseStatus, setCaseStatus] = useRecoilState(caseStatusState);
  const [consent, setConsent] = useState<string[]>([]);
  const [sendToOnboardingLoading, setSendToOnboardingLoading] = useState(false);
  const ownerLength = useRecoilValue(caseBeneficialOwnerState).length;
  // was onboarded manually wihtout Columbus integration
  const [isLegacyCase, setIsLegacyCase] = useState(false);

  // array of document names that failed to be archived in Columbus
  const [failedColumbusDocuments, setFailedColumbusDocuments] = useState<
    ColumbusArchive[]
  >([]);
  // gets set to true if polling is sending back 204 after max attempts
  const [unexpectedColumbusError, setUnexpectedColumbusError] = useState(false);

  const { id: caseId } = caseStatus;

  const getArchiveStatus = useCallback(async () => {
    const count = calculateTotalDocuments(
      onboardingTeam,
      onboardingTeams,
      ownerLength
    );

    console.log(`expecting ${count} documents`);

    try {
      const res = await dataColumbus.getColumbusArchiveStatus(caseId, count);
      const failedDocuments = await res.data;
      return { failedDocuments, statusCode: res.status };
    } catch (e) {
      return { failedDocuments: [], statusCode: 400 };
    }
  }, [onboardingTeam, onboardingTeams, caseId, ownerLength]);

  const { startPolling, stopPolling, callbackResponse, isPolling, attempts } =
    useInterval<any>(getArchiveStatus, useIntervalOptions);

  // "third useEffect", to know how to handle the polling response
  useEffect(() => {
    if (!callbackResponse || isLegacyCase) return;
    const { statusCode, failedDocuments } = callbackResponse;

    const unexpectedErrorCondition =
      !isPolling &&
      (statusCode === PENDING_STATUS_CODE || statusCode === ERROR_STATUS_CODE);

    // if polling is sending back 204 after max attempts. Should not really be possible to happen.
    // or if polling is sending back 400 after max attempts.
    if (unexpectedErrorCondition) {
      return setUnexpectedColumbusError(true);
    }

    // continue polling if status code is pending
    if (statusCode === PENDING_STATUS_CODE) return;

    // both for success and failure, stop polling
    if (statusCode === SUCCESS_STATUS_CODE) {
      // can be empty array or array of failed documents
      setFailedColumbusDocuments(failedDocuments);
      stopPolling();
    }
  }, [
    callbackResponse,
    stopPolling,
    setFailedColumbusDocuments,
    isPolling,
    isLegacyCase,
  ]);

  // "first effect", to fetch onboarding teams and status
  useEffect(() => {
    Promise.all([
      dataReview.getOnboardingTeams(),
      dataReview.getOnboardingStatus(caseId).catch(() => null),
    ])
      .then(([teams, onboardingStatus]) => {
        const filteredTeams = teams.filter(
          (onboardingTeam) => onboardingTeam.team !== OnboardingTeamNames.TEST
        );
        setOnboardingTeams(filteredTeams);
        if (onboardingStatus) {
          setOnboardingStatus(onboardingStatus);
          setEmail(onboardingStatus.email);
          setOnboardingTeam(onboardingStatus.team);

          if (isCaseReviewedBeforeColumbus(onboardingStatus.created)) {
            // we do not need to start polling columbus_archive for legacy cases
            setIsLegacyCase(true);
          } else {
            // start polling after fetching initial data
            setIsLegacyCase(false);
            startPolling();
          }
        } else {
          // No onboarding case has been created for case yet
          setOnboardingStatus(undefined);
          setEmail("");
          setOnboardingTeam(undefined);
        }
      })
      .finally(() => {
        setStatus(Status.DEFAULT);
      });
  }, [caseId]);

  const onSelectOnboardingTeam = (selectedTeam: string | undefined) => {
    const foundTeam = onboardingTeams?.find(
      (team) => team.team === selectedTeam
    );
    setEmail(foundTeam?.email ?? undefined);
    setOnboardingTeam(selectedTeam as OnboardingTeamNames);
  };

  const alternatives: Alternative<string | undefined>[] = useMemo(() => {
    return onboardingTeams
      ? [
          {
            value: undefined,
            text: <T>Select onboarding team</T>,
          },
          ...onboardingTeams.map((team) => ({
            value: team.team,
            text: team.name,
          })),
        ]
      : [
          {
            value: undefined,
            text: <T>Select onboarding team</T>,
          },
        ];
  }, [onboardingTeams]);

  const sendToOnboarding = useCallback(() => {
    if (!onboardingTeam || onboardingStatus) return;

    const foundTeam = onboardingTeams?.find(
      (team) => team.team === onboardingTeam
    );

    if (!foundTeam) return;

    setStatus(Status.PENDING);
    setSendToOnboardingLoading(true);

    const requestBody: SendOnboardingTeam = {
      team: foundTeam.team,
    };

    dataReview
      .sendToOnboarding(caseStatus.id, requestBody)
      .then((data) => {
        setCaseStatus((prev) => ({
          ...prev,
          sentToOnboarding: new Date().toUTCString() as UTCDate,
        }));
        setOnboardingStatus(data);
        setStatus(Status.DEFAULT);
        setSendToOnboardingLoading(false);
        startPolling();
      })
      .catch((err) => {
        setStatus(Status.ERROR);
        setTimeout(() => {
          setStatus(Status.DEFAULT);
          setSendToOnboardingLoading(false);
        }, 6000);
      });
  }, [
    caseStatus.id,
    onboardingTeam,
    onboardingTeams,
    setCaseStatus,
    onboardingStatus,
    startPolling,
  ]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(sendToOnboarding, 600);
  }, [sendToOnboarding]);

  // if (
  //   caseStatus.status !== CaseStatus.COMPLETED_VERIFICATION ||
  // ) {
  //   return null;
  // }

  let elem = null;
  if (status === Status.DEFAULT && !isPolling) {
    elem = (
      <>
        {failedColumbusDocuments.length > 0 || unexpectedColumbusError ? (
          <>
            <ErrorBox relative>
              {onboardingStatus ? (
                <>
                  <div style={{ lineHeight: 1.5, paddingBottom: 10 }}>
                    <T>
                      This case encountered a problem while archiving files to
                      Columbus!
                    </T>
                  </div>
                  <div>
                    <span className="onboarding-status-span">
                      {onboardingStatus.team}
                    </span>
                    <span className="onboarding-status-span">
                      {onboardingStatus?.email
                        ? onboardingStatus.email
                        : "no email was provided"}
                    </span>
                    <span className="onboarding-status-span">
                      {getIntlDate(
                        onboardingStatus.created,
                        i18n.language as Language
                      )}
                    </span>
                  </div>
                </>
              ) : (
                <T>All owners has been verified.</T>
              )}
            </ErrorBox>
            {unexpectedColumbusError ? (
              <div>
                <p className="onboarding-status-p">
                  <T>
                    An unexpected error occured while fetching the archive
                    status of the case files.
                  </T>
                </p>
                <p className="onboarding-status-p">
                  <T>
                    This may be caused by an unusual high demand on Columbus.
                    You can retry again in a few minutes to see if it gets
                    resolved.
                  </T>
                </p>
                <p className="onboarding-status-p">
                  <T>
                    However, if this error persists, you need to archive the
                    case file manually.
                  </T>
                </p>
                <Button block onClick={() => startPolling()}>
                  Retry
                </Button>
              </div>
            ) : (
              <div>
                <p className="onboarding-status-p">
                  <T>
                    The following files did not get properly archived and has to
                    be done so manually:
                  </T>
                </p>

                <ul className="onboarding-failed-doc-ul">
                  {failedColumbusDocuments.map((doc) => (
                    <li key={doc.filename} className="onboarding-failed-doc-li">
                      <ErrorOutline /> {doc.filename}
                    </li>
                  ))}
                </ul>
                <p className="onboarding-status-p">
                  <T>
                    You can find all case files by clicking the "Download case
                    files" button to the left.
                  </T>
                </p>
              </div>
            )}
          </>
        ) : (
          <SuccessBox relative>
            {onboardingStatus ? (
              <>
                <T>This case has been archived and sent to onboarding</T>
                <div>
                  <span className="onboarding-status-span">
                    {onboardingStatus.team}
                  </span>
                  <span className="onboarding-status-span">
                    {onboardingStatus?.email
                      ? onboardingStatus.email
                      : "no email was provided"}
                  </span>
                  <span className="onboarding-status-span">
                    {getIntlDate(
                      onboardingStatus.created,
                      i18n.language as Language
                    )}
                  </span>
                </div>
              </>
            ) : (
              <T>All owners has been verified.</T>
            )}
          </SuccessBox>
        )}
        <Form
          className="m-top-40"
          onSubmit={(_, form) => {
            if (form.isInvalid) {
              return;
            }
            sendToOnboarding();
          }}
        >
          <Select
            disabled={isAllowedToEdit && !onboardingStatus ? false : true}
            alternatives={alternatives}
            value={onboardingTeam}
            onChange={onSelectOnboardingTeam}
            label={<T>Onboarding team</T>}
            validators={[new RequiredValidator("No onboarding team selected.")]}
          />

          <>
            <TextInput
              label={<T>Onboarding team email</T>}
              placeholder={
                !onboardingTeam
                  ? "Select an onboaring team first"
                  : email
                  ? email
                  : "No email is registered for this onboarding team"
              }
              value={email}
              onChange={() => console.log("onChange")}
              disabled={true}
              type="email"
              validators={[
                new RequiredValidator(
                  "No email for onboarding team, enter manually."
                ),
                new EmailValidator("Email is not valid"),
                new MaxLengthValidator(
                  50,
                  "Email must be less than 50 characters"
                ),
                new EmailBackendValidator("Email is not valid"),
              ]}
            />
          </>

          {/* {onboardingTeam && (
            <div className={styles.archiveInfoBox}>
              <div className={styles.archiveInfoTitle}>
                <T>This team will insert </T>
                <span className={styles.archiveCount}>6</span>
                <T> lines in Columbus</T>
              </div>
              <div className={styles.acrhiveList}>
                <div className={styles.archiveRow}>
                  <Checkmark />

                  <div className={styles.archiveRowCount}>5</div>
                  <div className={styles.archiveRowDescription}>
                    <div className={styles.descriptionType}>KYC</div>
                    <div className={styles.descriptionText}>
                      1 zip file containing all attached documents
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.acrhiveList}>
                <div className={styles.archiveRow}>
                  <Checkmark />

                  <div className={styles.archiveRowCount}>5</div>
                  <div className={styles.archiveRowDescription}>
                    <div className={styles.descriptionType}>UBO</div>
                    <div className={styles.descriptionText}>
                      1 zip file for each UBO
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {!onboardingStatus && (
            <>
              <WarningBox>
                <T>
                  Please make sure that the selected onboarding team is correct.
                  Sending to onboarding will archive the files in Columbus and
                  the action cannot be reversed.
                </T>
              </WarningBox>
              <div className="m-top-20" />
              <Checkboxes
                name="consent"
                values={consent}
                onChange={setConsent}
                alternatives={checkboxAlternatives}
              />
            </>
          )}

          <Button
            className="m-top-20"
            block
            type="submit"
            status={
              !isAllowedToEdit || consent.length < 1 || onboardingStatus?.sent
                ? Status.DISABLED
                : Status.DEFAULT
            }
          >
            <TransSwitch>
              <TCondition condition={onboardingStatus?.sent ? true : false}>
                This case has already been sent to onboarding
              </TCondition>
              <TDefault>Send to onboarding</TDefault>
            </TransSwitch>
          </Button>
        </Form>
      </>
    );
  } else if (status === Status.ERROR) {
    elem = (
      <>
        <ErrorBox relative>
          <Trans>
            <b>Ooh no!</b> &nbsp;We couldn't send this to the onboarding team
          </Trans>
        </ErrorBox>
        <Button className="m-top-20" block onClick={retry}>
          <T>Try again?</T>
        </Button>
      </>
    );
  } else if (status === Status.SUCCESS && !isPolling && onboardingTeam) {
    elem = (
      <>
        <p>
          <b>
            <T>Done!</T>
          </b>
          <br />
          <Trans>
            This case has been archived in Columbus and we have also notified
            the onboarding team. You can follow the{" "}
            <Link
              link={generatePath(CASE_COMPLETE_PAGE, {
                id: caseStatus.id,
              })}
            >
              progress here
            </Link>
            .
          </Trans>
        </p>
      </>
    );
  } else if (!sendToOnboardingLoading && isPolling) {
    elem = (
      <div className="center">
        <Pending />
        <div>
          <Trans>
            Checking archiving status for case files, this might take a
            minute...
          </Trans>
        </div>
        <div
          style={{ fontWeight: 600 }}
        >{`Checking ${attempts} of ${MAX_POLLING_ATTEMPTS}`}</div>
      </div>
    );
  } else {
    elem = (
      <div className="center">
        <Pending />
        {sendToOnboardingLoading && (
          <div>
            <Trans>
              Sending case to onboarding team and archiving files in Columbus,
              this might take a minute...
            </Trans>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="send-to-onboarding">
      <div className="m-top-40">
        <AnimateHeight name={status}>
          <div>{elem}</div>
        </AnimateHeight>
      </div>
    </div>
  );
};

const alternative: Alternative<string> = {
  value: "I_UNDERSTAND",
  text: "I understand",
};

export const checkboxAlternatives: Alternative<string>[] = [alternative];

const calculateTotalDocuments = (
  selectedTeam: OnboardingTeamNames | undefined,
  allTeams: OnboardingTeam[] | undefined,
  beneficialOwners: number
) => {
  if (!selectedTeam || !allTeams) return 9999;

  const selectedOnboardingTeam = allTeams?.find(
    (onboardingTeam) => onboardingTeam.team === selectedTeam
  );
  if (!selectedOnboardingTeam) return 0;
  let count = 0;
  const { isKYC, isUBO } = selectedOnboardingTeam;

  if (isKYC) {
    count += 1; // KYC is always just 1 file since everything is merged in a zip
  }

  if (isUBO) {
    // 1 UBO zip file for each beneficialOwners
    count += beneficialOwners;
  }

  return count;
};
