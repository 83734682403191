import { Country } from "../../i18n";
import { BaseValidator } from "../form/validators/BaseValidator";
import { CBEValidator } from "../form/validators/CBEValidator";
import { IcoValidator } from "../form/validators/IcoValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { SirenValidator } from "../form/validators/SirenValidator";

export const companyRegistrationIdValidators: Record<Country, BaseValidator[]> =
  {
    [Country.BELGIUM]: [
      new RequiredValidator("Please enter CBE number"),
      new CBEValidator("CBE number is not valid"),
    ],
    [Country.FRANCE]: [
      new RequiredValidator("Please enter Siren number"),
      new SirenValidator("Siren is not valid"),
    ],
    [Country.LUXEMBURG]: [
      new RequiredValidator("Please enter Enterprise number"),
    ],
    [Country.NETHERLANDS]: [new RequiredValidator("Please enter KvK or BSB")],
    [Country.CZECH_REPUBLIC]: [
      new RequiredValidator("Please enter IČO number"),
      new IcoValidator("IČO is not valid"),
    ],
    [Country.SLOVAKIA]: [
      new RequiredValidator("Please enter IČO number"),
      new IcoValidator("IČO is not valid"),
    ],
    [Country.UNITED_KINGDOM]: [
      new RequiredValidator("Please enter company number"),
    ],
    [Country.GERMANY]: [new RequiredValidator("Please enter company number")],
  };

export const countryHasRegistryMap: Record<Country, boolean> = {
  [Country.BELGIUM]: false,
  [Country.FRANCE]: false,
  [Country.LUXEMBURG]: false,
  [Country.NETHERLANDS]: false,
  [Country.CZECH_REPUBLIC]: true,
  [Country.SLOVAKIA]: true,
  [Country.UNITED_KINGDOM]: false,
  [Country.GERMANY]: false,
};
