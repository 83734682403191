import { ServerError } from "../../network/API";
import { dataOutreach } from "../dataOutreach";
import { CaseId } from "../models/CommonTypes";
import { CompanyKyc } from "../models/caseTypes";

interface CompanyKycQueueProps {
  caseId: CaseId;
  kyc: CompanyKyc;
  callback: (
    error: ServerError<CompanyKyc> | null,
    response?: CompanyKyc
  ) => void;
}

class CompanyKycQueue {
  queue: CompanyKycQueueProps[] = [];
  isRequesting: boolean | null = null;

  save(
    caseId: CaseId,
    kyc: CompanyKyc,
    callback: (
      error: ServerError<CompanyKyc> | null,
      response?: CompanyKyc
    ) => void
  ) {
    this.isRequesting = true;

    dataOutreach
      .updateKyc(caseId, kyc)
      .then((response) => {
        this.isRequesting = null;

        const next = this.queue.pop();
        if (next) {
          this.save(next.caseId, next.kyc, next.callback);
        }

        callback(null, response);
      })
      .catch(() => {
        this.isRequesting = null;

        const next = this.queue.pop();
        if (next) {
          this.save(next.caseId, next.kyc, next.callback);
        }
      });
  }
}

export const companyKycQueue = new CompanyKycQueue();
