import React, { useState } from "react";
import { ZoomableImage } from "../../../../components/images/ZoomableImage";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Name } from "../../../../components/name/Name";
import { T } from "../../../../components/translation/T";
import { dataReview } from "../../../../data/dataReview";
import { CaseId } from "../../../../data/models/CommonTypes";
import { BeneficialOwner } from "../../../../data/models/caseTypes";
import { Status } from "../../../../data/types";
import { ReviewRejectWrapper } from "./ReviewRejectWrapper";

interface Props {
  owner: BeneficialOwner;
  caseId: CaseId;
  completed: () => void;
}

export const AssociateCompareReview: React.FunctionComponent<Props> = ({
  owner,
  completed,
  caseId,
}) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const { identity } = owner;

  const onReject = () => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataReview
        .rejectOwner(owner)
        .then(() => setStatus(Status.SUCCESS))
        .catch((err) => setStatus(Status.ERROR));
    }, 600);
  };

  if (!identity) {
    return null;
  }

  return (
    <div className="associate-review">
      <div className="associate-review-top">
        <h2>
          <Name associate={owner} />
        </h2>
      </div>

      <div className="review-box m-top-40">
        <div className="tablet-columns">
          <div>
            <div className="associate-review-selfie">
              <ZoomableImage image={identity.selfie} alt="Selfie" />
            </div>
          </div>
          <div>
            <div className="compare-id-and-selfie">
              <div>
                <ZoomableImage
                  image={identity.passport || identity.idFront}
                  alt="ID Card"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="m-top-20">
          <ReviewRejectWrapper
            status={status}
            onReject={onReject}
            caseId={caseId}
          >
            <T>Compare the selfie and the image in the ID</T>

            <div className="tablet-columns m-top-10">
              <div>
                <Button onClick={onReject} block className="danger-button">
                  <T>Reject selfie</T>
                </Button>
              </div>
              <div>
                <Button onClick={completed} block>
                  <T>Selfie and ID match</T>
                </Button>
              </div>
            </div>
          </ReviewRejectWrapper>
        </div>
      </div>
    </div>
  );
};
