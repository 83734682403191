import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Country, Language } from "../../i18n";
import { Select } from "../form/Select";
import { Alternative } from "../interactions/InputTypes";
import { getLanguageName } from "../translation/I18Utils";
import cx from "classnames";
import { Flag } from "../flags/Flag";
import "./NewLanguageSelect.scss";

const CountryOfLang: Record<Language, Country | string> = {
  [Language.FRENCH]: Country.FRANCE,
  [Language.GERMAN]: Country.GERMANY,
  [Language.DUTCH]: Country.NETHERLANDS,
  [Language.ENGLISH]: Country.UNITED_KINGDOM,
  [Language.CZECH]: Country.CZECH_REPUBLIC,
  [Language.SLOVAK]: Country.SLOVAKIA,
};

interface Props {
  className?: string;
}

export const NewLanguageSelect = ({ className }: Props) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const languageAlternatives: Alternative<Language>[] = useMemo(
    () =>
      Object.values(Language).map((key) => ({
        value: key as Language,
        text: getLanguageName(key as Language, language),
      })),
    [language]
  );

  return (
    <div className={cx("new-language-select", className)}>
      <Flag
        country={
          (CountryOfLang[language as Language] as Country) ||
          Country.UNITED_KINGDOM
        }
        height={24}
        rounded
        shadow
      />
      <Select
        value={language}
        alternatives={languageAlternatives}
        onChange={(newLanguage) => {
          i18n.changeLanguage(newLanguage);
        }}
      />
    </div>
  );
};
