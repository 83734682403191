import { useEffect, useRef, useState } from "react";

interface useIntervalOptions {
  interval?: number;
  maxAttempts?: number | null;
  startImmediately?: boolean;
}

export function useInterval<T>(
  callback: () => Promise<T>,
  options: useIntervalOptions = {
    interval: 1000,
    maxAttempts: null,
    startImmediately: true,
  }
) {
  const { interval: delay, maxAttempts, startImmediately } = options;

  const [attempts, setAttempts] = useState(1);
  const [isPolling, setIsRunning] = useState(startImmediately);
  const [callbackResponse, setCallbackResponse] = useState<T | null>(null);

  // Remember latest callback
  const savedCallback = useRef<typeof callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up interval
  useEffect(() => {
    if (!isPolling) return;

    async function tick() {
      if (!savedCallback.current) return;

      const response = await savedCallback.current();
      setCallbackResponse(response);

      setAttempts((prevAttempts) => prevAttempts + 1);
      // if (maxAttempts) {
      //   console.log(`Attempt ${attempts} of ${maxAttempts}...`);
      // }

      if (maxAttempts && attempts >= maxAttempts) {
        console.log("Max attempts reached, stopping polling");
        setIsRunning(false);
        setAttempts(1);
      }
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [callback, delay, isPolling, maxAttempts, attempts]);

  return {
    startPolling: () => setIsRunning(true),
    stopPolling: () => setIsRunning(false),
    isPolling,
    callbackResponse,
    maxAttempts,
    attempts,
  };
}
