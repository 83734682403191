import cx from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { CountrySelect } from "../../../components/countrySelect/CountrySelect";
import { Pagination } from "../../../components/pagination/Pagination";
import { Retry } from "../../../components/retry/Retry";
import { SearchInput } from "../../../components/searchInput/SearchInput";
import { T } from "../../../components/translation/T";
import { UserSelect } from "../../../components/userSelect/UserSelect";
import { DashboardCase, dataDashboard } from "../../../data/dataDashboard";
import { UserId } from "../../../data/models/CommonTypes";
import { FilterUtils } from "../../../data/models/Filter";
import { Page } from "../../../data/models/Page";
import { Status } from "../../../data/types";
import { Country } from "../../../i18n";
import { ABORT_ERROR } from "../../../network/API";
import { userState } from "../../../state/userState";
import {
  defaultFilter,
  Filter as FilterInterface,
  Store,
  STORE_KEY,
} from "../../../Store";
import { DASHBOARD_ROUTE } from "./DashboardPage";
import { DownloadExcel } from "./DownloadExcel";
import { Filter } from "./filter/Filter";
import { FilterRow } from "./FilterRow";
import "./FilterView.scss";

interface Props {
  className?: string;
}

export interface DocumentSearch {
  searchString: string;
  searchResult: string[];
  numOfMatches: number;
}

export const FilterView: React.FunctionComponent<Props> = ({ className }) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [cases, setCases] = useState<Page<DashboardCase>>();
  const [filter, setFilter] = useState<FilterInterface<DashboardCase>>({
    ...defaultFilter(),
  });
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const user = useRecoilValue(userState);
  const hasLoadedRef = React.useRef(false);
  const [sortField, setSortField] = useState<string>();
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">();
  const [documentSearch, setDocumentSearch] = useState<DocumentSearch>({
    searchString: "",
    searchResult: [],
    numOfMatches: 0,
  });

  useEffect(() => {
    if (search) {
      if (search === FilterUtils.getQueryFromFilter(filter)) {
        return;
      }
      const f = FilterUtils.searchToFilter<DashboardCase>(search);
      setFilter(f);
      Store.setValue(STORE_KEY.STORE_DASHBOARD_FILTER, f);
    } else {
      const search = `${FilterUtils.getQueryFromFilter(
        Store.getValue<FilterInterface<DashboardCase>>(
          STORE_KEY.STORE_DASHBOARD_FILTER
        )
      )}`;

      navigate(
        {
          pathname: DASHBOARD_ROUTE,
          search: search,
        },
        { replace: true }
      );
    }
  }, [search, navigate, filter]);

  const load = useCallback(
    (controller: AbortController) => {
      if (!hasLoadedRef.current) {
        setStatus(Status.PENDING);
      }
      dataDashboard
        .loadCases(
          { ...filter, size: 20 },
          {
            signal: controller.signal,
          }
        )
        .then((contractsResponse) => {
          setStatus(Status.SUCCESS);
          setCases(contractsResponse);
          hasLoadedRef.current = true;
        })
        .catch((error) => {
          if (error.name === ABORT_ERROR) {
            return;
          }
          setStatus(Status.ERROR);
        });
    },
    [filter]
  );

  const reload = useCallback(() => {
    const controller = new AbortController();
    load(controller);
    return () => controller?.abort();
  }, [load]);

  useEffect(() => {
    const controller = new AbortController();
    load(controller);
    return () => controller?.abort();
  }, [load]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const sortField = searchParams.get("sortField");
    const sortDirection = searchParams.get("sortDirection");

    setSortField(sortField ?? undefined);
    setSortDirection((sortDirection as "ASC" | "DESC") ?? undefined);
  }, [search]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 600);
  }, [load]);

  const onSortChange = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const property = (ev.target as HTMLButtonElement).dataset.id || "updated";

      const params = FilterUtils.getParams(filter);
      const alreadySorted = params.get("sortField") === property;
      params.set("sortField", property);

      const direction = alreadySorted
        ? sortDirection === "ASC"
          ? "DESC"
          : "ASC"
        : "DESC";
      params.set("sortDirection", direction);
      navigate(`${DASHBOARD_ROUTE}?${params.toString()}`);
    },
    [filter, navigate, sortDirection]
  );

  const setCountry = (country?: string | "benelux") => {
    if (country && country === "benelux") {
      const newFilter: FilterInterface<DashboardCase> = {
        ...filter,
        countries: ["BE", "NL", "LU"],
      };
      navigate(
        `${DASHBOARD_ROUTE}?${FilterUtils.getParams(newFilter).toString()}`
      );
      return;
    }

    const params = FilterUtils.getParams(filter);

    if (country) {
      params.set("countries", country);
    } else {
      params.delete("countries");
    }
    navigate(`${DASHBOARD_ROUTE}?${params.toString()}`);
  };

  const onPageChange = (page: number) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page.toString());
    navigate({
      pathname: pathname,
      search: "?" + searchParams.toString(),
    });
  };

  const onUserChange = (userId?: UserId) => {
    const newFilter: FilterInterface<DashboardCase> = {
      ...filter,
      userId,
    };

    if (typeof userId === "undefined") {
      delete newFilter.userId;
    }

    navigate(
      `${DASHBOARD_ROUTE}?${FilterUtils.getParams(newFilter).toString()}`
    );
  };

  const isSorted = (property: string) => {
    if (sortField === property) {
      return sortDirection;
    }
    return undefined;
  };

  const onDocumentSearch = useCallback(
    (searchString: string) => {
      if (searchString.length === 0 || !cases) {
        setDocumentSearch({
          searchString,
          searchResult: [],
          numOfMatches: 0,
        });
        return;
      }

      const regex = new RegExp(searchString, "i");
      let searchResult: string[] = [];
      let numOfMatches = 0;

      cases.content.forEach((dashboardCase) => {
        dashboardCase.documents?.forEach((doc) => {
          let hasBeenAddedToMatches = false;

          if (doc.refNumber && regex.test(doc.refNumber)) {
            numOfMatches++;
            hasBeenAddedToMatches = true;
            searchResult.push(doc.refNumber);
          }

          if (regex.test(doc.filename)) {
            if (!hasBeenAddedToMatches) {
              numOfMatches++;
            }
            searchResult.push(doc.filename);
          }
        });
      });

      setDocumentSearch({ searchString, searchResult, numOfMatches });
    },
    [cases, setDocumentSearch]
  );

  if (!search) {
    return null;
  }

  const isBeneluxSelected =
    [Country.BELGIUM, Country.NETHERLANDS, Country.LUXEMBURG].every((country) =>
      filter.countries?.includes(country)
    ) || false;

  return (
    <div className={cx("filter-view", className)}>
      <Filter filter={filter} />
      <Retry status={status} retry={retry}>
        <div className="filter-content-wrapper">
          <div className="filter-table-wrapper">
            <table className="filter-table">
              <thead className="case-header">
                <tr className="case-cells">
                  <th className="case-cell company-name">
                    <div>
                      <T>Legal name</T>
                    </div>
                  </th>
                  <th className="case-cell company-id">
                    <div>
                      <T>Company</T>
                    </div>
                  </th>

                  <th className="case-cell status">
                    <div>
                      <T>Status</T>
                    </div>
                  </th>
                  <th className="case-cell company-id">
                    <div>
                      <T>Documents</T>
                    </div>
                  </th>
                  <th className="case-cell stale">
                    <div>
                      <T>Stale reason</T>
                    </div>
                  </th>
                  <th className="case-cell sales-user">
                    <div>
                      <T>Representative</T>
                    </div>
                  </th>
                  <th className="case-cell created">
                    <div>
                      <button
                        className={isSorted("created")}
                        onClick={onSortChange}
                        data-id="created"
                      >
                        <T>Started</T>
                      </button>
                    </div>
                  </th>
                  <th className="case-cell updated">
                    <div>
                      <button
                        className={isSorted("updated")}
                        onClick={onSortChange}
                        data-id="updated"
                      >
                        <T>Updated</T>
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {cases?.content.map((c) => (
                  <FilterRow
                    key={c.caseId}
                    outreachCase={c}
                    user={user}
                    reloadOutreachCases={reload}
                    documentSearchResult={documentSearch.searchResult}
                  />
                ))}
                {cases?.content.length === 0 && (
                  <tr className="case case-cells">
                    <th colSpan={8} className="case-cell no-results center">
                      <T>No results</T>
                    </th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="filter-content-bottom-wrapper">
            <Pagination
              className="m-top-10"
              onPageChange={onPageChange}
              activePage={cases ? cases.number : 0}
              totalPages={cases ? cases.totalPages : 0}
              alwaysShow={true}
            />
            <div className="bottom-filters">
              <div>
                <SearchInput
                  placeholder="Search for documents"
                  value={documentSearch.searchString}
                  onChange={onDocumentSearch}
                />

                <div className="document-search-result">
                  {documentSearch.numOfMatches > 0 ? (
                    <span>
                      {documentSearch.numOfMatches}
                      <T> documents found</T>
                    </span>
                  ) : (
                    <span>
                      {documentSearch.searchString.length > 0 ? (
                        <T>No documents found</T>
                      ) : null}
                    </span>
                  )}
                </div>
              </div>
              <UserSelect value={filter.userId} onChange={onUserChange} />
              <CountrySelect
                onChange={setCountry}
                value={isBeneluxSelected ? "benelux" : filter.countries?.[0]}
                label="Country filter"
                name="country-filter"
                allowedCountries={Object.values(Country)}
                placeholder={<T>All countries</T>}
                benelux
              />
            </div>
          </div>
          <div>
            <DownloadExcel {...filter} />
          </div>
        </div>
      </Retry>
    </div>
  );
};
