import { T } from "../../components/translation/T";
import { UserSession } from "../../data/dataAuth";
import { DashboardItem, FilterRow } from "../sales/Dashboard/FilterRow";

interface Props {
  cases: DashboardItem[];
  user: UserSession | null;
  reloadOutreachCases: () => void;
  documentSearchResult: string[];
}

export const CaseList = ({
  cases,
  user,
  reloadOutreachCases,
  documentSearchResult,
}: Props) => {
  return (
    <table className="filter-table">
      <thead className="case-header">
        <tr className="case-cells">
          <th className="case-cell company-name">
            <div>
              <T>Legal name</T>
            </div>
          </th>
          <th className="case-cell company-id">
            <div>
              <T>Company</T>
            </div>
          </th>
          <th className="case-cell status">
            <div>
              <T>Status</T>
            </div>
          </th>
          <th className="case-cell documents">
            <div>
              <T>Documents</T>
            </div>
          </th>
          <th className="case-cell stale">
            <div>
              <T>Stale reason</T>
            </div>
          </th>
          <th className="case-cell sales-user">
            <div>
              <T>Assigned user</T>
            </div>
          </th>
          <th className="case-cell created">
            <div>
              <T>Started</T>
            </div>
          </th>
          <th className="case-cell updated">
            <div>
              <T>Updated</T>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {cases.map((c) => (
          <FilterRow
            key={c.caseId}
            outreachCase={c}
            user={user}
            reloadOutreachCases={reloadOutreachCases}
            documentSearchResult={documentSearchResult}
          />
        ))}
        {cases.length === 0 && (
          <tr className="case case-cells">
            <th colSpan={8} className="case-cell no-results">
              <div className="m-bottom-10">
                <T>No historic cases</T>
              </div>
            </th>
          </tr>
        )}
      </tbody>
    </table>
  );
};
