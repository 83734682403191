import { Card } from "../../../components/card/Card";
import { CaseStatusDisplay } from "../../../components/caseStatus/CaseStatus";
import { DataList } from "../../../components/datalist/DataList";
import { DataListItem } from "../../../components/datalist/DataListItem";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { T } from "../../../components/translation/T";
import { Case, CaseType } from "../../../data/models/caseTypes";

export const CaseCard = ({ outreach }: { outreach: Case }) => {
  const { status, timeline, updated, type, currentUser, caseClosedReason } =
    outreach;

  return (
    <Card accent>
      <Card.Header>
        <h5>
          <T>Case</T>
        </h5>
        <div className="header-badge">
          <CaseStatusDisplay
            status={status}
            caseCloseReason={caseClosedReason}
          />
        </div>
      </Card.Header>
      <div>
        <DataList>
          <DataListItem
            text="Type"
            value={
              type === CaseType.CURRENT_CUSTOMER ? (
                <T>Existing merchant</T>
              ) : (
                <T>New merchant</T>
              )
            }
          />
          <DataListItem
            text="Created"
            value={<FormattedDate date={timeline.created} />}
          />
          <DataListItem
            text="Updated"
            value={<FormattedDate date={updated} />}
          />
          <DataListItem
            text="Assigned representative"
            value={currentUser?.name}
          />
        </DataList>
      </div>
    </Card>
  );
};
