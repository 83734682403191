import React from "react";
import cx from "classnames";
import { CaseStatus, BeneficialOwner } from "../../../../data/models/caseTypes";
import { getConfirmedOffset } from "./ToOwners";
import { ITEM_HEIGHT } from "./Timeline";

interface Props {
  owners: BeneficialOwner[];
  status: CaseStatus;
}

export const Complete: React.FunctionComponent<Props> = ({
  owners,
  status,
}) => {
  const offset = getConfirmedOffset(owners);

  return (
    <>
      <line
        x1="10"
        x2="10"
        y1={offset - ITEM_HEIGHT}
        y2={offset}
        className="active"
      />
      <circle
        r="6"
        cx="10"
        cy={offset}
        className={cx({
          active: status === CaseStatus.COMPLETED_VERIFICATION,
        })}
      />
    </>
  );
};
