import { motion } from "framer-motion";
import { Beacon } from "../../../../components/beacon/Beacon";
import { WarningOutline } from "../../../../components/icons/WarningOutline";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { T } from "../../../../components/translation/T";
import { Validity } from "../../../../data/models/caseTypes";
import "./CaseEditMenuItem.scss";

interface Props {
  anchor: { name: string; anchor: string };
  validity: Validity;
  onClick: (id: string) => void;
  active?: boolean;
}

export const CaseEditMenuItem: React.FunctionComponent<Props> = ({
  anchor,
  validity,
  onClick,
  active = false,
}) => {
  return (
    <li className="case-edit-menu-item">
      <Button link data={anchor.anchor} onClick={() => onClick(anchor.anchor)}>
        {active && (
          <motion.span
            className="active-indicator"
            layoutId="case-edit-menu-active-indicator"
            transition={{ type: "linear", duration: 0.25 }}
          />
        )}
        <div className="anchor-validity">
          <Beacon mini validity={validity} icon={<WarningOutline />} />
        </div>
        <motion.div
          className="anchor-name"
          animate={{
            fontWeight: active ? 600 : 500,
            x: active ? 4 : 0,
          }}
          transition={{ type: "tween", duration: 0.25 }}
        >
          <T>{anchor.name}</T>
        </motion.div>
      </Button>
    </li>
  );
};
