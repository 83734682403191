import React from "react";
import { Button } from "../interactions/Buttons/Button";
import { T } from "../translation/T";

export const PermissionError = () => {
  return (
    <div className="retry-error">
      <p>
        <T>You don't have permission to load this contract</T>
      </p>
      <Button
        onClick={() => {
          window.location.pathname = "/";
        }}
      >
        <T>Back to dashboard</T>
      </Button>
    </div>
  );
};
