import { data } from "./data";
import { OutreachCaseResponse } from "./dataOutreach";
import { BeneficialOwnerId, OnboardingCaseId } from "./models/CommonTypes";
import { BeneficialOwner, MerchantCategory } from "./models/caseTypes";
import { API } from "../network/API";

const BASE_URL = "/onboarding";

export const dataOnboardingDownloadUrl = (id: OnboardingCaseId) =>
  API.getUrl(`${BASE_URL}/${id}/zip`);

export const dataOnboarding = {
  getMccs: (mccCodes: string[]) => {
    const params = mccCodes.map((code) => `id=${code}`).join("&");
    return data.get<MerchantCategory[]>(`${BASE_URL}/mcc?${params}`, true);
  },

  get: (id: OnboardingCaseId, forceRefresh = true) =>
    data.get<OutreachCaseResponse>(`${BASE_URL}/${id}`, forceRefresh),

  close: (id: OnboardingCaseId, name: string) =>
    data.post(`${BASE_URL}/${id}/close`, {
      name,
    }),

  getOwner: (
    id: OnboardingCaseId,
    ownerId: BeneficialOwnerId,
    forceRefresh = true
  ) =>
    data.get<BeneficialOwner>(
      `${BASE_URL}/${id}/owner/${ownerId}`,
      forceRefresh
    ),
};
