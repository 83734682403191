import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserRole } from "../../../../data/dataAuth";
import { dataOutreach } from "../../../../data/dataOutreach";
import { UserId } from "../../../../data/models/CommonTypes";
import { CaseStatus } from "../../../../data/models/caseTypes";
import { Status } from "../../../../data/types";
import { caseStatusState } from "../../../../state/caseStatusState";
import { userState } from "../../../../state/userState";
import { getUserIsAllowedToEdit } from "../CasePage";
import { NotAllowed } from "./NotAllowed";
import { NotAllowedAdmin } from "./NotAllowedAdmin";

export function Claim() {
  const user = useRecoilValue(userState);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [caseStatus, setCaseStatus] = useRecoilState(caseStatusState);
  const [edit] = useState<boolean>(caseStatus.edit);
  const [allowedToEdit] = useState<boolean>(
    getUserIsAllowedToEdit(caseStatus, user?.userId)
  );
  // const [caseEditable, setCaseEditable] = useState<boolean>(
  //   getCaseIsEditable(caseStatus)
  // );

  // useEffect(() => {
  //   setCaseEditable(getCaseIsEditable(caseStatus));
  // }, [caseStatus]);

  const onClick = useCallback(() => {
    setStatus(Status.SUCCESS);
    dataOutreach
      .claim(caseStatus.id)
      .then(() => {
        setCaseStatus({
          ...caseStatus,
          currentUser: user
            ? {
                id: user.userId,
                name: user.name,
              }
            : {
                id: "" as UserId,
                name: "",
              },
          edit: true,
        });
      })
      .catch(() => {
        setStatus(Status.ERROR);
        setCaseStatus({
          ...caseStatus,
          edit: false,
        });

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [caseStatus, setCaseStatus, user]);

  useEffect(() => {
    if (caseStatus.edit) {
      return;
    }
  }, [caseStatus]);

  if (edit) {
    return null;
  }

  if (caseStatus.status === CaseStatus.CLOSED) {
    return null;
  }

  if (user?.role === UserRole.MANAGER) {
    return <NotAllowedAdmin />;
  }

  // if (!caseEditable) {
  //   return <NotEditable caseStatus={caseStatus} user={user} />;
  // }

  if (!allowedToEdit) {
    return (
      <NotAllowed onClick={onClick} status={status} caseStatus={caseStatus} />
    );
  }

  return null;
}
