import React, { useCallback, useState } from "react";
import { Trans } from "react-i18next";
import { generatePath } from "react-router";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../../components/boxes/SuccessBox";
import { Pending } from "../../../../components/icons/Pending";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Link } from "../../../../components/links/Link";
import { Name } from "../../../../components/name/Name";
import { dataReview } from "../../../../data/dataReview";
import { CaseId } from "../../../../data/models/CommonTypes";
import { BeneficialOwner, CaseStatus } from "../../../../data/models/caseTypes";
import { Status } from "../../../../data/types";
import { CASE_REVIEW_ROUTE } from "../../review/CaseReviewPage";
import { SetterOrUpdater } from "recoil";
import { CaseStatusState } from "../../../../state/caseStatusState";
import { ReviewRejectWrapper } from "./ReviewRejectWrapper";
import { T } from "../../../../components/translation/T";
import { AssociateIdSummary } from "./AssociateIdSummary";
import { TextInput } from "../../../../components/form/TextInput";
import { DateTime } from "luxon";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Form } from "../../../../components/form/Form";
import { MinDateValidator } from "../../../../components/form/validators/MinDateValidator";
import { t } from "i18next";
import { MaxDateValidator } from "../../../../components/form/validators/MaxDateValidator";

export interface Props {
  owner: BeneficialOwner;
  caseId: CaseId;
  setStatusData: SetterOrUpdater<CaseStatusState>;
}

export const AssociateIdDataInputView: React.FunctionComponent<Props> = (
  props
) => {
  const { caseId, owner } = props;
  const alreadySignedOff = !!owner.identitySignedOff;

  return (
    <div className="associate-review">
      <div className="associate-review-top">
        <h2>
          <Name associate={owner} />
        </h2>
      </div>
      <div className="review-box m-top-40">
        <AssociateIdSummary owner={owner}>
          {alreadySignedOff ? (
            <div>
              <SuccessBox relative>
                <Trans>This owner has already been confirmed</Trans>
              </SuccessBox>

              <div className="m-top-20 center">
                <Link link={generatePath(CASE_REVIEW_ROUTE, { id: caseId })}>
                  <T>Back to review page</T>
                </Link>
              </div>
            </div>
          ) : (
            <AssociateIdDataInputViewInner {...props} />
          )}
        </AssociateIdSummary>
      </div>
    </div>
  );
};

const now = DateTime.now();
// TODO: how old can the expiry date be?
const minExpiryDate = now.toJSDate();
const maxDate = now.plus({ years: 100 });
const maxDateIso = maxDate.toFormat("yyyy-MM-dd");
const minDateIso = now.toFormat("yyyy-MM-dd");

export const AssociateIdDataInputViewInner: React.FunctionComponent<Props> = ({
  caseId,
  owner,
  setStatusData,
}) => {
  const [saveStatus, setSaveStatus] = useState<Status>(Status.DEFAULT);
  const [rejectStatus, setRejectStatus] = useState<Status>(Status.DEFAULT);
  const [expiryDate, setExpiryDate] = useState<string>();

  const onReject = useCallback(() => {
    setRejectStatus(Status.PENDING);
    setTimeout(() => {
      dataReview
        .rejectOwner(owner)
        .then(() => setRejectStatus(Status.SUCCESS))
        .catch((err) => setRejectStatus(Status.ERROR));
    }, 1500);
  }, [owner]);

  const onSave = () => {
    if (!expiryDate) return;
    setSaveStatus(Status.PENDING);
    setTimeout(() => {
      dataReview
        // TODO: change confirmOwner to accept expiryDate
        .confirmOwner(owner, expiryDate)
        .then((data) => {
          if (data.signoffCompleted) {
            // So, for now, contract will still be reloaded so
            // this won't help.
            setStatusData((prev) => ({
              ...prev,
              status: CaseStatus.COMPLETED_VERIFICATION,
            }));
          }
          setSaveStatus(Status.SUCCESS);
        })
        .catch((err) => setSaveStatus(Status.ERROR));
    }, 500);
  };

  return (
    <ReviewRejectWrapper
      status={rejectStatus}
      onReject={onReject}
      caseId={caseId}
    >
      <AnimateHeight name={`${saveStatus}`}>
        <div>
          {saveStatus === Status.PENDING && (
            <div className="center">
              <Pending />
            </div>
          )}

          {saveStatus === Status.ERROR && (
            <div>
              <ErrorBox relative>
                <T>We couldn't save identity information. Try again?</T>
              </ErrorBox>
              <Button block onClick={onSave} className="m-top-20">
                <T>Save and sign off</T>
              </Button>
              <br />
              <Link link={generatePath(CASE_REVIEW_ROUTE, { id: caseId })}>
                <T>Back to review page</T>
              </Link>
            </div>
          )}

          {saveStatus === Status.SUCCESS && (
            <div>
              <SuccessBox relative>
                <Trans>
                  <b>Thanks!</b> We have confirmed this owner
                </Trans>
              </SuccessBox>
              <div className="m-top-20 center">
                <Link link={generatePath(CASE_REVIEW_ROUTE, { id: caseId })}>
                  <T>Back to review page</T>
                </Link>
              </div>
            </div>
          )}

          {saveStatus === Status.DEFAULT && (
            <Form
              onSubmit={(_, form) => {
                if (form.isInvalid) {
                  return;
                }
                onSave();
              }}
            >
              <TextInput
                value={expiryDate}
                onChange={(value) => {
                  setExpiryDate(value);
                }}
                type="date"
                autocomplete="off"
                label={<T>Expiry date of ID</T>}
                placeholder="yyyy-mm-dd"
                attributes={{
                  max: maxDateIso,
                  min: minDateIso,
                }}
                validators={[
                  new RequiredValidator("Date of issue is required"),
                  new MinDateValidator(
                    minExpiryDate,
                    "The document has expired"
                  ),
                  new MaxDateValidator(
                    now.plus({ years: 100 }).toJSDate(),
                    t(
                      "You sure your documents are valid for the next 100 years?"
                    )
                  ),
                ]}
              />
              <div className="tablet-columns">
                <div>
                  <Button
                    block
                    className="m-top-10 danger-button"
                    onClick={onReject}
                  >
                    <T>Reject</T>
                  </Button>
                </div>
                <div>
                  <Button
                    block
                    type="submit"
                    status={saveStatus}
                    className="m-top-10"
                  >
                    <T>Sign off</T>
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </div>
      </AnimateHeight>
    </ReviewRejectWrapper>
  );
};
