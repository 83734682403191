import React from "react";
import cx from "classnames";
import { MenuItem as MenuItemInterface } from "./Menu";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { Error } from "../icons/Error";

interface Props {
  menuItem: MenuItemInterface;
}

export const MenuItem: React.FunctionComponent<Props> = ({ menuItem }) => {
  const location = useLocation();
  let match;

  if (menuItem.active === undefined) {
    match = matchPath(
      {
        path: menuItem.link,
      },
      location.pathname
    );
  } else if (menuItem.active) {
    match = true;
  } else {
    match = false;
  }

  return (
    <li>
      <NavLink
        className={cx("menu-link", {
          "is-active": match,
          "is-disabled": menuItem.disabled,
          "has-error": menuItem.error,
        })}
        to={menuItem.link}
      >
        {menuItem.name}
        {menuItem.error && (
          <span className="nav-link-warning">
            <Error />
          </span>
        )}
      </NavLink>
    </li>
  );
};
