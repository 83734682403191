import React from "react";
import { HelpModal } from "../../../../../components/helpModal/HelpModal";
import { T } from "../../../../../components/translation/T";
import foilImage1 from "./security-marks-foil-1.png";
import foilImage2 from "./security-marks-foil-2.png";
import marksImage1 from "./security-marks-photo-1.png";
import marksImage2 from "./security-marks-photo-2.png";
import marksImage3 from "./security-marks-photo-3.png";
import "./SecurityMarkHelpModal.scss";

export const SecurityMarkHelpModal = () => {
  return (
    <HelpModal>
      <div className="security-mark-help-modal">
        <h2>
          <T>Security mark</T>
        </h2>
        <div className="image-container m-top-40">
          <div>
            <h4>
              <T>Security foil</T>
            </h4>
            <p>
              <T>
                An Identity card usally contains one or more security features
                that are called foil. They are usally some sort of shiny icon of
                a national symbol
              </T>
            </p>
            <div className="image-wrapper">
              <img src={foilImage1} alt="Security foil" />
              <img src={foilImage2} alt="Security foil" />
            </div>
          </div>
          <div className="m-top-30">
            <h4>
              <T>Holographic face photo</T>
            </h4>
            <p>
              <T>
                On the ID card there should be one or more copies of the primary
                picture. These smaller picutures are usally embeded in a
                transparent plastic part or can have some text being overlayed
              </T>
            </p>
            <div className="image-wrapper">
              <img src={marksImage1} alt="Security mark" />
              <img src={marksImage2} alt="Security mark" />
              <img src={marksImage3} alt="Security mark" />
            </div>
          </div>
        </div>
        <br />
      </div>
    </HelpModal>
  );
};
