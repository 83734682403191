import React from "react";
import cx from "classnames";
import { MISSING_DATE } from "../../../../../components/utils";
import { TransSwitch } from "../../../../../components/translation/TransSwitch";
import { TCondition, TDefault } from "../../../../../components/translation/T";

interface Props {
  sentDate: string;
}

export const EventDataVerification: React.FunctionComponent<Props> = ({
  sentDate,
}) => {
  const active = sentDate !== MISSING_DATE;

  return (
    <li
      className={cx({
        active: active,
      })}
    >
      <div className="event">
        <div className="timeline-date">{sentDate}</div>

        <b>
          <TransSwitch>
            <TCondition condition={active}>
              Data reviewed and signed off
            </TCondition>
            <TDefault>Waiting for data review</TDefault>
          </TransSwitch>
        </b>
      </div>
    </li>
  );
};
