import { useRecoilValue } from "recoil";
import { getIntlDate } from "../../../../components/utils";
import { Created } from "./Created";
import { EventComplete } from "./TimelineEvents/EventComplete";
import { EventCreated } from "./TimelineEvents/EventCreated";
import { EventDataVerification } from "./TimelineEvents/EventDataVerification";
import { EventSentToOwners } from "./TimelineEvents/EventSentToOwners";
import { ToVerification } from "./ToVerification";
import { ToVerificationComplete } from "./ToVerificationComplete";
import { caseStatusState } from "../../../../state/caseStatusState";
import { caseBeneficialOwnerState } from "../../../../state/caseBeneficialOwnerState";
import { Complete } from "./Complete";
import "./Timeline.scss";
import { useTranslation } from "react-i18next";
import { Language } from "../../../../i18n";

// const MISSING_DATE = " - ";
export const ITEM_OFFSET = 20;
export const ITEM_HEIGHT = 85;
export const ITEM_HEIGHT_EVENT = 105;
export const ITEM_HEIGHT_OWNER = 180;

export function Timeline() {
  const caseStatus = useRecoilValue(caseStatusState);
  const owners = useRecoilValue(caseBeneficialOwnerState);
  const { i18n } = useTranslation();

  const createdDate = getIntlDate(
    caseStatus.timeline.created,
    i18n.language as Language
  );
  const sentToOwners = getIntlDate(
    caseStatus.timeline.sentToOwners,
    i18n.language as Language
  );
  const reviewData = getIntlDate(
    caseStatus.timeline.reviewedCompleted,
    i18n.language as Language
  );
  const completedDate = getIntlDate(
    caseStatus.timeline.closed,
    i18n.language as Language
  );

  return (
    <div className="timeline">
      <ul className="timeline-tags">
        <EventCreated createdDate={createdDate} />

        <EventSentToOwners sentDate={sentToOwners} />

        <EventDataVerification sentDate={reviewData} />

        {/* {owners.map((owner) => (
          <OwnerListItem
            caseId={caseData.id}
            key={owner.id}
            owner={owner}
            enableConfirmDetails={enableConfirmDetails}
          />
        ))} */}

        <EventComplete date={completedDate} status={caseStatus.status} />
      </ul>
      <svg className="timeline-sketch">
        <Created />
        <ToVerification />
        <ToVerificationComplete />
        {/* <ToOwners owners={owners} active={sentToOwners !== MISSING_DATE} /> */}
        <Complete status={caseStatus.status} owners={owners} />
      </svg>
    </div>
  );
}
