import React from "react";
import { Page } from "./Page";

export interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string;
}

export const PageStripped: React.FunctionComponent<Props> = (props) => (
  <Page {...props} stripped />
);
