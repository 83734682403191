import { RiFileExcel2Line } from "react-icons/ri";
import { T } from "../../../components/translation/T";
import styles from "./DownloadExcel.module.scss";
import { Link } from "../../../components/links/Link";
import {
  ExcelQueryParams,
  dataExcelDownloadUrl,
} from "../../../data/dataDocument";
import { FC } from "react";

interface Props extends ExcelQueryParams {}

export const DownloadExcel: FC<Props> = ({ userId, status, countries }) => {
  // perhaps add the query params to the filename?
  const filename = "outreach_cases.xlsx";

  return (
    <div className={styles.root}>
      <Link
        link={dataExcelDownloadUrl({ userId, status, countries })}
        className="as-button m-top-10"
        external
        hideExternal={true}
        download={filename}
      >
        <div className={styles.content}>
          <T>Download Excel</T>
          <RiFileExcel2Line />
        </div>
      </Link>
    </div>
  );
};
