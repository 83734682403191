import { generatePath } from "react-router";
import { CompanyRegistrationId } from "../data/models/CommonTypes";
import { Country } from "../i18n";
import { COMPANY_PAGE_URL } from "../pages/company/CompanyPage";

export const companyLink = (
  country: Country,
  companyRegistrationId: CompanyRegistrationId
) => {
  return generatePath(COMPANY_PAGE_URL, {
    country,
    companyRegistrationId,
  });
};
