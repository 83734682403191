import { BusinessModel } from "../../data/models/caseTypes";
import { Alternative } from "../interactions/InputTypes";
import { T } from "../translation/T";

const businessModelTextMap: Record<BusinessModel, string> = {
  [BusinessModel.B2B]: "Business to Business",
  [BusinessModel.B2C]: "Business to Consumer",
  [BusinessModel.B2B2C]: "Business to Business to Consumer",
  [BusinessModel.MARKETPLACE]: "Marketplace",
  [BusinessModel.WHOLESALE]: "Wholesale",
};

export const businessModelAlternatives: Alternative<BusinessModel>[] =
  Object.keys(BusinessModel).map((key) => ({
    value: BusinessModel[key as keyof typeof BusinessModel],
    text: (
      <T>
        {businessModelTextMap[BusinessModel[key as keyof typeof BusinessModel]]}
      </T>
    ),
  }));

export const BusinessModelDisplay = ({
  businessModel,
}: {
  businessModel?: BusinessModel;
}) => {
  if (!businessModel) {
    return <>-</>;
  }

  return (
    <span>
      {businessModelTextMap[businessModel] ? (
        <T>{businessModelTextMap[businessModel]}</T>
      ) : (
        "-"
      )}
    </span>
  );
};

export const businessModelString = (businessModel: BusinessModel) => {
  return businessModelTextMap[businessModel];
};
