import { Trans } from "react-i18next";
import { T } from "../../../../components/translation/T";

export const NotAllowedAdmin = () => {
  return (
    <div className="case-claim tablet-wrapper">
      <b className="text-regular-plus">
        <T>Heads up</T> ✋
      </b>
      <p>
        <Trans>
          You are viewing this case as a <b>manager</b> and are therefore unable
          to make changes to it.
        </Trans>
      </p>
    </div>
  );
};
