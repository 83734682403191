import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataList } from "../../../../components/datalist/DataList";
import { DataListItem } from "../../../../components/datalist/DataListItem";
import { FlagWithCountry } from "../../../../components/flags/FlagWithCountry";
import { TextInput } from "../../../../components/form/TextInput";
import { ZoomableImage } from "../../../../components/images/ZoomableImage";
import { Name } from "../../../../components/name/Name";
import { OwnerShare } from "../../../../components/ownerShare/OwnerShare";
import { T } from "../../../../components/translation/T";
import { getIntlDate } from "../../../../components/utils";
import { BeneficialOwner, IdScan } from "../../../../data/models/caseTypes";
import {
  Country,
  getDefaultCountryFromLanguage,
  Language,
} from "../../../../i18n";
import { ExtractedIdText } from "./components/ExtractedIdText";
import { ImageToggler } from "./components/ImageToggler";
import { DateTime } from "luxon";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";

interface Props extends React.PropsWithChildren {
  owner: BeneficialOwner;
}

export const AddressItem: React.FunctionComponent<Props> = ({ owner }) => {
  const { address } = owner;
  return (
    <DataListItem
      text="Address"
      value={
        <>
          <span>{address.street}</span>
          <br />
          <span>
            {address.postalCode}, {address.city}
            <br />
            <FlagWithCountry
              className="on-end"
              country={address.country as Country}
              height={18}
              rounded
            />
          </span>
        </>
      }
    />
  );
};

// function getBrowser() {
//   let userAgent = navigator.userAgent;

//   if (userAgent.match(/chrome|chromium|crios/i)) {
//     return (
//       <>
//         Chrome <Image alt="Chrome browser" src="/images/chrome.svg" ratio={1} />
//       </>
//     );
//   } else if (userAgent.match(/firefox|fxios/i)) {
//     return (
//       <>
//         Firefox{" "}
//         <Image alt="Firefox browser" src="/images/firefox.svg" ratio={1} />
//       </>
//     );
//   } else if (userAgent.match(/safari/i)) {
//     return (
//       <>
//         Safari <Image alt="Safari browser" src="/images/safari.svg" ratio={1} />
//       </>
//     );
//   } else if (userAgent.match(/opr\//i)) {
//     return (
//       <>
//         Opera <Image alt="Opera browser" src="/images/opera.svg" ratio={1} />
//       </>
//     );
//   } else if (userAgent.match(/edg/i)) {
//     return (
//       <>
//         Microsoft Edge{" "}
//         <Image alt="Microsoft Edge browser" src="/images/edge.svg" ratio={1} />
//       </>
//     );
//   } else {
//     return userAgent;
//   }
// }

export const AssociateIdSummary: React.FunctionComponent<Props> = ({
  owner,
  children,
}) => {
  const [idScan] = useState<IdScan>();
  const { i18n } = useTranslation();

  return (
    <div className="tablet-columns m-bottom-20">
      <div className="associate-review-passport m-bottom-10">
        <IdImages owner={owner} idScan={idScan} showIdText={false} />
      </div>

      <div>
        <h3>
          <T>Identity information</T>
        </h3>

        <p>
          <T>The following information was supplied by</T>{" "}
          <Name associate={owner} />.
        </p>
        <DataList>
          <DataListItem text="Name" value={<Name associate={owner} />} />

          <DataListItem
            text="Nationality"
            value={
              <FlagWithCountry
                country={owner.nationality as Country}
                height={18}
                rounded
              />
            }
          />

          <AddressItem owner={owner} />

          <DataListItem
            text="Date of birth"
            value={
              owner.dateOfBirth
                ? getIntlDate(owner.dateOfBirth, i18n.language as Language)
                : "-"
            }
          />
          <DataListItem
            text="Ownership"
            value={
              owner.ownership ? (
                <>
                  {owner.ownership}% &nbsp;
                  <OwnerShare share={owner.ownership} />
                </>
              ) : (
                "-"
              )
            }
          />
          <DataListItem
            text="By indirect ownership"
            value={owner.indirectOwnership.length ? "Yes" : "No"}
          />
          <DataListItem
            text="Preferred language"
            value={
              owner.communication.language ? (
                <FlagWithCountry
                  country={getDefaultCountryFromLanguage(
                    owner.communication.language as Language
                  )}
                  height={18}
                  rounded
                />
              ) : (
                owner.communication.language
              )
            }
          />

          <DataListItem
            text="Signed off"
            value={
              owner.identitySignedOff
                ? getIntlDate(
                    owner.identitySignedOff,
                    i18n.language as Language
                  )
                : "-"
            }
          />

          <DataListItem
            text="Signed off by"
            value={owner.identitySignedOffBy || "-"}
          />
        </DataList>
        {children}
      </div>
    </div>
  );
};

interface IdImagesProps {
  owner: BeneficialOwner;
  showIdText: boolean;
  idScan?: IdScan;
}

const IdImages: FunctionComponent<IdImagesProps> = ({
  owner,
  showIdText,
  idScan,
}) => {
  if (showIdText) {
    return <ExtractedIdText blocks={idScan?.idImage} />;
  }

  if (owner.identity?.passport && owner.identity?.selfie) {
    return (
      <ImageToggler
        images={[owner.identity.passport, owner.identity.selfie]}
        idScan={idScan}
        showIdText={showIdText}
        showTextBlocks={false}
      />
    );
  }

  if (
    owner.identity?.idFront &&
    owner.identity?.idBack &&
    owner.identity?.selfie
  ) {
    return (
      <ImageToggler
        images={[
          owner.identity.idFront,
          owner.identity.idBack,
          owner.identity.selfie,
        ]}
        idScan={idScan}
        showIdText={showIdText}
        showTextBlocks={false}
      />
    );
  }

  if (owner.identity?.passport) {
    if (showIdText) {
      return <ExtractedIdText blocks={idScan?.passport} />;
    }

    return (
      <div className="associate-review-passport">
        <ZoomableImage
          image={owner.identity.passport}
          textBlocks={idScan?.passport}
          alt="Passport"
          showTextBlocks={false}
        />
      </div>
    );
  }

  if (owner.identity?.idFront && owner.identity?.idBack) {
    return (
      <ImageToggler
        images={[owner.identity.idFront, owner.identity.idBack]}
        idScan={idScan}
        showIdText={showIdText}
        showTextBlocks={false}
      />
    );
  }

  return null;
};
