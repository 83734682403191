import { useCallback, useState } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useMultiForm } from "../../../components/form/MultiFormContext";
import { Button } from "../../../components/interactions/Buttons/Button";
import { T } from "../../../components/translation/T";
import { TransSwitch } from "../../../components/translation/TransSwitch";
import { dataOutreach } from "../../../data/dataOutreach";
import { CaseStatus } from "../../../data/models/caseTypes";
import { Status } from "../../../data/types";
import { useCaseEditStatus } from "../../../hooks/useCaseEditStatus";
import { caseStatusState } from "../../../state/caseStatusState";
import { CASE_COMPLETE_PAGE } from "../complete/CaseCompletePage";
import { CASE_REVIEW_ROUTE } from "../review/CaseReviewPage";

export function SendForConfirmation() {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const caseStatus = useRecoilValue(caseStatusState);
  const multiForm = useMultiForm();
  const [inputStatus] = useCaseEditStatus(status);

  const navigate = useNavigate();

  const send = useCallback(() => {
    if (!multiForm || multiForm.isInvalid) {
      multiForm?.forceValidation();
      return;
    }

    dataOutreach
      .send(caseStatus.id)
      .then(() => {
        setStatus(Status.SUCCESS);
        navigate(generatePath(CASE_REVIEW_ROUTE, { id: caseStatus.id }));
      })
      .catch(() => {
        setStatus(Status.ERROR);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [caseStatus.id, navigate, multiForm]);

  const renderSecondaryButton = () => {
    if (
      caseStatus.status === CaseStatus.OUTREACH ||
      caseStatus.status === CaseStatus.VERIFICATION
    ) {
      return (
        <Link
          className="as-button block ghost"
          to={generatePath(CASE_REVIEW_ROUTE, { id: caseStatus.id })}
        >
          To review page
        </Link>
      );
    }

    if (caseStatus.status === CaseStatus.COMPLETED_VERIFICATION) {
      return (
        <Link
          className="as-button block ghost"
          to={generatePath(CASE_COMPLETE_PAGE, {
            id: caseStatus.id,
          })}
        >
          <T>Go to summary</T>
        </Link>
      );
    }
  };

  return (
    <div className="send-for-confirmation">
      {renderSecondaryButton()}
      {caseStatus.status === CaseStatus.DATA_COLLECTION && (
        <Button type="button" block status={inputStatus} onClick={send}>
          <T>Send outreach</T>
        </Button>
      )}
    </div>
  );
}
