import { ChangeEvent, RefObject, useRef } from "react";
import { Props as InputProps } from "../interactions/Number/Number";
import cx from "classnames";
import { NumberInput } from "./NumberInput";
import { ValidationProps } from "./hooks/useValidation";
import { BaseValidator } from "./validators/BaseValidator";
import { RequiredValidatorName } from "./validators/RequiredValidator";
import "./RangeInput.scss";

interface Props
  extends Omit<InputProps, "status" | "required" | "value" | "onChange">,
    Omit<ValidationProps, "validators"> {
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
  onChange: (
    minValue: number | undefined,
    maxValue: number | undefined,
    name: string,
    event?: ChangeEvent<HTMLInputElement>
  ) => void;
  values: [number?, number?];
  min?: number;
  max?: number;
  suffix?: string;
  // validation
  minValidators?: BaseValidator[];
  maxValidators?: BaseValidator[];
  sharedValidators?: BaseValidator[];
}

export const RangeInput = ({
  className,
  onChange,
  minValidators = [],
  maxValidators = [],
  sharedValidators = [],
  scrollToRef,
  forceValidation = false,
  disabled,
  label,
  values,
  ...props
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [minValue, maxValue] = values;

  const innerMinValidators = disabled
    ? []
    : [...minValidators, ...sharedValidators];

  const innerMaxValidators = disabled
    ? []
    : [...maxValidators, ...sharedValidators];

  const isRequired =
    (innerMinValidators.some(
      (validator) => validator.name === RequiredValidatorName
    ) &&
      typeof minValue === "undefined") ||
    (innerMaxValidators.some(
      (validator) => validator.name === RequiredValidatorName
    ) &&
      typeof maxValue === "undefined");

  return (
    <div className={cx("input", "range-input", className)} ref={ref}>
      <div className="input-label-wrapper">
        <div className="input-label">
          <div className="input-label-tag">
            {label || <div className="invisible">empty</div>}
          </div>
        </div>
        {isRequired && (
          <div className="required-marker text-small">required</div>
        )}
      </div>

      <div className="input-wrapper">
        <NumberInput
          {...props}
          name="min"
          value={minValue}
          onChange={(newValue, name) => onChange(newValue, maxValue, name)}
          validators={[...minValidators, ...sharedValidators]}
          disabled={disabled}
          message="Min"
          hint="Min"
          scrollToRef={ref}
        />
        <div className="separator" />
        <NumberInput
          {...props}
          name="max"
          value={maxValue}
          onChange={(newValue, name) => onChange(minValue, newValue, name)}
          validators={[...maxValidators, ...sharedValidators]}
          disabled={disabled}
          message="Max"
          hint="Max"
          scrollToRef={ref}
        />
      </div>
    </div>
  );
};
