import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { UserRole } from "../../data/dataAuth";
import { LOGOUT_ROUTE } from "../../pages/login/Logout";
import { DASHBOARD_ROUTE } from "../../pages/sales/Dashboard/DashboardPage";
import { userState } from "../../state/userState";
import { Button } from "../interactions/Buttons/Button";
import { NewLanguageSelect } from "../languageSelect/NewLanguageSelect";
import { Link } from "../links/Link";
import { Menu } from "../menu/Menu";
import { T } from "../translation/T";
import { VerticalMenu } from "../verticalMenu/VerticalMenu";
import { CompanySearchOverlay } from "./CompanySearchOverlay";
import { menuItems } from "./menuItems";
import { NewCaseOverlay } from "./NewCaseOverlay";
import { SupportBox } from "./SupportBox";
import { TranslationNav } from "./TranslationNav";
import "./VerticalNav.scss";

export const VerticalNav: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const location = useLocation();

  const currentRoute = useMemo(
    () => `${location.pathname}${location.search}`,
    [location]
  );

  const items = useMemo(
    () => menuItems(currentRoute, user!)[user?.role ?? UserRole.SALES],
    [user, currentRoute]
  );

  const isDashboard = useMemo(
    () => currentRoute.includes(DASHBOARD_ROUTE),
    [currentRoute]
  );

  // user should always be set here
  if (!user) {
    return null;
  }

  const isManager = user.role === UserRole.MANAGER;

  return (
    <VerticalMenu logo={true}>
      <CompanySearchOverlay />
      {!isManager && (
        <div className="m-top-20">
          <NewCaseOverlay />
        </div>
      )}

      {!isDashboard && (
        <ul>
          <li className="m-top-20">
            <Link className="as-button ghost block" link={DASHBOARD_ROUTE}>
              <T>Dashboard</T>
            </Link>
          </li>
        </ul>
      )}

      <nav>
        <Menu menuItems={items} />
        <ul>
          <TranslationNav />
          <li className="logout-list-item">
            <Button
              block
              ghost
              onClick={() => {
                navigate(LOGOUT_ROUTE);
              }}
            >
              <span>
                <T id="Logout {{name}}" options={{ name: user.name }} />
              </span>
            </Button>
          </li>
        </ul>
      </nav>
      <div className="bottom-content">
        <div className="language-select">
          <span className="text-passive text-small">
            <T>Switch language</T>
          </span>
          <NewLanguageSelect />
        </div>
        <SupportBox />
      </div>
    </VerticalMenu>
  );
};
