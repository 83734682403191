import React from "react";
import cx from "classnames";
import { Checkmark } from "../icons/Checkmark";
import { ErrorOutline } from "../icons/ErrorOutline";
import { Profile } from "../icons/Profile";
import { WarningOutline } from "../icons/WarningOutline";
import "./Beacon.scss";
import { Validity } from "../../data/models/caseTypes";

interface Props {
  validity?: Validity;
  mini?: boolean;
  className?: string;
  icon?: React.ReactNode;
}

export const Beacon: React.FunctionComponent<Props> = ({
  validity,
  mini = false,
  className,
  icon = <Profile />,
}) => {
  if (validity === Validity.MISSING) {
    return (
      <div
        className={cx("beacon", className, validity, {
          mini,
        })}
      >
        <ErrorOutline />
      </div>
    );
  }

  if (validity === Validity.PARTIAL) {
    return (
      <div className={cx("beacon", className, validity, { mini })}>
        <WarningOutline />
      </div>
    );
  }

  if (validity === Validity.VALID) {
    return (
      <div className={cx("beacon", className, validity, { mini })}>
        <Checkmark />
      </div>
    );
  }

  return (
    <div className={cx("beacon", className, validity, { mini })}>{icon}</div>
  );
};
