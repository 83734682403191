import { FC, useState } from "react";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import { Input } from "../../../components/interactions/Input/Input";
import {
  dataMccAdministration,
  NewMccRequest,
  RISK_LEVEL_OPTIONS,
} from "../../../data/dataMccAdministration";
import { MccRiskLevel } from "../../../data/models/caseTypes";
import { Status } from "../../../data/types";

import "./mcc.scss";
export interface ModalProps {
  onSave: () => void;
  onClose: () => void;
}

const DEFAULT_REQUEST: NewMccRequest = {
  code: "",
  name: "",
  note: "",
  category: "",
  faceToFace: MccRiskLevel.NONE,
  cardNotPresent: MccRiskLevel.NONE,
};

export const AddMccModal: FC<ModalProps> = ({ onSave, onClose }) => {
  const [newMcc, setNewMcc] = useState<NewMccRequest>(DEFAULT_REQUEST);

  const isFormValid = () => {
    return (
      newMcc.code.length === 4 &&
      !isNaN(Number(newMcc.code)) &&
      newMcc.name.length > 0 &&
      newMcc.category.length > 0
    );
  };

  const [codeStatus, setCodeStatus] = useState(Status.DEFAULT);
  const [industryStatus, setIndustryStatus] = useState(Status.DEFAULT);
  const [categoryStatus, setCategoryStatus] = useState(Status.DEFAULT);

  return (
    <div className="add-mcc-modal">
      <FieldSet header="Add new MCC" className="m-bottom-20">
        <div className="tablet-columns">
          <div>
            <Input
              onChange={(value) => {
                setNewMcc({ ...newMcc, code: value });
              }}
              value={newMcc.code}
              name={"code"}
              label="Code"
              status={codeStatus}
              message={
                codeStatus === Status.ERROR
                  ? "Code is required and is 4 digits long"
                  : null
              }
              placeholder="MCC, 4 digits"
              onBlur={() => {
                if (newMcc.code.length === 0) {
                  setCodeStatus(Status.DEFAULT);
                  return;
                }
                if (newMcc.code.length !== 4) {
                  setCodeStatus(Status.ERROR);
                  return;
                }
                if (isNaN(Number(newMcc.code))) {
                  setCodeStatus(Status.ERROR);
                  return;
                }
                setCodeStatus(Status.SUCCESS);
              }}
            />
          </div>
          <div>
            <Input
              onChange={(value) => {
                setNewMcc({ ...newMcc, name: value });
              }}
              value={newMcc.name}
              name={"name"}
              label="Industry"
              status={industryStatus}
              message={
                industryStatus === Status.ERROR ? "Industry is required" : null
              }
              placeholder="MCC Industry"
              onBlur={() => {
                if (newMcc.name.length === 0) {
                  setIndustryStatus(Status.ERROR);
                  return;
                }
                if (newMcc.name.length > 5) {
                  setIndustryStatus(Status.SUCCESS);
                  return;
                }
                setIndustryStatus(Status.DEFAULT);
              }}
            />
          </div>
        </div>

        <label htmlFor="note" className="input input-text">
          <div className="input-label">Note</div>
          <div className="input-frame">
            <textarea
              id="note"
              rows={3}
              value={newMcc.note}
              onChange={(e) => {
                const value = !e.target.value ? "" : "" + e.target.value;
                setNewMcc({ ...newMcc, note: value });
              }}
            ></textarea>
          </div>
        </label>

        <div className="m-top-20">
          <Input
            onChange={(value) => {
              setNewMcc({ ...newMcc, category: value });
            }}
            value={newMcc.category}
            name="category"
            label="Category"
            status={categoryStatus}
            message={
              categoryStatus === Status.ERROR ? "Category is required" : null
            }
            placeholder="MCC Category"
            onBlur={() => {
              if (newMcc.category.length === 0) {
                setCategoryStatus(Status.ERROR);
                return;
              }
              if (newMcc.category.length > 5) {
                setCategoryStatus(Status.SUCCESS);
                return;
              }
              setCategoryStatus(Status.DEFAULT);
            }}
          />
        </div>

        <div className="tablet-columns">
          <div>
            <Dropdown
              label="Face to face"
              alternatives={RISK_LEVEL_OPTIONS}
              value={newMcc.faceToFace}
              onChange={(value) => {
                setNewMcc({ ...newMcc, faceToFace: value as MccRiskLevel });
              }}
            />
          </div>
          <div>
            <Dropdown
              label="Card not present"
              alternatives={RISK_LEVEL_OPTIONS}
              value={newMcc.cardNotPresent}
              onChange={(value) => {
                setNewMcc({
                  ...newMcc,
                  cardNotPresent: value as MccRiskLevel,
                });
              }}
            />
          </div>
        </div>
      </FieldSet>
      <div className="tablet-columns">
        <div className="m-top-10">
          <Button
            onClick={() => {
              dataMccAdministration.addNewMcc(newMcc).then(() => {
                onSave();
                onClose();
              });
            }}
            status={isFormValid() ? Status.DEFAULT : Status.DISABLED}
            block
          >
            Save
          </Button>
        </div>

        <div className="m-top-10">
          <Button ghost block onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};
