import { Transition, Variants } from "framer-motion";
import { MotionCircles } from "../../components/graphics/MotionCircles";
import "./LoginBackground.scss";

const DURATION = 0.15;
const STROKE_WIDTH = 10;

const transition: Transition = {
  staggerChildren: DURATION * 1.5,
  staggerDirection: 0,
  duration: 1,
};

const variants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: transition,
  },
};

const variants2: Variants = {
  ...variants,
  animate: {
    ...variants.animate,
    transition: {
      ...transition,
      delay: DURATION * 2.5,
      delayChildren: DURATION * 5,
    },
  },
};

const childVariants: Variants = {
  initial: {
    opacity: 1,
    scale: 0,
    strokeWidth: STROKE_WIDTH,
  },
  animate: (custom: number) => ({
    scale: [0.9, 1, 1, 0.9],
    strokeWidth: [
      STROKE_WIDTH,
      STROKE_WIDTH * 1.25 + custom,
      STROKE_WIDTH * 1.25 + custom,
      STROKE_WIDTH,
    ],
    transition: {
      duration: DURATION * 30,
      repeat: Infinity,
      repeatDelay: DURATION,
      times: [0, 0.25, 0.75, 1],
    },
  }),
};

const childVariants2: Variants = {
  initial: {
    opacity: 1,
    scale: 0,
    strokeWidth: STROKE_WIDTH * 1.25,
  },
  animate: (custom: number) => ({
    scale: [0.9, 1, 1, 0.9],
    strokeWidth: [
      STROKE_WIDTH * 1.25,
      STROKE_WIDTH * 1.25 * 1.25 + custom,
      STROKE_WIDTH * 1.25 * 1.25 + custom,
      STROKE_WIDTH * 1.25,
    ],
    transition: {
      duration: DURATION * 30,
      repeat: Infinity,
      repeatDelay: DURATION,
      times: [0, 0.25, 0.75, 1],
    },
  }),
};

export const LoginBackground = () => {
  return (
    <div className="login-background">
      <MotionCircles variants={variants2} childVariants={childVariants2} />
      <MotionCircles variants={variants} childVariants={childVariants} />
    </div>
  );
};
