import { ITEM_HEIGHT, ITEM_OFFSET } from "./Timeline";

export function ToVerificationComplete() {
  const start = ITEM_OFFSET + ITEM_HEIGHT;
  const end = start + ITEM_HEIGHT;

  return (
    <>
      <line x1="10" x2="10" y1={start} y2={end} className="active" />
      <circle r="4" cx="10" cy={end} className="active" />
    </>
  );
}
