import { FC } from "react";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import styles from "./EditCompanyData.module.scss";
import { MdOutlineClose as CloseIcon } from "react-icons/md";
import { EditCompanyData } from "./EditCompanyData";
import { NewCompany } from "../CompanyPage";

const motionProps: MotionProps = {
  initial: {
    opacity: 0,
  },
  exit: { opacity: 0 },
  animate: {
    opacity: 1,
  },
  transition: { duration: 0.1 },
};

interface Props {
  open: boolean;
  onClose: () => void;
  company: NewCompany;
  reloadOutreachCases: () => void;
}

export const EditCompanyDataModal: FC<Props> = ({
  open,
  onClose,
  company,
  reloadOutreachCases,
}) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div className={styles.modal} {...motionProps}>
          <div className={styles.modalContent}>
            <button onClick={onClose} className={styles.closeButton}>
              <CloseIcon />
            </button>
            <EditCompanyData
              handleCloseButton={onClose}
              company={company}
              reloadOutreachCases={reloadOutreachCases}
            />
          </div>
        </motion.div>
      )}
      ;
    </AnimatePresence>
  );
};
