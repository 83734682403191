import cx from "classnames";
import { useEffect, useState, VoidFunctionComponent } from "react";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Overlay } from "../../../components/overlay/Overlay";
import { CaseId, UserId } from "../../../data/models/CommonTypes";
import { Status } from "../../../data/types";
import { User, userAdministration } from "../../../data/dataManager";
import "./DelegateCaseOverlay.scss";
import { UserRole } from "../../../data/dataAuth";
import { CaseType } from "../../../data/models/caseTypes";

interface Props {
  outreachCase?: { caseId: CaseId; type: CaseType };
  currentUser: UserId;
  onDelegate: () => void;
  onClose: () => void;
}

export const DelegateCaseOverlay: VoidFunctionComponent<Props> = ({
  currentUser,
  outreachCase,
  onDelegate,
  onClose,
}) => {
  const [targetUserId, setTargetUserId] = useState<UserId>();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!outreachCase) {
      return;
    }

    const { type } = outreachCase;

    const role =
      type === CaseType.NEW_CUSTOMER ? UserRole.SALES : UserRole.RISK;
    setLoading(true);
    setTargetUserId(undefined);
    userAdministration
      // TODO: load all users non-paged here instead, that's what we did in the other projects
      .getUsers(0, 999, role)
      .then((users) => {
        const sortedUsers = users.content
          .filter(({ id }) => id !== currentUser)
          .sort((a, b) => a.name.localeCompare(b.name));
        setUsers(sortedUsers);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentUser, outreachCase, setUsers]);

  return (
    <div className="delegate-case-overlay light-theme">
      <Overlay open={typeof outreachCase !== "undefined"} onClose={onClose}>
        <div className="sales-admin-list">
          {users.map((user) => {
            return (
              <div
                key={user.id}
                className={cx("sales-admin-row m-top-10", {
                  active: user.id === targetUserId,
                })}
              >
                <div
                  className="sales-name"
                  onClick={() => {
                    setTargetUserId(user.id);
                  }}
                >
                  <span>
                    <b>{user.name}</b> - {user.email}
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        <Button
          className="m-top-20 delegate-confirm"
          onClick={() => {
            if (!targetUserId || !outreachCase?.caseId) {
              return;
            }
            userAdministration
              .delegate(outreachCase?.caseId, targetUserId)
              .then(() => {
                onDelegate();
              });
          }}
          status={
            loading
              ? Status.DISABLED
              : targetUserId
              ? Status.DEFAULT
              : Status.DISABLED
          }
        >
          Delegate
        </Button>
      </Overlay>
    </div>
  );
};
