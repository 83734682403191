import { useRecoilValue } from "recoil";
import { Card } from "../../../components/card/Card";
import { CompanyRegistrationIdDisplay } from "../../../components/companyRegistrationId/CompanyRegistrationIdDisplay";
import { DataList } from "../../../components/datalist/DataList";
import { DataListItem } from "../../../components/datalist/DataListItem";
import { FlagWithCountry } from "../../../components/flags/FlagWithCountry";
import { External } from "../../../components/icons/External";
import { LegalEntityTypeDisplay } from "../../../components/legalEntityType/LegalEntityTypeDisplay";
import { Link } from "../../../components/links/Link";
import { T } from "../../../components/translation/T";
import { caseCompanyState } from "../../../state/caseCompanyState";
import { companyLink } from "../../../util/CompanyLink";

export const CompanyInformation = () => {
  const company = useRecoilValue(caseCompanyState);

  const { legalName, companyRegistrationId, country, legalEntityType } =
    company;

  const companyName = legalName ? legalName : companyRegistrationId;

  return (
    <div className="company-information">
      <Card border>
        <Card.Header>
          <h5>
            <T>Company information</T>
          </h5>
        </Card.Header>
        <div className="card-body">
          <DataList align="left">
            <DataListItem
              text="Company name"
              value={
                <Link
                  link={companyLink(country, companyRegistrationId)}
                  className="postfix"
                  blank
                >
                  {companyName}
                  <External />
                </Link>
              }
            />
            <DataListItem
              text="Company registration number"
              value={
                <CompanyRegistrationIdDisplay
                  country={country}
                  id={companyRegistrationId}
                />
              }
            />
            <DataListItem
              text="Legal entity type"
              value={
                <LegalEntityTypeDisplay legalEntityType={legalEntityType} />
              }
            />

            <DataListItem
              text="Country"
              value={<FlagWithCountry country={country} height={20} rounded />}
            />
          </DataList>
        </div>
      </Card>
    </div>
  );
};
