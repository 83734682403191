import { motion } from "framer-motion";
import { useState } from "react";
import { Address } from "../../data/models/caseTypes";
import { Button } from "../interactions/Buttons/Button";
import cx from "classnames";
import { AddressForm } from "./AddressForm";
import { Form } from "../form/Form";
import { T } from "../translation/T";
import "./AddressPopover.scss";

interface Props {
  address: Address;
  onChange: (address: Address) => void;
  disabled?: boolean;
  addressRequiredFields:
    | Record<keyof Address, boolean>
    | Record<keyof Omit<Address, "country">, boolean>;
  showCountryCode?: boolean;
}

export const AddressPopover = ({ onChange, ...props }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="address-popover">
      <Button ghost block onClick={() => setIsOpen(true)}>
        <T>Enter manually</T>
      </Button>
      {isOpen && (
        <AddressPopoverInner
          onClose={() => setIsOpen(false)}
          {...{ onChange, ...props }}
        />
      )}
    </div>
  );
};

type InnerProps = {
  onClose: () => void;
} & Props;

const AddressPopoverInner = ({
  onChange,
  onClose,
  address,
  ...props
}: InnerProps) => {
  const [innerAddress, setInnerAddress] = useState<Address>(() => address);

  return (
    <>
      <motion.div
        className="address-popover__backdrop"
        onClick={onClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
      />
      <motion.div
        className={cx("address-popover__container", {
          "address-popover--upwards": false,
        })}
        initial={{ borderRadius: 0 }}
        animate={{ borderRadius: 30 }}
        transition={{ duration: 0.25 }}
      >
        <div className="address-popover__content">
          <div className="address-popover__header">
            <h5>Company address</h5>
            <Button
              className="address-popover__button m-bottom-10"
              ghost
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </div>
          <Form>
            <div className="tablet-columns address-form">
              <AddressForm
                onChange={setInnerAddress}
                address={innerAddress}
                {...{
                  ...props,
                }}
              />
            </div>
          </Form>
          <Button
            block
            onClick={() => {
              onChange(innerAddress);
              onClose();
            }}
          >
            Save
          </Button>
        </div>
      </motion.div>
    </>
  );
};
