import { UserSession } from "./data/dataAuth";
import { DashboardCase } from "./data/dataDashboard";
import { UserId } from "./data/models/CommonTypes";
import { CaseStatus } from "./data/models/caseTypes";
import { SortDirection } from "./data/models/Page";
import { Country } from "./i18n";

export interface Filter<T extends {}> {
  page: number;
  size?: number;
  sortField?: keyof T;
  sortDirection?: SortDirection;
  status?: CaseStatus[];
  countries?: string[];
  userId?: UserId;
}

export const defaultFilter = <T extends {}>(): Filter<T> => ({
  page: 0,
  size: 20,
});

export enum Theme {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum STORE_KEY {
  STORE_THEME = "store.ui.theme",
  STORE_LOGIN_EMAIL = "store.auth.login.email",
  STORE_USER = "store.auth.user",
  STORE_DASHBOARD_FILTER = "store.dashboard.filter",
  STORE_FEATURES = "store.features",
  STORE_COUNTRY = "store.country",
  STORE_ONBOARDING_NAME = "store.onboarding.name",
}

interface StoredData {
  [STORE_KEY.STORE_THEME]: Theme;
  [STORE_KEY.STORE_LOGIN_EMAIL]: string;
  [STORE_KEY.STORE_DASHBOARD_FILTER]: Filter<DashboardCase>;
  [STORE_KEY.STORE_USER]: UserSession | null;
  [STORE_KEY.STORE_FEATURES]: boolean;
  [STORE_KEY.STORE_COUNTRY]: Country | undefined;
  [STORE_KEY.STORE_ONBOARDING_NAME]: string;
}

const NAME = "wl-outreach";

const defaultValues = {
  [STORE_KEY.STORE_THEME]: Theme.LIGHT,
  [STORE_KEY.STORE_LOGIN_EMAIL]: "",
  [STORE_KEY.STORE_DASHBOARD_FILTER]: { ...defaultFilter() },
  [STORE_KEY.STORE_USER]: null,
  [STORE_KEY.STORE_FEATURES]: false,
  [STORE_KEY.STORE_COUNTRY]: undefined,
  [STORE_KEY.STORE_ONBOARDING_NAME]: "",
};

let storedData: StoredData = { ...defaultValues };

const storedStringData = window.localStorage.getItem(NAME);
if (storedStringData) {
  try {
    storedData = { ...defaultValues, ...JSON.parse(storedStringData) };
  } catch (err) {}
}

export const Store = {
  getValue<T>(key: keyof StoredData) {
    return storedData[key] as unknown as T;
  },

  setValue<Key extends keyof StoredData>(key: Key, value: StoredData[Key]) {
    storedData[key] = value;
    try {
      window.localStorage.setItem(NAME, JSON.stringify(storedData));
    } catch (err) {
      //Do not log
    }
  },

  getStorage() {
    return storedData;
  },
};
