import { ReactNode } from "react";
import { atom } from "recoil";

export interface SaveState {
  message?: string | ReactNode;
  date: Date;
}

export const caseSaveState = atom<SaveState[]>({
  key: "case-save-state",
  default: [],
});

export const caseErrorState = atom<SaveState[]>({
  key: "case-error-state",
  default: [],
});
