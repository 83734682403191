import cx from "classnames";
import React, { useMemo } from "react";
import { BeneficialOwner } from "../../../../data/models/caseTypes";
import { ITEM_HEIGHT, ITEM_HEIGHT_OWNER, ITEM_HEIGHT_EVENT } from "./Timeline";

interface Props {
  owners: BeneficialOwner[];
  active: boolean;
}

export function getOwnersEndOffset(owners: BeneficialOwner[], start: number) {
  return start + ITEM_HEIGHT + owners.length * ITEM_HEIGHT_OWNER;
}

export const MANDATORY_EVENTS = 0; // "Contract created", "Contract sent for confirmation"

export function getOwnerOffset() {
  const start = MANDATORY_EVENTS + ITEM_HEIGHT_EVENT;

  return start + ITEM_HEIGHT;
}

export function getConfirmedOffset(owners: BeneficialOwner[]) {
  return getOwnersEndOffset([], getOwnerOffset());
}

export const ToOwners: React.FunctionComponent<Props> = ({
  owners,
  active,
}) => {
  const allConfirmed = useMemo(() => {
    return owners.every((owner) => owner.identitySignedOff);
  }, [owners]);

  const length = owners.length;
  const start = getOwnerOffset();
  const end = getOwnersEndOffset(owners, start);

  if (!length) {
    return null;
  }

  const items = [];
  for (let i = 0; i < length; i++) {
    const itemEnd = start + ITEM_HEIGHT + i * ITEM_HEIGHT_OWNER;
    items.push(
      <React.Fragment key={`to-owners-${owners[i].id}`}>
        <line
          x1="10"
          x2="50"
          y1={start}
          y2={itemEnd}
          className={cx({ active })}
        />

        <line
          x1="50"
          x2="10"
          y1={itemEnd}
          y2={end}
          className={cx({ active: allConfirmed })}
        />

        <circle r="4" cx="50" cy={itemEnd} className={cx({ active })} />
      </React.Fragment>
    );
  }

  return <>{items}</>;
};
