import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { API } from "./network/API";
import LanguageDetector from "i18next-browser-languagedetector";

const SENT_CACHE = new Set<string>();

export enum Country {
  BELGIUM = "BE",
  CZECH_REPUBLIC = "CZ",
  NETHERLANDS = "NL",
  SLOVAKIA = "SK",
  LUXEMBURG = "LU",
  UNITED_KINGDOM = "GB",
  FRANCE = "FR",
  GERMANY = "DE",
  // SWEDEN = "SWE",
}

// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
export enum Language {
  FRENCH = "fr",
  GERMAN = "de",
  DUTCH = "nl",
  ENGLISH = "en",
  CZECH = "cs",
  SLOVAK = "sk",
}

export const ACTIVATED_LANGUAGES = [
  Language.FRENCH,
  Language.DUTCH,
  Language.ENGLISH,
  Language.CZECH,
];

export function getDefaultCountryFromLanguage(lang: Language): Country {
  if (lang === Language.CZECH) {
    return Country.CZECH_REPUBLIC;
  }

  if (lang === Language.ENGLISH) {
    return Country.UNITED_KINGDOM;
  }

  if (lang === Language.GERMAN) {
    return Country.GERMANY;
  }

  return lang.toUpperCase() as Country;
}

export enum Currency {
  EUR = "EUR",
  GBP = "GBP",
  CZK = "CZK",
  SKK = "SKK",
}

export const CountryCurrency: Record<Country, Currency> = {
  [Country.FRANCE]: Currency.EUR,
  [Country.BELGIUM]: Currency.EUR,
  [Country.NETHERLANDS]: Currency.EUR,
  [Country.LUXEMBURG]: Currency.EUR,
  [Country.CZECH_REPUBLIC]: Currency.CZK,
  [Country.SLOVAKIA]: Currency.SKK,
  [Country.UNITED_KINGDOM]: Currency.GBP,
  [Country.GERMANY]: Currency.EUR,
};

export enum TRANSLATION_NAMESPACE {
  ADMIN = "admin",
  OUTREACH = "outreach",
  MCC = "mcc",
}

export const DEFAULT_TRANSLATION_NAMESPACE = TRANSLATION_NAMESPACE.ADMIN;

const HttpApi = new Backend(null, {
  loadPath: API.getUrl("/translation/{{lng}}/{{ns}}.json"),
  allowMultiLoading: false,
  crossDomain: true,
  withCredentials: true,
  requestOptions: {
    mode: "cors",
    credentials: "include",
  },
});

i18n
  .use(HttpApi)
  .use(initReactI18next)
  /**
   * TODO: Let's use a better solution than this
   * Let's try this custom config to utilize caching of selected language
   */
  .use(
    new LanguageDetector(undefined, {
      order: ["localStorage"],
    })
  )
  .init({
    debug: false,
    // Since our translation keys are the translation in english, we should not need a fallback
    fallbackLng: Language.ENGLISH,
    ns: [DEFAULT_TRANSLATION_NAMESPACE, TRANSLATION_NAMESPACE.MCC],
    defaultNS: DEFAULT_TRANSLATION_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    saveMissingTo: "current",
    load: "languageOnly",
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      const language = lng[0];

      if (language === Language.ENGLISH) {
        return;
      }

      const cacheKey = language + ns + key;
      if (SENT_CACHE.has(cacheKey)) {
        return;
      }
      SENT_CACHE.add(cacheKey);
      fetch(API.getUrl(`/translation/${ns}.json`), {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: key,
          site: DEFAULT_TRANSLATION_NAMESPACE,
        }),
      }).catch(() => {
        console.warn("Couldnt send missing translation [" + key + "]");
      });
    },
  });

export default i18n;
