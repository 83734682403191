import React, { useCallback, ReactNode, useEffect, useRef } from "react";
import cx from "classnames";
import { Close } from "../icons/Close";
import { Button } from "../interactions/Buttons/Button";
import { Status } from "../../data/types";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
import "./Overlay.scss";
import { motion } from "framer-motion";
import { useEditSidebarWidth } from "../../hooks/useEditSidebarWidth";

interface Props {
  open: boolean;
  children: ReactNode;
  onClose: (event?: MouseEvent) => void;
  disableClose?: boolean;
  status?: Status;
  className?: string;
}

const ESC = "Escape";

export const Inner: React.FunctionComponent<{
  children: ReactNode;
  tryClose: () => void;
}> = ({ children, tryClose }) => {
  const offset = useEditSidebarWidth();

  if (!children) {
    return null;
  }

  return (
    <>
      <motion.div
        id="overlay-backdrop"
        onClick={tryClose}
        style={{
          right: offset,
        }}
      >
        <dialog className="overlay" open>
          <Button
            type="button"
            className="overlay-close action ghost small"
            onClick={tryClose}
          >
            <Close />
          </Button>
          <div className="overlay-body">{children}</div>
        </dialog>
      </motion.div>
    </>
  );
};

export const PersistentOverlay: React.FunctionComponent<Props> = ({
  open,
  children,
  onClose,
  disableClose = false,
  status,
  className,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  const tryClose = useCallback(
    (event?: MouseEvent) => {
      event?.preventDefault();

      if (disableClose) {
        return;
      }

      if (event && event.target !== event.currentTarget) {
        return;
      }

      onClose(event);
    },
    [onClose, disableClose]
  );

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    if (open) {
      disableBodyScroll(dialogRef.current);
      const elem = dialogRef.current.querySelector("input");
      if (elem?.disabled) {
        return;
      }
      elem?.focus();
    } else {
      enableBodyScroll(dialogRef.current);
    }

    const elem = dialogRef.current;
    return () => {
      if (elem) {
        enableBodyScroll(elem);
      }
    };
  }, [open]);

  const handler = useCallback(
    (ev: KeyboardEvent) => {
      if (disableClose) {
        return;
      }

      if (ev.code === ESC) {
        (document.activeElement as HTMLElement).blur();
        onClose();
      }
    },
    [onClose, disableClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handler, false);
    return () => {
      window.removeEventListener("keydown", handler, false);
    };
  }, [handler]);

  const classes = cx(
    "dialog-wrapper",
    "persistent-overlay",
    status,
    className,
    {
      "is-active": open,
      "disable-close": disableClose,
    }
  );

  return (
    <div className={classes} ref={dialogRef}>
      <Inner tryClose={tryClose}>{children}</Inner>
    </div>
  );
};
