import { useState } from "react";
import { useParams } from "react-router-dom";
import { T } from "../../../components/translation/T";
import { CaseId } from "../../../data/models/CommonTypes";
import { Case, CaseStatus } from "../../../data/models/caseTypes";
import { CasePageMenu } from "../../CasePageMenu";
import { CaseLoadWrapper } from "../CaseLoadWrapper";
import { CaseComplete } from "./CaseComplete";
import "./CaseCompletePage.scss";
import { DownloadCase } from "./DownloadCase";

export const CASE_COMPLETE_PAGE = "/outreach/:id/summary";

export const CaseCompletePage = () => {
  const { id } = useParams<{ id: CaseId }>();
  const [outreach, setOutreach] = useState<Case>();

  if (!id) {
    return null;
  }

  return (
    <CasePageMenu title="Case complete" className="case-page" caseId={id}>
      <section>
        <article>
          <CaseLoadWrapper onChange={setOutreach} caseId={id}>
            {outreach && <CaseCompletePageInner outreach={outreach} />}
          </CaseLoadWrapper>
        </article>
      </section>
    </CasePageMenu>
  );
};

const CaseCompletePageInner = ({ outreach }: { outreach: Case }) => {
  const canDownload =
    outreach.status === CaseStatus.COMPLETED_VERIFICATION ||
    outreach.status === CaseStatus.CLOSED;

  return (
    <div className="case-complete-page">
      <div className="m-bottom-40">
        <h1>
          <T>Case summary</T>
        </h1>
      </div>
      <CaseComplete outreach={outreach}>
        {canDownload && (
          <DownloadCase id={outreach.id} company={outreach.company} />
        )}
      </CaseComplete>
    </div>
  );
};
