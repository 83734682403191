export type BeneficialOwnerId = string & { isOwnerId: true };
export type UserId = string & { isUserId: true };
export type Cas = number & { isCasId: true };
export type LinkId = string & { isLinkId: true };
export type UTCDate = string & { isUTCDate: true };
export type CaseId = string & { isCaseId: true };
export type OnboardingCaseId = string & { isOnboardingCaseId: true };
export type CompanyId = string & { isCompanyId: true };
export type OutreachRequestId = number & { isOutreachRequestId: true };
export type CompanyRegistrationId = string & { isCompanyRegistrationId: true };
export type DocumentId = string & { isDocumentId: true };

export const ONGOING_RESPONSE = "ONGOING_RESPONSE";
