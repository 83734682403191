import cx from "classnames";
import i18n from "../../i18n";
import { getCountryDisplayName } from "../translation/I18Utils";
import { Flag, FlagProps } from "./Flag";
import "./FlagWithCountry.scss";

interface Props extends FlagProps {
  className?: string;
}

export const FlagWithCountry = ({ country, className, ...props }: Props) => {
  const { language } = i18n;

  return (
    <span className={cx("flag-with-country", className)}>
      <Flag {...{ ...props, country }} />
      <span className="country">
        {getCountryDisplayName(country, language)}
      </span>
    </span>
  );
};
