import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import { Beacon } from "../../../components/beacon/Beacon";
import { Flag } from "../../../components/flags/Flag";
import { Edit } from "../../../components/icons/Edit";
import { Visibility } from "../../../components/icons/Visibility";
import { Link } from "../../../components/links/Link";
import { ReminderPopover } from "../../../components/reminder/ReminderPopover";
import { T, TCondition, TDefault } from "../../../components/translation/T";
import { TransSwitch } from "../../../components/translation/TransSwitch";
import { getIntlDate } from "../../../components/utils";
import { CaseId, OnboardingCaseId } from "../../../data/models/CommonTypes";
import { BeneficialOwner, Validity } from "../../../data/models/caseTypes";
import { Country, Language } from "../../../i18n";
import { CaseStatusState } from "../../../state/caseStatusState";
import { ONBOARDING_OWNER_PAGE_ROUTE } from "../../onboarding/OnboardingOwnerPage";
import { OWNER_REVIEW_PAGE } from "../signoff/AssociateReview/OwnerReviewPage";
import "./ReviewOwnerListItem.scss";
import { useRecoilValue } from "recoil";
import { userState } from "../../../state/userState";
import { UserRole } from "../../../data/dataAuth";

interface Props {
  caseId: CaseId;
  owner: BeneficialOwner;
  enableConfirmDetails: boolean;
  onboardingId?: OnboardingCaseId;
  caseStatusState?: CaseStatusState;
}

/* function getReminderDateOld(owner: BeneficialOwner) {
  const { emailSent, smsSent } = owner.communication;

  if (!emailSent && !smsSent) {
    return;
  }

  if (!emailSent) {
    return new Date(smsSent as string);
  }

  if (!smsSent) {
    return new Date(emailSent as string);
  }

  const emailDate = new Date(emailSent as string);
  const smsDate = new Date(smsSent as string);

  return new Date(Math.max(emailDate.getTime(), smsDate.getTime()));
} */

export const OwnerButton: React.FunctionComponent<Props> = ({
  caseId,
  owner,
  enableConfirmDetails,
  onboardingId,
}) => {
  const user = useRecoilValue(userState);
  const { identity } = owner;
  const signedOff =
    typeof owner.identitySignedOff !== "undefined" &&
    typeof owner.identitySignedOffBy !== "undefined";

  const idDocumentUploaded = typeof identity?.uploaded !== "undefined";

  const disabled = !enableConfirmDetails && !owner.identitySignedOff;

  const canSendReminder =
    user?.role === UserRole.SALES || user?.role === UserRole.RISK;

  const link = onboardingId
    ? generatePath(ONBOARDING_OWNER_PAGE_ROUTE, {
        id: onboardingId,
        beneficialOwnerId: owner.id,
      })
    : generatePath(OWNER_REVIEW_PAGE, {
        id: caseId,
        beneficialOwnerId: owner.id,
      });

  if (signedOff || idDocumentUploaded) {
    return (
      <Link
        className={cx("event-button", "small", "as-button", {
          "is-disabled": disabled,
        })}
        link={link}
      >
        {owner.identitySignedOff ? (
          <>
            <Visibility /> <T>View</T>
          </>
        ) : (
          <>
            <Edit /> <T>Confirm</T>
          </>
        )}
      </Link>
    );
  }

  return (
    <div className="review-buttons">
      <Link className="as-button small is-disabled" link="/">
        <Visibility /> <T>Waiting</T>
      </Link>
      <ReminderPopover owner={owner} disabled={!canSendReminder} />
    </div>
  );
};

export const OwnerListItem: React.FunctionComponent<Props> = ({
  caseId,
  owner,
  enableConfirmDetails,
  onboardingId,
  caseStatusState,
}) => {
  const { identity, tracking } = owner;
  const { i18n } = useTranslation();
  const hasViewedCase = tracking.pageViewed;

  const reminderDate = owner.communication.reminderSent;
  const showReminderDate =
    caseStatusState?.timeline.sentToOwners && reminderDate;

  return (
    <li className={cx("review-owner-list-item", "active")}>
      <div className="relative">
        <div className="owner-item-name">
          <div className="owner-item-flag">
            {owner.nationality && (
              <Flag
                height={14}
                country={owner.nationality as Country}
                rounded
              />
            )}
          </div>
          {`${owner.firstName} ${owner.lastName}`}
        </div>
        <div className="owner-item-reminder">
          <Beacon
            className="aligned"
            mini
            validity={owner.outreachSent ? Validity.VALID : Validity.MISSING}
          />{" "}
          <TransSwitch>
            <TCondition
              condition={owner.outreachSent ? true : false}
              id={"Outreach sent - {{date}}"}
              options={{
                name: owner.identitySignedOffBy,
                date: getIntlDate(
                  owner.outreachSent,
                  i18n.language as Language
                ),
              }}
            ></TCondition>
            <TDefault>Outreach not sent</TDefault>
          </TransSwitch>
        </div>
        {showReminderDate ? (
          <div className="owner-item-reminder">
            <Beacon className="aligned" mini validity={Validity.VALID} />{" "}
            <div>
              <T>Sent reminder</T> -{" "}
              {getIntlDate(reminderDate, i18n.language as Language)}
            </div>
          </div>
        ) : null}

        <div className="owner-item-signature">
          <Beacon
            className="aligned"
            mini
            validity={
              typeof tracking === "undefined"
                ? Validity.MISSING
                : hasViewedCase
                ? Validity.VALID
                : //Message sent, not viewed
                  Validity.DEFAULT
            }
          />{" "}
          <div>
            <T>Viewed case</T> -{" "}
            {hasViewedCase ? (
              <>{getIntlDate(hasViewedCase, i18n.language as Language)}</>
            ) : (
              <T>Not viewed</T>
            )}
          </div>
        </div>

        <div className="owner-item-docs">
          <Beacon
            className="aligned"
            mini
            validity={
              typeof identity?.uploaded !== "undefined"
                ? Validity.VALID
                : Validity.MISSING
            }
          />{" "}
          <div>
            <T>Identification uploaded</T> -{" "}
            {identity?.uploaded ? (
              <>{getIntlDate(identity?.uploaded, i18n.language as Language)}</>
            ) : null}
          </div>
        </div>

        <div className="owner-item-confirmed">
          <Beacon
            className="aligned"
            mini
            validity={
              owner.identitySignedOff ? Validity.VALID : Validity.MISSING
            }
          />{" "}
          <div>
            <TransSwitch>
              <TCondition
                id={"Identity signed off by {{ name }} - {{date}}"}
                options={{
                  name: owner.identitySignedOffBy,
                  date: getIntlDate(
                    owner.identitySignedOff,
                    i18n.language as Language
                  ),
                }}
                condition={owner.identitySignedOff !== undefined}
              ></TCondition>
              <TDefault>Pending review & sign off</TDefault>
            </TransSwitch>
          </div>
        </div>
      </div>
      {/* <OwnerShare share={75} /> */}
      <OwnerButton {...{ caseId, enableConfirmDetails, owner, onboardingId }} />
    </li>
  );
};
