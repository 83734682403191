import React from "react";
import { BeneficialOwner } from "../../data/models/caseTypes";

interface Props {
  associate: BeneficialOwner;
}

export const Name: React.FunctionComponent<Props> = ({ associate: owner }) => {
  if (owner.firstName && owner.lastName) {
    return (
      <>
        {owner.lastName}, {owner.firstName}
      </>
    );
  }

  return <>{owner.firstName}</> || <>{owner.lastName}</> || <>&nbsp;</>;
};
