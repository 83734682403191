import cx from "classnames";
import { nanoid } from "nanoid";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { TextInput } from "../../../../components/form/TextInput";
import { Error } from "../../../../components/icons/Error";
import { Progress } from "../../../../components/progress/Progress";
import { T } from "../../../../components/translation/T";
import { TransSwitch } from "../../../../components/translation/TransSwitch";
import {
  CaseDocument,
  dataDocument,
  NewCaseDocument,
} from "../../../../data/dataDocument";
import { CaseId, DocumentId } from "../../../../data/models/CommonTypes";
import { Status } from "../../../../data/types";
import { useIsMountedRef } from "../../../../hooks/useIsMounted";
import { API } from "../../../../network/API";
import "./DocUpload.scss";
import { FiExternalLink } from "react-icons/fi";

interface Props {
  caseId: CaseId;
  doc: NewCaseDocument | CaseDocument;
  setUploadedDoc: (document: CaseDocument) => void;
  onRemoveDoc: (id: DocumentId) => void;
  onError?: (document: CaseDocument | NewCaseDocument) => void;
  accept?: string;
  disabled?: boolean;
  referenceNumber: string;
}

export interface ProgressInterface {
  progress: number;
  name: string;
}

export const MIME_PDF = "application/pdf";

export enum FileType {
  PDF,
  IMAGE,
}

export interface ImageInterface {
  url?: string;
  type?: FileType;
}

const buildImage = (caseId: CaseId, doc: CaseDocument | NewCaseDocument) => {
  if (!doc.id) {
    return undefined;
  }

  return {
    url: API.getUrl(`/api/outreach/${caseId}/documents/${doc.id}`),
    type: doc.mimeType === MIME_PDF ? FileType.PDF : FileType.IMAGE,
  };
};

export const DocUpload: React.FunctionComponent<Props> = ({
  caseId,
  doc,
  setUploadedDoc,
  onRemoveDoc,
  onError,
  accept = "image/*,application/pdf",
  disabled = false,
  referenceNumber,
}) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [refNumber, setRefNumber] = useState<string>("");
  const identifier = useRef<string>(nanoid());
  const fileElemRef = useRef<HTMLInputElement>(null);
  const mounted = useIsMountedRef();

  useEffect(() => {
    if (doc && doc.id) {
      setRefNumber(doc.refNumber || "");
    }
  }, [doc]);

  const [progress, setProgress] = useState<ProgressInterface>({
    progress: 0,
    name: "",
  });

  const image = useMemo(() => doc.id && buildImage(caseId, doc), [caseId, doc]);

  const handleFileTooLarge = useCallback(() => {
    setStatus(Status.ERROR);
    setTimeout(() => setStatus(Status.DEFAULT), 5000);
  }, []);

  const upload = useCallback(
    (targetFile: File, caseId: CaseId) => {
      if (!targetFile) {
        return;
      }

      setStatus(Status.PENDING);
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          setProgress({
            progress: (event.loaded / event.total) * 100,
            name: targetFile.name,
          });
        }
      });

      req.onload = function () {
        if (req.status === 200) {
          setProgress({
            progress: 100,
            name: targetFile.name,
          });

          const document = JSON.parse(req.response) as CaseDocument;

          setUploadedDoc(document);
          setTimeout(() => {
            if (!mounted.current) {
              return;
            }
            setStatus(Status.DEFAULT);
          }, 4000);
        } else {
          onError && onError(doc);
          setStatus(Status.ERROR);
          setTimeout(() => setStatus(Status.DEFAULT), 4000);
        }
      };

      const formData = new FormData();
      formData.append("document", targetFile);
      formData.append("type", doc.type);
      formData.append("ref", referenceNumber);

      req.open("POST", API.getUrl(`/api/outreach/${caseId}/documents`));
      req.withCredentials = true;
      req.send(formData);
    },
    [setUploadedDoc, onError, doc, mounted, referenceNumber]
  );

  const onAttachFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const targetFile = ev.target.files?.[0];

      if (!targetFile) {
        return;
      }

      const TEN_MEGABYTES = 10000000;
      if (targetFile.size > TEN_MEGABYTES) {
        return handleFileTooLarge();
      }

      if (!doc.id) {
        upload(targetFile, caseId);
        return;
      }

      dataDocument
        .delete(caseId, doc.id)
        .then(() => {
          upload(targetFile, caseId);
        })
        .catch(() => {
          upload(targetFile, caseId);
        });
    },
    [caseId, doc.id, upload, handleFileTooLarge]
  );

  return (
    <div className={cx("doc-upload", status)}>
      <div className="wl-reference-container">
        <TextInput
          name="wordlineRef"
          // value={doc.refNumber || refNumber}
          value={doc.refNumber || referenceNumber}
          onChange={(value) => setRefNumber(value)}
          // disabled={(doc && doc.id) || disabled ? true : false}
          disabled={true}
          label={"Reference number"}
        />
      </div>
      <div className="upload-button-container">
        <div className="view-link-container">
          {status === Status.ERROR && (
            <span className="upload-file-too-large">
              <T>File is too large!</T>
            </span>
          )}
          {image && (
            <ViewLink image={image} filename={doc.filename || "view file"} />
          )}
        </div>
        <input
          className="upload-file-input"
          type="file"
          id={identifier.current}
          name={identifier.current}
          onChange={(event) => onAttachFile(event)}
          ref={fileElemRef}
          multiple={false}
          accept={accept}
          disabled={disabled}
        />
        <label htmlFor={identifier.current}>
          <span
            className={cx("button", "upload-button-span", status, {
              "is-disabled": disabled,
            })}
          >
            <TransSwitch>
              <T.Condition condition={!!doc.id}>Replace document</T.Condition>
              <T.Default>Upload document</T.Default>
            </TransSwitch>
            {status === Status.ERROR && <Error />}
          </span>
        </label>
        <Progress {...progress} />
      </div>
    </div>
  );
};

interface ViewLinkProps {
  image: ImageInterface;
  filename: string;
}

const ViewLink: React.FunctionComponent<ViewLinkProps> = ({
  image,
  filename,
}) => {
  return (
    <a
      href={image.url}
      target="_blank"
      rel="noreferrer"
      className="doc-upload-view-link"
    >
      <span className="view-link-filename">View uploaded file</span>
      <span className="view-link-icon">
        <FiExternalLink />
      </span>
    </a>
  );
};
