import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { BeneficialOwner } from "../data/models/caseTypes";
import { caseBeneficialOwnerState } from "../state/caseBeneficialOwnerState";

export const useSetBeneficialOwner = () => {
  const setBeneficialOwners = useSetRecoilState(caseBeneficialOwnerState);

  const setBeneficialOwner = useCallback(
    (newBeneficialOwner: BeneficialOwner) => {
      setBeneficialOwners((prevState) => [
        ...prevState.filter(
          (beneficialOwner) => beneficialOwner.id !== newBeneficialOwner.id
        ),
        newBeneficialOwner,
      ]);
    },
    [setBeneficialOwners]
  );

  return {
    setBeneficialOwner,
  };
};
