import React, { useEffect, useState } from "react";
import { FaEye, FaPlus } from "react-icons/fa";
import { generatePath, useNavigate } from "react-router";
import { CompanyRegistrationId } from "../../data/models/CommonTypes";
import { Status } from "../../data/types";
import i18n, { Country } from "../../i18n";
import { CASE_ROUTE } from "../../pages/sales/case/CasePage";
import { CASE_COMPLETE_PAGE } from "../../pages/sales/complete/CaseCompletePage";
import { AnimateHeight } from "../animate/AnimateHeight";
import { WarningBox } from "../boxes/WarningBox";
import { CountrySelector } from "../countrySelector/CountrySelector";
import { CreateCaseButtonText } from "../createCaseButton/CreateCaseButtonText";
import { useCreateCase } from "../createCaseButton/useCreateCase";
import { Form } from "../form/Form";
import { HiddenInput } from "../form/HiddenInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { Button } from "../interactions/Buttons/Button";
import { NewOverlay } from "../overlay/NewOverlay";
import { SearchInput } from "../searchInput/SearchInput";
import { getCountryDisplayName } from "../translation/I18Utils";
import { T } from "../translation/T";
import {
  companyRegistrationIdValidators,
  countryHasRegistryMap,
} from "./companyValidators";

export function cleanNonDigits(value: string) {
  return value.replace(/\D/g, "") as CompanyRegistrationId;
}

interface Props {
  onClose: () => void;
}

const NewCaseOverlayInner: React.FunctionComponent<Props> = ({ onClose }) => {
  const { language } = i18n;
  const [id, setId] = useState<CompanyRegistrationId>();
  const [country, setCountry] = useState<Country>();
  const navigate = useNavigate();
  const ref = React.useRef<HTMLFormElement>(null);
  const {
    status,
    createNewCase,
    newCaseId,
    existingCaseId,
    reset: resetLookup,
  } = useCreateCase(id, country);

  const reset = () => {
    resetLookup();
    setId(undefined);
  };

  const openExistingCase = () => {
    if (status === Status.SUCCESS && existingCaseId) {
      navigate(generatePath(CASE_COMPLETE_PAGE, { id: existingCaseId }));
    }
  };

  useEffect(() => {
    if (status === Status.SUCCESS && newCaseId) {
      navigate(generatePath(CASE_ROUTE, { id: newCaseId }));
    }
  }, [navigate, newCaseId, status]);

  const hint =
    country && !countryHasRegistryMap[country] ? (
      <T
        id="No register available for {{ country }}"
        options={{
          country: getCountryDisplayName(country, language),
        }}
      />
    ) : undefined;

  return (
    <>
      <Form
        onSubmit={(_, ref) => {
          if (ref.isInvalid) {
            return;
          }
          createNewCase();
        }}
        ref={ref}
      >
        <h4>
          <T>Start new case</T>
        </h4>
        <AnimateHeight name={existingCaseId || "none"}>
          {existingCaseId ? (
            <div key="new">
              <WarningBox transparent>
                <T>There is already an ongoing case for this company</T>
                <br />
                <T>Do you want to view it?</T>
              </WarningBox>
              {country && id && (
                <>
                  <Button
                    className="m-top-20"
                    block
                    onClick={openExistingCase}
                    type="button"
                  >
                    <FaEye style={{ marginRight: 10 }} />{" "}
                    <T>View existing case</T>
                  </Button>
                </>
              )}
              <div className="m-top-20">
                <Button block onClick={reset} type="button" className="as-link">
                  <T>go back</T>
                </Button>
              </div>
            </div>
          ) : (
            <div key="search">
              <div className="m-bottom-20">
                <CountrySelector value={country} onChange={setCountry} />
                <HiddenInput
                  name="country"
                  value={country}
                  validators={[new RequiredValidator("Country is required")]}
                  label={"Country"}
                  scrollToRef={ref}
                />
              </div>
              <SearchInput
                value={id}
                onChange={(value) => setId(value as CompanyRegistrationId)}
                validators={
                  country ? companyRegistrationIdValidators[country] : []
                }
                autoFocus
                hint={hint}
              />
              <Button className="m-top-10" block type="submit" status={status}>
                <CreateCaseButtonText />
              </Button>
            </div>
          )}
        </AnimateHeight>
      </Form>
    </>
  );
};

export const NewCaseOverlay: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="new-case-overlay-overlay">
      <Button className="block" onClick={() => setIsOpen(true)}>
        <FaPlus style={{ marginRight: 10 }} /> <T>Start new case</T>
      </Button>
      <NewOverlay open={isOpen} onClose={() => setIsOpen(false)}>
        <NewCaseOverlayInner onClose={() => setIsOpen(false)} />
      </NewOverlay>
    </div>
  );
};
