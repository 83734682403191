import React from "react";
import { FilterView } from "./FilterView";
import { Page } from "../../Page";
import "./DashboardPage.scss";

export const DASHBOARD_ROUTE = "/dashboard";

export const DashboardPage: React.FunctionComponent = () => {
  return (
    <Page title="Dashboard" className="dashboard" background>
      <section>
        <article>
          <FilterView />
        </article>
      </section>
    </Page>
  );
};
