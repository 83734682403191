import { FC } from "react";
import "./BeneficialOwnerListItem.scss";
import {
  BeneficialOwner,
  CaseStatus,
  Validity,
} from "../../../../data/models/caseTypes";
import { Beacon } from "../../../../components/beacon/Beacon";
import { RiMailForbidLine, RiMailSendLine } from "react-icons/ri";
import { T } from "../../../../components/translation/T";
import cx from "classnames";
import { useRecoilValue } from "recoil";
import { caseStatusState } from "../../../../state/caseStatusState";

interface Props {
  owner: BeneficialOwner;
  validity?: Validity;
  actions?: React.ReactNode;
}

export function getBeneficialOwnerName(firstName?: string, lastName?: string) {
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }

  return firstName || lastName || "No name set";
}

export const BeneficialOwnerListItem: FC<Props> = ({
  owner,
  validity,
  actions,
}) => {
  const { status } = useRecoilValue(caseStatusState);

  const rootClass = cx("beneficial-owner-list-item", {
    "beneficial-owner-list-item--disabled": owner.outreachSent,
  });

  const outreachChipClass = cx("outreach-status-chip", "text-small", {
    "outeach-status-chip--not-sent": owner.outreachSent === undefined,
  });

  const showOutreachChip =
    status === CaseStatus.OUTREACH || status === CaseStatus.VERIFICATION;

  return (
    <div className={rootClass}>
      <Beacon validity={validity} />

      <div className="text-small owner-row-short-info">
        <ul>
          <li>{getBeneficialOwnerName(owner.firstName, owner.lastName)}</li>
          <li className="short-info-contact">
            {owner.communication.email} {owner.communication.phoneNumber}
          </li>
        </ul>
      </div>
      {showOutreachChip && (
        <div className={outreachChipClass}>
          {owner.outreachSent ? (
            <>
              <RiMailSendLine />
              <span>
                <T>outreach sent</T>
              </span>
            </>
          ) : (
            <>
              <RiMailForbidLine />
              <span>
                <T>outreach not sent</T>
              </span>
            </>
          )}
        </div>
      )}
      {actions && <div className="benefical-owner-item-actions">{actions}</div>}
    </div>
  );
};
