import { motion } from "framer-motion";
import { useState } from "react";
import { BeneficialOwner, Communication } from "../../data/models/caseTypes";
import { Button } from "../interactions/Buttons/Button";
import cx from "classnames";
import { Form } from "../form/Form";
import "./ReminderPopover.scss";
import { T } from "../translation/T";
import { PhoneNumberCountry } from "../contact/PhoneNumberCountry";
import { TextInput } from "../form/TextInput";
import { EmailBackendValidator } from "../form/validators/EmailBackendValidator";
import { EmailValidator } from "../form/validators/EmailValidator";
import { MaxLengthValidator } from "../form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../form/validators/MinLengthValidator";
import { PhoneCountryCodeValidator } from "../form/validators/PhoneCountryCodeValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { Status } from "../../data/types";
import { dataReview } from "../../data/dataReview";
import { useSetBeneficialOwner } from "../../hooks/useSetBeneficialOwner";
import { TransSwitch } from "../translation/TransSwitch";
import { UTCDate } from "../../data/models/CommonTypes";

interface Props {
  disabled?: boolean;
  owner: BeneficialOwner;
}

export const ReminderPopover = ({ ...props }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="reminder-popover">
      <Button
        className="small"
        block
        onClick={() => setIsOpen(true)}
        status={props.disabled ? Status.DISABLED : Status.DEFAULT}
      >
        <T>Reminder</T>
      </Button>
      {isOpen && (
        <AddressPopoverInner
          onClose={() => setIsOpen(false)}
          {...{ ...props }}
        />
      )}
    </div>
  );
};

type InnerProps = {
  onClose: () => void;
} & Props;

const AddressPopoverInner = ({ onClose, owner, ...props }: InnerProps) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [innerContact, setInnerContact] = useState<Communication>(() => ({
    ...owner.communication,
  }));
  const { setBeneficialOwner } = useSetBeneficialOwner();

  const isEmailRequired =
    !innerContact.phoneNumber || innerContact.phoneNumber.length < 1;
  const isPhoneRequired = !innerContact.email || innerContact.email.length < 1;

  const onSendReminder = () => {
    setStatus(Status.PENDING);
    dataReview
      .remindOwner(owner.id, {
        email: innerContact.email || undefined,
        phoneNumber: innerContact.phoneNumber || undefined,
      })
      .then(() => {
        setStatus(Status.SUCCESS);
        setBeneficialOwner({
          ...owner,
          communication: {
            ...innerContact,
            reminderSent: new Date().toUTCString() as UTCDate,
          },
        });
      })
      .catch(() => {
        setStatus(Status.ERROR);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 3000);
      });
  };

  return (
    <>
      <motion.div
        className="reminder-popover__backdrop"
        onClick={onClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
      />
      <motion.div
        className={cx("reminder-popover__container", {
          "reminder-popover--upwards": false,
        })}
      >
        <div className="reminder-popover__content">
          <div className="reminder-popover__header">
            <h5>
              <T>Send reminder</T>
            </h5>
            <Button
              className="reminder-popover__button m-bottom-10 small"
              ghost
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </div>
          <Form
            onSubmit={(_, form) => {
              if (form.isInvalid) {
                return;
              }

              onSendReminder();
            }}
          >
            <div className="tablet-columns reminder-form">
              <div className={cx("contact-input")}>
                <TextInput
                  onChange={(value) => {
                    setInnerContact({
                      ...innerContact,
                      email: value,
                    });
                  }}
                  name="email"
                  label="Email"
                  value={innerContact.email}
                  validators={[
                    ...(isEmailRequired
                      ? [new RequiredValidator("Email is required")]
                      : []),
                    new EmailValidator("Email is not valid"),
                    new MaxLengthValidator(
                      50,
                      "Email must be less than 50 characters"
                    ),
                    new EmailBackendValidator("Email is not valid"),
                  ]}
                  disabled={false}
                />
              </div>

              <div className={cx("contact-input")}>
                <TextInput
                  onChange={(value) =>
                    setInnerContact({
                      ...innerContact,
                      phoneNumber: value,
                    })
                  }
                  name="number"
                  label="Mobile phone number"
                  value={innerContact.phoneNumber}
                  validators={[
                    ...(isPhoneRequired
                      ? [
                          new RequiredValidator(
                            "Mobile phone number is required"
                          ),
                        ]
                      : []),
                    new PhoneCountryCodeValidator(
                      "Mobile phone number must start with a valid country code e.g +32..."
                    ),
                    new MinLengthValidator(
                      8,
                      "Mobile phone number must be at least 8 characters"
                    ),
                    new MaxLengthValidator(
                      14,
                      "Mobile phone number must be less than 14 characters"
                    ),
                  ]}
                  disabled={false}
                  message={
                    <PhoneNumberCountry
                      phoneNumber={innerContact.phoneNumber}
                    />
                  }
                />
              </div>
            </div>
            <Button block type="submit" status={status}>
              <TransSwitch>
                <T.Condition condition={status === Status.SUCCESS}>
                  Reminder sent
                </T.Condition>
                <T.Default>Send reminder</T.Default>
              </TransSwitch>
            </Button>
          </Form>
        </div>
      </motion.div>
    </>
  );
};
