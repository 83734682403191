import React, { ReactElement } from "react";
import { Children } from "react";
import {
  DefaultNamespace,
  KeyPrefix,
  Namespace,
  TFuncKey,
} from "react-i18next";
import { SwitchContextProvider } from "./SwitchContext";
import { TConditionalProps, TDefaultProps, TCondition, TDefault } from "./T";
import {
  TransCondition,
  TransConditionalProps,
  TransDefault,
  TransDefaultProps,
} from "./Trans";

interface Props<
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
> {
  children: ReactElement<AllowedChildren<K, N, TKPrefix, E>>[];
}

type AllowedChildren<
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
> =
  | TransConditionalProps<K, N, TKPrefix, E>
  | TransDefaultProps<K, N, TKPrefix, E>
  | TConditionalProps
  | TDefaultProps;

export function TransSwitch<
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>({ children }: Props<K, N, TKPrefix, E>) {
  const hasChildrenWithTrueCondition = (
    Children.toArray(children) as ReactElement<
      AllowedChildren<K, N, TKPrefix, E>
    >[]
  ).find((child) => {
    if (!React.isValidElement(child)) {
      return false;
    }

    if (isTransCondition(child) || isTCondition(child)) {
      return child.props.condition;
    }

    return false;
  });

  return (
    <SwitchContextProvider>
      {(
        Children.toArray(children) as ReactElement<
          AllowedChildren<K, N, TKPrefix, E>
        >[]
      ).map((child) => {
        if (
          !hasChildrenWithTrueCondition &&
          (isTransDefault(child) || isTDefault(child))
        ) {
          return React.isValidElement(child)
            ? React.cloneElement(child as any, { visible: true } as any)
            : null;
        } else if (
          hasChildrenWithTrueCondition &&
          (isTransDefault(child) || isTDefault(child))
        ) {
          return React.isValidElement(child)
            ? React.cloneElement(child as any, { visible: false } as any)
            : null;
        }

        if (isTransCondition(child) || isTCondition(child)) {
          return React.isValidElement(child)
            ? React.cloneElement(
                child as any,
                {
                  visible: child.props.condition,
                } as any
              )
            : null;
        }

        throw new Error("TransSwitch child is neither default nor condition");
      })}
    </SwitchContextProvider>
  );
}

const isTransCondition = <
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>(
  child: ReactElement<AllowedChildren<K, N, TKPrefix, E>>
): child is ReactElement<TransConditionalProps<K, N, TKPrefix, E>> => {
  return child.type === TransCondition;
};

const isTransDefault = <
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>(
  child: ReactElement<AllowedChildren<K, N, TKPrefix, E>>
): child is ReactElement<TransDefaultProps<K, N, TKPrefix, E>> => {
  return child.type === TransDefault;
};

const isTCondition = <
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>(
  child: ReactElement<AllowedChildren<K, N, TKPrefix, E>>
): child is ReactElement<TConditionalProps> => {
  return child.type === TCondition;
};

const isTDefault = <
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>(
  child: ReactElement<AllowedChildren<K, N, TKPrefix, E>>
): child is ReactElement<TDefaultProps> => {
  return child.type === TDefault;
};
