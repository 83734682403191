import { useState, useEffect, useMemo, FC } from "react";
import { Form } from "../../../components/form/Form";
import { AddressWithSearch } from "../../../components/address/AddressWithSearch";
import { WarningBox } from "../../../components/boxes/WarningBox";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Select } from "../../../components/form/Select";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Alternative } from "../../../components/interactions/InputTypes";
import { legalEntityTypeString } from "../../../components/legalEntityType/LegalEntityTypeDisplay";
import { T } from "../../../components/translation/T";
import { dataCompany } from "../../../data/dataCompany";
import { SaveCompanyRequest } from "../../../data/models/caseTypes";
import { LegalEntityType } from "../../../data/models/LegalEntityTypes";
import { Status } from "../../../data/types";
import { CaseId } from "../../../data/models/CommonTypes";
import styles from "./EditCompanyData.module.scss";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { NewCompany } from "../CompanyPage";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { AnimatePresence, motion, MotionProps } from "framer-motion";

interface Props {
  company: NewCompany;
  reloadOutreachCases: () => void;
  handleCloseButton: () => void;
}

export const EditCompanyData: FC<Props> = ({
  company,
  reloadOutreachCases,
  handleCloseButton,
}) => {
  const [status, setStatus] = useState(Status.DEFAULT);
  const [saveCompanyReqData, setSaveCompanyReqData] =
    useState<SaveCompanyRequest>({});
  const [addressSearchValue, setAddressSearchValue] = useState("");
  const [consent, setConsent] = useState<string[]>([]);
  const { companyId, country } = company;

  const legalEntityTypeAlternatives = useMemo(
    () => [
      {
        value: undefined,
        text: <T>Select a legal entity type</T>,
      },
      ...Object.keys(LegalEntityType).map(
        (key): Alternative<LegalEntityType> => ({
          value: key as LegalEntityType,
          text: <T>{legalEntityTypeString(key as LegalEntityType)}</T>,
        })
      ),
    ],
    []
  );

  useEffect(() => {
    setSaveCompanyReqData({ ...company });
  }, [company]);

  const onUpdate = <T extends keyof SaveCompanyRequest>(
    value: SaveCompanyRequest[T],
    key: T
  ) => {
    setSaveCompanyReqData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onSave = () => {
    if (status === Status.PENDING) return;
    setStatus(Status.PENDING);

    dataCompany
      .updateCompany(companyId, "NO_CASE_ID" as CaseId, saveCompanyReqData)
      .then(() => {
        setStatus(Status.SUCCESS);
        reloadOutreachCases();
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  };

  const getButtonStatus = () => {
    if (status === Status.PENDING) {
      return Status.PENDING;
    }
    if (consent.length === 0) {
      return Status.DISABLED;
    } else {
      return Status.DEFAULT;
    }
  };

  return (
    <div>
      <Form
        onSubmit={(_, form) => {
          form.setForceErrors(true);
          if (form.isInvalid) {
            return;
          }
          onSave();
        }}
        name="manual-company-data"
      >
        <div className="manual-company-data">
          <FieldSet
            header={<T>Company information</T>}
            headerClassName={"background-color-bg"}
          >
            <div className="tablet-columns">
              <div>
                <TextInput
                  name="companyName"
                  value={saveCompanyReqData.legalName}
                  onChange={(value) => onUpdate(value, "legalName")}
                  label="Legal name"
                  validators={[new RequiredValidator("Legal name is required")]}
                />
              </div>
              <div>
                <Select
                  alternatives={legalEntityTypeAlternatives}
                  name="legalEntityType"
                  value={saveCompanyReqData.legalEntityType}
                  onChange={(value) => onUpdate(value, "legalEntityType")}
                  label="Legal entity type"
                  validators={[
                    new RequiredValidator("Legal entity type is required"),
                  ]}
                />
              </div>
            </div>
            <div className={styles.address}>
              <AddressWithSearch
                address={saveCompanyReqData.address || {}}
                onChange={(address) => {
                  onUpdate(address, "address");
                }}
                defaultCountry={country}
                showCountry={false}
                searchValue={addressSearchValue}
                setSearchValue={setAddressSearchValue}
              />
            </div>
          </FieldSet>
          <div className={styles.warningBox}>
            <WarningBox>
              By editing the company's information{" "}
              <strong>{company.historicCases.length} associated cases</strong>{" "}
              will also be affected by the change.
            </WarningBox>
          </div>
          <Checkboxes
            name="consent"
            values={consent}
            onChange={setConsent}
            alternatives={checkboxAlternatives}
          />
          <AnimatePresence>
            {status === Status.ERROR && (
              <motion.div {...motionProps} className={styles.statusBox}>
                <ErrorBox>
                  <T>Could not update company information</T>
                </ErrorBox>
              </motion.div>
            )}
            {status === Status.SUCCESS && (
              <motion.div {...motionProps} className={styles.statusBox}>
                <SuccessBox>
                  <T>Company information updated successfully</T>
                </SuccessBox>
              </motion.div>
            )}
            <div className={styles.buttons}>
              <Button
                className="ghost"
                type="button"
                onClick={handleCloseButton}
              >
                <T>Cancel</T>
              </Button>
              <Button type="submit" status={getButtonStatus()}>
                <T>Update company information</T>
              </Button>
            </div>
          </AnimatePresence>
        </div>
      </Form>
    </div>
  );
};

const motionProps: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

const alternative: Alternative<string> = {
  value: "I_UNDERSTAND",
  text: "I understand",
};

export const checkboxAlternatives: Alternative<string>[] = [alternative];
