import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { FieldSet } from "../../../../components/fieldSet/FieldSet";
import { Form } from "../../../../components/form/Form";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { T } from "../../../../components/translation/T";
import { CaseDocument, DocumentType } from "../../../../data/dataDocument";
import { DocumentId } from "../../../../data/models/CommonTypes";
import { useCaseEditStatus } from "../../../../hooks/useCaseEditStatus";
import { caseDocumentState } from "../../../../state/caseDocumentState";
import { caseStatusState } from "../../../../state/caseStatusState";
import { LinkAnchors } from "../CaseEdit";
import { FormName } from "../menus/CaseEditMenu";
import { DocumentUpload } from "./DocumentUpload";
import { TextInput } from "../../../../components/form/TextInput";
import { WarningBox } from "../../../../components/boxes/WarningBox";

export const DocumentCollection = () => {
  const { id: caseId } = useRecoilValue(caseStatusState);
  const [documents, setDocuments] = useRecoilState(caseDocumentState);
  const [, disabled] = useCaseEditStatus();
  const [contractRef, setContractRef] = useState("");

  const contractDocuments = useMemo(
    () =>
      documents.filter(({ type }) => type === DocumentType.CONTRACT) || {
        type: DocumentType.CONTRACT,
      },
    [documents]
  );

  const otherDocuments = useMemo(
    () => documents.filter(({ type }) => type !== DocumentType.CONTRACT),
    [documents]
  );

  const allDocuments = [...contractDocuments, ...otherDocuments];

  const contractRefIsDisabled = allDocuments.length > 0 ? true : false;

  useEffect(() => {
    const firstDocumentWithRef = allDocuments.find(
      (doc) => doc.refNumber != null
    );
    if (firstDocumentWithRef === undefined) return;

    setContractRef(firstDocumentWithRef.refNumber as string);
  }, []);

  const addDocument = useCallback(
    (document: CaseDocument, documentId?: DocumentId) => {
      setDocuments((documents) => [
        ...documents.filter(({ id }) => id !== documentId),
        document,
      ]);
    },
    [setDocuments]
  );

  const onRemove = useCallback(
    (id: DocumentId) => {
      setDocuments((documents) => documents.filter((doc) => doc.id !== id));
    },
    [setDocuments]
  );

  return (
    <Form name={FormName.DOCUMENT_COLLECTION}>
      <div className="document-collection">
        <ScrollPositionAnchor id={LinkAnchors.DOCUMENT_COLLECTION.anchor} />
        <FieldSet header={<T>{LinkAnchors.DOCUMENT_COLLECTION.name}</T>}>
          <AnimateHeight name={contractDocuments.length.toString()}>
            <>
              <TextInput
                value={contractRef}
                onChange={setContractRef}
                label={"Reference number"}
                disabled={contractRefIsDisabled}
              />
              {contractRefIsDisabled && (
                <div className="m-bottom-30">
                  <WarningBox>
                    <T>
                      If you want to change the reference number, please remove
                      all uploaded documents.
                    </T>
                  </WarningBox>
                </div>
              )}

              {contractDocuments.map((document) => (
                <DocumentUpload
                  key={document.id}
                  setUploadedDoc={(newDocument) =>
                    addDocument(newDocument, document.id)
                  }
                  onRemoveDoc={onRemove}
                  description={<T>Upload a contract to the case</T>}
                  referenceNumber={contractRef}
                  {...{ document, caseId, disabled }}
                />
              ))}

              <DocumentUpload
                key={contractDocuments.length}
                document={{ type: DocumentType.CONTRACT }}
                setUploadedDoc={addDocument}
                onRemoveDoc={onRemove}
                description={
                  <T>Upload a new contract document to the case (Max 10 MB)</T>
                }
                referenceNumber={contractRef}
                {...{ caseId, disabled }}
              />
            </>
          </AnimateHeight>
          <hr />
          <AnimateHeight name={otherDocuments.length.toString()}>
            <>
              {otherDocuments.map((document) => (
                <DocumentUpload
                  key={document.id}
                  setUploadedDoc={(newDocument) =>
                    addDocument(newDocument, document.id)
                  }
                  onRemoveDoc={onRemove}
                  description={
                    <T>Upload other documents related to the case</T>
                  }
                  referenceNumber={contractRef}
                  {...{ document, caseId, disabled }}
                />
              ))}

              <DocumentUpload
                key={otherDocuments.length}
                document={{ type: DocumentType.OTHER }}
                setUploadedDoc={addDocument}
                onRemoveDoc={onRemove}
                description={
                  <T>Upload other documents related to the case (Max 10 MB)</T>
                }
                referenceNumber={contractRef}
                {...{ caseId, disabled }}
              />
            </>
          </AnimateHeight>
        </FieldSet>
      </div>
    </Form>
  );
};
