import { UserSession, UserRole } from "../../data/dataAuth";
import { DashboardCase } from "../../data/dataDashboard";
import { CaseStatus } from "../../data/models/caseTypes";
import { FilterUtils } from "../../data/models/Filter";
import { SortDirection } from "../../data/models/Page";
import { MCC_ADMIN_PAGE } from "../../pages/manager/mcc/MccAdministrationPage";
import { COMMUNICATION_LIST_URL } from "../../pages/communication/communicationList/CommunicationList";
import { TRANSLATIONS_PAGE } from "../../pages/manager/translation/MissingTranslations";
import { USER_MANAGEMENT_PAGE } from "../../pages/manager/users/UserManagementPage";
import { DASHBOARD_ROUTE } from "../../pages/sales/Dashboard/DashboardPage";
import { defaultFilter, Filter } from "../../Store";
import { Attach } from "../icons/Attach";
import { CheckCircleOutline } from "../icons/CheckCircleOutline";
import { Clock } from "../icons/Clock";
import { ListEdit } from "../icons/ListEdit";
import { Profile } from "../icons/Profile";
import { MenuItem } from "../menu/Menu";
import { T } from "../translation/T";

function getOwnCaseRoute(user: UserSession | null) {
  if (!user) {
    return DASHBOARD_ROUTE;
  }
  const filter: Filter<DashboardCase> = {
    ...defaultFilter<DashboardCase>(),
    sortField: "created",
    sortDirection: SortDirection.DESC,
    userId: user.userId,
  };
  return `${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(filter)}`;
}

function getCompletedCasesRoute() {
  const filter: Filter<DashboardCase> = {
    ...defaultFilter<DashboardCase>(),
    status: [CaseStatus.COMPLETED_VERIFICATION, CaseStatus.CLOSED],
    sortField: "updated",
    sortDirection: SortDirection.DESC,
  };
  return `${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(filter)}`;
}

function getDefaultRoute() {
  const filter = { ...defaultFilter() };
  return `${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(filter)}`;
}

function getOrderByRoute() {
  const filter: Filter<DashboardCase> = {
    ...defaultFilter<DashboardCase>(),
    sortField: "updated",
    sortDirection: SortDirection.DESC,
  };
  return `${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(filter)}`;
}

export const menuItems = (
  currentRoute: string,
  user: UserSession
): Record<UserRole, MenuItem[]> => ({
  [UserRole.OPERATIONS]: [],
  [UserRole.RISK]: [
    {
      name: (
        <>
          <ListEdit />
          <T>All cases</T>
        </>
      ),
      link: getDefaultRoute(),
      disabled: false,
      active: getDefaultRoute() === currentRoute,
    },
    {
      name: (
        <>
          <Profile />
          <T>My cases</T>
        </>
      ),
      link: getOwnCaseRoute(user),
      disabled: false,
      active: getOwnCaseRoute(user) === currentRoute,
    },
    {
      name: (
        <>
          <Clock />
          <T>Order by updated</T>
        </>
      ),
      link: getOrderByRoute(),
      disabled: false,
      active: getOrderByRoute() === currentRoute,
    },
    {
      name: (
        <>
          <CheckCircleOutline />
          <T>Completed cases</T>
        </>
      ),
      link: getCompletedCasesRoute(),
      disabled: false,
      active: getCompletedCasesRoute() === currentRoute,
    },
  ],
  [UserRole.MANAGER]: [
    {
      name: (
        <>
          <ListEdit />
          <T>All cases</T>
        </>
      ),
      link: DASHBOARD_ROUTE,
      disabled: false,
    },
    {
      name: (
        <>
          <Profile />
          <T>User management</T>
        </>
      ),
      link: USER_MANAGEMENT_PAGE,
    },
    {
      name: (
        <>
          <Attach />
          <T>Translations</T>
        </>
      ),
      link: TRANSLATIONS_PAGE,
    },
    {
      name: (
        <>
          <Attach />
          <T>Email / SMS</T>
        </>
      ),
      link: COMMUNICATION_LIST_URL,
    },
    {
      name: (
        <>
          <Attach />
          <T>Mcc Admin</T>
        </>
      ),
      link: MCC_ADMIN_PAGE,
    },
  ],
  [UserRole.SALES]: [
    {
      name: (
        <>
          <ListEdit />
          <T>All cases</T>
        </>
      ),
      link: getDefaultRoute(),
      disabled: false,
      active: getDefaultRoute() === currentRoute,
    },
    {
      name: (
        <>
          <Profile />
          <T>My cases</T>
        </>
      ),
      link: getOwnCaseRoute(user),
      disabled: false,
      active: getOwnCaseRoute(user) === currentRoute,
    },
    {
      name: (
        <>
          <Clock />
          <T>Order by updated</T>
        </>
      ),
      link: getOrderByRoute(),
      disabled: false,
      active: getOrderByRoute() === currentRoute,
    },
    {
      name: (
        <>
          <CheckCircleOutline />
          <T>Completed cases</T>
        </>
      ),
      link: getCompletedCasesRoute(),
      disabled: false,
      active: getCompletedCasesRoute() === currentRoute,
    },
  ],
});
