import { useEffect, useState, VFC } from "react";
import { Button } from "../../../components/interactions/Buttons/Button";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import {
  dataTranslation,
  TranslationText,
} from "../../../data/dataTranslation";
import { Status } from "../../../data/types";

import "./MissingTranslations.scss";

export interface Props {
  translationText?: TranslationText;
  onClose: (updated: boolean) => Promise<void>;
  onNext: (translation: TranslationText) => Promise<void>;
}

export const SuggestTranslationOverlay: VFC<Props> = ({
  translationText,
  onClose,
  onNext,
}) => {
  const isOpen = !!translationText;

  return (
    <NewOverlay open={isOpen} onClose={() => onClose(false)}>
      <SuggestTranslationOverlayInner
        translationText={translationText as TranslationText}
        onClose={onClose}
        onNext={onNext}
      />
    </NewOverlay>
  );
};

interface InnerProps extends Props {
  translationText: TranslationText;
}

export const SuggestTranslationOverlayInner: VFC<InnerProps> = ({
  translationText,
  onClose,
  onNext,
}) => {
  const [translatedText, setTranslatedText] = useState<string>(
    translationText.value ?? translationText.key
  );
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setTranslatedText(translationText.value ?? translationText.key);
  }, [translationText, setTranslatedText]);

  const translationHasTags =
    translationText.key.includes("<") || translationText.key.includes("{{");

  return (
    <div className="edit-translation-overlay">
      <h4>Edit translation</h4>
      <p>{translationText.key}</p>
      <form onSubmit={(event) => event.preventDefault()}>
        <label htmlFor="textValue" className="input input-text">
          <div className="input-frame">
            <textarea
              id="textValue"
              rows={3}
              value={translatedText}
              onChange={(e) => {
                const value = !e.target.value ? "" : "" + e.target.value;
                setTranslatedText(value);
              }}
            ></textarea>
          </div>
          <div className="input-messages">
            <div className="input-hint">
              {translationHasTags
                ? "Make sure to have the same tags as shown in the original text"
                : null}
            </div>
          </div>
        </label>
        <Button
          className="m-top-20"
          status={
            translatedText
              ? isSaving
                ? Status.PENDING
                : Status.DEFAULT
              : Status.DISABLED
          }
          onClick={() => {
            setIsSaving(true);
            dataTranslation
              .suggestText(translationText.id, translatedText)
              .then(async () => {
                await onClose(true);
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                setIsSaving(false);
              });
          }}
        >
          Save
        </Button>
        <Button
          className="m-top-20"
          status={
            translatedText
              ? isSaving
                ? Status.PENDING
                : Status.DEFAULT
              : Status.DISABLED
          }
          onClick={() => {
            setIsSaving(true);
            dataTranslation
              .suggestText(translationText.id, translatedText)
              .then(async () => {
                await onNext(translationText);
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                setIsSaving(false);
              });
          }}
        >
          Save & translate next
        </Button>
      </form>
    </div>
  );
};
