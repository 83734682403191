import React from "react";
import { Status } from "../../../../data/types";
import { DateTime, Interval } from "luxon";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { CaseStatusState } from "../../../../state/caseStatusState";
import { T } from "../../../../components/translation/T";
import { Trans } from "react-i18next";

interface Props {
  caseStatus: CaseStatusState;
  onClick: () => void;
  status: Status;
}

export const NotAllowed: React.FunctionComponent<Props> = ({
  caseStatus,
  onClick,
  status,
}) => {
  let ago;

  if (caseStatus.updated) {
    const now = DateTime.now();
    const updated = DateTime.fromISO(caseStatus.updated);
    const interval = Interval.fromDateTimes(updated, now);
    const days = interval.length("days");
    const hours = interval.length("hours");

    if (days > 1) {
      ago = (
        <T
          id="It was edited {{count}} day ago."
          options={{
            count: Math.floor(days),
          }}
        />
      );
    } else if (hours > 1) {
      ago = (
        <T
          id="It was edited {{count}} hour ago."
          options={{
            count: Math.floor(hours),
          }}
        />
      );
    } else {
      ago = (
        <T
          id="It was edited {{count}} minute ago."
          options={{
            count: Math.floor(interval.length("minutes")),
          }}
        />
      );
    }
  }

  return (
    <div className="case-claim landscape-wrapper">
      <b className="text-regular-plus">
        <T>Heads up</T> ✋
      </b>
      <p>
        <Trans>
          This case has already been worked on by{" "}
          <>
            user: <i>{caseStatus.currentUser.name}</i>
          </>
          . {ago}
        </Trans>
      </p>
      <Button block onClick={onClick} status={status}>
        <T>Claim and edit</T>
      </Button>
    </div>
  );
};
