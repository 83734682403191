export enum LegalEntityType {
  SOLE_PROPRIETARY = "SOLE_PROPRIETARY",
  GENERAL_PARTNERSHIP = "GENERAL_PARTNERSHIP",
  LIMITED_PARTNERSHIP = "LIMITED_PARTNERSHIP",
  LIMITED = "LIMITED",
  PUBLIC_COMPANY = "PUBLIC_COMPANY",
  ASSOCIATION = "ASSOCIATION",
  TRUST = "TRUST",
  OTHER = "OTHER",
}
