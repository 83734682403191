import React from "react";
import { T } from "../../../../../components/translation/T";

interface Props {
  createdDate: string;
}

export const EventCreated: React.FunctionComponent<Props> = ({
  createdDate,
}) => {
  return (
    <li className="active">
      <div className="event">
        <div className="timeline-date">{createdDate}</div>
        <b>
          <T>Case created</T>
        </b>
      </div>
    </li>
  );
};
