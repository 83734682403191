import cx from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { dataOutreach } from "../../data/dataOutreach";
import {
  MccRiskLevel,
  MerchantCategory,
  Validity,
} from "../../data/models/caseTypes";
import { Status } from "../../data/types";
import { TRANSLATION_NAMESPACE } from "../../i18n";
import { AnimateHeight } from "../animate/AnimateHeight";
import { Beacon } from "../beacon/Beacon";
import { HiddenInput } from "../form/HiddenInput";
import { TextInput } from "../form/TextInput";
import { MinLengthValidator } from "../form/validators/MinLengthValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { Trash } from "../icons/Trash";
import { Alternative } from "../interactions/InputTypes";
import { T } from "../translation/T";
import { getMccOpts, id } from "../utils";
import "./MCC.scss";

interface Props {
  onChange: (value: string[], name: string) => void;
  value: string[];
  name: string;
  disabled?: boolean;
}

export const getMccInfo = (info?: MerchantCategory) => {
  if (!info) {
    return null;
  }

  const name = (
    <T
      options={{
        ns: TRANSLATION_NAMESPACE.MCC,
      }}
    >
      {info.name}
    </T>
  );

  if (info.faceToFace === MccRiskLevel.RISK_APPROVAL) {
    return (
      <>
        <b>{info.code}</b> - {name}
        <div>
          <Beacon mini validity={Validity.PARTIAL} /> <T>Needs approval</T>
        </div>
      </>
    );
  }

  if (info.faceToFace === MccRiskLevel.NOT_ALLOWED) {
    return (
      <>
        <b>{info.code}</b> - {name}
        <div>
          <Beacon mini validity={Validity.MISSING} /> <T>Not allowed</T>
        </div>
      </>
    );
  }

  return (
    <>
      <b>{info.code}</b> - {name}
    </>
  );
};

export const MCC: React.FunctionComponent<Props> = ({
  onChange,
  value,
  name,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [internal, setInternal] = useState<Status>(Status.PENDING);
  const [search, setSearch] = useState("");
  const identifier = useRef<string>(id());
  const [mccs, setMCCs] = useState<MerchantCategory[]>([]);
  const [validCodes, setValidCodes] = useState<Set<string>>(new Set());
  const [categories, setCategories] = useState<Alternative<string>[]>(
    getMccOpts([], t)
  );
  const ref = useRef<HTMLDivElement>(null);

  const change = useCallback(
    (changeValue: string, changeName: string) => {
      setSearch(changeValue);
      const isValid = validCodes.has(changeValue);

      if (isValid && !value.includes(changeValue)) {
        onChange([...value, changeValue], changeName);
        setSearch("");
      }
    },
    [onChange, validCodes, value]
  );

  const remove = useCallback(
    (code: string) => {
      onChange(
        value.filter((c) => c !== code),
        name
      );
    },
    [onChange, value, name]
  );

  useEffect(() => {
    dataOutreach
      .loadMcc()
      .then((data) => {
        setMCCs(data);
        setValidCodes(new Set(data.map((mcc) => mcc.code)));
        setCategories(getMccOpts(data, t));
        setInternal(Status.SUCCESS);
      })
      .catch(() => {
        setInternal(Status.ERROR);
      });
  }, [t]);

  // grab all mccs from value
  const selectedEntries = useMemo(() => {
    return value.map((code) => {
      const option = mccs.find((mcc) => mcc.code === code);
      return {
        code,
        info: option,
      };
    });
  }, [mccs, value]);

  return (
    <>
      <div className="mcc-input" ref={ref}>
        <TextInput
          onChange={change}
          label="MCC"
          value={search}
          name={name}
          list={identifier.current}
          disabled={disabled || internal !== Status.SUCCESS}
          placeholder="Search for MCC"
        />
        {internal !== Status.ERROR && (
          <datalist id={identifier.current}>
            {categories.map((opt) => (
              <option value={opt.value} key={opt.value}>
                {opt.text}
              </option>
            ))}
          </datalist>
        )}
      </div>
      <AnimateHeight name={selectedEntries.length.toString() || "none"}>
        <>
          {selectedEntries.map(({ code, info }) => (
            <div
              key={code}
              className={cx("mcc-additional-info", "text-small", "line-small")}
            >
              <button
                type="button"
                className={cx("remove-button", {
                  "--disabled": disabled,
                })}
                onClick={() => remove(code)}
              >
                <Trash />
              </button>
              <span className="info">{info ? getMccInfo(info) : code}</span>
            </div>
          ))}
          <HiddenInput
            name="mcc-validation"
            value={value}
            validators={[
              new RequiredValidator("MCC is required"),
              new MinLengthValidator(1, "Add at least one MCCs"),
            ]}
            label={"MCC"}
            scrollToRef={ref}
            animate={false}
          />
        </>
      </AnimateHeight>
    </>
  );
};
