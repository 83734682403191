import styles from "./Backdrop.module.scss";
import { FunctionComponent } from "react";
import cx from "classnames";
import { AnimatePresence, MotionProps, motion } from "framer-motion";

interface Props {
  open: boolean;
  onClick: () => void;
  variant?: "transparent" | "dark" | "blur";
  children?: React.ReactNode;
}

const motionProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 0.5 },
  exit: {
    opacity: 0,
    transition: {
      opacity: { duration: 0.15 },
    },
  },
};

export const Backdrop: FunctionComponent<Props> = ({
  open,
  onClick,
  variant = "transparent",
}) => {
  const backdropClass = cx(styles.root, {
    [styles.transparent]: variant === "transparent",
    [styles.dark]: variant === "dark",
    [styles.blur]: variant === "blur",
  });

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={backdropClass}
          onClick={onClick}
          {...motionProps}
        ></motion.div>
      )}
    </AnimatePresence>
  );
};
