import { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { AddressWithSearch } from "../../../../components/address/AddressWithSearch";
import { WarningBox } from "../../../../components/boxes/WarningBox";
import { FieldSet } from "../../../../components/fieldSet/FieldSet";
import { Form } from "../../../../components/form/Form";
import { Select } from "../../../../components/form/Select";
import { TextInput } from "../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Alternative } from "../../../../components/interactions/InputTypes";
import { legalEntityTypeString } from "../../../../components/legalEntityType/LegalEntityTypeDisplay";
import { T, TCondition, TDefault } from "../../../../components/translation/T";
import { TransSwitch } from "../../../../components/translation/TransSwitch";
import { dataCompany } from "../../../../data/dataCompany";
import {
  RegistryResult,
  SaveCompanyRequest,
} from "../../../../data/models/caseTypes";
import { LegalEntityType } from "../../../../data/models/LegalEntityTypes";
import { Status } from "../../../../data/types";
import { useCaseEditStatus } from "../../../../hooks/useCaseEditStatus";
import { caseCompanyState } from "../../../../state/caseCompanyState";
import { caseStatusState } from "../../../../state/caseStatusState";
import "./ManualCompanyData.scss";

export const ManualCompanyData = () => {
  const [status, setStatus] = useState(Status.DEFAULT);
  const [company, setCompany] = useState<SaveCompanyRequest>({});
  const [addressSearchValue, setAddressSearchValue] = useState("");
  const [caseCompanyData, setCaseCompanyData] =
    useRecoilState(caseCompanyState);
  const [caseStatusData, setCaseStatusData] = useRecoilState(caseStatusState);
  const { registryResult, id: caseId } = caseStatusData;
  const { id, country, legalEntityType } = caseCompanyData;
  const { address } = company;
  const hasInitialLegalEntityType = useRef(
    typeof legalEntityType !== "undefined"
  );

  const [inputStatus, inputDisabled] = useCaseEditStatus(status);

  useEffect(() => {
    setCompany({ ...caseCompanyData });
  }, [caseCompanyData]);

  const onUpdate = <T extends keyof SaveCompanyRequest>(
    value: SaveCompanyRequest[T],
    key: T
  ) => {
    setCompany({
      ...company,
      [key]: value,
    });
  };

  const onSave = () => {
    setStatus(Status.PENDING);

    setStatus(Status.ERROR);
    setStatus(Status.SUCCESS);

    dataCompany
      .updateCompany(id, caseId, company)
      .then(() => {
        setStatus(Status.SUCCESS);
        setCaseCompanyData((prevData) => ({
          ...prevData,
          ...company,
        }));
        setCaseStatusData((prevData) => ({
          ...prevData,
          needManualCompanyData: false,
        }));
      })
      .catch(() => {
        setStatus(Status.ERROR);
        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 3000);
      });
  };

  const legalEntityTypeAlternatives = useMemo(
    () => [
      {
        value: undefined,
        text: <T>Select a legal entity type</T>,
      },
      ...Object.keys(LegalEntityType).map(
        (key): Alternative<LegalEntityType> => ({
          value: key as LegalEntityType,
          text: <T>{legalEntityTypeString(key as LegalEntityType)}</T>,
        })
      ),
    ],
    []
  );

  return (
    <div>
      <Form
        onSubmit={(_, form) => {
          form.setForceErrors(true);
          if (form.isInvalid) {
            return;
          }
          onSave();
        }}
        name="manual-company-data"
      >
        <div className="manual-company-data">
          <div>
            <WarningBox>
              <TransSwitch>
                <TCondition
                  condition={registryResult === RegistryResult.NO_DATA}
                >
                  We could not find any information on this company in the
                  business registryy. Please verify that the company actually
                  exists then enter the information manually.
                </TCondition>
                <TDefault>
                  There was no data available for this company. Please enter the
                  information manually.
                </TDefault>
              </TransSwitch>
            </WarningBox>
          </div>

          <hr />

          <FieldSet header={<T>Company information</T>}>
            <div className="tablet-columns">
              <div>
                <TextInput
                  name="companyName"
                  value={company.legalName}
                  onChange={(value) => onUpdate(value, "legalName")}
                  label="Legal name"
                  validators={[new RequiredValidator("Legal name is required")]}
                  disabled={inputDisabled}
                />
              </div>
              <div>
                <Select
                  alternatives={legalEntityTypeAlternatives}
                  name="legalEntityType"
                  value={company.legalEntityType}
                  onChange={(value) => onUpdate(value, "legalEntityType")}
                  label="Legal entity type"
                  validators={[
                    new RequiredValidator("Legal entity type is required"),
                  ]}
                  // disabled={hasInitialLegalEntityType.current || inputDisabled}
                />
              </div>
            </div>
            <div>
              <AddressWithSearch
                address={address || {}}
                onChange={(address) => {
                  onUpdate(address, "address");
                }}
                defaultCountry={country}
                showCountry={false}
                searchValue={addressSearchValue}
                setSearchValue={setAddressSearchValue}
              />
            </div>
          </FieldSet>

          <Button block type="submit" status={inputStatus} className="m-top-40">
            <T>Proceed with company data</T>
          </Button>
        </div>
      </Form>
    </div>
  );
};
