import React, { ReactNode, useCallback, useRef } from "react";
import { Beacon } from "../../../../components/beacon/Beacon";
import {
  CaseDocument,
  dataDocument,
  DocumentType,
  NewCaseDocument,
} from "../../../../data/dataDocument";
import { CaseId, DocumentId } from "../../../../data/models/CommonTypes";
import { Validity } from "../../../../data/models/caseTypes";
import { useWidth } from "../../../../hooks/useWidth";
import { DocUpload } from "./DocUpload";
import "./DocumentUpload.scss";
import { Attach } from "../../../../components/icons/Attach";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Trash } from "../../../../components/icons/Trash";
import { Status } from "../../../../data/types";
import cx from "classnames";

interface Props {
  document: CaseDocument | NewCaseDocument;
  setUploadedDoc: (document: CaseDocument) => void;
  onRemoveDoc: (id: DocumentId) => void;
  caseId: CaseId;
  description: string | ReactNode;
  disabled?: boolean;
  referenceNumber: string;
}

export const DESKTOP_WIDTH = 1024;
export const LANDSCAPE_WIDTH = 480;

const typeToString: Record<DocumentType, string> = {
  [DocumentType.CONTRACT]: "Contract document",
  [DocumentType.OTHER]: "Other document",
};

export const DocumentUpload: React.FunctionComponent<Props> = ({
  document,
  setUploadedDoc,
  onRemoveDoc,
  caseId,
  description,
  disabled = false,
  referenceNumber,
}) => {
  const width = useWidth();
  const isTouch = useRef<boolean>(
    window.matchMedia &&
      window.matchMedia("(pointer:coarse)").matches &&
      width < DESKTOP_WIDTH
  );

  const accept = isTouch.current
    ? "application/pdf;image/*;capture=camera"
    : "image/*,application/pdf";

  const documentUploadClass = cx({
    "document-upload": true,
    "document-upload-active": document.id,
    "document-upload-inactive": !document.id,
  });

  const onRemoveDocument = useCallback(() => {
    const { id } = document;
    if (!id) return;

    dataDocument
      .delete(caseId, id)
      .then(() => {
        onRemoveDoc(id);
      })
      .catch(() => {
        // setStatus(Status.ERROR);
        // setTimeout(() => setStatus(Status.DEFAULT), 4000);
      });
  }, [caseId, document, onRemoveDoc]);

  const buttonClass = cx({
    "remove-button": true,
    "danger-button": true,
    small: width < LANDSCAPE_WIDTH,
  });

  return (
    <div className={documentUploadClass}>
      <div className="document-upload-header">
        <div className="beacon-column">
          <Beacon
            validity={document.id ? Validity.VALID : Validity.DEFAULT}
            icon={<Attach />}
          />
        </div>
        <div className="content-column">
          <div className="text-wrapper">
            <b>
              {document.filename
                ? document.filename
                : typeToString[document.type]}
            </b>
            <div className="text-passive">
              {document.filename ? typeToString[document.type] : description}
            </div>
          </div>
        </div>
        <div className="delete-column">
          {document.id && (
            <Button
              onClick={onRemoveDocument}
              className={buttonClass}
              action
              status={disabled ? Status.DISABLED : Status.DEFAULT}
            >
              <Trash />
            </Button>
          )}
        </div>
      </div>
      <div className="document-upload-body">
        <div className="beacon-column"></div>
        <div className="content-column">
          <DocUpload
            accept={accept}
            doc={document}
            caseId={caseId}
            setUploadedDoc={setUploadedDoc}
            onRemoveDoc={onRemoveDoc}
            onError={() => {}}
            disabled={disabled}
            referenceNumber={referenceNumber}
          />
        </div>
        <div className="delete-column"></div>
      </div>
    </div>
  );
};
