import { FunctionComponent } from "react";
import { TextBlock } from "../../../../../data/models/caseTypes";

interface Props {
  blocks?: TextBlock[];
}

export const ExtractedIdText: FunctionComponent<Props> = ({ blocks }) => {
  if (!blocks) {
    return <div>No extracted texts found in image</div>;
  }

  return (
    <div className="associate-review-extracted-text">
      {blocks.map((textBlock, idx, blocks) => {
        const prevHeight = idx === 0 ? 0 : blocks[idx - 1].height;
        const top = textBlock.top + prevHeight;
        return (
          <div
            key={textBlock.height}
            style={{
              left: `${textBlock.left * 100}%`,
              top: `${top * 100}%`,
            }}
          >
            {textBlock.text}
          </div>
        );
      })}
    </div>
  );
};
