import { useState } from "react";
import { useParams } from "react-router-dom";
import { NewLanguageSelect } from "../../components/languageSelect/NewLanguageSelect";
import { T } from "../../components/translation/T";
import { SupportBox } from "../../components/verticalNav/SupportBox";
import { OnboardingCaseId } from "../../data/models/CommonTypes";
import { Case } from "../../data/models/caseTypes";
import { Page } from "../Page";
import { CaseComplete } from "../sales/complete/CaseComplete";
import { OnboardingLoadWrapper } from "./OnboardingLoadWrapper";
import { DownloadCase } from "./DownloadCase";
import "./OnboardingPage.scss";

export const ONBOARDING_PAGE_ROUTE = "/boarding/:id";

export const OnboardingPage = () => {
  const { id } = useParams<{ id: OnboardingCaseId }>();
  const [outreach, setOutreach] = useState<Case>();

  if (!id) {
    return null;
  }

  return (
    <Page stripped background title="Onboarding" className="case-page">
      <section>
        <article>
          <OnboardingLoadWrapper onChange={setOutreach} id={id}>
            {outreach && <OnboardingPageInner {...{ outreach, id }} />}
          </OnboardingLoadWrapper>
        </article>
      </section>
    </Page>
  );
};

const OnboardingPageInner = ({
  outreach,
  id,
}: {
  outreach: Case;
  id: OnboardingCaseId;
}) => {
  return (
    <div className="onboarding-page">
      <div className="header">
        <h1>
          <T>Onboarding of case</T>
        </h1>
        <NewLanguageSelect />
      </div>
      <CaseComplete outreach={outreach} onboardingId={id}>
        <DownloadCase id={id} company={outreach.company} />
        {/* <div className="divider" />
        <div className="m-bottom-30">
          <CloseCase id={id} closed={outreach.status === CaseStatus.CLOSED} />
        </div>
        <div className="divider" /> */}
        <SupportBox className="m-top-30" />
      </CaseComplete>
    </div>
  );
};
