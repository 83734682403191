import { generatePath, useParams } from "react-router-dom";
import { NewLanguageSelect } from "../../components/languageSelect/NewLanguageSelect";
import {
  BeneficialOwnerId,
  OnboardingCaseId,
} from "../../data/models/CommonTypes";
import { BeneficialOwner } from "../../data/models/caseTypes";
import { Page } from "../Page";
import { useLoadOnboardingOwner } from "./useLoadOnboardingOwner";
import { Retry } from "../../components/retry/Retry";
import "./OnboardingPage.scss";
import { AssociateIdSummary } from "../sales/signoff/AssociateReview/AssociateIdSummary";
import { Card } from "../../components/card/Card";
import { Link } from "../../components/links/Link";
import { T } from "../../components/translation/T";
import { ONBOARDING_PAGE_ROUTE } from "./OnboardingPage";

export const ONBOARDING_OWNER_PAGE_ROUTE =
  "/boarding/:id/owner/:beneficialOwnerId";

export const OnboardingOwnerPage = () => {
  const { id, beneficialOwnerId } = useParams<{
    id: OnboardingCaseId;
    beneficialOwnerId: BeneficialOwnerId;
  }>();

  const { owner, loadStatus, reload } = useLoadOnboardingOwner(
    id,
    beneficialOwnerId
  );

  if (!id || !beneficialOwnerId) {
    return <div>Onboarding case id or owner id is missing from URL</div>;
  }

  return (
    <Page stripped background title="Onboarding" className="case-page">
      <section>
        <article>
          <Retry
            retry={() =>
              id && beneficialOwnerId && reload(id, beneficialOwnerId)
            }
            status={loadStatus}
          >
            {owner && <OnboardingPageInner {...{ owner, id }} />}
          </Retry>
        </article>
      </section>
    </Page>
  );
};

const OnboardingPageInner = ({
  owner,
  id,
}: {
  owner: BeneficialOwner;
  id: OnboardingCaseId;
}) => {
  return (
    <div className="onboarding-page">
      <div className="header">
        <h1>
          {owner.firstName} {owner.lastName}
        </h1>
        <NewLanguageSelect />
      </div>

      <Card>
        <AssociateIdSummary owner={owner}>
          <Link
            link={generatePath(ONBOARDING_PAGE_ROUTE, {
              id,
            })}
            className="as-button block"
          >
            <T>Back to case</T>
          </Link>
        </AssociateIdSummary>
      </Card>
    </div>
  );
};
