import cx from "classnames";
import React, { ReactNode, useCallback } from "react";
import { Status } from "../../../data/types";
import { T } from "../../translation/T";
import "./ButtonPane.scss";

export interface ButtonInPaneProps {
  data?: any;
  text: string | ReactNode;
  active?: boolean;
}

interface Props {
  onClick: (data?: any) => void;
  status?: Status;
  buttons: ButtonInPaneProps[];
  className?: string;
}

function translate(v?: string | React.ReactNode) {
  if (!v) {
    return v;
  }
  if (typeof v === "string") {
    return <T>{v}</T>;
  }
  return v;
}

export const ButtonPane: React.FunctionComponent<Props> = (props) => {
  const { buttons, status = Status.DEFAULT, onClick, className } = props;

  const click = useCallback(
    (button: ButtonInPaneProps) => () => onClick(button.data),
    [onClick]
  );

  if (!buttons.length) {
    return null;
  }

  return (
    <div
      className={cx(
        "button-pane",
        status,
        className,
        `length-${buttons.length}`
      )}
    >
      {buttons.map((button, idx) => {
        return (
          <button
            type="button"
            onClick={click(button)}
            disabled={status !== Status.DEFAULT}
            key={button.text?.toString()}
            className={cx({
              active: button.active,
            })}
          >
            <span className="truncated">{translate(button.text)}</span>
          </button>
        );
      })}
    </div>
  );
};
