import React, { ReactNode } from "react";
import cx from "classnames";
import { StatusBox } from "./StatusBox";

interface Props {
  children: string | ReactNode;
  relative?: boolean;
  transparent?: boolean;
}

export const SuccessBox: React.FunctionComponent<Props> = ({
  children,
  relative,
  transparent,
}) => {
  return (
    <StatusBox
      className={cx("status-box", "success-box", {
        relative,
        transparent,
      })}
    >
      {children}
    </StatusBox>
  );
};
