import cx from "classnames";
import { motion, Variants } from "framer-motion";

interface Props {
  className?: string;
  strokeWidth?: number;
  variants?: Variants;
  childVariants?: Variants;
}

export const MotionCircles = ({
  className,
  strokeWidth,
  variants,
  childVariants,
}: Props) => {
  return (
    <svg
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={cx("circles", className)}
      style={{
        strokeWidth: strokeWidth,
        color: "#46BEAA",
      }}
    >
      <motion.g
        id="circles"
        stroke="none"
        fill="none"
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.circle
          id="Oval"
          stroke="currentColor"
          cx="250"
          cy="250"
          r="220"
          variants={childVariants}
          custom={1}
          style={{ originX: "50%", originY: "50%" }}
        />
        <motion.circle
          id="Oval"
          stroke="currentColor"
          cx="250"
          cy="250"
          r="180"
          variants={childVariants}
          custom={2}
          style={{ originX: "50%", originY: "50%" }}
        />
        <motion.circle
          id="Oval"
          stroke="currentColor"
          cx="250"
          cy="250"
          r="140"
          variants={childVariants}
          custom={3}
          style={{ originX: "50%", originY: "50%" }}
        />
        <motion.circle
          id="Oval"
          stroke="currentColor"
          cx="250"
          cy="250"
          r="100"
          variants={childVariants}
          custom={4}
          style={{ originX: "50%", originY: "50%" }}
        />
        <motion.circle
          id="Oval"
          stroke="currentColor"
          cx="250"
          cy="250"
          r="60"
          variants={childVariants}
          custom={5}
          style={{ originX: "50%", originY: "50%" }}
        />
      </motion.g>
    </svg>
  );
};
