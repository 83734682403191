import React, {
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Trans } from "react-i18next";
import { Button } from "../../components/interactions/Buttons/Button";
import { Input } from "../../components/interactions/Input/Input";
import { T } from "../../components/translation/T";
import { dataAuth, UserSession } from "../../data/dataAuth";
import { Status } from "../../data/types";
import { useIsMountedRef } from "../../hooks/useIsMounted";

interface Props {
  onLogin: (sessionResponse: UserSession) => void;
  requestId?: string;
  onReset: () => void;
}

function getPotentialError(otp?: string) {
  if (!otp) {
    return "A code has to be supplied";
  }
  return "";
}

export const LoginOtp: React.FunctionComponent<Props> = ({
  onLogin,
  requestId,
  onReset,
}) => {
  const [otp, setOtp] = useState<string>();
  const [otpStatus, setOtpStatus] = useState<Status>(Status.DEFAULT);
  const [otpError, setOtpError] = useState<ReactNode>();
  const statusRef = useRef<Status>(Status.DEFAULT);
  const mounted = useIsMountedRef();
  const formRef = useRef<HTMLFormElement>(null);

  const updateStatus = useCallback((statusValue: Status) => {
    statusRef.current = statusValue;
    setOtpStatus(statusValue);
  }, []);

  const onOtpSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      updateStatus(Status.PENDING);

      const error = getPotentialError(otp);
      if (error) {
        updateStatus(Status.ERROR);
        setOtpError(error);
        return;
      }

      dataAuth
        .postOtp(requestId || "", otp || "")
        .then(onLogin)
        .catch(() => {
          updateStatus(Status.ERROR);
          setOtpError("Ooh no! Something bad happened. Try again?");

          setTimeout(() => {
            if (!mounted.current) {
              return;
            }

            if (statusRef.current !== Status.ERROR) {
              return;
            }

            updateStatus(Status.DEFAULT);
          }, 4000);
        });
    },
    [requestId, otp, onLogin, updateStatus, mounted]
  );

  const onBlur = () => {
    const error = getPotentialError(otp);
    if (error) {
      updateStatus(Status.ERROR);
      setOtpError(error);
    }
  };

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    const elem = formRef.current.querySelector("[name='input-login-otp']");
    if (elem) {
      setTimeout(() => {
        (elem as HTMLInputElement).focus();
      }, 900);
    }
  }, []);

  return (
    <form onSubmit={onOtpSubmit} ref={formRef}>
      <p>
        <Trans>
          <b>Perfect!</b> A login code was sent to your email
        </Trans>
      </p>
      <Input
        name="input-login-otp"
        label="Enter code"
        hint="Please check your mail for the login code"
        value={otp}
        onChange={(val) => {
          updateStatus(Status.DEFAULT);
          setOtp(val);
        }}
        status={otpStatus}
        message={otpError}
        onBlur={onBlur}
        autocomplete="off"
      />
      <div className="m-top-30">
        <Button block type="submit" status={otpStatus}>
          <T>Submit code</T>
        </Button>
        <Button block ghost onClick={onReset}>
          <T>Reset</T>
        </Button>
      </div>
    </form>
  );
};
