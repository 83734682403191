import styles from "./StaleReason.module.scss";
import { FC, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { useWidth } from "../../hooks/useWidth";
import { T } from "../translation/T";

interface Props {
  chipPosition: {
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    bottom: number;
  };
  open: boolean;
}

export const StaleReasonTooltip: FC<Props> = ({ chipPosition, open }) => {
  const screenWidth = useWidth();

  const tooltipRef = useRef<HTMLDivElement>(null);

  const getLeftPosition = () => {
    const POPOVER_WIDTH = 350;
    const MARGIN_X = 10;
    // end x-coordinate of popover
    const popoverRight = chipPosition.left + POPOVER_WIDTH;
    // start x-coordinate of popover after centered
    const chipXCoordinateMiddle = chipPosition.left + chipPosition.width / 2;
    const popoverHalfWidth = POPOVER_WIDTH / 2;
    const centeredPopoverLeft = chipXCoordinateMiddle - popoverHalfWidth;

    // chip is overflowing to the left of the screen
    if (centeredPopoverLeft < 0) {
      return MARGIN_X;
    }

    // chip is overflowing to the right of the screen
    if (popoverRight > screenWidth) {
      const widthOverflow = popoverRight - screenWidth;
      return chipPosition.left - widthOverflow - MARGIN_X;
    }

    return centeredPopoverLeft;
  };

  const getTopPosition = () => {
    const POPOVER_HEIGHT = 40;
    // add logic for now overflowing
    return chipPosition.top - POPOVER_HEIGHT;
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          ref={tooltipRef}
          className={styles.tooltip}
          initial={{
            scale: 0.8,
            opacity: 0,
            top: getTopPosition(),
            left: getLeftPosition(),
          }}
          exit={{ scale: 0.8, opacity: 0 }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          transition={{ duration: 0.1 }}
        >
          <T>You cannot add stale reason for closed Case</T>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
