import { atom } from "recoil";
import { Cas } from "../data/models/CommonTypes";
import { CompanyKyc } from "../data/models/caseTypes";

export const caseKycState = atom<CompanyKyc>({
  key: "case-kyc-state",
  default: {
    cas: 0 as Cas,
    mainCountriesOperates: [],
    mcc: [],
  },
});
