import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../../components/boxes/StatusBoxes";
import { ConfirmButton } from "../../../components/interactions/Buttons/ConfirmButton";
import { T, TCondition, TDefault } from "../../../components/translation/T";
import { TransSwitch } from "../../../components/translation/TransSwitch";
import { dataOutreach } from "../../../data/dataOutreach";
import { CaseStatus } from "../../../data/models/caseTypes";
import { Status } from "../../../data/types";
import { caseStatusState } from "../../../state/caseStatusState";
import { userState } from "../../../state/userState";
import { DASHBOARD_ROUTE } from "../Dashboard/DashboardPage";

export function DeleteCase() {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const caseStatus = useRecoilValue(caseStatusState);
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  function onRemove() {
    setStatus(Status.DEFAULT);
    dataOutreach
      .discard(caseStatus.id)
      .then(() => navigate(DASHBOARD_ROUTE))
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }

  if (caseStatus.status !== CaseStatus.DATA_COLLECTION) return null;

  return (
    <div className="delete-case">
      {status === Status.ERROR && (
        <StatusBoxes status={status}>
          <ErrorBox relative>
            <T>Problems when discarding the case. Try again?</T>
          </ErrorBox>
        </StatusBoxes>
      )}

      <div className="delete-warning">
        <strong>
          <T>Be aware!</T>
        </strong>
        <p>
          <T>
            This action can't be undone. This case will be closed and any
            beneficial owner data will be deleted
          </T>
        </p>
        <ConfirmButton
          className="danger-button"
          onClick={onRemove}
          removeButtonText="Yes, discard"
          status={
            caseStatus.currentUser.id === user?.userId
              ? Status.DEFAULT
              : Status.DISABLED
          }
          small={false}
        >
          <T>Discard case</T>
        </ConfirmButton>
      </div>
    </div>
  );
}
