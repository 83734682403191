import React from "react";
import cx from "classnames";
import { Image } from "../../components/images/Image";

interface Props {
  className?: string;
}

export const LogoStripped: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <div className={cx("logo", className)}>
      <Image
        src="/images/logo-worldline-stripped.svg"
        ratio={2004.77 / 269.6}
        alt="Worldline logo"
      />
    </div>
  );
};
