import { ServerError } from "../../network/API";
import { dataOutreach } from "../dataOutreach";
import { SaveBeneficialOwner } from "../models/AssociateTypes";
import {
  BeneficialOwnerId,
  CaseId,
  ONGOING_RESPONSE,
} from "../models/CommonTypes";
import { BeneficialOwner } from "../models/caseTypes";

export enum OwnerSaveType {
  FULL = "FULL",
  CONTACT = "CONTACT",
}

interface BeneficialOwnerQueueProps {
  caseId: CaseId;
  beneficialOwner: SaveBeneficialOwner;
  saveType: OwnerSaveType;
  callback: (
    error: ServerError<BeneficialOwner> | null | typeof ONGOING_RESPONSE,
    response?: BeneficialOwner
  ) => void;
}

class BeneficialOwners {
  queue: BeneficialOwnerQueueProps[] = [];
  isRequesting: BeneficialOwnerId | null = null;

  isInQueue(owner: SaveBeneficialOwner) {
    return !!this.queue.find(
      (ownerInQueue) => ownerInQueue.beneficialOwner.id === owner.id
    );
  }

  saveOwner(
    caseId: CaseId,
    owner: SaveBeneficialOwner,
    saveType: OwnerSaveType,
    callback: (
      error: ServerError<BeneficialOwner> | null | typeof ONGOING_RESPONSE,
      response?: BeneficialOwner
    ) => void
  ) {
    if (this.isRequesting) {
      this.queue = this.queue.filter(
        (ownerInQueue) => ownerInQueue.beneficialOwner.id !== owner.id
      );

      this.queue.push({
        caseId,
        beneficialOwner: owner,
        saveType,
        callback,
      });
      return;
    }

    if (!this.queue.length) {
      this.postOwner(caseId, owner, saveType, callback);
      return;
    }

    const next = this.queue.pop();
    if (next) {
      this.postOwner(
        next.caseId,
        next.beneficialOwner,
        next.saveType,
        next.callback
      );
    }
  }

  postOwner(
    caseId: CaseId,
    owner: SaveBeneficialOwner,
    saveType: OwnerSaveType,
    callback: (
      error: ServerError<BeneficialOwner> | null | typeof ONGOING_RESPONSE,
      response?: BeneficialOwner
    ) => void
  ) {
    this.isRequesting = owner.id ?? null;

    getPromise(caseId, owner, saveType)
      .then((response) => {
        const hasRecentUpdate = this.isInQueue(owner);
        this.isRequesting = null;

        const next = this.queue.pop();
        if (next) {
          this.postOwner(
            next.caseId,
            next.beneficialOwner,
            next.saveType,
            next.callback
          );
        }

        hasRecentUpdate ? callback(ONGOING_RESPONSE) : callback(null, response);
      })
      .catch((err) => {
        const hasRecentUpdate = this.isInQueue(owner);
        this.isRequesting = null;

        const next = this.queue.pop();
        if (next) {
          this.postOwner(
            next.caseId,
            next.beneficialOwner,
            next.saveType,
            next.callback
          );
        }

        hasRecentUpdate ? callback(ONGOING_RESPONSE) : callback(err);
      });
  }

  deleteOwner(caseId: CaseId, ownerId: BeneficialOwnerId) {
    return dataOutreach.deleteBeneficialOwner(caseId, ownerId);
  }
}

function getPromise(
  caseId: CaseId,
  owner: SaveBeneficialOwner,
  saveType: OwnerSaveType
) {
  if (saveType === OwnerSaveType.FULL) {
    return dataOutreach.postBeneficialOwner(caseId, owner);
  }

  if (saveType === OwnerSaveType.CONTACT) {
    return dataOutreach.updateBeneficialOwnerContact(caseId, owner);
  }

  return Promise.reject();
}

export const beneficialOwnerQueue = new BeneficialOwners();
