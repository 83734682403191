import {
  MccRiskLevel,
  MerchantCategory,
  Validity,
} from "../../data/models/caseTypes";
import { TRANSLATION_NAMESPACE } from "../../i18n";
import { Beacon } from "../beacon/Beacon";
import { T } from "../translation/T";

export const MCCInfo = ({
  mcc,
  info,
}: {
  mcc: string;
  info?: MerchantCategory;
}) => {
  if (!info) {
    return <>{mcc}</>;
  }

  const name = (
    <T
      options={{
        ns: TRANSLATION_NAMESPACE.MCC,
      }}
    >
      {info.name}
    </T>
  );

  if (info.faceToFace === MccRiskLevel.RISK_APPROVAL) {
    return (
      <>
        <div className="row">
          <b>{info.code}</b> - {name}
        </div>
        <div>
          <Beacon mini validity={Validity.PARTIAL} /> <T>Needs approval</T>
        </div>
      </>
    );
  }

  if (info.faceToFace === MccRiskLevel.NOT_ALLOWED) {
    return (
      <>
        <div className="row">
          <b>{info.code}</b> - {name}
        </div>
        <div>
          <Beacon mini validity={Validity.MISSING} /> <T>Not allowed</T>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row">
        <b>{info.code}</b> - {name}
      </div>
    </>
  );
};
