import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Pending } from "../../../components/icons/Pending";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Input } from "../../../components/interactions/Input/Input";
import { UserRole } from "../../../data/dataAuth";
import { CaseId, UserId } from "../../../data/models/CommonTypes";
import { Page } from "../../../data/models/Page";
import { Status } from "../../../data/types";
import {
  NewUserRequest,
  User,
  userAdministration,
} from "../../../data/dataManager";
import { DelegateCaseOverlay } from "./DelegateCaseOverlay";
import { Page as PageComponent } from "../../Page";
import { Select } from "../../../components/form/Select";
import { DashboardCase, dataDashboard } from "../../../data/dataDashboard";
import { CaseStatusDisplay } from "../../../components/caseStatus/CaseStatus";
import { Pagination } from "../../../components/pagination/Pagination";
import { Table } from "../../../components/table/Table";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { caseLink } from "../../../util/CaseLink";
import { Link } from "../../../components/links/Link";
import { Flag } from "../../../components/flags/Flag";
import { companyLink } from "../../../util/CompanyLink";
import { ConfirmButton } from "../../../components/interactions/Buttons/ConfirmButton";
import "./EditUserPage.scss";
import { USER_MANAGEMENT_PAGE } from "./UserManagementPage";
import { CaseStatus, CaseType } from "../../../data/models/caseTypes";

export const EDIT_USER_PAGE = "/admin/users/:id";

type PageParams = {
  id: UserId;
};

const hasChanged = (original: User | undefined, request: NewUserRequest) => {
  if (!original) {
    return false;
  }
  return (
    original.email !== request.email ||
    original.name !== request.name ||
    original.role !== request.role
  );
};

// TODO: Refactor with new Form and form validation
export const EditUserPage = () => {
  const navigate = useNavigate();
  let { id } = useParams<PageParams>();

  const [originalUser, setOriginalUser] = useState<User>();

  const [cases, setCases] = useState<Page<DashboardCase>>();
  const [casePage, setCasePage] = useState(0);

  const [loading, setLoading] = useState(true);
  const [userRequest, setUserRequest] = useState<NewUserRequest>({
    email: "",
    name: "",
    role: UserRole.SALES,
  });

  const [nameStatus, setNameStatus] = useState(Status.DEFAULT);
  const [emailStatus, setEmailStatus] = useState(Status.DEFAULT);
  const [emailError, setEmailError] = useState("");

  const [delegateCase, setDelegateCase] = useState<{
    caseId: CaseId;
    type: CaseType;
  }>();

  const isValid = useMemo(() => {
    if (
      userRequest.email.length === 0 ||
      userRequest.name.length === 0 ||
      !userRequest.role
    ) {
      return false;
    }

    if (!userAdministration.isValidEmail(userRequest.email)) {
      return false;
    }
    return true;
  }, [userRequest]);

  const load = useCallback(() => {
    if (!id) {
      //Dirty som fan
      return;
    }

    const userId = id;

    setLoading(true);
    Promise.all([
      userAdministration.getUser(userId),
      dataDashboard.loadCases({ page: casePage, userId, size: 10 }, {}),
    ])

      .then(([user, cases]) => {
        setCases(cases);

        setOriginalUser(user);

        setUserRequest({
          email: user.email,
          name: user.name,
          role: user.role,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id, casePage, setUserRequest, setLoading, setOriginalUser]);

  useEffect(() => {
    load();
  }, [load]);

  if (loading) {
    return (
      <PageComponent padded title="User" className="edit-user">
        <FieldSet header={<span>User</span>}>
          Loading.. <Pending />
        </FieldSet>
      </PageComponent>
    );
  }

  if (!id) {
    return null;
  }

  return (
    <PageComponent padded title="Edit user" className="edit-user-page">
      <DelegateCaseOverlay
        outreachCase={delegateCase}
        currentUser={id}
        onClose={() => {
          setDelegateCase(undefined);
        }}
        onDelegate={() => {
          if (!id) {
            return;
          }
          setDelegateCase(undefined);
          load();
        }}
      />
      <section>
        <article>
          <FieldSet header="User">
            <div className="tablet-columns">
              <div>
                <Input
                  onChange={(value) => {
                    setUserRequest({ ...userRequest, name: value });
                  }}
                  value={userRequest.name}
                  name="name"
                  label="Name"
                  status={nameStatus}
                  message={
                    nameStatus === Status.ERROR ? "Name is required" : null
                  }
                  placeholder="Name of the user"
                  onBlur={() => {
                    if (userRequest.name.length === 0) {
                      setNameStatus(Status.ERROR);
                    } else if (userRequest.name.length > 0) {
                      setNameStatus(Status.SUCCESS);
                    } else {
                      setNameStatus(Status.DEFAULT);
                    }
                  }}
                />
              </div>
              <div>
                <Input
                  onChange={(value) => {
                    setUserRequest({ ...userRequest, email: value });
                  }}
                  value={userRequest.email}
                  name="email"
                  label="Email"
                  status={emailStatus}
                  message={emailStatus === Status.ERROR ? emailError : null}
                  placeholder="Email of the user, will be used as login"
                  onBlur={() => {
                    if (userRequest.email.length === 0) {
                      setEmailError("Email is required");
                      setEmailStatus(Status.ERROR);
                      return;
                    }
                    if (userAdministration.isValidEmail(userRequest.email)) {
                      setEmailStatus(Status.SUCCESS);
                    } else {
                      setEmailError("Wrong format on email");
                      setEmailStatus(Status.ERROR);
                    }
                  }}
                />
              </div>
              <div>
                <Select
                  label="Role"
                  name="role"
                  value={userRequest.role}
                  onChange={(value) => {
                    if (value === UserRole.MANAGER) {
                      return;
                    }
                    setUserRequest({ ...userRequest, role: value });
                  }}
                  alternatives={[
                    { value: UserRole.SALES, text: "Sales" },
                    { value: UserRole.RISK, text: "Risk" },
                    { value: UserRole.OPERATIONS, text: "Operations" },
                  ]}
                />
              </div>
              <div className="delete-wrapper">
                <ConfirmButton
                  onClick={() => {
                    if (!id) {
                      return;
                    }

                    userAdministration.deleteUser(id).then(() => {
                      navigate(USER_MANAGEMENT_PAGE);
                    });
                  }}
                  status={Status.DEFAULT}
                  small={false}
                  className="danger-button"
                >
                  Delete user
                </ConfirmButton>
              </div>
            </div>
            <div className="tablet-columns">
              <div className="m-top-10">
                <Button
                  onClick={() => {
                    if (!id) {
                      return;
                    }
                    userAdministration
                      .updateUser(userRequest, id)
                      .then((updatedUser) => {
                        setOriginalUser(updatedUser);

                        setUserRequest({
                          email: updatedUser.email,
                          name: updatedUser.name,
                          role: updatedUser.role,
                        });
                      });
                  }}
                  status={
                    hasChanged(originalUser, userRequest) && isValid
                      ? Status.DEFAULT
                      : Status.DISABLED
                  }
                  block
                >
                  Save
                </Button>
              </div>
              <div className="m-top-10">
                <Button
                  ghost
                  block
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go back
                </Button>
              </div>
            </div>
          </FieldSet>
          <hr />
        </article>
        <article>
          <h5>Current Cases</h5>

          <Table>
            <thead>
              <tr>
                <th>Legal name</th>
                <th>Status</th>
                <th>Started</th>
                <th>Updated</th>
                <th></th>
                <th>Delegate</th>
              </tr>
            </thead>
            <tbody>
              {cases?.content.map((outreachCase) => (
                <tr key={outreachCase.caseId}>
                  <td className="truncate">
                    <Link
                      link={companyLink(
                        outreachCase.country,
                        outreachCase.companyRegistrationId
                      )}
                    >
                      {outreachCase.country && (
                        <Flag
                          height={16}
                          rounded
                          country={outreachCase.country}
                        />
                      )}{" "}
                      {outreachCase.companyName
                        ? outreachCase.companyName
                        : outreachCase.companyRegistrationId}
                    </Link>
                  </td>
                  <td className="truncate">
                    <CaseStatusDisplay
                      status={outreachCase.status}
                      caseCloseReason={outreachCase.closedReason}
                    />
                  </td>
                  <td className="truncate">
                    <FormattedDate
                      date={outreachCase.created}
                      pattern="yyyy-MM-dd"
                    />
                  </td>
                  <td className="truncate">
                    {outreachCase.updated ? (
                      <FormattedDate
                        date={outreachCase.updated}
                        pattern="yyyy-MM-dd"
                      />
                    ) : (
                      " - "
                    )}
                  </td>
                  <td>
                    <Button
                      ghost
                      className="small"
                      onClick={() => {
                        navigate(
                          caseLink(outreachCase.caseId, outreachCase.status)
                        );
                      }}
                      status={Status.DEFAULT}
                    >
                      Open
                    </Button>
                  </td>
                  <td>
                    <Button
                      ghost
                      className="small"
                      onClick={() => {
                        setDelegateCase({
                          caseId: outreachCase.caseId,
                          type: outreachCase.caseType,
                        });
                      }}
                      status={
                        outreachCase.status === CaseStatus.CLOSED
                          ? Status.DISABLED
                          : Status.DEFAULT
                      }
                    >
                      Delegate
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="m-top-10">
            <Pagination
              activePage={cases ? cases.number : 0}
              totalPages={cases ? cases.totalPages : 0}
              onPageChange={(page) => {
                setCasePage(page);
              }}
            />
          </div>
        </article>
      </section>
    </PageComponent>
  );
};
