import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { Form, FormContainer } from "../../../../components/form/Form";
import { TextInput } from "../../../../components/form/TextInput";
import {
  BeneficialOwner,
  BeneficialOwnerType,
} from "../../../../data/models/caseTypes";
import { getBeneficialOwnerName } from "./BeneficialOwnerListItem";
import cx from "classnames";
import {
  beneficialOwnerQueue,
  OwnerSaveType,
} from "../../../../data/queues/BeneficialOwnerQueue";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { caseMainState } from "../../../../state/caseMainState";
import { Status } from "../../../../data/types";
import { useSetBeneficialOwner } from "../../../../hooks/useSetBeneficialOwner";
import { ONGOING_RESPONSE } from "../../../../data/models/CommonTypes";
import { ServerError } from "../../../../network/API";
import { caseErrorState, caseSaveState } from "../../../../state/caseSaveState";
import { MaxLengthValidator } from "../../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../../components/form/validators/MinLengthValidator";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { PhoneCountryCodeValidator } from "../../../../components/form/validators/PhoneCountryCodeValidator";
import { EmailValidator } from "../../../../components/form/validators/EmailValidator";
import { SaveBeneficialOwner } from "../../../../data/models/AssociateTypes";
import { EmailBackendValidator } from "../../../../components/form/validators/EmailBackendValidator";
import { LanguageSelect } from "../../../../components/languageSelect/LanguageSelect";
import { PhoneNumberCountry } from "../../../../components/contact/PhoneNumberCountry";
import { caseBeneficialOwnerState } from "../../../../state/caseBeneficialOwnerState";
import { BeneficialOwnerSaveError } from "./BeneficialOwnerSaveError";
import "./BeneficialOwnerForm.scss";
import { T } from "../../../../components/translation/T";

interface Props {
  owner: BeneficialOwner;
  beneficialOwnerType: BeneficialOwnerType;
  onClose: () => void;
  formName: string;
  formContainer: React.MutableRefObject<FormContainer | undefined>;
  scrollToRef?: RefObject<HTMLElement>;
}

const requestFromOwner = (owner: BeneficialOwner): SaveBeneficialOwner => {
  return {
    id: owner.id,
    firstName: owner.firstName,
    lastName: owner.lastName,
    title: owner.title,
    email: owner.communication.email,
    number: owner.communication.phoneNumber,
    language: owner.communication.language,
  };
};

export const BeneficialOwnerForm = ({
  owner,
  beneficialOwnerType,
  onClose,
  formName,
  formContainer,
  scrollToRef,
}: Props) => {
  const { id } = useRecoilValue(caseMainState);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [removeStatus, setRemoveStatus] = useState<Status>(Status.DEFAULT);
  const [saveRequest, setSaveRequest] = useState<SaveBeneficialOwner>(
    requestFromOwner({ ...owner })
  );
  const originalSaveRequest = useRef<SaveBeneficialOwner>({ ...saveRequest });
  const setDataSaved = useSetRecoilState(caseSaveState);
  const setDataError = useSetRecoilState(caseErrorState);
  const [isFormInvalid, setIsFormInvalid] = useState(
    formContainer.current ? formContainer.current.isInvalid : true
  );
  const handler = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { setBeneficialOwner } = useSetBeneficialOwner();

  const setBeneficialOwners = useSetRecoilState(caseBeneficialOwnerState);

  const saveType = OwnerSaveType.FULL;

  useEffect(() => {
    const onUpdate = () => {
      if (handler && handler.current) {
        clearTimeout(handler.current);
      }

      if (isFormInvalid === formContainer.current?.isInvalid) {
        return;
      }

      if (formContainer.current?.isPending) {
        return;
      }

      handler.current = setTimeout(() => {
        setIsFormInvalid(
          formContainer.current ? formContainer.current.isInvalid : true
        );
      }, 200);
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
      if (handler && handler.current) {
        clearTimeout(handler.current);
      }
    };
  }, [isFormInvalid, formContainer]);

  useEffect(() => {
    originalSaveRequest.current = requestFromOwner({ ...owner });
    setSaveRequest({ ...originalSaveRequest.current });
    formContainer.current?.resetValidation();
  }, [formContainer, owner]);

  const onChange = useCallback(
    <T extends keyof SaveBeneficialOwner>(
      value: SaveBeneficialOwner[T],
      key: T
    ) => {
      setSaveRequest((owner) => ({
        ...owner,
        [key]: value,
      }));
    },
    []
  );

  const callback = useCallback(
    (
      err: "ONGOING_RESPONSE" | ServerError<BeneficialOwner> | null,
      response?: BeneficialOwner
    ) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      if (err) {
        setStatus(Status.ERROR);
        // setTimeout(() => setStatus(Status.DEFAULT), 4000);
        setDataError((dataErrors) =>
          dataErrors.concat({
            date: new Date(),
            message: (
              <>
                Error when trying to save owner{" "}
                <b>Please check this owner again.</b>
              </>
            ),
          })
        );
        return;
      }

      setStatus(Status.DEFAULT);
      if (response) {
        setBeneficialOwner(response);
      }

      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );

      onClose();
    },
    [onClose, setBeneficialOwner, setDataError, setDataSaved]
  );

  const onSave = useCallback(() => {
    if (formContainer.current?.isValid) {
      beneficialOwnerQueue.saveOwner(
        id,
        {
          ...saveRequest,
          email: saveRequest.email || undefined,
          number: saveRequest.number || undefined,
        },
        saveType,
        callback
      );
    }
  }, [formContainer, id, saveRequest, saveType, callback]);

  const onRemove = useCallback(() => {
    setRemoveStatus(Status.PENDING);
    setTimeout(() => {
      beneficialOwnerQueue
        .deleteOwner(id, owner.id)
        .then((response) => {
          setBeneficialOwners(response);
          setRemoveStatus(Status.DEFAULT);
        })
        .catch(() => {
          setRemoveStatus(Status.ERROR);
          setTimeout(() => setRemoveStatus(Status.DEFAULT), 4000);
        });
    }, 500);
  }, [id, owner.id, setBeneficialOwners]);

  const discardClose = () => {
    setSaveRequest(originalSaveRequest.current);
    onClose();
  };

  const isEmailRequired = !saveRequest.number || saveRequest.number.length < 1;
  const isPhoneRequired = !saveRequest.email || saveRequest.email.length < 1;

  const showTitle = beneficialOwnerType === BeneficialOwnerType.ROLE;

  return (
    <Form
      formContainer={formContainer}
      name={formName}
      onSubmit={(_, form) => {
        if (form.isInvalid) {
          return;
        }
        onSave();
      }}
      className="beneficial-owner-form"
    >
      <h4>
        {getBeneficialOwnerName(saveRequest.firstName, saveRequest.lastName)}
      </h4>

      {status === Status.ERROR && <BeneficialOwnerSaveError />}

      <div className="m-top-30">
        <div className="tablet-columns">
          <div className={cx("contact-input")}>
            <TextInput
              onChange={(value) => onChange(value, "firstName")}
              name="firstName"
              label="Firstname"
              value={saveRequest.firstName}
              validators={[
                new RequiredValidator("First name is required"),
                new MinLengthValidator(
                  2,
                  "First name must be at least 2 characters"
                ),
                new MaxLengthValidator(
                  50,
                  "First name must be less than 50 characters"
                ),
              ]}
              disabled={false}
              scrollToRef={scrollToRef}
            />
          </div>
          <div className={cx("contact-input")}>
            <TextInput
              onChange={(value) => onChange(value, "lastName")}
              name="lastName"
              label="Lastname"
              value={saveRequest.lastName}
              validators={[
                new RequiredValidator("Last name is required"),
                new MinLengthValidator(
                  2,
                  "Last name must be at least 2 characters"
                ),
                new MaxLengthValidator(
                  50,
                  "Last name must be less than 50 characters"
                ),
              ]}
              disabled={false}
              scrollToRef={scrollToRef}
            />
          </div>
          {showTitle && (
            <div className={cx("contact-input")}>
              <TextInput
                onChange={(value) => onChange(value, "title")}
                name="title"
                label="Title"
                value={saveRequest.title}
                validators={[new RequiredValidator("Title is required")]}
                disabled={false}
                scrollToRef={scrollToRef}
              />
            </div>
          )}
        </div>
        <div className="tablet-columns">
          <div className={cx("contact-input")}>
            <TextInput
              onChange={(value) => onChange(value, "email")}
              name="email"
              label="Email"
              value={saveRequest.email}
              validators={[
                ...(isEmailRequired
                  ? [new RequiredValidator("Email is required")]
                  : []),
                new EmailValidator("Email is not valid"),
                new MaxLengthValidator(
                  50,
                  "Email must be less than 50 characters"
                ),
                new EmailBackendValidator("Email is not valid"),
              ]}
              disabled={false}
              scrollToRef={scrollToRef}
            />
          </div>

          <div className={cx("contact-input")}>
            <TextInput
              onChange={(value) => onChange(value, "number")}
              name="number"
              label="Mobile phone number"
              value={saveRequest.number}
              validators={[
                ...(isPhoneRequired
                  ? [new RequiredValidator("Mobile phone number is required")]
                  : []),
                new PhoneCountryCodeValidator(
                  "Mobile phone number must start with a valid country code e.g +32..."
                ),
                new MinLengthValidator(
                  8,
                  "Mobile phone number must be at least 8 characters"
                ),
                new MaxLengthValidator(
                  14,
                  "Mobile phone number must be less than 14 characters"
                ),
              ]}
              disabled={false}
              scrollToRef={scrollToRef}
              message={<PhoneNumberCountry phoneNumber={saveRequest.number} />}
            />
          </div>
          <div className={cx("contact-input")}>
            <LanguageSelect
              onChange={(value) => onChange(value, "language")}
              name="language"
              label="Communication language"
              value={saveRequest.language}
              validators={[
                new RequiredValidator("Communication language is required"),
              ]}
              disabled={false}
              scrollToRef={scrollToRef}
            />
          </div>
        </div>
      </div>

      <div className="tablet-columns m-top-10 button-row">
        <div className="placeholder-column" />
        <div>
          {isFormInvalid ? (
            <Button
              type="button"
              block
              className="danger-button"
              onClick={discardClose}
            >
              <T>Discard changes and close</T>
            </Button>
          ) : (
            <Button onClick={onSave} block>
              <T>Save and close</T>
            </Button>
          )}
        </div>

        <div>
          <Button
            className="danger-button"
            block
            onClick={onRemove}
            status={removeStatus}
          >
            <T>Remove person</T>
          </Button>
        </div>
      </div>
    </Form>
  );
};
