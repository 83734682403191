import React, { useEffect, useState } from "react";
import cx from "classnames";
import { MenuWrapper } from "../components/menuWrapper/MenuWrapper";
import { VerticalNav } from "../components/verticalNav/VerticalNav";
import "./Page.scss";

export interface PageProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  stripped?: boolean;
  padded?: boolean;
  centered?: boolean;
  background?: boolean;
}

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  className,
  title,
  stripped = false,
  padded = false,
  centered = false,
  background = false,
}) => {
  const [menuOpen, toggleMenu] = useState<boolean>(false);

  useEffect(() => {
    if (!title) {
      document.title = "Worldline Outreach";
      return;
    }
    document.title = `${title} | Worldline Outreach`;
  }, [title]);

  if (stripped) {
    return (
      <div
        className={cx("page", "stripped", className, { centered, background })}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={cx("page", className, {
        centered,
        background,
      })}
    >
      <MenuWrapper
        menuComponent={<VerticalNav />}
        {...{ padded, toggleMenu, menuOpen }}
      >
        {children}
      </MenuWrapper>
    </div>
  );
};
