import { useState, useEffect, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { PermissionError } from "../../components/permissionError/PermissionError";
import { Retry } from "../../components/retry/Retry";
import { CaseId } from "../../data/models/CommonTypes";
import { Case } from "../../data/models/caseTypes";
import { useIsMountedRef } from "../../hooks/useIsMounted";
import { useLoadCase } from "../../hooks/useLoadCase";
import { userState } from "../../state/userState";

interface Props extends React.PropsWithChildren {
  caseId?: CaseId;
  onChange?: (outreachCase: Case) => void;
}

export const CaseLoadWrapper: React.FunctionComponent<Props> = ({
  caseId,
  onChange,
  children,
}) => {
  const user = useRecoilValue(userState);
  const mounted = useIsMountedRef();
  const [missingPermission, setMissingPermission] = useState(false);

  const { outreachCase, loadStatus, errorCode, reload } = useLoadCase(
    caseId,
    user?.userId
  );

  useEffect(() => {
    if (outreachCase) {
      onChange?.(outreachCase);
    }
  }, [outreachCase, onChange]);

  useEffect(() => {
    if (errorCode === 403) {
      setMissingPermission(true);
    }
  }, [errorCode]);

  const retry = useCallback(() => {
    setTimeout(() => {
      if (!mounted.current) {
        return;
      }
      if (!caseId) {
        return;
      }
      reload(caseId);
    }, 300);
  }, [reload, caseId, mounted]);

  if (!caseId) {
    return <div>Case id missing from URL</div>;
  }

  return (
    <>
      {missingPermission ? (
        <PermissionError />
      ) : (
        <Retry retry={retry} status={loadStatus}>
          {children}
        </Retry>
      )}
    </>
  );
};
