import React from "react";
import { CasError } from "../../../components/errors/CasError";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Overlay } from "../../../components/overlay/Overlay";
import { T } from "../../../components/translation/T";
import { Status } from "../../../data/types";
import { CaseSaveError } from "./CasePage";

interface Props {
  error: CaseSaveError | null;
  setError: (error: CaseSaveError) => void;
  retry: () => void;
  onClose: () => void;
  reclaimAndSave: () => void;
}

const CaseEditErrorInner: React.FunctionComponent<Props> = ({
  error,
  setError,
  retry,
  onClose,
  reclaimAndSave,
}) => {
  if (error === CaseSaveError.CAS_ERROR) {
    return <CasError />;
  }

  if (error === CaseSaveError.CLAIM_ERROR) {
    return (
      <>
        <strong>
          <T>Weird</T>{" "}
        </strong>{" "}
        🤔
        <p className="line-small">
          <T>Someone else has claimed this case.</T>
        </p>
        <Button onClick={reclaimAndSave} block>
          <T>Reclaim and save?</T>
        </Button>
        <Button ghost onClick={onClose} block>
          <T>Cancel</T>
        </Button>
      </>
    );
  }

  if (error === CaseSaveError.SERVER_ERROR) {
    return (
      <>
        <strong>Ooh noo! </strong> 😔
        <p className="line-small">
          <T>Technical problems, we could't save your change</T>
        </p>
        <Button onClick={retry} block>
          <T>Try again?</T>
        </Button>
      </>
    );
  }

  return (
    <>
      <strong>Ooh noo! </strong> 😔
      <p className="line-small">
        <T>
          We couldn't save the data. Please review all data and make sure
          nothing is out of the ordinary
        </T>
      </p>
      <Button ghost onClick={onClose} block>
        <T>Close</T>
      </Button>
    </>
  );
};

export const CaseEditError: React.FunctionComponent<Props> = ({
  error,
  setError,
  retry,
  onClose,
  reclaimAndSave,
}) => {
  const disableClose = error === CaseSaveError.CAS_ERROR;
  return (
    <div className="case-edit-error">
      <Overlay
        open={error !== null}
        onClose={onClose}
        status={Status.ERROR}
        disableClose={disableClose}
      >
        <CaseEditErrorInner
          error={error}
          setError={setError}
          retry={retry}
          onClose={onClose}
          reclaimAndSave={reclaimAndSave}
        />
      </Overlay>
    </div>
  );
};
