import styles from "./CaseStepper.module.scss";
import { caseStatusToString } from "../../../components/caseStatus/CaseStatus";
import { CaseStatus } from "../../../data/models/caseTypes";
import { Checkmark } from "../../../components/icons/Checkmark";
import cx from "classnames";
import { useRecoilValue } from "recoil";
import { caseStatusState } from "../../../state/caseStatusState";
import { T } from "../../../components/translation/T";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { TransSwitch } from "../../../components/translation/TransSwitch";

export const CaseStepper = () => {
  const { status } = useRecoilValue(caseStatusState);

  const dataCollectionIsCompleted = status !== CaseStatus.DATA_COLLECTION;

  const outreachIsCompleted =
    status === CaseStatus.VERIFICATION ||
    status === CaseStatus.COMPLETED_VERIFICATION ||
    status === CaseStatus.CLOSED;

  const verificationIsCompleted =
    status === CaseStatus.COMPLETED_VERIFICATION ||
    status === CaseStatus.CLOSED;

  const completedVerificationIsCompleted = status === CaseStatus.CLOSED;

  /////// CIRCLE CLASSES

  const stepOneClass = cx(styles.stepCircle, {
    [styles.stepCircleActive]: status === CaseStatus.DATA_COLLECTION,
    [styles.stepCircleCompleted]: dataCollectionIsCompleted,
  });

  const stepTwoClass = cx(styles.stepCircle, {
    [styles.stepCircleActive]: status === CaseStatus.OUTREACH,
    [styles.stepCircleCompleted]: outreachIsCompleted,
  });

  const stepThreeClass = cx(styles.stepCircle, {
    [styles.stepCircleActive]: status === CaseStatus.VERIFICATION,
    [styles.stepCircleCompleted]: verificationIsCompleted,
  });

  const stepFourClass = cx(styles.stepCircle, {
    [styles.stepCircleActive]: status === CaseStatus.COMPLETED_VERIFICATION,
    [styles.stepCircleCompleted]: status === CaseStatus.CLOSED,
  });

  const stepFiveClass = cx(styles.stepCircle, {
    [styles.stepCircleCompleted]: status === CaseStatus.CLOSED,
  });

  /////// LINE CLASSES

  const lineOneClass = cx(styles.stepLine, {
    [styles.stepLineActive]: status !== CaseStatus.DATA_COLLECTION,
  });

  const lineTwoClass = cx(styles.stepLine, {
    [styles.stepLineActive]:
      status === CaseStatus.VERIFICATION ||
      status === CaseStatus.COMPLETED_VERIFICATION ||
      status === CaseStatus.CLOSED,
  });

  const lineThreeClass = cx(styles.stepLine, {
    [styles.stepLineActive]: verificationIsCompleted,
  });

  const lineFourClass = cx(styles.stepLine, {
    [styles.stepLineActive]: status === CaseStatus.CLOSED,
  });

  /////// TITLE CLASSES

  const titleOneClass = cx(styles.stepTitle, {
    [styles.stepTitleActive]: true,
  });

  const titleTwoClass = cx(styles.stepTitle, {
    [styles.stepTitleActive]: status !== CaseStatus.DATA_COLLECTION,
  });

  const titleThreeClass = cx(styles.stepTitle, {
    [styles.stepTitleActive]:
      status === CaseStatus.VERIFICATION ||
      status === CaseStatus.COMPLETED_VERIFICATION ||
      status === CaseStatus.CLOSED,
  });

  const titleFourClass = cx(styles.stepTitle, {
    [styles.stepTitleActive]:
      status === CaseStatus.COMPLETED_VERIFICATION ||
      status === CaseStatus.CLOSED,
  });

  const titleFiveClass = cx(styles.stepTitle, {
    [styles.stepTitleActive]: status === CaseStatus.CLOSED,
  });

  return (
    <>
      <div className={styles.root}>
        <div className={styles.indicator}>
          <div className={styles.circleContainer}>
            <div className={stepOneClass}>
              <span></span>
              <div className={styles.circleInner}>
                {dataCollectionIsCompleted && <Checkmark />}
              </div>
            </div>
          </div>
          <div className={lineOneClass}></div>
          <div className={styles.circleContainer}>
            <div className={stepTwoClass}>
              <span></span>

              <div className={styles.circleInner}>
                {outreachIsCompleted && <Checkmark />}
              </div>
            </div>
          </div>
          <div className={lineTwoClass}></div>
          <div className={styles.circleContainer}>
            <div className={stepThreeClass}>
              <span></span>

              <div className={styles.circleInner}>
                {verificationIsCompleted && <Checkmark />}
              </div>
            </div>
          </div>
          <div className={lineThreeClass}></div>
          <div className={styles.circleContainer}>
            <div className={stepFourClass}>
              <span></span>
              <div className={styles.circleInner}>
                {completedVerificationIsCompleted && <Checkmark />}
              </div>
            </div>
          </div>
          <div className={lineFourClass}></div>
          <div className={styles.circleContainer}>
            <div className={stepFiveClass}>
              <span></span>
              <div className={styles.circleInner}>
                {completedVerificationIsCompleted && <Checkmark />}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.stepTitles}>
          <div className={titleOneClass}>
            {caseStatusToString(CaseStatus.DATA_COLLECTION)}
          </div>
          <div className={titleTwoClass}>
            {caseStatusToString(CaseStatus.OUTREACH)}
          </div>
          <div className={titleThreeClass}>
            {caseStatusToString(CaseStatus.VERIFICATION)}
          </div>
          <div className={titleFourClass}>
            {caseStatusToString(CaseStatus.COMPLETED_VERIFICATION)}
          </div>
          <div className={titleFiveClass}>
            {caseStatusToString(CaseStatus.CLOSED)}
          </div>
        </div>
      </div>

      {verificationIsCompleted && (
        <div className={styles.message}>
          <AiOutlineExclamationCircle />
          <TransSwitch>
            <T.Condition
              id='You can only edit a case in "{{outreach}}" and "{{verification}}"'
              condition={status === CaseStatus.COMPLETED_VERIFICATION}
              options={{
                outreach: caseStatusToString(CaseStatus.OUTREACH),
                verification: caseStatusToString(CaseStatus.VERIFICATION),
              }}
            />
            <T.Condition condition={status === CaseStatus.CLOSED}>
              This case is closed
            </T.Condition>
          </TransSwitch>
        </div>
      )}
    </>
  );
};
