import React, { useCallback } from "react";
import { Button } from "../interactions/Buttons/Button";

export function CasError() {
  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      <strong>Ooh noo!</strong> 😔
      <p className="line-small">
        The case has been updated by someone else! You must reload to see the
        latest information
      </p>
      <Button onClick={reload} block>
        Reload page?
      </Button>
    </>
  );
}
