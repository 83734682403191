import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Button } from "../../components/interactions/Buttons/Button";
import { T } from "../../components/translation/T";
import { userState } from "../../state/userState";
import { Store, STORE_KEY } from "../../Store";
import { PageStripped } from "../PageStripped";
import { LOGIN_ROUTE } from "./LoginPage";
import "./Logout.scss";

export const LOGOUT_ROUTE = "/logout";

export const Logout: React.FunctionComponent = () => {
  const setUserState = useSetRecoilState(userState);
  const { search } = useLocation();
  const params = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
  const navigate = useNavigate();

  const expired = params.has("expired");

  useEffect(() => {
    Store.setValue(STORE_KEY.STORE_USER, null);
    setUserState(null);
  }, [setUserState]);

  const onClose = useCallback(() => {
    const url = params.get("expired");
    navigate(LOGIN_ROUTE, { state: { from: url } });
  }, [navigate, params]);

  return (
    <PageStripped title="Logout">
      <section>
        <article>
          <div className="logout-wrapper">
            {expired ? (
              <>
                <h4>
                  <T>Session expired</T>
                </h4>
                <p>
                  <T>
                    So, it seems like your session has expired. You will have to
                    sign in again. No worries. Take care.
                  </T>
                </p>
              </>
            ) : (
              <>
                <h4>
                  <T>Logged out</T>
                </h4>
                <p>
                  <T>You are now logged out</T>
                </p>
              </>
            )}
            <Button onClick={onClose} block>
              <T>Sign in</T>
            </Button>
          </div>
        </article>
      </section>
    </PageStripped>
  );
};
