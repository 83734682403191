import React, { useCallback, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router";
import { Edit } from "../../../components/icons/Edit";
import { Pending } from "../../../components/icons/Pending";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Pagination } from "../../../components/pagination/Pagination";
import { Table } from "../../../components/table/Table";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { UserRole } from "../../../data/dataAuth";
import { User, userAdministration } from "../../../data/dataManager";
import { Page, SortDirection } from "../../../data/models/Page";
import { Page as PageComponent } from "../../Page";
import { AddUserModal } from "./AddUserModal";
import { EDIT_USER_PAGE } from "./EditUserPage";
import "./UserManagementPage.scss";

export const USER_MANAGEMENT_PAGE = "/admin/users";
const PAGE_LIMIT = 20;

export const UserManagementPage: React.FunctionComponent = () => {
  const [users, setUsers] = useState<Page<User>>();
  const navigate = useNavigate();
  const location = useLocation();

  const onPageChange = (page: number) => {
    const search = new URLSearchParams(location.search);
    search.set("page", page.toString());
    navigate({
      pathname: location.pathname,
      search: "?" + search.toString(),
    });
  };

  const onSortChange = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const property = (ev.target as HTMLButtonElement).dataset.id || "name";
    const search = new URLSearchParams(location.search);
    search.set("sort", property);

    const direction = users?.sort.sorted
      ? users?.sort.direction === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
      : SortDirection.ASC;
    search.set("sortOrder", direction);

    navigate({
      pathname: location.pathname,
      search: "?" + search.toString(),
    });
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const page = search.has("page") ? Number(search.get("page")) : 0;

    userAdministration
      .getUsers(page, PAGE_LIMIT)
      .then(setUsers)
      .catch((err) => {
        console.error(err);
      });
  }, [setUsers, location]);

  const onEdit = useCallback(
    (data: any) => {
      navigate(generatePath(EDIT_USER_PAGE, { id: data.id }));
    },
    [navigate]
  );

  if (users === undefined) {
    return (
      <PageComponent
        padded
        title="User management"
        className="user-management-page"
      >
        <section>
          <div className="center">
            <Pending />
          </div>
        </section>
      </PageComponent>
    );
  }

  // const showLastLogin = (lastLogin?: AdminLoginHistory) => {
  //   if (!lastLogin) {
  //     return "-";
  //   }

  //   const hoursSinceLogin = DateTime.fromISO(lastLogin.created).diffNow(
  //     "hours"
  //   );

  //   if (hoursSinceLogin.hours < 48) {
  //     return (
  //       <span>
  //         <FormattedDuration duration={hoursSinceLogin} />
  //       </span>
  //     );
  //   }
  //   return <FormattedDate date={lastLogin.created} pattern="yyyy-MM-dd" />;
  // };

  const isSorted = (property: string) => {
    if (!users.sort.sorted) {
      return undefined;
    }

    if (users.sort.property === property) {
      return users.sort.direction === "ASC" ? "ascending" : "descending";
    }

    return undefined;
  };

  return (
    <PageComponent
      padded
      title="User management"
      className="user-management-page"
    >
      <section>
        <article>
          <AddUserModal
            onSave={() => {
              onPageChange(0);
            }}
          />
        </article>

        <article>
          <Table className="m-top-20">
            <thead>
              <tr>
                <th />
                <th>
                  <button
                    className={isSorted("name")}
                    onClick={onSortChange}
                    data-id="name"
                  >
                    Name
                  </button>
                </th>
                <th>
                  <button
                    className={isSorted("email")}
                    onClick={onSortChange}
                    data-id="email"
                  >
                    Email
                  </button>
                </th>
                <th>
                  <button
                    className={isSorted("role")}
                    onClick={onSortChange}
                    data-id="role"
                  >
                    Role
                  </button>
                </th>
                <th>
                  <button
                    className={isSorted("created")}
                    onClick={onSortChange}
                    data-id="created"
                  >
                    Created
                  </button>
                </th>
                {/* <th>
                  <button
                    className={isSorted("lastLogin")}
                    onClick={onSortChange}
                    data-id="lastLogin"
                  >
                    Last Login
                  </button>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {users.content.map((row) => (
                <tr key={row.id}>
                  <td className="text-center">
                    {row.role !== UserRole.MANAGER && (
                      <Button
                        action
                        className="mini"
                        data={{
                          id: row.id,
                        }}
                        onClick={onEdit}
                      >
                        <Edit />
                      </Button>
                    )}
                  </td>
                  <td className="truncate">{row.name}</td>
                  <td className="truncate">{row.email}</td>
                  <td className="truncate">{row.role}</td>
                  <td className="truncate">
                    <FormattedDate date={row.created} pattern="yyyy-MM-dd" />
                  </td>
                  {/* <td className="truncate">{showLastLogin(row.lastLogin)}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination
            className="m-top-10"
            onPageChange={onPageChange}
            activePage={users.number}
            totalPages={users.totalPages}
          />
        </article>
      </section>
    </PageComponent>
  );
};
