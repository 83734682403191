import { RefObject, useEffect, useMemo } from "react";
import i18n, { ACTIVATED_LANGUAGES, Language } from "../../i18n";
import { ValidationProps } from "../form/hooks/useValidation";
import { Select } from "../form/Select";
import { getLanguageOpts } from "../translation/I18Utils";

interface Props extends ValidationProps {
  label: string;
  name: string;
  value?: string;
  hint?: string;
  disabled?: boolean;
  onChange: (
    value: string | undefined,
    name: string,
    event?: React.ChangeEvent
  ) => void;
  scrollToRef?: RefObject<HTMLElement>;
}

export const LanguageSelect = ({ value, onChange, ...props }: Props) => {
  const { language } = i18n;

  const languageAlternatives = useMemo(
    () => getLanguageOpts(true, language, ACTIVATED_LANGUAGES),
    [language]
  );

  // do not allow languages outside of the activated languages
  useEffect(() => {
    if (value && !ACTIVATED_LANGUAGES.includes(value as Language)) {
      onChange(undefined, props.name);
    }
  }, [value, onChange, props.name]);

  return (
    <Select
      value={value || undefined}
      alternatives={languageAlternatives}
      onChange={onChange}
      {...props}
    />
  );
};
