import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  CaseId,
  OnboardingCaseId,
  UTCDate,
} from "../../../data/models/CommonTypes";
import { CaseStatus } from "../../../data/models/caseTypes";
import { caseBeneficialOwnerState } from "../../../state/caseBeneficialOwnerState";
import { caseCompanyState } from "../../../state/caseCompanyState";
import {
  CaseStatusState,
  caseStatusState,
} from "../../../state/caseStatusState";
import { CasePageMenu } from "../../CasePageMenu";
import { CaseLoadWrapper } from "../CaseLoadWrapper";
import { Timeline } from "../signoff/Timeline/Timeline";
import { OwnerListItem } from "./ReviewOwnerListItem";
import { Claim } from "../case/claim/Claim";
import { SendToOnboarding } from "./SendToOnboarding";
import { T } from "../../../components/translation/T";
import { RetractCase } from "../case/RetractCase";
import "./CaseReviewPage.scss";
import { EditCase } from "../case/EditCase";
import { DownloadCase } from "../complete/DownloadCase";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { CloseCase } from "../../onboarding/CloseCase";
import { userState } from "../../../state/userState";
import { getUserIsAllowedToEdit } from "../case/CasePage";
import { useEffect, useState } from "react";
import { dataReview } from "../../../data/dataReview";
import { MissingColumbusData } from "./MissingColumbusData";

export const CASE_REVIEW_ROUTE = "/outreach/:id/review";

interface OnboardingStatus {
  isSent: boolean;
  id: OnboardingCaseId | undefined;
}

// cases reviewed before this date has not document_expiry_date saved
const beforeColumbusReleaseDate = "2023-07-26T00:00:00.000000Z";
const COLUMBUS_RELEASE_DATE = new Date(beforeColumbusReleaseDate);

const canConfirmDetails = (caseStatus: CaseStatusState): boolean => {
  const { status, hasCompleteKycData, edit } = caseStatus;
  const cannotConfirm =
    !edit || status !== CaseStatus.VERIFICATION || !hasCompleteKycData;
  return cannotConfirm ? false : true;
};

export const isCaseReviewedBeforeColumbus = (
  reviewedCompleted: UTCDate | undefined
): boolean => {
  if (!reviewedCompleted) return false;
  const reviewedDate = new Date(reviewedCompleted);
  return reviewedDate.getTime() <= COLUMBUS_RELEASE_DATE.getTime();
};

export const CaseReviewPage = () => {
  const { id } = useParams<{ id: CaseId }>();

  if (!id) {
    return null;
  }

  return (
    <CasePageMenu title="Case" className="case-page" caseId={id}>
      <section>
        <article>
          <CaseLoadWrapper caseId={id}>
            <CaseReviewPageInner />
          </CaseLoadWrapper>
        </article>
      </section>
    </CasePageMenu>
  );
};

const CaseReviewPageInner = () => {
  const company = useRecoilValue(caseCompanyState);
  const caseStatus = useRecoilValue(caseStatusState);
  const owners = useRecoilValue(caseBeneficialOwnerState);
  const user = useRecoilValue(userState);
  const isAllowedToEdit = getUserIsAllowedToEdit(caseStatus, user?.userId);

  const enableConfirmDetails = canConfirmDetails(caseStatus);

  const isCompletedStatuses =
    caseStatus.status === CaseStatus.COMPLETED_VERIFICATION ||
    caseStatus.status === CaseStatus.CLOSED;

  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus>({
    isSent: false,
    id: undefined,
  });

  useEffect(() => {
    dataReview
      .getOnboardingStatus(caseStatus.id)
      .then((resp) => {
        // if there is an onboardingCase, then it means that the case has been sent to onboarding
        setOnboardingStatus({ isSent: true, id: resp.id });
      })
      .catch(() => setOnboardingStatus({ isSent: false, id: undefined }));
  }, [caseStatus]);

  const renderSendToOnboardingSection = () => {
    if (caseStatus.status !== CaseStatus.COMPLETED_VERIFICATION) {
      return null;
    }
    if (onboardingStatus.isSent === true && onboardingStatus.id !== undefined) {
      return <SendToOnboarding isAllowedToEdit={isAllowedToEdit} />;
    }
    // case has NOT been sent to onboarding AND has also been reviewed before Columbus
    if (isCaseReviewedBeforeColumbus(caseStatus.reviewedCompleted)) {
      return <MissingColumbusData date={COLUMBUS_RELEASE_DATE} />;
    }
    // case has NOT been sent to onboarding but it was reviewed after Columbus
    return <SendToOnboarding isAllowedToEdit={isAllowedToEdit} />;
  };

  return (
    <div className="case-review-page">
      <h1>{company.legalName}</h1>
      <Claim />
      <div className="tablet-columns content">
        <div>
          <h4>
            <T>Timeline</T>
          </h4>
          <Timeline />
          {caseStatus.hasCompleteKycData === false &&
            caseStatus.status !== CaseStatus.CLOSED && (
              <div>
                <ErrorBox>
                  <T>
                    This case has incomplete information. Please ensure all
                    required information has been provided.
                  </T>
                </ErrorBox>
              </div>
            )}
        </div>

        <div>
          <h4>
            <T>Beneficial Owners</T>
          </h4>
          {owners.map((owner) => (
            <OwnerListItem
              caseId={caseStatus.id}
              caseStatusState={caseStatus}
              key={owner.id}
              owner={owner}
              enableConfirmDetails={enableConfirmDetails}
            />
          ))}
        </div>
      </div>
      <div className="tablet-columns">
        <div>
          <EditCase isAllowedToEdit={isAllowedToEdit} />
          {isCompletedStatuses && (
            <div className="download-case-column m-top-40">
              <DownloadCase id={caseStatus.id} company={company} />
            </div>
          )}
        </div>

        <div>
          {renderSendToOnboardingSection()}
          {isCompletedStatuses && (
            <>
              <hr />
              <CloseCase
                caseStatus={caseStatus}
                onboardingStatus={onboardingStatus}
              />
            </>
          )}
        </div>
      </div>
      <div className="tablet-columns">
        <div>
          <RetractCase />
        </div>
      </div>
    </div>
  );
};
