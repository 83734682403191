import { useState } from "react";
import { generatePath } from "react-router";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../../components/boxes/StatusBoxes";
import { ConfirmButton } from "../../../components/interactions/Buttons/ConfirmButton";
import { T } from "../../../components/translation/T";
import { dataOutreach } from "../../../data/dataOutreach";
import { CaseStatus } from "../../../data/models/caseTypes";
import { Status } from "../../../data/types";
import { caseStatusState } from "../../../state/caseStatusState";
import { userState } from "../../../state/userState";
import { CASE_ROUTE } from "./CasePage";

export function RetractCase() {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const caseStatus = useRecoilValue(caseStatusState);
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  function onRemove() {
    setStatus(Status.DEFAULT);
    dataOutreach
      .retract(caseStatus.id)
      .then(() => {
        navigate(
          generatePath(CASE_ROUTE, {
            id: caseStatus.id,
          })
        );
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }

  if (
    caseStatus.status !== CaseStatus.OUTREACH &&
    caseStatus.status !== CaseStatus.VERIFICATION
  ) {
    return null;
  }

  return (
    <div className="retract-case">
      {status === Status.ERROR && (
        <StatusBoxes status={status}>
          <ErrorBox relative>
            <T>We failed to retract the case. Try again?</T>
          </ErrorBox>
        </StatusBoxes>
      )}

      <div className="delete-warning">
        <strong>
          <T>Be aware!</T>
        </strong>
        <p>
          <T>
            All sent links will be rendered obsolete, all uploaded documents
            will be removed and the case will be placed in editing mode
          </T>
        </p>
        <ConfirmButton
          className="danger-button"
          onClick={onRemove}
          removeButtonText="Yes, retract"
          status={
            caseStatus.currentUser.id === user?.userId
              ? Status.DEFAULT
              : Status.DISABLED
          }
          small={false}
        >
          <T>Retract case</T>
        </ConfirmButton>
      </div>
    </div>
  );
}
