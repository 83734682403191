import { FC, useState, useRef } from "react";
import { CaseStaleReason } from "../../data/models/caseTypes";
import styles from "./StaleReason.module.scss";
import cx from "classnames";
import { T } from "../translation/T";
import { StaleReasonPopover } from "./StaleReasonPopover";
import { createPortal } from "react-dom";
import { Backdrop } from "../backdrop/Backdrop";
import { CaseId } from "../../data/models/CommonTypes";
import {
  MdAddCircle as AddIcon,
  MdOutlineRemoveCircleOutline as RemoveIcon,
} from "react-icons/md";
import { CaseStatus } from "../../data/models/caseTypes";
import { StaleReasonTooltip } from "./StaleReasonTooltip";

export interface CaseUser {
  id: string;
  name: string;
}

interface Props {
  caseStatus: CaseStatus | undefined;
  staleReason?: CaseStaleReason | null;
  caseId: CaseId;
  caseUser?: CaseUser;
  reloadOutreachCases: () => void;
}

const startPositon = {
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  bottom: 0,
};

export const StaleReasonChip: FC<Props> = ({
  staleReason = null,
  caseId,
  reloadOutreachCases,
  caseStatus,
  caseUser,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [chipPosition, setChipPosition] = useState(startPositon);
  const [chipDisabledPosition, setChipDisabledPosition] =
    useState(startPositon);

  const backdropRef = useRef<Element>(
    document.getElementById("backdrop-portal")!
  );
  const popoverRef = useRef<Element>(
    document.getElementById("popover-portal")!
  );
  const chipRef = useRef<HTMLButtonElement>(null);
  const chipDisabledRef = useRef<HTMLButtonElement>(null);

  const chipClass = cx(styles.chip, {
    [styles.chipStale]: staleReason !== null,
  });

  const chipDisabledClass = cx(styles.chip, styles.chipDisabled);

  const handleChipClick = () => {
    if (!chipRef.current) return;
    const DOMRect = chipRef.current?.getBoundingClientRect() || startPositon;
    setChipPosition(DOMRect);
    setPopoverOpen(true);
  };

  const handleDisabledChipHover = () => {
    if (!chipDisabledRef.current) return;
    const DOMRect =
      chipDisabledRef.current.getBoundingClientRect() || startPositon;
    setChipDisabledPosition(DOMRect);
    setTooltipOpen(true);
  };

  return (
    <div className={styles.root}>
      {caseStatus === CaseStatus.CLOSED ? (
        <button
          ref={chipDisabledRef}
          className={chipDisabledClass}
          onFocus={handleDisabledChipHover}
          onBlur={() => setTooltipOpen(false)}
        >
          <T>N/A</T>
        </button>
      ) : (
        <button className={chipClass} onClick={handleChipClick} ref={chipRef}>
          <div className={styles.chipText}>
            <T>
              {staleReason !== null ? staleReasonToString(staleReason) : "None"}
            </T>
          </div>
          <div className={styles.icon}>
            {staleReason ? <RemoveIcon /> : <AddIcon />}
          </div>
        </button>
      )}
      {createPortal(
        <StaleReasonTooltip
          chipPosition={chipDisabledPosition}
          open={tooltipOpen}
        />,
        popoverRef.current
      )}
      {createPortal(
        <StaleReasonPopover
          caseId={caseId}
          chipPosition={chipPosition}
          open={popoverOpen}
          selectedReason={staleReason}
          onClose={() => setPopoverOpen(false)}
          reloadOutreachCases={reloadOutreachCases}
          caseUser={caseUser}
        />,
        popoverRef.current
      )}
      {createPortal(
        <Backdrop
          open={popoverOpen}
          onClick={() => setPopoverOpen(!popoverOpen)}
        />,
        backdropRef.current
      )}
    </div>
  );
};

export const staleReasonToString = (reason: CaseStaleReason) => {
  switch (reason) {
    case CaseStaleReason.DO_NOT_COOPERATE:
      return "Do not cooperate";
    case CaseStaleReason.HAVE_CANCELLED:
      return "Have cancelled";
    case CaseStaleReason.UNREACHABLE:
      return "Unreachable";
    case CaseStaleReason.REMAIN_IN_AWAITING_REPLY:
      return "Remain in awaiting reply";
    case CaseStaleReason.ERROR_IN_CONTRACT:
      return "Error in contract";
    case CaseStaleReason.GIVEBACK_RECEIVED:
      return "Giveback received";
    case CaseStaleReason.GIVEBACK_IN_PROGRESS:
      return "Giveback in progress";
    case CaseStaleReason.GIVEBACK_RESOLVED:
      return "Giveback resolved";
    case CaseStaleReason.AWAITING_MANUAL_UBO_REGISTRATION:
      return "Awaiting manual UBO registration";
    case CaseStaleReason.MANUAL_UBO_REGISTRATION_FINISHED:
      return "Manual UBO registration finished";
    default:
      return reason;
  }
};
