import cx from "classnames";
import "./Card.scss";

interface Props extends React.PropsWithChildren {
  className?: string;
  accent?: boolean;
  border?: boolean;
}

interface HeaderProps extends React.PropsWithChildren {
  header?: string | React.ReactNode;
  className?: string;
  border?: boolean;
}

export const Card: React.FunctionComponent<Props> & {
  Header: React.FunctionComponent<HeaderProps>;
} = ({ accent, border, children, className }) => {
  return (
    <div
      className={cx("card", className, {
        "--accent": accent,
        "--border": border,
      })}
    >
      {children}
    </div>
  );
};

export const CardHeader: React.FunctionComponent<HeaderProps> = ({
  header,
  children,
}) => {
  return (
    <div className="card-header">
      {header && <h5>{header}</h5>}
      {children}
    </div>
  );
};

Card.Header = CardHeader;
