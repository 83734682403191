import { useState, useEffect } from "react";
import { dataOnboarding } from "../../data/dataOnboarding";
import { MerchantCategory } from "../../data/models/caseTypes";
import { MCCInfo } from "./MCCInfo";
import cx from "classnames";
import "./MCCInfoList.scss";

export const MCCInfoList = ({ mccs }: { mccs: string[] }) => {
  const [info, setInfo] = useState<MerchantCategory[]>([]);

  useEffect(() => {
    if (mccs.length < 1) {
      return;
    }
    dataOnboarding.getMccs(mccs).then((response) => {
      setInfo(response);
    });
  }, [mccs]);

  return (
    <div className="mcc-info-list">
      {mccs.map((mcc) => (
        <div className={cx("mcc-info", "text-small", "line-small")} key={mcc}>
          <MCCInfo mcc={mcc} info={info.find(({ code }) => code === mcc)} />
        </div>
      ))}
    </div>
  );
};
