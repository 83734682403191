import { BaseValidator } from "./BaseValidator";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { cleanNonDigits } from "../../verticalNav/CompanySearchOverlay";

const ICO_LENGTH = 8;

export class IcoValidator extends BaseValidator {
  name = "ICO validator";

  validate(value: string): Promise<ValidationResponse> {
    const cleanValue = cleanNonDigits(value);
    return new Promise<ValidationResponse>((resolve) => {
      if (validateICO(cleanValue)) {
        resolve({
          status: VALIDATION_STATE.SUCCESS,
        });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: cleanValue.length < 8,
        });
      }
    });
  }
}

export function validateICO(ico: string) {
  const length = ico.length;
  if (length !== ICO_LENGTH) {
    return false;
  }

  let sum = 0;
  let multiplier = 8;

  for (let i = 0; i < 7; i++) {
    let value = Number(ico[i]);
    sum += value * multiplier;
    multiplier--;
  }

  const checksum = (11 - (sum % 11)) % 10;
  const checkdigit = Number(ico[7]);
  return checksum === checkdigit;
}
