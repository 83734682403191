import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ZoomableImage } from "../../../../components/images/ZoomableImage";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Name } from "../../../../components/name/Name";
import { T } from "../../../../components/translation/T";
import { Visible } from "../../../../components/visible/Visible";
import { dataReview } from "../../../../data/dataReview";
import { CaseId } from "../../../../data/models/CommonTypes";
import { BeneficialOwner } from "../../../../data/models/caseTypes";
import { Status } from "../../../../data/types";
import { ImageToggler } from "./components/ImageToggler";
import { IdCard } from "./idType/IdCard";
import { Passport } from "./idType/Passport";
import { ReviewRejectWrapper } from "./ReviewRejectWrapper";

export interface Props {
  owner: BeneficialOwner;
  caseId: CaseId;
  completed: () => void;
}

export const AssociateIdCardReview: React.FunctionComponent<Props> = ({
  owner,
  caseId,
  completed,
}) => {
  const { identity } = owner;
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [done, setDone] = useState<boolean>();
  const { t } = useTranslation();

  const onReject = () => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataReview
        .rejectOwner(owner)
        .then(() => setStatus(Status.SUCCESS))
        .catch((err) => setStatus(Status.ERROR));
    }, 600);
  };

  const printIdImages = () => {
    if (!identity) {
      return null;
    }

    if (identity.passport) {
      return (
        <div className="associate-review-passport">
          <ZoomableImage image={identity.passport} alt="Passport" />
        </div>
      );
    }

    if (identity.idFront && identity.idBack) {
      return <ImageToggler images={[identity.idFront, identity.idBack]} />;
    }

    return null;
  };

  if (!identity) {
    return null;
  }

  const isIdCard = identity.idFront !== undefined;

  return (
    <div className="associate-review">
      <div className="associate-review-top">
        <h2>
          <Name associate={owner} />
        </h2>
      </div>
      <div className="review-box m-top-40">
        <div className="tablet-columns">
          <div>
            <div className="associate-review-passport m-bottom-10">
              {printIdImages()}
            </div>
          </div>
          <div>
            <h3>{isIdCard ? t("Id Card") : t("Passport")}</h3>

            <ReviewRejectWrapper
              onReject={onReject}
              status={status}
              caseId={caseId}
            >
              {isIdCard ? (
                <IdCard setDone={setDone} />
              ) : (
                <Passport setDone={setDone} />
              )}
              <div className="m-top-40 relative">
                <div className="review-actions relative">
                  <Visible show={done === true}>
                    <Button onClick={completed} block>
                      <T>Continue</T>
                    </Button>
                  </Visible>

                  <Visible show={done === false}>
                    <Button onClick={onReject} block className="danger-button">
                      <T>Reject uploaded ID</T>
                    </Button>
                  </Visible>
                </div>
              </div>
            </ReviewRejectWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
