import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserRole } from "../../data/dataAuth";
import { Language } from "../../i18n";
import { translationStateActiveSelector } from "../../state/translationState";
import { userState } from "../../state/userState";
import { Button } from "../interactions/Buttons/Button";
import { Link } from "../links/Link";
import { NewOverlay } from "../overlay/NewOverlay";
import { getLanguageName } from "../translation/I18Utils";

const MERCHANT_TRANSLATION_URL = `${process.env.REACT_APP_OUTREACH_URL}/TEST`;

export const TranslationNav = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const user = useRecoilValue(userState);
  const [showLanguageWarning, setShowLanguageWarning] = useState(false);

  const [isTranslationModeActive, setTranslationModeActive] = useRecoilState(
    translationStateActiveSelector
  );

  const hasTranslationPermission = useMemo(
    () => user?.role === UserRole.MANAGER,
    [user]
  );

  const toggleTranslationManagement = useCallback(() => {
    if (language === Language.ENGLISH) {
      setShowLanguageWarning(true);
      return;
    }
    setTranslationModeActive(!isTranslationModeActive);
  }, [isTranslationModeActive, language, setTranslationModeActive]);

  const merchantTranslationUrl = useMemo(
    () => `${MERCHANT_TRANSLATION_URL}?lang=${language}`,
    [language]
  );

  return (
    <>
      {hasTranslationPermission && (
        <>
          <NewOverlay
            open={showLanguageWarning}
            onClose={() => setShowLanguageWarning(false)}
          >
            <h4>Language can't be translated</h4>
            <p>
              <strong>{getLanguageName(language as Language)}</strong> is not
              available for translation, change to another language to start
              translation mode.
            </p>
            <Button block onClick={() => setShowLanguageWarning(false)}>
              Close
            </Button>
          </NewOverlay>
          <li className="logout-list-item">
            <Button
              block
              ghost={!isTranslationModeActive}
              onClick={toggleTranslationManagement}
            >
              Translation mode
            </Button>
            {isTranslationModeActive && (
              <Link
                className="as-button ghost block m-top-20 small"
                external
                link={merchantTranslationUrl}
              >
                Translate outreach
              </Link>
            )}
          </li>
        </>
      )}
    </>
  );
};
