import { CompanyRegistrationId } from "../../data/models/CommonTypes";
import { Country } from "../../i18n";
import { patterFormatter } from "react-number-format";

interface Props {
  country: Country;
  id: CompanyRegistrationId;
}

// empty will fallback to no formatting
const companyRegistrationIdFormatMap: Record<Country, string> = {
  [Country.BELGIUM]: "####.###.###",
  [Country.CZECH_REPUBLIC]: "##########",
  [Country.SLOVAKIA]: "##########",
  [Country.FRANCE]: "### ### ### #####",
  [Country.LUXEMBURG]: "",
  [Country.NETHERLANDS]: "########",
  [Country.UNITED_KINGDOM]: "",
  [Country.GERMANY]: "",
};

export const CompanyRegistrationIdDisplay = ({ country, id }: Props) => {
  if (!country) {
    return <>{id}</>;
  }

  return (
    <>
      {patterFormatter(id, {
        format: companyRegistrationIdFormatMap[country] || id,
      })}
    </>
  );
};

export const companyRegistrationIdString = (
  country: Country,
  id: CompanyRegistrationId
) => {
  return "";
};
