import React, { ReactNode } from "react";
import { MenuItem as MenuItemElem } from "./MenuItem";
import "./Menu.scss";

export interface MenuItem {
  name: string | ReactNode;
  link: string;
  disabled?: boolean;
  active?: boolean;
  error?: boolean;
}

interface Props {
  menuItems: MenuItem[];
}

export const Menu: React.FunctionComponent<Props> = ({ menuItems }) => {
  if (menuItems.length === 0) {
    return null;
  }

  return (
    <nav className="menu">
      <ul>
        {menuItems.map((item, index) => (
          <MenuItemElem key={item.link + index} menuItem={item} />
        ))}
      </ul>
    </nav>
  );
};
