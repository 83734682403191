import { Status } from "../../data/types";
import { T } from "../translation/T";
import { TransSwitch } from "../translation/TransSwitch";

export const CreateCaseButtonText = ({ status }: { status?: Status }) => {
  return (
    <TransSwitch>
      <T.Condition condition={status === Status.PENDING}>
        Creating case
      </T.Condition>
      <T.Condition condition={status === Status.ERROR}>
        Something went wrong
      </T.Condition>
      <T.Condition condition={status === Status.SUCCESS}>
        Case created
      </T.Condition>
      <T.Default>Create a new case</T.Default>
    </TransSwitch>
  );
};
