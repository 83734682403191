import cx from "classnames";
import { motion } from "framer-motion";
import React from "react";
import { FaRegBuilding } from "react-icons/fa";
import {
  CaseStatusDisplay,
  caseStatusToString,
} from "../../../components/caseStatus/CaseStatus";
import { CompanyRegistrationIdDisplay } from "../../../components/companyRegistrationId/CompanyRegistrationIdDisplay";
import { Flag } from "../../../components/flags/Flag";
import { Link } from "../../../components/links/Link";
import { T } from "../../../components/translation/T";
import { getIntlDate } from "../../../components/utils";
import { UserSession } from "../../../data/dataAuth";
import { DashboardCase } from "../../../data/dataDashboard";
import { UTCDate } from "../../../data/models/CommonTypes";
import { CaseStaleReason, CaseStatus } from "../../../data/models/caseTypes";
import { caseLink } from "../../../util/CaseLink";
import { companyLink } from "../../../util/CompanyLink";
import "./FilterRow.scss";
import {
  StaleReasonChip,
  staleReasonToString,
} from "../../../components/staleReasonChip/StaleReasonChip";
import { DocumentChip } from "../../../components/documentChip/DocumentChip";

export interface DashboardItem
  extends Pick<DashboardCase, "caseId" | "caseType" | "created">,
    Partial<
      Pick<
        DashboardCase,
        | "status"
        | "currentUser"
        | "companyRegistrationId"
        | "companyName"
        | "country"
        | "staleReason"
        | "closedReason"
        | "documents"
      >
    > {
  updated?: UTCDate;
  closed?: UTCDate;
}

interface Props {
  outreachCase: DashboardItem;
  user: UserSession | null;
  reloadOutreachCases: () => void;
  documentSearchResult: string[];
}

function resolveRepresentativeName(caseStatus?: CaseStatus, name?: string) {
  if (!caseStatus || caseStatus === CaseStatus.CLOSED) {
    return "-";
  }

  if (!name) {
    return <T>Not assigned</T>;
  }

  return name;
}

function resolveStaleReasonName(
  caseStatus?: CaseStatus,
  staleReason?: CaseStaleReason
) {
  if (!caseStatus || caseStatus === CaseStatus.CLOSED) {
    return "Not available";
  }

  if (!staleReason) {
    return "Not assigned";
  }

  return staleReasonToString(staleReason);
}

export const FilterRow: React.FunctionComponent<Props> = ({
  user,
  outreachCase,
  reloadOutreachCases,
  documentSearchResult,
}) => {
  const status = outreachCase.status || CaseStatus.CLOSED;

  const { companyRegistrationId, companyName, country, caseId, documents } =
    outreachCase;

  const displayName = companyName || companyRegistrationId;
  return (
    <motion.tr
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={cx("case case-cells", {
        highlighted: user?.userId === outreachCase.currentUser?.id,
      })}
    >
      <td className="case-cell company-name" title={outreachCase.companyName}>
        <div>
          <Link link={caseLink(outreachCase.caseId, status)}>
            {country && <Flag height={16} rounded country={country} />}
            {displayName}
          </Link>
        </div>
      </td>
      <td className="case-cell company-id" title={caseStatusToString(status)}>
        <div>
          {companyRegistrationId && country && (
            <Link link={companyLink(country, companyRegistrationId)}>
              <FaRegBuilding
                style={{
                  display: "inline-block",
                  marginRight: 4,
                  marginBottom: -3,
                }}
              />
              <CompanyRegistrationIdDisplay
                id={companyRegistrationId}
                country={country}
              />
            </Link>
          )}
        </div>
      </td>

      <td className="case-cell status" title={caseStatusToString(status)}>
        <span className="case-status-cell">
          <span>
            <CaseStatusDisplay
              status={status}
              caseCloseReason={outreachCase.closedReason}
            />
          </span>
        </span>
        <div>
          <CaseStatusDisplay
            status={status}
            caseCloseReason={outreachCase.closedReason}
          />
        </div>
      </td>
      <td className="case-cell company-name" title={"documents"}>
        <DocumentChip
          documents={documents}
          documentSearchResult={documentSearchResult}
        />
      </td>
      <td
        className="case-cell stale"
        title={resolveStaleReasonName(
          outreachCase.status,
          outreachCase.staleReason
        )}
      >
        <div className="case-stale-cell">
          <StaleReasonChip
            caseStatus={status}
            caseId={caseId}
            staleReason={outreachCase.staleReason}
            caseUser={outreachCase.currentUser}
            reloadOutreachCases={reloadOutreachCases}
          />
        </div>
      </td>
      <td
        className="case-cell sales-user"
        title={outreachCase.currentUser?.name || "Not assigned"}
      >
        <div>
          {resolveRepresentativeName(
            outreachCase.status,
            outreachCase.currentUser?.name
          )}
        </div>
      </td>
      <td className="case-cell created" title={outreachCase.created || " - "}>
        <div>
          {outreachCase.created ? getIntlDate(outreachCase.created) : " - "}
        </div>
      </td>
      <td className="case-cell updated" title={outreachCase.updated || " - "}>
        <div>
          {outreachCase.closed
            ? getIntlDate(outreachCase.closed)
            : outreachCase.updated
            ? getIntlDate(outreachCase.updated)
            : " - "}
        </div>
      </td>
    </motion.tr>
  );
};
