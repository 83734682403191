import React, {
  useCallback,
  ReactNode,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import cx from "classnames";
import { Close } from "../icons/Close";
import { Button } from "../interactions/Buttons/Button";
import { Status } from "../../data/types";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
import "./Overlay.scss";
import { useEditSidebarWidth } from "../../hooks/useEditSidebarWidth";
import { motion } from "framer-motion";

interface Props {
  open: boolean;
  children: ReactNode;
  onClose: (event?: MouseEvent) => void;
  disableClose?: boolean;
  status?: Status;
  className?: string;
}

const ESC = 27;

export const Inner: React.FunctionComponent<{
  children: ReactNode;
  tryClose: () => void;
}> = ({ children, tryClose }) => {
  const offset = useEditSidebarWidth();

  if (!children) {
    return null;
  }

  return (
    <>
      <motion.div
        id="overlay-backdrop"
        onClick={tryClose}
        style={{
          right: offset,
        }}
      >
        <dialog className="overlay" open>
          <Button
            type="button"
            className="overlay-close action ghost small"
            onClick={tryClose}
          >
            <Close />
          </Button>
          <div className="overlay-body">{children}</div>
        </dialog>
      </motion.div>
    </>
  );
};

export const Overlay: React.FunctionComponent<Props> = ({
  open,
  children,
  onClose,
  disableClose = false,
  status,
  className,
}) => {
  const [elems, setElems] = useState<ReactNode>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const dialogRef = useRef<HTMLDivElement>(null);

  const onTransitionEnd = useCallback(
    (ev: TransitionEvent) => {
      if ((ev.target as HTMLElement).classList.contains("dialog-wrapper")) {
        if (ev.propertyName !== "opacity") {
          return;
        }

        if (!open) {
          setElems(null);
        }
      }
    },
    [open]
  );

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    const ref = dialogRef.current;
    ref.addEventListener("transitionend", onTransitionEnd);

    return () => {
      ref.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [onTransitionEnd]);

  const tryClose = useCallback(
    (event?: MouseEvent) => {
      event?.preventDefault();

      if (disableClose) {
        return;
      }

      if (event && event.target !== event.currentTarget) {
        return;
      }

      onClose(event);
    },
    [onClose, disableClose]
  );

  useEffect(() => {
    if (open) {
      setElems(children);
    } else {
      setShowDialog(false);
    }
  }, [open, children]);

  useLayoutEffect(() => {
    if (elems) {
      setShowDialog(true);
    }
  }, [elems]);

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    if (open) {
      disableBodyScroll(dialogRef.current);
    } else {
      enableBodyScroll(dialogRef.current);
    }
  }, [open]);

  const handler = useCallback(
    (ev: KeyboardEvent) => {
      if (disableClose) {
        return;
      }

      if (ev.keyCode === ESC) {
        (document.activeElement as HTMLElement).blur();
        onClose();
      }
    },
    [onClose, disableClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handler, false);
    return () => {
      window.removeEventListener("keydown", handler, false);
    };
  }, [handler]);

  const classes = cx("dialog-wrapper", status, className, {
    "is-active": showDialog,
  });

  return (
    <div className={classes} ref={dialogRef}>
      <Inner tryClose={tryClose}>{elems}</Inner>
    </div>
  );
};
