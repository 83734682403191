import { BaseValidator } from "./BaseValidator";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { cleanNonDigits } from "../../verticalNav/CompanySearchOverlay";

const CBE_LENGTH = 10;

export class CBEValidator extends BaseValidator {
  name = "CBE validator";

  validate(value: string): Promise<ValidationResponse> {
    const cleanValue = cleanNonDigits(value);
    return new Promise<ValidationResponse>((resolve) => {
      if (validateCBE(cleanValue)) {
        resolve({
          status: VALIDATION_STATE.SUCCESS,
        });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: cleanValue.length < 10,
        });
      }
    });
  }
}

export function validateCBE(cbe: string) {
  const length = cbe.length;
  if (length !== CBE_LENGTH) {
    return false;
  }

  const firstPart = Number(cbe.substring(0, 8));
  const lastPart = Number(cbe.substring(8));
  const result = 97 - (firstPart - Math.trunc(firstPart / 97) * 97);
  if (result === 0) {
    return lastPart === 97;
  } else {
    return lastPart === result;
  }
}
