import { useState, useCallback, useEffect } from "react";
import { OutreachLookupStatus, dataOutreach } from "../../data/dataOutreach";
import { CaseId, CompanyRegistrationId } from "../../data/models/CommonTypes";
import { Status } from "../../data/types";
import { Country } from "../../i18n";

const POLL_INTERVAL = 2000;
const ERROR_TIMEOUT = 5000;

export const useCreateCase = (
  companyRegistrationId?: CompanyRegistrationId,
  country?: Country
) => {
  const [lookupId, setLookupId] = useState<string>();
  const [status, setStatus] = useState<Status>();
  const [newCaseId, setNewCaseId] = useState<CaseId>();
  const [existingCaseId, setExistingCaseId] = useState<CaseId>();
  const [, setLookupStatus] = useState<OutreachLookupStatus>();

  useEffect(() => {
    if (companyRegistrationId && country) {
      setStatus(Status.DEFAULT);
      setLookupId(undefined);
      setNewCaseId(undefined);
      setExistingCaseId(undefined);
      setLookupStatus(undefined);
    }
  }, [companyRegistrationId, country]);

  const createNewCase = () => {
    if (!companyRegistrationId || !country) {
      return;
    }

    setStatus(Status.PENDING);
    dataOutreach
      .create(companyRegistrationId, country)
      .then(({ lookupId, caseId }) => {
        if (caseId) {
          setStatus(Status.SUCCESS);
          setExistingCaseId(caseId);
        } else {
          setLookupId(lookupId);
        }
      })
      .catch((e) => {
        console.error(e);
        setStatus(Status.ERROR);
        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, ERROR_TIMEOUT);
      });
  };

  const handleError = useCallback(() => {
    setStatus(Status.ERROR);
    setTimeout(() => {
      setStatus(Status.DEFAULT);
    }, ERROR_TIMEOUT);
  }, []);

  const pollCase = useCallback(() => {
    if (!lookupId) {
      return;
    }
    dataOutreach
      .poll(lookupId)
      .then(({ status, caseId }) => {
        setLookupStatus(status);
        if (status === OutreachLookupStatus.FAILED) {
          handleError();
        }
        if (status === OutreachLookupStatus.COMPLETE && caseId) {
          setStatus(Status.SUCCESS);
          setNewCaseId(caseId);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [handleError, lookupId]);

  useEffect(() => {
    if (lookupId) {
      setLookupStatus(OutreachLookupStatus.PENDING);
      const interval = setInterval(async () => {
        pollCase();
      }, POLL_INTERVAL);

      return () => clearInterval(interval);
    } else {
      setStatus(Status.DEFAULT);
      setLookupStatus(undefined);
    }
  }, [lookupId, pollCase]);

  const reset = useCallback(() => {
    setStatus(Status.DEFAULT);
    setLookupId(undefined);
    setNewCaseId(undefined);
    setExistingCaseId(undefined);
    setLookupStatus(undefined);
  }, []);

  return {
    lookupId,
    createNewCase,
    status,
    newCaseId,
    existingCaseId,
    reset,
  };
};
