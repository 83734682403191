import React, { FunctionComponent, ReactNode, useState } from "react";
import { Overlay } from "../overlay/Overlay";
import { MdOutlineLiveHelp } from "react-icons/md";
import "./HelpModal.scss";

interface Props {
  children: ReactNode;
}

export const HelpModal: FunctionComponent<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <React.Fragment>
      <button
        type="button"
        className="help-modal-button"
        onClick={() => setIsOpen(true)}
      >
        <MdOutlineLiveHelp color={"var(--link)"} size={24} />
      </button>
      <Overlay open={isOpen} onClose={() => setIsOpen(false)}>
        {children}
      </Overlay>
    </React.Fragment>
  );
};
