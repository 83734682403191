import React from "react";
import {
  CaseClosedReason,
  CaseStatus as Type,
} from "../../data/models/caseTypes";
import { T } from "../translation/T";
import cx from "classnames";
import styles from "./CaseStatus.module.scss";

interface Props {
  status: Type;
  caseCloseReason?: CaseClosedReason | undefined;
}

function toEngKey(status: Type) {
  switch (status) {
    case Type.DATA_COLLECTION:
      return "Creating case";
    case Type.OUTREACH:
      return "Awaiting reply";
    case Type.VERIFICATION:
      return "Pending review";
    case Type.COMPLETED_VERIFICATION:
      return "Review Completed";
    case Type.CLOSED:
      return "Closed";
    default:
      return "Closed";
  }
}

export const CaseStatusDisplay: React.FunctionComponent<Props> = ({
  status,
  caseCloseReason,
}) => {
  const getStatusText = () => {
    if (status === Type.CLOSED && caseCloseReason)
      return caseCloseReasonToString(caseCloseReason);
    return toEngKey(status);
  };

  const rootClass = cx(styles.root, {
    [styles.discarded]:
      status === Type.CLOSED && caseCloseReason === CaseClosedReason.DISCARDED,
    [styles.finalized]:
      status === Type.CLOSED &&
      caseCloseReason === CaseClosedReason.CASE_FINALIZED,
  });

  return (
    <span className={rootClass}>
      <T>{getStatusText()}</T>
    </span>
  );
};

export function caseStatusToString(status: Type) {
  return toEngKey(status);
}

export function caseCloseReasonToString(closeReason: CaseClosedReason) {
  switch (closeReason) {
    case CaseClosedReason.CASE_FINALIZED:
      return "Case finalized";
    case CaseClosedReason.DISCARDED:
      return "Discarded";
    case CaseClosedReason.OUTREACH_REJECTED:
      return "Outreach rejected";
    case CaseClosedReason.REGISTRY_NOT_VALID:
      return "Registry not valid";
    default:
      return "Closed";
  }
}
