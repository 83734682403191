import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRecoilValue } from "recoil";
import { translationStateActiveSelector } from "../../state/translationState";

export interface SwitchContextProps {
  ids: string[];
  setIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SwitchContext = createContext<SwitchContextProps>(
  undefined as unknown as SwitchContextProps
);

export const SwitchContextProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [ids, setIds] = useState<string[]>([]);

  return (
    <SwitchContext.Provider value={{ ids, setIds }}>
      {children}
    </SwitchContext.Provider>
  );
};

export function useSwitch(id: string) {
  const translationModeActive = useRecoilValue(translationStateActiveSelector);
  const context = useContext(SwitchContext);
  const setIds = context?.setIds;

  useEffect(() => {
    if (typeof setIds === "undefined") {
      return;
    }

    setIds((previousState) => {
      return [...previousState, id];
    });

    return () => {
      setIds((previousState) => {
        if (previousState.includes(id)) {
          const oldIndex = previousState.indexOf(id);
          const arrayCopy = [...previousState].splice(oldIndex, 1);
          return arrayCopy;
        }
        return previousState;
      });
    };
  }, [id, setIds]);

  return translationModeActive;
}

export function useAllIds() {
  const context = useContext(SwitchContext);

  if (typeof context === "undefined") {
    return [];
  }

  return context.ids;
}
