import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { Dynamic, Status } from "../../../data/types";
import { id } from "../../utils";
import "./Base.scss";
import "./Input.scss";
import { Checkmark } from "../../icons/Checkmark";
import { Disabled } from "../../icons/Disabled";
import { Pending } from "../../icons/Pending";
import { Error } from "../../icons/Error";
import { T } from "../../translation/T";

export interface Props {
  status?: Status;
  className?: string;
  value?: string;
  placeholder?: string;
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  onChange: (
    value: string,
    name: string,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onBlur?: (value: string, name: string) => void;
  name?: string;
  message?: string | React.ReactNode;
  autoFocus?: boolean;
  attributes?: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >;
  required?: boolean;
  autocomplete?: string;
}

export interface StatusMessage {
  status: Status;
  message?: ReactNode | string;
}

function translate(v?: string | React.ReactNode) {
  if (!v) {
    return v;
  }
  if (typeof v === "string") {
    return <T>{v}</T>;
  }
  return v;
}

function translateOrEmpty(v?: string | React.ReactNode) {
  if (!v) {
    return <div className="invisible">empty</div>;
  }
  return translate(v);
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      status = Status.DEFAULT,
      className,
      name,
      label = null,
      value = "",
      onChange,
      onBlur,
      message = null,
      hint = null,
      placeholder = "",
      autoFocus,
      attributes = {},
      required,
      autocomplete,
    },
    ref
  ) => {
    const identifier = useRef<string>(id());
    const prevValue = useRef<string | undefined>(value);
    const [internalStatus, setInternalStatus] = useState<StatusMessage>({
      status: Status.DEFAULT,
    });

    const change = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.currentTarget.value, name || identifier.current, event);
        setTimeout(() => {
          setInternalStatus((prevStatus) => ({
            ...prevStatus,
            status: Status.DEFAULT,
          }));
        }, 0);
      },
      [onChange, name]
    );

    const onFocus = useCallback(() => {
      prevValue.current = value;
    }, [value]);

    const blur = useCallback(() => {
      if (prevValue.current === value) {
        return;
      }

      onBlur && onBlur(value, name || identifier.current);
    }, [onBlur, value, name]);

    const props: Dynamic = attributes;

    let consolidatedMessage = message;
    let consolidatedStatus = status;
    if (status === Status.DEFAULT) {
      consolidatedStatus = internalStatus.status;
      consolidatedMessage = internalStatus.message || "";
    }

    return (
      <label
        className={cx("input", "input-text", className, consolidatedStatus)}
        htmlFor={identifier.current}
      >
        <div className="input-label-wrapper">
          <div className="input-label">
            <div className="input-label-tag">{translateOrEmpty(label)}</div>
          </div>
          {!value && required && (
            <div className="required-marker text-small">
              <T>required</T>
            </div>
          )}
        </div>

        <div className="input-frame">
          <textarea
            name={name || identifier.current}
            id={identifier.current}
            value={value}
            onChange={change}
            onBlur={blur}
            onFocus={onFocus}
            placeholder={placeholder}
            disabled={status === Status.DISABLED}
            autoFocus={autoFocus}
            autoComplete={autocomplete}
            ref={ref}
            {...props}
          />

          <div className="input-status">
            <Checkmark />
            <Disabled />
            <Pending />
            <Error />
          </div>
        </div>

        <div className="input-messages">
          <div className="input-hint">{translate(hint)}</div>
          <div className="input-message">{translate(consolidatedMessage)}</div>
        </div>
      </label>
    );
  }
);
