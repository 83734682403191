import cx from "classnames";
import { FunctionComponent, useState } from "react";
import { ZoomableImage } from "../../../../../components/images/ZoomableImage";
import { IdScan } from "../../../../../data/models/caseTypes";
import { ExtractedIdText } from "./ExtractedIdText";
import "./ImageToggler.scss";

interface Props {
  images: string[];
  //Inte jättesnyggt att man är beroende av den här, det blir fulhackat..
  showIdText?: boolean;
  showTextBlocks?: boolean;
  idScan?: IdScan;
}

export const ImageToggler: FunctionComponent<Props> = ({
  images,
  showIdText = false,
  showTextBlocks = false,
  idScan,
}) => {
  const [activeImage, setActiveImage] = useState(0);

  if (images.length === 0) {
    return null;
  }

  const textBlocks = activeImage === 0 ? idScan?.idFront : idScan?.idBack;

  return (
    <div className="image-toggler">
      <div className="image-toggler__image-wrapper">
        {showIdText ? (
          <ExtractedIdText blocks={textBlocks} />
        ) : (
          <ZoomableImage
            image={images[activeImage]}
            showTextBlocks={showTextBlocks}
            alt="Photo of identification document"
            textBlocks={textBlocks}
          />
        )}
      </div>
      <div className="image-toggler__thumbnails">
        {images.map((image, index) => (
          <button
            key={index}
            type="button"
            onClick={() => {
              setActiveImage(index);
            }}
            className={cx("image-toggler__button", {
              active: index === activeImage,
            })}
          >
            <img
              src={image}
              alt={`Thumbnail of identification document ${index + 1}`}
            />
          </button>
        ))}
      </div>
    </div>
  );
};
