import { AnimatePresence, motion, Variants } from "framer-motion";
import { useRecoilValue } from "recoil";
import { Notifier } from "../../../components/notifier/Notifier";
import { caseStatusState } from "../../../state/caseStatusState";
import { BeneficialOwners } from "./beneficialOwners/BeneficialOwners";
import { Claim } from "./claim/Claim";
import { CompanyData } from "./companyData/CompanyData";
import { DeleteCase } from "./DeleteCase";
import { FinancialData } from "./financialData/FinancialData";
import { ManualCompanyData } from "./manualCompanyData/ManualCompanyData";
import { SendForConfirmation } from "./SendForConfirmation";
import { CaseType } from "../../../data/models/caseTypes";
import { DocumentCollection } from "./documents/DocumentCollection";
import { CompanyInformation } from "./CompanyInformation";
import { CaseStepper } from "./CaseStepper";
import { IsLegacyCase } from "./IsLegacyCase";

export const LinkAnchors = {
  DOCUMENT_COLLECTION: {
    name: "Document upload",
    anchor: "document-collection",
  },
  COMPANY_DATA: { name: "General information", anchor: "company-data" },
  FINANCIAL_DATA: { name: "Financial information", anchor: "financial-data" },
  OWNERS: { name: "Beneficial Owners", anchor: "owners" },
};

export const CaseEdit = () => {
  const { needManualCompanyData, type } = useRecoilValue(caseStatusState);

  const isNewMerchant = type === CaseType.NEW_CUSTOMER;

  return (
    <div className="case-edit">
      <Notifier />

      <Claim />

      <hr />
      <hr />

      <AnimatePresence initial={false} mode="wait">
        {needManualCompanyData ? (
          <motion.div
            style={{ transformOrigin: "top center" }}
            variants={variants}
            initial="initial"
            animate="show"
            exit="exit"
            key="manual-company-data"
          >
            <ManualCompanyData />

            <DeleteCase />
          </motion.div>
        ) : (
          <motion.div
            style={{ transformOrigin: "top center" }}
            variants={variants}
            initial="initial"
            animate="show"
            exit="exit"
            key="case-data"
          >
            <CompanyInformation />

            <hr />

            <CaseStepper />

            <hr />

            <IsLegacyCase />

            {isNewMerchant && (
              <>
                <DocumentCollection />

                <hr />
                <hr />
              </>
            )}

            <CompanyData />

            <hr />
            <hr />

            <FinancialData />

            <hr />
            <hr />

            <BeneficialOwners />

            <hr />
            <hr />

            <SendForConfirmation />

            <DeleteCase />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const variants: Variants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 20,
    },
  },
  exit: {
    opacity: 0,
    y: -100,
    transition: {
      duration: 0.25,
    },
  },
};
