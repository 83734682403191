import { FlagWithCountry } from "../../../components/flags/FlagWithCountry";
import { Country } from "../../../i18n";
import "./MainCountriesOfOperation.scss";

export const MainCountriesOfOperation = ({
  countries,
}: {
  countries: string[];
}) => {
  return (
    <div className="main-countries-of-operation">
      {countries.map((country) => (
        <FlagWithCountry
          key={country}
          country={country as Country}
          height={20}
          rounded
        />
      ))}
    </div>
  );
};
