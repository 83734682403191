import { BusinessModelDisplay } from "../../../components/businessModel/BusinessModelDisplay";
import { DataList } from "../../../components/datalist/DataList";
import { DataListItem } from "../../../components/datalist/DataListItem";
import { MCCInfoList } from "../../../components/mcc/MCCInfoList";
import { T } from "../../../components/translation/T";
import { CompanyKyc } from "../../../data/models/caseTypes";

export const CompleteGeneralKyc = ({ kyc }: { kyc: CompanyKyc }) => {
  const {
    businessChannel,
    mcc,
    mostSoldItem,
    businessModel,
    productDescription,
    hasNonWorldlineBusiness,
    nonWorldlineBusiness,
    regulatedEntity,
    regulatedBy,
    licensedEntity,
    licensedBy,
  } = kyc;
  return (
    <div className="complete-general-kyc">
      <div>
        <DataList align="left">
          <DataListItem
            text="Business channel"
            value={businessChannel ? <T>{businessChannel}</T> : undefined}
          />
          <DataListItem
            text="Business model"
            value={<BusinessModelDisplay businessModel={businessModel} />}
          />
          <DataListItem text="Most sold item" value={mostSoldItem} />
        </DataList>

        <div className="divider" />

        <DataList type="vertical">
          <DataListItem text="MCC" value={<MCCInfoList mccs={mcc} />} />
          <DataListItem text="Product description" value={productDescription} />
          {hasNonWorldlineBusiness && (
            <DataListItem
              text="Business not processed via the WL entity"
              value={nonWorldlineBusiness}
            />
          )}
          {regulatedEntity && (
            <DataListItem text="Regulated by" value={regulatedBy} />
          )}
          {licensedEntity && (
            <DataListItem text="Licensed by" value={licensedBy} />
          )}
        </DataList>
      </div>
    </div>
  );
};
