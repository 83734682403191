import { DashboardDocument } from "../../data/dataDashboard";
import { useEffect, useRef, useState } from "react";
import styles from "./Document.module.scss";
import { MdAttachFile } from "react-icons/md";

import cx from "classnames";
import { DocumentPopover } from "./DocumentPopover";
import { createPortal } from "react-dom";
import { Backdrop } from "../backdrop/Backdrop";

interface Props {
  documents?: DashboardDocument[];
  documentSearchResult: string[];
}

const startPositon = {
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  bottom: 0,
};

export const isDocumentMatch = (
  document: DashboardDocument,
  searchResult: string[]
) => {
  if (document.refNumber) {
    return (
      searchResult.includes(document.refNumber) ||
      searchResult.includes(document.filename)
    );
  }
  return searchResult.includes(document.filename);
};

export const DocumentChip: React.FunctionComponent<Props> = ({
  documents,
  documentSearchResult,
}) => {
  const [chipPosition, setChipPosition] = useState(startPositon);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [highlight, setHighlight] = useState(false);

  const backdropRef = useRef<Element>(
    document.getElementById("backdrop-portal")!
  );
  const popoverRef = useRef<Element>(
    document.getElementById("popover-portal")!
  );

  const chipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!documents) return;
    const hasDocumentMatch = () => {
      if (documents.length === 0) return false;
      return documents.some((doc) =>
        isDocumentMatch(doc, documentSearchResult)
      );
    };

    setHighlight(hasDocumentMatch());
  }, [documents, documentSearchResult]);

  const onChipClick = () => {
    if (!documents || documents.length === 0) return false;
    const DOMRect = chipRef.current?.getBoundingClientRect() || startPositon;
    setChipPosition(DOMRect);
    setPopoverOpen(true);
  };

  const chipContainerClass = cx(styles.chipContainer, {
    [styles.highlight]: highlight,
  });

  const chipClass = cx(styles.chip, {
    [styles.chipDisabled]: !documents || documents.length === 0,
  });

  return (
    <div className={styles.root}>
      <div className={chipContainerClass} onClick={onChipClick} ref={chipRef}>
        <div className={chipClass}>
          <MdAttachFile />
          {documents?.length || 0}
        </div>
        {createPortal(
          <DocumentPopover
            open={popoverOpen}
            documents={documents}
            chipPosition={chipPosition}
            documentSearchResult={documentSearchResult}
          />,
          popoverRef.current
        )}
      </div>
      {createPortal(
        <Backdrop
          open={popoverOpen}
          onClick={() => setPopoverOpen(!popoverOpen)}
        />,
        backdropRef.current
      )}
    </div>
  );
};
