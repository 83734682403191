import cx from "classnames";
import { DateTime, Settings } from "luxon";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { LogoutWarning } from "./components/logoutWarning/LogoutWarning";
import { RouteListener } from "./components/RouteListener";
import { UserRole, UserSession } from "./data/dataAuth";
import { CompanyPage, COMPANY_PAGE_URL } from "./pages/company/CompanyPage";
import { LoginPage } from "./pages/login/LoginPage";
import { Logout, LOGOUT_ROUTE } from "./pages/login/Logout";
import { CasePage, CASE_ROUTE } from "./pages/sales/case/CasePage";
import {
  DashboardPage,
  DASHBOARD_ROUTE,
} from "./pages/sales/Dashboard/DashboardPage";
import {
  OwnerReviewPage,
  OWNER_REVIEW_PAGE,
} from "./pages/sales/signoff/AssociateReview/OwnerReviewPage";
import { PersistSettings } from "./PersistSettings";
import { themeState, viewState } from "./state/uiState";
import { userState } from "./state/userState";
import i18n from "./i18n";
import {
  MissingTranslationsPage,
  TRANSLATIONS_PAGE,
} from "./pages/manager/translation/MissingTranslations";
import {
  UserManagementPage,
  USER_MANAGEMENT_PAGE,
} from "./pages/manager/users/UserManagementPage";
import {
  EditUserPage,
  EDIT_USER_PAGE,
} from "./pages/manager/users/EditUserPage";
import {
  MccAdministrationPage,
  MCC_ADMIN_PAGE,
} from "./pages/manager/mcc/MccAdministrationPage";
import { translationManagementState } from "./state/translationState";
import { TranslationManagement } from "./components/translation/TranslationManagement";
import {
  EmailPreview,
  EMAIL_PREVIEW_URL,
} from "./pages/communication/emailPreview/EmailPreview";
import {
  CommunicationList,
  COMMUNICATION_LIST_URL,
} from "./pages/communication/communicationList/CommunicationList";
import {
  TextPreview,
  TEXT_PREVIEW_URL,
} from "./pages/communication/textPreview/TextPreview";
import {
  CaseReviewPage,
  CASE_REVIEW_ROUTE,
} from "./pages/sales/review/CaseReviewPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import {
  CaseCompletePage,
  CASE_COMPLETE_PAGE,
} from "./pages/sales/complete/CaseCompletePage";
import {
  OnboardingPage,
  ONBOARDING_PAGE_ROUTE,
} from "./pages/onboarding/OnboardingPage";
import {
  OnboardingOwnerPage,
  ONBOARDING_OWNER_PAGE_ROUTE,
} from "./pages/onboarding/OnboardingOwnerPage";
import { ErrorBoundary } from "@sentry/react";

import { MOCK_ROUTE, MockPage } from "./pages/MockPage";

Settings.defaultLocale = i18n.language;

interface Props {
  user: UserSession | null;
  role?: UserRole;
}

const SecureRoute: React.FunctionComponent<Props> = (props) => {
  const user = useRecoilValue(userState);
  if (!user) {
    return <Navigate to={"/"} />;
  }

  if (props.role && user.role !== props.role) {
    return <Navigate to={"/"} />;
  }

  const isPast = DateTime.now() > DateTime.fromISO(user.expiry);
  if (isPast) {
    return <Navigate to={"/"} />;
  }
  return <Outlet />;
};

function App() {
  const theme = useRecoilValue(themeState);
  const view = useRecoilValue(viewState);

  const user = useRecoilValue(userState);

  const { active: isTranslationEditorActive } = useRecoilValue(
    translationManagementState
  );

  const isTranslationManagementActive =
    isTranslationEditorActive && user && user.role === UserRole.MANAGER;

  return (
    <BrowserRouter>
      <ErrorBoundary showDialog={false}>
        <RouteListener />
        <LogoutWarning />
        <PersistSettings />
        <div className={theme}>
          <div className={cx("app", view)}>
            <div id="tooltip-portal" />
            <div id="backdrop-portal" />
            <div id="popover-portal" />
            {isTranslationManagementActive && <TranslationManagement />}
            <main>
              <Routes>
                <Route element={<SecureRoute user={user} />}>
                  <Route path={MOCK_ROUTE} element={<MockPage />} />
                  <Route path={CASE_ROUTE} element={<CasePage />} />

                  <Route
                    path={CASE_REVIEW_ROUTE}
                    element={<CaseReviewPage />}
                  />

                  <Route
                    path={CASE_COMPLETE_PAGE}
                    element={<CaseCompletePage />}
                  />

                  <Route
                    path={OWNER_REVIEW_PAGE}
                    element={<OwnerReviewPage />}
                  />

                  <Route path={COMPANY_PAGE_URL} element={<CompanyPage />} />

                  <Route path={DASHBOARD_ROUTE} element={<DashboardPage />} />
                </Route>

                <Route
                  element={<SecureRoute user={user} role={UserRole.MANAGER} />}
                >
                  <Route
                    path={USER_MANAGEMENT_PAGE}
                    element={<UserManagementPage />}
                  />
                  <Route path={EDIT_USER_PAGE} element={<EditUserPage />} />

                  <Route
                    path={TRANSLATIONS_PAGE}
                    element={<MissingTranslationsPage />}
                  />
                  <Route
                    path={MCC_ADMIN_PAGE}
                    element={<MccAdministrationPage />}
                  />

                  <Route
                    path={COMMUNICATION_LIST_URL}
                    element={<CommunicationList />}
                  />
                  <Route path={EMAIL_PREVIEW_URL} element={<EmailPreview />} />
                  <Route path={TEXT_PREVIEW_URL} element={<TextPreview />} />
                </Route>

                <Route
                  path={ONBOARDING_PAGE_ROUTE}
                  element={<OnboardingPage />}
                />
                <Route
                  path={ONBOARDING_OWNER_PAGE_ROUTE}
                  element={<OnboardingOwnerPage />}
                />
                <Route path={LOGOUT_ROUTE} element={<Logout />} />
                <Route index element={<LoginPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </main>
          </div>
        </div>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
