import React from "react";
import cx from "classnames";
import { RadioUnchecked } from "./RadioUnchecked";
import { RadioChecked } from "./RadioChecked";
import "./Radio.scss";

interface Props {
  className?: string;
  checked: boolean;
  disabled?: boolean;
}

export const Radio: React.FunctionComponent<Props> = ({
  className,
  checked,
  disabled,
}) => {
  return (
    <div
      className={cx("radio", className, {
        checked,
        "radio--disabled": disabled,
      })}
    >
      <RadioUnchecked />
      <RadioChecked />
    </div>
  );
};
