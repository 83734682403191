import { useState, useEffect, useCallback } from "react";

import { PermissionError } from "../../components/permissionError/PermissionError";
import { Retry } from "../../components/retry/Retry";
import { OnboardingCaseId } from "../../data/models/CommonTypes";
import { Case } from "../../data/models/caseTypes";
import { useIsMountedRef } from "../../hooks/useIsMounted";
import { useLoadOnboarding } from "./useLoadOnboarding";

interface Props extends React.PropsWithChildren {
  id?: OnboardingCaseId;
  onChange?: (outreachCase: Case) => void;
}

export const OnboardingLoadWrapper: React.FunctionComponent<Props> = ({
  id,
  onChange,
  children,
}) => {
  const mounted = useIsMountedRef();
  const [missingPermission, setMissingPermission] = useState(false);

  const { outreachCase, loadStatus, errorCode, reload } = useLoadOnboarding(id);

  useEffect(() => {
    if (outreachCase) {
      onChange?.(outreachCase);
    }
  }, [outreachCase, onChange]);

  useEffect(() => {
    if (errorCode === 403) {
      setMissingPermission(true);
    }
  }, [errorCode]);

  const retry = useCallback(() => {
    setTimeout(() => {
      if (!mounted.current) {
        return;
      }
      if (!id) {
        return;
      }
      reload(id);
    }, 300);
  }, [reload, id, mounted]);

  if (!id) {
    return <div>Onboarding case id is missing from URL</div>;
  }

  return (
    <>
      {missingPermission ? (
        <PermissionError />
      ) : (
        <Retry retry={retry} status={loadStatus}>
          {children}
        </Retry>
      )}
    </>
  );
};
