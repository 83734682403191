import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  ResetRegisteredTranslationsSelector,
  translationManagementState,
  TranslationsSelector,
  translationStateActiveSelector,
  translationStateSelector,
} from "../../state/translationState";
import { NewOverlay } from "../overlay/NewOverlay";
import { TranslationManagementOverlay } from "./TranslationManagementOverlay";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "../interactions/Buttons/Button";
import { AnimatePresence, motion } from "framer-motion";
import { TranslationList } from "./TranslationList";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Language } from "../../i18n";
import "./TranslationManagement.scss";
import { userState } from "../../state/userState";
import { UserRole } from "../../data/dataAuth";
import { dataTranslation } from "../../data/dataTranslation";

export interface TranslationAlternative {
  value: string;
  translated?: string;
}

export const TranslationManagement: React.FunctionComponent = () => {
  const [
    { translation, registeredTranslations, translations: translationTexts },
  ] = useRecoilState(translationManagementState);
  const setActive = useSetRecoilState(translationStateActiveSelector);

  const resetRegisteredTranslations = useSetRecoilState(
    ResetRegisteredTranslationsSelector
  );
  const setTranslation = useSetRecoilState(translationStateSelector);

  const [, setTranslationTexts] = useRecoilState(TranslationsSelector);

  const [showList, setShowList] = useState(true);
  const location = useLocation();
  const { i18n } = useTranslation();
  const user = useRecoilValue(userState);
  const isAdmin = user?.role === UserRole.MANAGER;

  const refreshTranslations = useCallback(
    () =>
      dataTranslation
        .loadTranslations(i18n.language as Language, true)
        .then(setTranslationTexts),
    [i18n.language, setTranslationTexts]
  );

  useEffect(() => {
    // TODO: need to fix this so a user can refresh translations without an admin session
    refreshTranslations();
  }, [refreshTranslations, i18n.language, isAdmin]);

  const translationText = useMemo(
    () => translationTexts?.find((t) => t.key === translation?.selected),
    [translation, translationTexts]
  );

  const alternatives = useMemo(
    () =>
      translation?.alternatives?.map(
        (value): TranslationAlternative => ({
          value,
          translated: translationTexts?.find((t) => t.key === value)?.value,
        })
      ),
    [translation, translationTexts]
  );

  const handleClose = async () => {
    refreshTranslations()
      .catch((error: Error) => {
        console.log(error);
      })
      .finally(() => setTranslation(undefined));
  };

  useEffect(() => {
    resetRegisteredTranslations();
  }, [location.pathname, resetRegisteredTranslations]);

  return (
    <div className="translation-management">
      {/* <TranslationManagementBanner /> */}
      <NewOverlay onClose={handleClose} open={!!translation} width={800}>
        {translation && (
          <TranslationManagementOverlay
            translationText={translationText}
            onClose={handleClose}
            onRefresh={refreshTranslations}
            alternatives={alternatives}
            selected={translation.selected}
          />
        )}
      </NewOverlay>
      <div className="translation-menu">
        <AnimatePresence>
          {showList && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{
                opacity: { type: "tween", duration: 0.15 },
                y: { type: "spring", duration: 0.5 },
              }}
              key="list"
            >
              <TranslationList
                onClose={() => setShowList(false)}
                translationTexts={translationTexts}
              />
            </motion.div>
          )}
          {!showList && (
            <div className="list-buttons" key="buttons">
              <motion.div layoutId="translation-list-button">
                <Button ghost onClick={() => setShowList(true)}>
                  View{" "}
                  {registeredTranslations.length > 0
                    ? registeredTranslations.length
                    : "all"}{" "}
                  translations on page
                </Button>
              </motion.div>
              <motion.div layoutId="translation-exit-button">
                <Button
                  className="danger-button"
                  block
                  onClick={() => setActive(false)}
                >
                  Exit translation mode
                </Button>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
