import { cleanNonDigits } from "../../verticalNav/CompanySearchOverlay";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

const SIREN_LENGTH = 9;

export class SirenValidator extends BaseValidator {
  name = "Siren validator";

  validate(value: string): Promise<ValidationResponse> {
    const cleanValue = cleanNonDigits(value);
    return new Promise<ValidationResponse>((resolve) => {
      if (validateSiren(cleanValue)) {
        resolve({
          status: VALIDATION_STATE.SUCCESS,
        });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: cleanValue.length < SIREN_LENGTH,
        });
      }
    });
  }
}

export function validateSiren(siren: string) {
  const length = siren.length;
  if (length !== SIREN_LENGTH) {
    return false;
  }

  let total = 0;
  for (let i = 0; i < SIREN_LENGTH; i++) {
    let value = Number(siren[i]);
    if (i % 2 !== 0) {
      value *= 2;
    }
    total += value > 9 ? value - 9 : value;
  }
  return (total % 10 === 0);
}
