import { useCallback, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FieldSet } from "../../../../components/fieldSet/FieldSet";
import { Form } from "../../../../components/form/Form";
import { hasRealErrors } from "../../../../components/form/FormHelpers";
import { ScrollPositionAnchor } from "../../../../components/scrollPosition/ScrollPositionAnchor";
import { clearCas } from "../../../../components/utils";
import { BusinessChannel, CompanyKyc } from "../../../../data/models/caseTypes";
import { Status } from "../../../../data/types";
import { caseErrorState, caseSaveState } from "../../../../state/caseSaveState";
import { LinkAnchors } from "../CaseEdit";
import { CaseEditError } from "../CaseEditError";
import { CaseSaveError, handleError } from "../CasePage";
import { FormName } from "../menus/CaseEditMenu";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { NumberInput } from "../../../../components/form/NumberInput";
import { caseMainState } from "../../../../state/caseMainState";
import { caseKycState } from "../../../../state/caseKycState";
import { ButtonPaneWithLabel } from "../../../../components/interactions/Buttons/ButtonPaneInput";
import { RangeInput } from "../../../../components/form/RangeInput";
import { MinValidator } from "../../../../components/form/validators/MinValidator";
import { useCaseEditStatus } from "../../../../hooks/useCaseEditStatus";
import { AnimateHeightMotion } from "../../../../components/animate/AnimateHeightMotion";
import { TextInput } from "../../../../components/form/TextInput";
import { dataOutreach } from "../../../../data/dataOutreach";
import { CountryCurrency } from "../../../../i18n";
import { caseCompanyState } from "../../../../state/caseCompanyState";
import { CountrySelect } from "../../../../components/countrySelect/CountrySelect";
import { CountryList } from "../../../../components/countryList/CountryList";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { MaxValidator } from "../../../../components/form/validators/MaxValidator";
import { useEstimatedTransactionCount } from "./useEstimatedTransactionCount";
import { useEstimatedTurnover } from "./useEstimatedTurnover";
import { GreaterThanValidator } from "../../../../components/form/validators/GreaterThanValidator";
import { LessThanValidator } from "../../../../components/form/validators/LessThanValidator";
import { LegalEntityType } from "../../../../data/models/LegalEntityTypes";
import { companyKycQueue } from "../../../../data/queues/CompanyKycQueue";
import { ServerError } from "../../../../network/API";
import { T } from "../../../../components/translation/T";

export function FinancialData() {
  const [error, setError] = useState<CaseSaveError | null>(null);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const setDataSaved = useSetRecoilState(caseSaveState);
  const setDataError = useSetRecoilState(caseErrorState);
  const { id } = useRecoilValue(caseMainState);
  const { country, legalEntityType } = useRecoilValue(caseCompanyState);
  const [kycData, setKycData] = useRecoilState(caseKycState);
  const [, disabled] = useCaseEditStatus(status);
  const countryRef = useRef<HTMLDivElement>(null);

  const averageTransaction = getAverageTransaction(
    kycData.rangeOfTransactionsMin,
    kycData.rangeOfTransactionsMax
  );

  const transactionCountSuggestion = useEstimatedTransactionCount(
    averageTransaction,
    kycData.estimatedAnnualTurnover
  );
  const turnoverSuggestion = useEstimatedTurnover(
    country,
    averageTransaction,
    kycData.estimatedTransactionsPerYear
  );

  const prevSaved = useRef<string>(JSON.stringify(clearCas(kycData)));

  const onClose = useCallback(() => {
    setError(null);
  }, []);

  const onSave = useCallback(
    (updatedKyc: CompanyKyc) => {
      const stringCopy = JSON.stringify(clearCas(updatedKyc));
      if (prevSaved.current === stringCopy) {
        return;
      } else {
        prevSaved.current = stringCopy;
      }

      companyKycQueue.save(
        id,
        { ...updatedKyc },
        (err: ServerError<CompanyKyc> | null, response?: CompanyKyc) => {
          if (err) {
            prevSaved.current = "";
            setStatus(Status.DEFAULT);
            handleError(err, setError);
            setDataError((dataErrors) =>
              dataErrors.concat({
                date: new Date(),
              })
            );
          }

          if (response) {
            setStatus(Status.DEFAULT);
            setKycData((prevData) => ({ ...prevData, cas: response.cas }));
            setDataSaved((dataSaved) =>
              dataSaved.concat({
                date: new Date(),
              })
            );
          }
        }
      );
    },
    [id, setDataError, setDataSaved, setKycData]
  );

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(onSave, 500);
  }, [onSave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataOutreach
        .claim(id)
        .then(() => onSave(kycData))
        .catch(onSave); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [id, onSave, kycData]);

  const onUpdate = useCallback(
    <T extends keyof CompanyKyc>(value: CompanyKyc[T], key: T) => {
      setKycData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    },
    [setKycData]
  );

  const hideListedEntityInputs =
    legalEntityType === LegalEntityType.ASSOCIATION ||
    legalEntityType === LegalEntityType.SOLE_PROPRIETARY;

  return (
    <Form
      onSaveTrigger={(_, form) => {
        const realErrors = hasRealErrors(form);
        if (!realErrors) {
          onSave(kycData);
        }
      }}
      name={FormName.FINANCIAL_DATA}
    >
      <div className="financial-data">
        <ScrollPositionAnchor id={LinkAnchors.FINANCIAL_DATA.anchor} />

        <CaseEditError
          error={error}
          setError={setError}
          retry={retry}
          onClose={onClose}
          reclaimAndSave={reclaimAndSave}
        />
        <FieldSet header={<T>{LinkAnchors.FINANCIAL_DATA.name}</T>}>
          <div>
            <RangeInput
              label="Estimated range of transaction value"
              values={[
                kycData.rangeOfTransactionsMin,
                kycData.rangeOfTransactionsMax,
              ]}
              name="rangeOfTransactions"
              onChange={(min, max) => {
                setKycData((prevData) => ({
                  ...prevData,
                  rangeOfTransactionsMin: min,
                  rangeOfTransactionsMax: max,
                }));
              }}
              sharedValidators={[
                new GreaterThanValidator(0, "Value must be greater than 0"),
              ]}
              minValidators={[
                new RequiredValidator("min value is required"),
                ...(typeof kycData.rangeOfTransactionsMax !== "undefined"
                  ? [
                      new LessThanValidator(
                        kycData.rangeOfTransactionsMax,
                        "Must be less than max value"
                      ),
                    ]
                  : []),
              ]}
              maxValidators={[
                new RequiredValidator("max value is required"),
                ...(typeof kycData.rangeOfTransactionsMin !== "undefined"
                  ? [
                      new GreaterThanValidator(
                        kycData.rangeOfTransactionsMin,
                        "Must be greater than min value"
                      ),
                    ]
                  : []),
              ]}
              suffix={CountryCurrency[country]}
              min={0}
              disabled={disabled}
            />
          </div>
          <div>
            <RangeInput
              label="Estimated WL processed value"
              values={[
                kycData.rangeOfWlTransactionsMin,
                kycData.rangeOfWlTransactionsMax,
              ]}
              name="rangeOfWlTransactions"
              onChange={(min, max) => {
                setKycData((prevData) => ({
                  ...prevData,
                  rangeOfWlTransactionsMin: min,
                  rangeOfWlTransactionsMax: max,
                }));
              }}
              sharedValidators={[
                new GreaterThanValidator(0, "Value must be greater than 0"),
              ]}
              minValidators={[
                new RequiredValidator("min value is required"),
                ...(typeof kycData.rangeOfWlTransactionsMax !== "undefined"
                  ? [
                      new LessThanValidator(
                        kycData.rangeOfWlTransactionsMax,
                        "Must be less than max value"
                      ),
                    ]
                  : []),
              ]}
              maxValidators={[
                new RequiredValidator("max value is required"),
                ...(typeof kycData.rangeOfWlTransactionsMin !== "undefined"
                  ? [
                      new GreaterThanValidator(
                        kycData.rangeOfWlTransactionsMin,
                        "Must be greater than min value"
                      ),
                    ]
                  : []),
              ]}
              suffix={CountryCurrency[country]}
              min={0}
              disabled={disabled}
            />
          </div>
          <div className="tablet-columns">
            <div>
              <NumberInput
                label="Estimated number of transactions per year"
                value={kycData.estimatedTransactionsPerYear}
                name="estimatedTransactionsPerYear"
                onChange={(value) =>
                  onUpdate(value, "estimatedTransactionsPerYear")
                }
                validators={[
                  new RequiredValidator(
                    "Estimated number of transactions is required"
                  ),
                ]}
                disabled={disabled}
                min={1}
                max={1000000000}
                message={transactionCountSuggestion}
                hint={transactionCountSuggestion}
              />
            </div>
            <div>
              <NumberInput
                label="Estimated annual turnover"
                value={kycData.estimatedAnnualTurnover}
                name="estimatedAnnualTurnover"
                onChange={(value) => onUpdate(value, "estimatedAnnualTurnover")}
                validators={[
                  new RequiredValidator(
                    "Estimated annual turnover is required"
                  ),
                ]}
                disabled={disabled}
                suffix={CountryCurrency[country]}
                min={1}
                message={turnoverSuggestion}
                hint={turnoverSuggestion}
              />
            </div>
          </div>

          {kycData.businessChannel !== BusinessChannel.POS && (
            <>
              <div ref={countryRef}>
                <CountrySelect
                  className="compact-input"
                  label={
                    "Significant geographical markets of operation (20% or more of turnover)"
                  }
                  name={"country"}
                  placeholder={"Select country..."}
                  filteredCountries={kycData.mainCountriesOperates}
                  onChange={(country) => {
                    if (!country) {
                      return null;
                    }
                    setKycData((prevData) => ({
                      ...prevData,
                      mainCountriesOperates: [
                        ...prevData.mainCountriesOperates,
                        country,
                      ],
                    }));
                  }}
                  disabled={
                    disabled || kycData.mainCountriesOperates.length >= 5
                  }
                  value={kycData.mainCountriesOperates.toString()}
                  validators={[new RequiredValidator("Country is required")]}
                />
                <div className="m-bottom-30">
                  <CountryList
                    disabled={disabled}
                    countries={kycData.mainCountriesOperates}
                    onChange={(countries) => {
                      if (disabled) return;
                      setKycData((prevData) => ({
                        ...prevData,
                        mainCountriesOperates: countries,
                      }));
                    }}
                  />
                  <HiddenInput
                    value={kycData.mainCountriesOperates.length}
                    name="mainCountriesOperates"
                    validators={[
                      new MinValidator(
                        1,
                        "Main countries of operation is required"
                      ),
                      new MaxValidator(5, "Maximum number of countries is 5"),
                    ]}
                    label="Main countries of operation"
                    scrollToRef={countryRef}
                  />
                </div>
              </div>

              <ButtonPaneWithLabel
                label="Will there be third party payments?"
                value={kycData.thirdPartyPayments}
                className="m-top-20"
                onChange={(value) => onUpdate(value, "thirdPartyPayments")}
                small
                buttons={[
                  {
                    text: "Yes",
                    active: kycData.thirdPartyPayments === true,
                    data: true,
                  },
                  {
                    text: "No",
                    active: kycData.thirdPartyPayments === false,
                    data: false,
                  },
                ]}
                validators={[new RequiredValidator("Required to answer")]}
                disabled={disabled}
                status={status}
              />
            </>
          )}

          {!hideListedEntityInputs && (
            <div>
              <ButtonPaneWithLabel
                label="Is the company or parent company stock listed?"
                value={kycData.listedEntity}
                className="m-top-20"
                onChange={(value) => onUpdate(value, "listedEntity")}
                small
                buttons={[
                  {
                    text: "Yes",
                    active: kycData.listedEntity === true,
                    data: true,
                  },
                  {
                    text: "No",
                    active: kycData.listedEntity === false,
                    data: false,
                  },
                ]}
                validators={[new RequiredValidator("Required to answer")]}
                disabled={disabled}
              />

              <AnimateHeightMotion presence>
                {kycData.listedEntity && (
                  <div className="tablet-columns">
                    <div>
                      <TextInput
                        name="listedIsin"
                        value={kycData.listedIsin}
                        onChange={(value) => onUpdate(value, "listedIsin")}
                        label="Isin"
                        validators={[new RequiredValidator("Isin is required")]}
                        disabled={disabled}
                      />
                    </div>
                    <div>
                      <TextInput
                        name="listedTickerName"
                        value={kycData.listedTickerName}
                        onChange={(value) =>
                          onUpdate(value, "listedTickerName")
                        }
                        label="Ticker name"
                        validators={[
                          new RequiredValidator("Ticker name item is required"),
                        ]}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                )}
              </AnimateHeightMotion>
            </div>
          )}
        </FieldSet>
      </div>
    </Form>
  );
}

const getAverageTransaction = (min?: number, max?: number) => {
  return typeof max !== "undefined" && typeof min !== "undefined"
    ? (max - min) / 2
    : 0;
};
