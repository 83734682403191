import { isArray } from "lodash";
import { Filter, defaultFilter } from "../../Store";

export const FilterUtils = {
  searchToFilter: <T extends {}>(search: string) => {
    const params = new URLSearchParams(search);
    const keys: { [key: string]: string | string[] } = {};
    params.forEach((value, key) => {
      if (key === "status" || key === "countries") {
        keys[key] = [
          ...(isArray(keys[key]) ? (keys[key] as string[]) : []),
          value,
        ];
      } else {
        keys[key] = value;
      }
    });
    return FilterUtils.paramsToFilter<T>(keys);
  },

  getQueryFromFilter: <T extends {}>(
    filter: Filter<T> = defaultFilter<T>()
  ) => {
    return `?${FilterUtils.getParams(filter).toString()}`;
  },

  paramsToFilter: <T extends {}>(params: {
    [key: string]: string | string[];
  }) => {
    const filter: { [key: string]: string | number | string[] } = { ...params };

    if (!params.page) {
      return defaultFilter<T>();
    }

    if (typeof params.page === "string") {
      filter.page = parseInt(params.page, 10);
    }
    if (params.size && typeof params.size === "string") {
      filter.size = parseInt(params.size, 10);
    }

    return filter as unknown as Filter<T>;
  },

  getParams: <T extends {}>(filter: Filter<T>) => {
    const params = new URLSearchParams();
    (Object.keys(filter) as Array<keyof typeof filter>).forEach((key) => {
      if (key === "page") {
        params.set(key, filter[key]?.toString());
      } else if (key === "status" || key === "countries") {
        if (filter[key] && !isArray(filter[key])) {
          params.append(key, filter[key] as unknown as string);
        } else {
          filter[key]?.forEach((value) => {
            params.append(key, value);
          });
        }
      } else {
        params.set(key, (filter[key] || "")?.toString());
      }
    });

    return params;
  },
};
