import React from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./App";
import { RecoilExternalStatePortal } from "./RecoilExternalStatePortal";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";
import { APP_ID } from "./network/API";
import "./i18n";
import "./ui/reset.scss";
import "./ui/typography.scss";
import "./index.scss";

Sentry.init({
  dsn: "https://b243ba9a5a834479a9b4928336635480@o4504866465185792.ingest.sentry.io/4504876976832512",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.25,
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.REACT_APP_ENV,
});

Sentry.setContext("session", {
  appId: APP_ID,
});

declare global {
  interface Window {
    maps: any;
    d3: any;
    topojson: any;
  }
}

window.maps = window.maps || {
  loaded: {},
};

window.maps.importScript = (url: string) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.addEventListener("load", () => resolve(script), false);
    script.addEventListener("error", () => reject(script), false);
    document.body.appendChild(script);
  }).catch((err) => {
    console.log("Could not import script ", url, err);
  });
};
const container = document.getElementById("root");
const root = createRoot(container!);

function AppWithCallback() {
  return (
    // <React.StrictMode>
    <RecoilRoot>
      <App />
      <RecoilExternalStatePortal />
    </RecoilRoot>
    // </React.StrictMode>
  );
}

root.render(<AppWithCallback />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
