import { AnimatePresence, motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import i18n, { Country } from "../../i18n";
import { AnimateHeightMotion } from "../animate/AnimateHeightMotion";
import { Flag } from "../flags/Flag";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { getCountryDisplayName } from "../translation/I18Utils";
import "./CountryList.scss";

interface Props {
  countries: string[];
  onChange: (countries: string[]) => void;
  disabled: boolean;
}

export const CountryList = ({ countries, onChange, disabled }: Props) => {
  const { language } = i18n;

  return (
    <AnimateHeightMotion presence>
      {countries.length > 0 && (
        <ul className="country-list">
          <AnimatePresence initial={false}>
            {countries.map((country) => (
              <motion.li
                key={country}
                layout
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                transition={{
                  duration: 0.25,
                  ease: "easeOut",
                }}
              >
                <motion.button
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.15 }}
                  type="button"
                  onClick={() =>
                    onChange(countries.filter((c) => c !== country))
                  }
                >
                  <Flag country={country as Country} />
                  <span>{getCountryDisplayName(country, language)}</span>
                  {disabled ? (
                    <MdDoNotDisturbAlt className="disabled-icon" />
                  ) : (
                    <MdClose className="close-icon" />
                  )}
                </motion.button>
              </motion.li>
            ))}
          </AnimatePresence>
        </ul>
      )}
    </AnimateHeightMotion>
  );
};
