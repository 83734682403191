import { useState } from "react";
import { Trans } from "react-i18next";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { SuccessBox } from "../../components/boxes/SuccessBox";
import { TextInput } from "../../components/form/TextInput";
import {
  ConfirmButton,
  ConfirmType,
} from "../../components/interactions/Buttons/ConfirmButton";
import { T, TCondition, TDefault } from "../../components/translation/T";
import { TransSwitch } from "../../components/translation/TransSwitch";
import { dataOnboarding } from "../../data/dataOnboarding";
import { CaseClosedReason, CaseStatus } from "../../data/models/caseTypes";
import { OnboardingCaseId } from "../../data/models/CommonTypes";
import { Status } from "../../data/types";
import { useLoadCase } from "../../hooks/useLoadCase";
import { CaseStatusState } from "../../state/caseStatusState";
import { Store, STORE_KEY } from "../../Store";

interface Props {
  caseStatus: CaseStatusState;
  onboardingStatus: OnboardingStatus;
}

interface OnboardingStatus {
  isSent: boolean;
  id: OnboardingCaseId | undefined;
}

export const CloseCase = ({ caseStatus, onboardingStatus }: Props) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>(
    Store.getValue<string>(STORE_KEY.STORE_ONBOARDING_NAME)
  );
  const { reload } = useLoadCase();

  const isDisabled = !onboardingStatus.id || !onboardingStatus.isSent;

  const onNameChange = (value: string) => {
    setName(value);
    Store.setValue(STORE_KEY.STORE_ONBOARDING_NAME, value);
  };

  const closeCase = () => {
    if (!onboardingStatus.id) return;

    if (!name) {
      setError("Please enter a name");
      return;
    }
    setStatus(Status.PENDING);
    dataOnboarding
      .close(onboardingStatus.id, name)
      .then(() => {
        setStatus(Status.SUCCESS);
        reload(caseStatus.id, caseStatus.currentUser.id);
      })
      .catch(() => {
        setError();
      });
  };

  const setError = (error?: string) => {
    setStatus(Status.ERROR);
    setErrorText(error);

    setTimeout(() => {
      setStatus(Status.DEFAULT);
    }, 3000);
  };

  const isClosed = caseStatus.status === CaseStatus.CLOSED;

  const isFinalized =
    caseStatus.caseClosedReason === CaseClosedReason.CASE_FINALIZED;

  const isDiscarded =
    caseStatus.caseClosedReason === CaseClosedReason.DISCARDED;

  if (isDiscarded)
    return (
      <ErrorBox>
        <Trans>
          <b>This case was discarded</b>.
        </Trans>
      </ErrorBox>
    );

  return (
    <div className="close-case m-top-40">
      <AnimateHeight name={isClosed ? "closed" : "open"}>
        {isFinalized ? (
          <SuccessBox relative>
            <Trans>
              <b>All done!</b> This case is finalized and closed.
            </Trans>
            {/* <div>
              <span className="onboarding-status-span">OPERATIONS</span>
              <span className="onboarding-status-span">2023-03-28</span>
            </div> */}
          </SuccessBox>
        ) : (
          <>
            <p>
              <b>
                <TransSwitch>
                  <TCondition condition={isDisabled}>
                    Send case to onboarding before finalizing it.
                  </TCondition>
                  <TDefault>All done? Mark the case as finalized.</TDefault>
                </TransSwitch>
              </b>
            </p>
            <TextInput
              disabled={isDisabled}
              className="compact-input"
              label="Name"
              value={name}
              onChange={onNameChange}
            />
            <ConfirmButton
              onClick={closeCase}
              status={isDisabled ? Status.DISABLED : status}
              small={false}
              removeButtonText="Yes, close"
              confirmType={ConfirmType.SUCCESS}
            >
              <T>Finalize case</T>
            </ConfirmButton>
            {status === Status.ERROR && errorText && (
              <div className="error-message">
                <T>{errorText}</T>
              </div>
            )}
          </>
        )}
      </AnimateHeight>
    </div>
  );
};
